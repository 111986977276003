//@ts-check
//@ts-ignore
SubmissionCandidateAssessmentResultCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "Server", "Translate", "ToasterService", "multiSelect", "PopupService", "drawProgressCircle"];
angular.module('app').controller('SubmissionCandidateAssessmentResultCtrl', SubmissionCandidateAssessmentResultCtrl);

function SubmissionCandidateAssessmentResultCtrl($scope, $rootScope, $state, $stateParams, $timeout, Server, Translate, ToasterService, multiSelect, PopupService, drawProgressCircle) {
    console.log('SubmissionCandidateAssessmentResultCtrl');
    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user)) {
          $state.go('submission', { candidateId: $stateParams.candidateId });
        }
    });

    $scope.selectedAssessment = undefined;
    $scope.candidate = undefined;

    $scope.setAssessment = function() {
        if ($scope.candidate && $scope.candidate.assessments) {
            $scope.selectedAssessment = $scope.candidate.assessments.find(a => a.assessmentId === $stateParams.assessmentId);
            computeQuestionsRatings($scope.selectedAssessment);
        }
    }

    function computeQuestionsRatings(assessment) {
        if (!assessment.questions) {
            return;
        }
        for(const question of assessment.questions) {
            var rating;
            if (assessment.reviews && assessment.reviews[$rootScope.user._id] && assessment.reviews[$rootScope.user._id][question._id]) {
                rating = assessment.reviews[$rootScope.user._id][question._id];
            }
            question.id = question._id;
            question.rating = rating;
        }
    }

    $scope.rate = function(question, rate) {
        question.rating = rate;
        const reqBody = {
            question
        };
        Server.post('candidates/'+$stateParams.candidateId+'/assessment/'+$stateParams.assessmentId+'/rating', reqBody)
            .then(res => {
                ToasterService.success('rating_saved');
            })
            .catch(err => {
                ToasterService.failure(err, 'err_0_error_occurred');
            })
    }

    $scope.$on('onCandidateData', function (event, candidate) {
        if (candidate) {
            $scope.candidate = candidate;
            $scope.setAssessment();
        }
    });

    $scope.setAssessment();
}
