angular.module('analytics.mixpanel').config(['$mixpanelProvider', function($mixpanelProvider) {
  $mixpanelProvider.apiKey(window.__env.mixpanelToken);
}]);

angular.module('app.services').factory('EventTracker', ["$rootScope", "$mixpanel", function($rootScope, $mixpanel) {
  let exportedFunctions = {};

  exportedFunctions.trackCampaignCandidateAdd = function() {
    try {
      $mixpanel.track(
        'Click + Add a candidate',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'button-add-candidate', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignCandidateSave = function() {
    try {
      $mixpanel.track(
        'Click Save (add a candidate)',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'button-add-candidate', event_category3: 'save' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignAddUserGroup = function() {
    try {
      $mixpanel.track(
        'Create a campaign → Select a user group in the campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'settings', event_category3: 'create', event_category4: 'add-user-group' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignFinish = function() {
    try {
      $mixpanel.track(
        'Click Finish in campaign settings',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'settings', event_category3: 'button-finish', event_category4: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignGear = function() {
    try {
      $mixpanel.track(
        'Click ⚙️',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'options-dropdown', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignDuplicate = function() {
    try {
      $mixpanel.track(
        'Click Duplicate campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'options-dropdown', event_category3: 'duplicate' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignEdit = function() {
    try {
      $mixpanel.track(
        'Click Edit Campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'options-dropdown', event_category3: 'edit' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignExport = function() {
    try {
      $mixpanel.track(
        'Click Export Data',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'options-dropdown', event_category3: 'export-data' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPause = function() {
    try {
      $mixpanel.track(
        'Click Pause Campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'options-dropdown', event_category3: 'pause' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJob = function() {
    try {
      $mixpanel.track(
        'Click Post your job offer',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJobCareerPage = function() {
    try {
      $mixpanel.track(
        'Post your job offer : Career page (at least one is ticked) ',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'career-page', event_category4: 'check' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJobHRMarketing = function() {
    try {
      $mixpanel.track(
        'Post your job offer : HR Marketing > Form is send / Click on button send',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'hr-marketing', event_category4: 'send' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJobInviteEmail = function() {
    try {
      $mixpanel.track(
        'Post your job offer : Invite by email > Click on button send',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'invite-email', event_category4: 'send' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJobPublicLink = function() {
    try {
      $mixpanel.track(
        'Post your job offer : Click Copy public link',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'public-link', event_category4: 'copy' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignPostJobQrCode = function() {
    try {
      $mixpanel.track(
        'Post your job offer : Download the QR code',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'job-posting', event_category3: 'qr-code', event_category4: 'download' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignSuperMatch = function() {
    try {
      $mixpanel.track(
        'Automation : Tick on SuperMatch',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'settings', event_category3: 'automation', event_category4: 'supermatch-check' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignThreshold = function() {
    try {
      $mixpanel.track(
        'Automation : Tick Selection Threshold',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'settings', event_category3: 'automation', event_category4: 'threshold-check' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignVonq = function () {
    try {
      $mixpanel.track(
        'Click Posting Marketplace',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'vonq-marketplace', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackVonqAddCart = function() {
    try {
      $mixpanel.track(
        'Click to get a job ad',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'vonq-marketplace', event_category3: 'cart-add-product' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackVonqCheckout = function() {
    try {
      $mixpanel.track(
        'Click Checkout',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'vonq-marketplace', event_category3: 'payment', event_category4: 'started' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackVonqPaySuccess = function() {
    try {
      $mixpanel.track(
        'Purchase a job ad',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'campaign', event_category2: 'vonq-marketplace', event_category3: 'payment', event_category4: 'done' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignCandidateView = function() {
    try {
      $mixpanel.track(
        'Click on Candidate Name',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'dashboard-campaigns', event_category2: 'candidate-card', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignCandidates = function() {
    try {
      $mixpanel.track(
        'Click on Campaign to see candidate data base',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'dashboard-campaigns', event_category2: 'campaign-card' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignNew = function() {
    try {
      $mixpanel.track(
        'Create a new campaign (+ button)',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'dashboard-campaigns', event_category2: 'button-new' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignView = function() {
    try {
      $mixpanel.track(
        'Click View campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'dashboard-campaigns', event_category2: 'options-dropdown', event_category3: 'view' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackCandidateAdd = function() {
    try {
      $mixpanel.track(
        'Click Add Candidates',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'candidate-page', event_category2: 'csv-import', event_category3: 'import' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCandidateCsvImport = function() {
    try {
      $mixpanel.track(
        'Click Import the file (csv)',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'candidate-page', event_category2: 'csv-import', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCandidateStageChange = function() {
    try {
      $mixpanel.track(
        'Change candidate stage from candidate-list',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'candidate-page', event_category2: 'candidate-item', event_category3: 'stage-change' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCampaignCandidateStageChange = function() {
    try {
      $mixpanel.track(
        'Candidate > Name Clicked > Stage dropdown is changed',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'candidate', event_category2: 'actions', event_category3: 'stage-change' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCollaboratorAddUserGroup = function() {
    try {
      $mixpanel.track(
        'Collaborators → Add a user group to an existing collaborator',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'collaborator', event_category2: 'user-group', event_category3: 'add' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackCrudListCreate = function(category1 = 'crudList') {
    try {
      $mixpanel.track(
        `Click "Create ${category1}"`,{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: category1, event_category2: 'new', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackCrudListSave = function(category1 = 'crudList') {
    try {
      $mixpanel.track(
        `Click "Save ${category1}"`,{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: category1, event_category2: 'new', event_category3: 'save' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingAdd = function() {
    try {
      $mixpanel.track(
        'Click "Add Branding"',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'button-add-branding' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingAddVideo = function() {
    try {
      $mixpanel.track(
        'In Employer Branding > Info, "add a video" & save',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'info', event_category3: 'change-video-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingLogo = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, add/change logo',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-logo-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingColor = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change color',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-color-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingBannerImage = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change image banner',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-banner-image-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingBannerVideo = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change banner video',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-banner-video-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingFavicon = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change favicon',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-favicon-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingEmailSender = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change email sender',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-email-sender-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingEmailSignature = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change email signature',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-email-signature-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackEmployerBrandingChangeBannerOpacity = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change banner opacity',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-banner-opacity-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackEmployerBrandingChangeNavbarOpacity = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change navbar opacity',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-navbar-opacity-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackEmployerBrandingChangeNavbarFontColor = function() {
    try {
      $mixpanel.track(
        'In Employer branding > brand assets, change navbar font color',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'brand-assets', event_category3: 'change-navbar-font-color-save'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCopyCareerLink = function() {
    try {
      $mixpanel.track(
        'In Employer branding > career page > copy career page link',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'copy-career-link'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCopyRssLink = function() {
    try {
      $mixpanel.track(
        'In Employer branding > copy RSS link',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'copy-rss-link'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerAddCampaign = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Add (tick) a new campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'add-campaign'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerRemoveCampaign = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Remove (untick) a campaign',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'remove-campaign'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerSetInteractiveMap = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Show interactive map is ticked ✅',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'set-interactive-map'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerSetCategoryFilter = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Show category filter is ticked ✅',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'set-category-filter'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerSetCountriesFilter = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Show countries filter is ticked ✅',{
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id,
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'set-countries-filter'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerSetCitiesFilter = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Show cities filter is ticked ✅',{
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user_id,
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'set-cities-filter'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackEmployerBrandingCareerChangeBannerTitle = function() {
    try {
      $mixpanel.track(
        'In Employer branding > Banner title is changed (something is put in the text area)',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'employer-branding', event_category2: 'career-page', event_category3: 'change-banner-title'
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackInterviewInvite = function() {
    try {
      $mixpanel.track(
        'Click Invite a candidate',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'interview-scheduler', event_category2: 'invite-candidate', event_category3: 'click' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackInterviewInviteSend = function() {
    try {
      $mixpanel.track(
        'Click "Send an invitation"',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'interview-scheduler', event_category2: 'invite-candidate', event_category3: 'send' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackMenuAnalytics = function() {
    try {
      $mixpanel.track(
        'Click "Analytics" on the menu',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'analytics' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackMenuCampaign = function() {
    try {
      $mixpanel.track(
        'Click Campaign on the menu',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'campaign' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackMenuCandidates = function() {
    try {
      $mixpanel.track(
        'Click "Candidates" on the menu',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'candidate-page' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackMenuEmployerBranding = function() {
    try {
      $mixpanel.track(
        'Click on the menu "Employer Branding"',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'employer-branding' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackMenuInterviewScheduler = function() {
    try {
      $mixpanel.track(
        'Click "Interview Scheduler" on the menu',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'iterview-scheduler' 
        },);
    } catch(e) {
      console.error(e);
    }
  }
  exportedFunctions.trackMenuTasks = function() {
    try {
      $mixpanel.track(
        'Click "Tasks" on the menu',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'navigation', event_category2: 'tasks' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  exportedFunctions.trackTaskDone = function() {
    try {
      $mixpanel.track(
        'Status Changed to ✅',{ 
          adminUserId: $rootScope?.user?.adminId || $rootScope?.user._id, 
          event_category1: 'tasks', event_category2: 'status-check' 
        },);
    } catch(e) {
      console.error(e);
    }
  }

  return exportedFunctions
}])