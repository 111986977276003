//@ts-check
angular.module('app.services').factory('authorizationService', ["$rootScope", "$q", "$location", "Server", "ToasterService", function($rootScope, $q, $location, Server, ToasterService) {
  return {
    /**
     * 
     * @param {RequiredUserRights} requiredRights 
     */
    permissionCheck: function (requiredRights) {
      const authService = this;
      const deferred = $q.defer();

      if (!$rootScope.user) {
        Server.get('users/me').then(user => {
          $rootScope.user = user;
          authService.validateAccess($rootScope.user, requiredRights, deferred);
        })
      } else {
        authService.validateAccess($rootScope.user, requiredRights, deferred);
      }

      return deferred.promise;
    },

    isAuthenticated: function () {
      if (!this.authPromise) {
          this.authPromise = this.permissionCheck({});
          this.authPromise.finally(() => {
              this.authPromise = null;
          });
      }
      return this.authPromise;
  },

    /**
     * 
     * @param { any } user
     * @param {RequiredUserRights} requiredRights
     * @param { any } deferred
     */
    validateAccess: function (user, requiredRights, deferred) {
      let hasAccess = false;
      const isAuthenticated = user && !user.error
      if (isAuthenticated) {
        hasAccess = true;
        Object.keys(requiredRights).forEach(userRight => {
          hasAccess = hasAccess && $rootScope.fns.userHasRights(userRight, requiredRights[userRight], user);
        })
      }
      if (hasAccess) {
        deferred.resolve();
      } else {
        $location.path(isAuthenticated ? '/dashboard' : '/login');
        const unregister = $rootScope.$on('$locationChangeSuccess', function (next, current) {
          ToasterService.failure({}, isAuthenticated ? 'err_107_missing_permission' : 'err_106_not_authenticated');
          deferred.resolve();
          unregister();
        });
      }
    }
  }
}])

/**
 *  @typedef RequiredUserRights
 *  @type {{ [key: string]: 'view'|'edit'|'delete'|true }}
 */