//@ts-check
/** {@link import('./list-filter.component.js) link} */

//@ts-ignore
CrudListCtrl.$inject = ["$scope", "$element", "$attrs", "$rootScope", "$state", "Server", "PopupService", "Translate", "ToasterService", "Util", "$window", "multiSelect", "matchingSliderOpts", "EventTracker"];
angular.module('app')
  .component('crudList', {
    templateUrl: '../templates/components/crud-list.component.html',
    controller: CrudListCtrl,
    bindings: {
      identifier: '@',
      translation: '<',
      properties: '<',
      items: '<',
      itemActions: '<',
      userRightName: '@',
      isItemEditable: '&',
      onEditCancelling: '&',
      onItemAddClicked: '&',
      onItemEditClicked: '&',
      onItemDelete: '&',
      onItemSave: '&',
      onItemWrapperData: '&',
      loadItems: '&',
      onInputValidation: '&',
      onInitialized: '&'
    },
    transclude: {
      headerTitle: "?headerTitle",
      headerActions: "?headerActions",
      noItems: "?noItems",
    },
  });

/**
 * 
 * @param { CrudListScope } $scope
 * @param { HTMLElement } $element
 */
function CrudListCtrl($scope, $element, $attrs, $rootScope, $state, Server, PopupService, Translate, ToasterService, Util, $window, multiSelect, matchingSliderOpts, EventTracker) {
  $scope.matchingFcts = Util.matchingFcts;
  $scope.matchingSliders = _.cloneDeep(matchingSliderOpts);
  $scope.matchingSliders.question.options.disabled = true;
  $scope.matchingSliders.answer.options.disabled = true;

  $scope.texts = $rootScope.texts;
  $rootScope.profilePhoto = Util.profilePhoto;

  $scope.editingItem = undefined;
  $scope.items = [];
  $scope.filteredItems = [];
  $scope.globalSearch = {
    text: ''
  };
  $scope.listFilterProperties = [];
  $scope.hasBodyProps = false;

  $scope.initComponent = function() {
    const thisScope = $scope;
    $scope.showsGlobalFilter = $scope.$ctrl.properties.some(p => p.isGlobalFilter);
    $scope.hasBodyProps = $scope.$ctrl.properties.some(m => m.location === "body");
    if (!thisScope.$ctrl.items || thisScope.$ctrl.items.length === 0) {
      thisScope.items = []
      thisScope.filteredItems = [];
    } else {
      thisScope.items = [...thisScope.$ctrl.items.map((item) => {
        /** @type { CrudListItemWrapper } */
        const wrapper = {
          meta: { 
              isExpanded: false,
              isEditable: thisScope.isItemEditable(item),
              itemActions: thisScope.$ctrl.itemActions?.filter(act => !act.isVisible || act.isVisible(item)),
              errorFields: {},
              options: {}
          },
          data: item,
        };
        thisScope.defineItemWrapperProperties(wrapper);
        return wrapper;
      })];

      for (const item of thisScope.items) {
        thisScope.onItemWrapperData(item);
        thisScope.fillItemOptions(item);
      }
      
      if (thisScope.filterHeader) {
        thisScope.filteredItems = thisScope.filterHeader.applyFilter(thisScope.items);
      } else {
        thisScope.filteredItems = [...thisScope.items];
      }
      
      $scope.initFilters();
    }    
  }

  $scope.initFilters = function() {
    $scope.listFilterProperties = [...$scope.$ctrl.properties
      .filter(prop => prop.location === "header")
      .map((prop, idx, arr) => {
        /** @type {'text'|'dropdown'} */
        const filterType = prop.type === 'text' ? 'text' : 'dropdown';

        // TODO: try to show on filters only values that exists in the data
        // const filterExisting = (options) => {
        //   if (!options || options.length === 0) {
        //     return [];
        //   }
        //   if (!$scope.items || $scope.items.length === 0) {
        //     return options;
        //   }
        //   const validOptions = _.uniq($scope.items.flatMap(p => p.data[prop.key]));
        //   return options.filter(opt => validOptions.find(existing => existing === opt.id) !== undefined);
        // }
        // const optionsFactory = (prop) => {
        //   const result = prop.optionsFactory();
        //   return Promise.resolve(prop.optionsFactory()).then(filterExisting)
        // }

        const filterProp = {
          key: `data.${prop.key}`,
          type: filterType,
          text: prop.text,
          noSort: prop.noSort,
          noFilter: prop.noFilter,
          cssClasses: prop.filterCssClass || '',
          options: prop.options,
          optionsFactory: prop.optionsFactory,
        }

        if (prop.type === 'toggle') {
          filterProp.cssClasses += ' crud-list__toggle__col';
        } else if (prop.type === 'date') {
          filterProp.cssClasses += ' crud-list__date__col';
        } else if (prop.type === 'datetime') {
          filterProp.cssClasses += ' crud-list__datetime__col';
        } else if (prop.type === 'collaborators') {
          filterProp.cssClasses += ' crud-list__collaborators__col';
        } else if (prop.type === 'stars') {
          filterProp.cssClasses += ' crud-list__stars__col';
        } else if (prop.type === 'image') {
          filterProp.cssClasses += ' crud-list__image__col';
        } else if (prop.type === 'matching') {
          filterProp.cssClasses += ' crud-list__matching__col';
        }

        if (prop.type === 'dropdown') {
          filterProp.key += '[0]';
        }

        if (idx === arr.length-1 && $scope.$ctrl.itemActions) {
          filterProp.cssClasses += ' crud-list__item-actions__col'
        }

        return filterProp;
      })
    ];
  }

  $scope.defineItemWrapperProperties = function(wrapper) {
    for (const prop of $scope.$ctrl.properties) {
      if (prop.type === "dropdown") {
        Object.defineProperty(wrapper, prop.key+"Name", {
          get: function() {
            let label = prop.emptyText;
            if (this.data[prop.key] && this.data[prop.key].length > 0 && this.meta.options[prop.key]) {
              if (prop.extraSettings.idProperty) {
                const idProp = prop.extraSettings.idProperty;
                const opt = this.meta.options[prop.key].find(item => item[idProp] === this.data[prop.key][0][idProp]);
                if (opt) {
                  label = opt[prop.labelKey] || opt.label;
                }
              } else {
                const opt = this.meta.options[prop.key].find(item => item === this.data[prop.key][0]);
                if (opt) {
                  label = opt[prop.labelKey] || opt.label;
                }
              }
            }
            return label;
          }
        })
      }
    }
  }

  /**
   * Triggers the item wrapper data on values change
   * @param { CrudListItemWrapper } item
   * @param { CrudListProperty } prop
   */
  $scope.propValueChanged = function(item, prop) {
    $scope.syncTimeDates(item);
    $scope.$ctrl.onItemWrapperData({ $item: item });
  }

  /**
   * Toggle the done status for the task
   * @param {CrudListItemWrapper} item
   * @param {CrudListProperty} prop 
   */
  $scope.toggleProperty = async function(item, prop) {
    if (!(item.meta.isEditable && $scope.userHasRights('edit'))) {
      return;
    }

    item.data[prop.key] = !item.data[prop.key];
    if (prop.onToggle) {
      prop.onToggle(item.data);
    }
    $scope.$ctrl.onItemWrapperData({ $item: item });
  }

  /**
   * Set the rating of the item if it is editable and expanded
   * @param {CrudListItemWrapper} item
   * @param {number} nstars 
   * @param {CrudListProperty} prop 
   */
  $scope.rate = function(item, nstars, prop) {
    if (item.meta.isExpanded && item.meta.isEditable) {
        item.data[prop.key] = nstars;
    }
    $scope.$ctrl.onItemWrapperData({ $item: item });
  };

  /**
   * Assign a collaborator to the property
   * @param {CrudListItemWrapper} item
   * @param {string} collaboratorId 
   * @param {CrudListProperty} prop 
   */
  $scope.collaboratorAssigned = function(item, collaboratorId, prop) {
    item.data[prop.key] = [...new Set([...item.data[prop.key], collaboratorId])];
  }

  /**
   * Unassign a collaborator to the property
   * @param {CrudListItemWrapper} item
   * @param {Object} collaborator 
   * @param {CrudListProperty} prop 
   */
  $scope.collaboratorRemoved = function(item, collaborator, prop) {
    item.data[prop.key] = item.data[prop.key].filter(collabId => collabId !== collaborator._id);
  }

  $scope.addItemClick = function() {
    $scope.$ctrl.addItem();
  }

  /**
   * Save current state of the items and expand the card for editing
   * @param { CrudListItemWrapper } item 
   */
  $scope.editItemClick = function(item) {
      $scope.cancelEdit();
      item.meta.isExpanded = true;
      $scope.editingItem = JSON.parse(JSON.stringify(item.data));
      $scope.$ctrl.onItemEditClicked({ $item: item.data });
  }

  /**
   * Show dialog to confirm is user wants to delete, and do it if confirmed
   * @param { CrudListItemWrapper } item 
   */
  $scope.confirmDelete = function(item) {
    PopupService.openGenericPopup($scope, {
      submit: function () {
        $scope.items = $scope.items.filter(i => i && i.data._id !== item.data._id);
        if ($scope.filterHeader) {
          $scope.filteredItems = $scope.filterHeader.applyFilter($scope.items);
        }

        if (item.data._id) {
          $scope.$ctrl.onItemDelete({ $item: item.data });
        } else {
          $scope.$ctrl.loadItems();
        }
        $scope.modalHandle.close();
      },
      title: Translate.getLangString('delete_confirmation_title'),
      warningText: Translate.getLangString($scope.$ctrl.translation.delete_warning || 'delete_confirmation_warning'),
      yesText: Translate.getLangString('delete'),
      noText: Translate.getLangString('cancel')
    }, 'templates/modal-confirm-warning.html', {});
  }

  $scope.itemActionSelected = function(selected, item) {
    selected.action(item);
  }

  /**
   * 
   * @param { CrudListItemWrapper } item
  */
  $scope.validateInput = function(item) {
    const errorFields = $scope.$ctrl.onInputValidation({ $item: item.data });
    if (errorFields) {
      //@ts-ignore
      return _.isEmpty(errorFields);
    }
    return true;
  }

  /**
   * 
   * @param { CrudListItemWrapper } item
  */
  $scope.saveEntry = function(item) {
    EventTracker.trackCrudListSave($scope.$ctrl.identifier);
    $scope.syncTimeDates(item);
    if (!$scope.validateInput(item)) {
      ToasterService.failure({}, 'err_1_form');
      return;
    }
    const saveResult = $scope.$ctrl.onItemSave({ $item: item.data });
    if (saveResult && saveResult.then) {
      saveResult.then((savedItem) => {
        if (savedItem !== false) {
          item.meta.isExpanded = false;
          $scope.editingItem = undefined;
          if (!item.data._id && savedItem && savedItem._id) {
            item.data._id = savedItem._id;
          }
        }
      });
    }
  }

  /**
   * Cancel edit, collapse card and return to saved state
   */
  $scope.cancelEdit = function() {
    if ($scope.editingItem) {
      const editing = $scope.items.find(item => item.meta.isExpanded);
      if (editing) {
        if (editing.data._id) {
          if ($scope.editingItem._id === editing.data._id) {
            const savedStateKeys = Object.keys($scope.editingItem);
            const scopeEditingItem = $scope.editingItem;
            $scope.$ctrl.onEditCancelling({
              $editingItem: {
                oldState: $scope.editingItem,
                currentState: editing,
              }
            });
            Object.keys(editing.data).forEach(k => {
                if (savedStateKeys.includes(k)) {
                  let value = scopeEditingItem[k];
                  const prop = $scope.$ctrl.properties.find(p => p.key === k)
                  if (prop && prop.type === "datetime" && typeof value === 'string') {
                    value = moment(value);
                  }
                  editing.data[k] = value;
                }
            });
          }
          editing.meta.isExpanded = false;
          $scope.editingItem = undefined;
        } else {
          $scope.items = $scope.items.filter(item => item && item.data._id);
          if ($scope.filterHeader) {
            $scope.filteredItems = $scope.filterHeader.applyFilter($scope.items);
          }
        }
        $scope.$ctrl.onItemWrapperData({ $item: editing });
      }
    }
  }

  /**
   * Rule to define if an items is editable by the current user
   * @param { any } item 
   * @returns { boolean }
   */
  $scope.isItemEditable = function(item) {
    if (!$scope.userHasRights('edit')) {
      return false;
    }
    const customPermission = $scope.$ctrl.isItemEditable({ $item: item });
    return customPermission === undefined ? true : customPermission;
  }

  /**
   * 
   * @param {CrudListItemWrapper} item
   */
  $scope.onItemWrapperData = function(item) {
    for (const prop of $scope.$ctrl.properties) {
      if (prop.type === 'date' || prop.type === 'datetime') {
        const datePropKey = prop.type === 'datetime' ? (prop.dateKey || 'date') : prop.key;
        Object.defineProperty(item.data, datePropKey + 'dateString', {
          get: () => {
            const date = item.data[datePropKey];
            return date ? moment(date).toDate().toLocaleDateString('en-GB') : '';
          },
          set: (value) => {
            if (value) {
              item.data[datePropKey] = moment(value, 'DD/MM/yyyy').toDate().toISOString();
            } else {
              item.data[datePropKey] = '';
            }
          }
        })
      }
    }
    $scope.$ctrl.onItemWrapperData({ $item: item });
  }

  /**
   * 
   * @param {CrudListItemWrapper} item 
   */
  $scope.syncTimeDates = function(item) {
    for (const prop of $scope.$ctrl.properties) {
      if (prop.type === 'datetime') {
        const dateProp = prop.dateKey || 'date';
        if (item.data[dateProp] && item.data[prop.key]) {
          item.data[dateProp] = moment(item.data[dateProp]).toISOString(true).slice(0, 10);
          item.data[dateProp] += moment(item.data[prop.key]).toISOString(true).slice(10, 16);
          item.data[prop.key] = moment(item.data[dateProp]).toDate();
        }
      }
    }
  }

  /**
   * 
   * @param {CrudListItemWrapper} item 
   */
  $scope.fillItemOptions = function(item) {
    for (const prop of $scope.$ctrl.properties) {
      if (prop.type === "dropdown") {
        if (prop.options) {
          item.meta.options[prop.key] = prop.options
        } else if (prop.optionsFactory) {
          Promise.resolve(prop.optionsFactory())
            .then(res => item.meta.options[prop.key] = res);
        } else {
          item.meta.options[prop.key] = [];
        }

        if (!item.data[prop.key]) {
          item.data[prop.key] = [];
        }
      }
    }
  }

  $scope.applyGlobalFilter = _.debounce(function() {
    $scope.filterItems();
    if ($scope.globalSearch.text) {
      const globalFilterProps = $scope.$ctrl.properties.filter(p => p.isGlobalFilter)
      $scope.filteredItems = $scope.filteredItems.filter(item => {
        return globalFilterProps.some(prop => {
          const propValue = _.get(item.data, prop.key)
          if (!propValue) {
            return false;
          }
          if (Array.isArray(propValue)) {
            return propValue.some(nested => nested?.toString()?.toUpperCase()?.includes($scope.globalSearch.text.toUpperCase()));
          } else {
            return propValue?.toString()?.toUpperCase()?.includes($scope.globalSearch.text.toUpperCase());
          }
        })
      })
    }
    $scope.$apply()
  }, 250)

  $scope.filterItems = function(filter) {
    $scope.filteredItems = $scope.filterHeader.applyFilter($scope.items);
  }

  $scope.sortItems = function(sort) {
    $scope.filteredItems = $scope.filterHeader.applySort($scope.filteredItems);
  }

  /**
   * Filter properties that should be in the card header
   * @param {CrudListProperty} prop 
   * @returns {boolean}
   */
  $scope.filterHeaderProp = function(prop) {
    return prop.location === 'header';
  }

  /**
   * Filter properties that should be in the card body
   * @param {CrudListProperty} prop 
   * @returns {boolean}
   */
   $scope.filterBodyProp = function(prop) {
    return prop.location === 'body';
  }

  $scope.userHasRights = function(level) {
    return !$scope.$ctrl.userRightName || $rootScope.fns.userHasRights($scope.$ctrl.userRightName, level)
  }

  $scope.$ctrl.getEditingItem = function() {
    const currentState = $scope.items.find(item => item.meta.isExpanded && item.meta.isEditable);
    return {
      oldState: $scope.editingItem,
      currentState,
    }
  }

  /**
   * 
   * @param {CrudListItemData} item 
   * @returns 
   */
  $scope.$ctrl.getItemWrapper = function(item) {
    return $scope.items.find(item => item.data && item);
  }

  $scope.$ctrl.setPropOptions = function(key, options) {
    for (const item of $scope.items) {
      item.meta.options[key] = options;
    }
    if ($scope.filterHeader) {
      $scope.filterHeader.setPropOptions(key, options);
    }
  }

  $scope.$ctrl.addItem = function(initialValue) {
    EventTracker.trackCrudListCreate($scope.$ctrl.identifier);
    $scope.cancelEdit();
    const data = {}
    /** @type { CrudListItemWrapper } */
    const wrapper = {
        meta: {
          isExpanded: true,
          isEditable: true,
          itemActions: $scope.$ctrl.itemActions?.filter(act => !act.isVisible || act.isVisible(data)),
          errorFields: {},
          options: {}
        },
        data: data
    };
    $scope.fillItemOptions(wrapper);
    for (const prop of $scope.$ctrl.properties) {
      if (prop.defaultValue) {
        wrapper.data[prop.key] = prop.defaultValue;
      }
    }
    if (initialValue) {
      for (const prop of Object.keys(initialValue)) {
        wrapper.data[prop] = initialValue[prop];
      }
    }
    $scope.onItemWrapperData(wrapper);
    $scope.filteredItems.unshift(wrapper);
    $scope.items.unshift(wrapper);
    $scope.$ctrl.onItemAddClicked({ $item: wrapper.data });
    return $scope.editingItem = JSON.parse(JSON.stringify(wrapper.data));
  }

  $scope.$ctrl.$onChanges = function(changeObj) {
    let hasChanges = false;
    if (changeObj.items && changeObj.items.currentValue !== changeObj.items.previousValue) {
      hasChanges = true;
    }
    if (changeObj.properties && changeObj.properties.currentValue !== changeObj.properties.previousValue) {
      hasChanges = true;
    }
    if (changeObj.itemActions && changeObj.itemActions.currentValue !== changeObj.itemActions.previousValue) {
      hasChanges = true;
    }

    if (hasChanges) {
      $scope.initComponent();
    }
  }

  $scope.$ctrl.$onInit = function() {
    const translationDefaults = {
      add_item: 'add_item',
      items: 'items',
      no_items: 'no_entries_exists',
      delete_warning: 'delete_confirmation_warning',
    }
    if (!$scope.$ctrl.translation) {
      $scope.$ctrl.translation = translationDefaults;
    } else {
      $scope.$ctrl.translation = {
        ...translationDefaults,
        ...$scope.$ctrl.translation,
      };
    }
    if ($scope.$ctrl.onInitialized) {
      $scope.$ctrl.onInitialized({ $crudList: $scope.$ctrl })
    }
  }
}

/**
 * @typedef CrudListItemCallback
 * @type { (wrapper: CrudListItemWrapper) => void }
 */

/**
 * @typedef CrudListItemData
 * @type { { _id?: string, [key: string]: any } }
 */

/**
 * @typedef CrudListEditingItem
 * @property { CrudListItemData } [oldState]
 * @property { CrudListItemData } [currentState]
 */

/**
 * @typedef ItemAction
 * @property { string } text
 * @property { (item: any) => void } action
 * @property { (item: any) => boolean } isVisible
 */

/**
 * @typedef CrudListController
 * @property { () => void } $onInit
 * @property { (any) => void } $onChanges
 * @property { string } [identifier]
 * @property { Array } items
 * @property { Record<string,string> } translation
 * @property { CrudListProperty[] } properties
 * @property { ItemAction[] } [itemActions]
 * @property { string } userRightName
 * @property { () => void } loadItems
 * @property { (eventData: { $item: any }) => void } onItemDelete
 * @property { (eventData: { $item: CrudListItemData }) => Object } onInputValidation
 * @property { (eventData: { $item: CrudListItemData }) => Object } onItemSave
 * @property { (eventData: { $item: CrudListItemData }) => Object } isItemEditable
 * @property { (eventData: { $item: CrudListItemData }) => void } onItemAddClicked 
 * @property { (eventData: { $item: CrudListItemData }) => void } onItemEditClicked 
 * @property { (eventData: { $item: CrudListItemWrapper }) => void } onItemWrapperData
 * @property { (eventData: { $editingItem: CrudListEditingItem}) => void } onEditCancelling
 * @property { (eventData: { $crudList: CrudListController }) => void } onInitialized
 * @property { (intialValue?: CrudListItemData) => CrudListEditingItem } addItem
 * @property { () => CrudListEditingItem } getEditingItem
 * @property { (item: CrudListItemData) => (CrudListItemWrapper|undefined) } getItemWrapper
 * @property { (key: string, options: any[]) => void} setPropOptions
 */

/**
 * @typedef CrudListScope
 * @property { CrudListItemData } [editingItem]
 * @property { any } texts
 * @property { CrudListController } $ctrl
 * @property { CrudListItemWrapper[] } items
 * @property { CrudListItemWrapper[] } filteredItems
 * @property { boolean } showsGlobalFilter
 * @property { Record<string,string> } globalSearch
 * @property { FilterProperty[] } listFilterProperties
 * @property { boolean } hasBodyProps
 * @property { () => void } initComponent
 * @property { () => void } initFilters
 * @property { (item: CrudListItemData) => boolean } isItemEditable
 * @property { CrudListItemCallback } defineItemWrapperProperties
 * @property { (item: CrudListItemWrapper, prop: CrudListProperty ) => void } propValueChanged
 * @property { CrudListItemCallback } syncTimeDates
 * @property { CrudListItemCallback } onItemWrapperData
 * @property { CrudListItemCallback } fillItemOptions
 * @property { ListFilterController } filterHeader
 * @property { (item: CrudListItemWrapper, prop: CrudListProperty) => void } toggleProperty
 * @property { (item: CrudListItemWrapper, nstars: number, prop: CrudListProperty) => void } rate
 * @property { (item: CrudListItemWrapper, collaboratorId: string, prop: CrudListProperty) => void } collaboratorAssigned
 * @property { (item: CrudListItemWrapper, collaborator: {_id: string}, prop: CrudListProperty) => void } collaboratorRemoved
 * @property { () => void } addItemClick
 * @property { () => void } cancelEdit
 * @property { CrudListItemCallback } editItemClick
 * @property { CrudListItemCallback } confirmDelete
 * @property { (item: CrudListItemWrapper) => boolean } validateInput
 * @property { CrudListItemCallback } saveEntry
 * @property { () => void } applyGlobalFilter
 * @property { () => void } filterItems
 * @property { () => void } sortItems
 * @property { (prop: CrudListProperty) => boolean } filterHeaderProp
 * @property { (prop: CrudListProperty) => boolean } filterBodyProp
 * @property { any } modalHandle
 * @property { any } matchingFcts
 * @property { any } matchingSliders
 * @property { (selected: ItemAction, item: any) => void } itemActionSelected
 * @property { (string) => boolean } userHasRights
 */

/**
 * @typedef CrudPropertyRef
 * @property { string } state
 * @property { Object } stateParams
 */

/**
 * @typedef CrudListProperty
 * @property { 'text'|'dropdown'|'toggle'|'date'|'datetime'|'quill'|'stars'|'collaborators'|'image'|'matching'|'badge' } type
 * @property { 'header'|'body' } location
 * @property { string } key
 * @property { string } [labelKey]
 * @property { string } [dateKey] used on datetime type fileds, to have different fields for setting the date and time, defaults to date
 * @property { string } text
 * @property { string } [emptyText]
 * @property { any } [defaultValue] Value set automatically when adding a new item
 * @property { string } [dataCssClass]
 * @property { string } [filterCssClass]
 * @property { boolean } [isGlobalFilter]
 * @property { boolean } [noSort]
 * @property { boolean } [noFilter]
 * @property { Array } [options]
 * @property { CrudPropertyRef } [ref] Object used to define a u-sref property for dropdown columns
 * @property { () => Promise<Array> } [optionsFactory]
 * @property { Object } [extraSettings] ng-dropdown-multiselect extra settings
 * @property { Object } [dropdownEvents] ng-dropdown-multiselect events
 * @property { (item: CrudListItemData) => void } [onToggle] used with toggle properties when the toggle needs to immediatly call the server
 * @property { boolean } [disabled]
 */

/** 
 * @typedef { Object } CrudListItemMeta
 * @property { boolean } isExpanded
 * @property { boolean } isEditable
 * @property { ItemAction[] } [itemActions]
 * @property { Object } errorFields key/value pair for field validation erros, the key is the key of the faulted property
 * @property { Object } options key/value pair for storing each item options when field type is dropdown
 * @property { Object } [cssClasses] additional cssClasses that may be filled with onItemStyling based on item's data
*/

/**
 * @typedef { Object } CrudListItemWrapper
 * @property { CrudListItemMeta } meta
 * @property { CrudListItemData } data
*/