//@ts-check
//@ts-ignore
MessageTextboxCtrl.$inject = ["$scope", "$rootScope", "NG_QUILL_CONFIG_EMAILS", "overlaySpinner", "Translate", "Server", "Upload", "ToasterService"];
angular.module('app')
  .component('messageTextbox', {
    templateUrl: '../templates/components/message-textbox.component.html',
    controller: MessageTextboxCtrl,
    bindings: {
      message: '<',
      templates: '<',
      variables: '<',
      editorModules: '<',
      allowAttachments: '<',
      attachmentAccept: '<',
      attchmentMaxSize: '<',
      onUploadAttachment: '&',
      onRemoveAttachment: '&',
    },
  });

function MessageTextboxCtrl($scope, $rootScope, NG_QUILL_CONFIG_EMAILS, overlaySpinner, Translate, Server, Upload, ToasterService) {
  $scope.editorModules = $scope.$ctrl.editorModules || NG_QUILL_CONFIG_EMAILS.modules;
  $scope.activeMessageTemplate = '';
  $scope.attachmentAccept = $scope.$ctrl.attachmentAccept;
  $scope.attchmentMaxSize = $scope.$ctrl.attchmentMaxSize;

  $scope.messageTemplateClick = function(messageTemplate) {
    $scope.activeMessageTemplate = messageTemplate;
    $scope.$ctrl.message.text = messageTemplate.body;
  }

  $scope.attachmentsChanged = function(files, file, newFiles, duplicateFiles, invalidFiles, event) {
    if (invalidFiles.length > 0) {
      ToasterService.failure(invalidFiles.map(m => m.$error), "file_not_selected_or_invalid");
    }
    if (newFiles) {
      const distinctValidFiles = newFiles.filter(file => !duplicateFiles.includes(file) && !invalidFiles.includes(file))
      if (distinctValidFiles.length > 0) {
        return $scope.onUploadAttachment(distinctValidFiles);
      }
    }
  }

  $scope.removeAttachment = function(index) {
    return $scope.onRemoveAttachment(index);
  }

  $scope.onUploadAttachment = async function(files) {
    const overlay = overlaySpinner.show('message-textbox');
    for (const file of files) {
        try {
            let response = await Upload.upload({
                url: Server.makeUrl(`users/${$rootScope.user._id}/documents`),
                data: {
                    file: file
                }
            });
            $scope.$ctrl.message.attachments.push(response.data);
        } catch (err) {
            ToasterService.failure(err, 'err_61_attachment_not_ok');
        }
    }
    overlay.hide();
  }

  $scope.onEditorCreated = function(quill) {
    $scope.quillRef = quill;
  }

  /**
   * 
   * @param {{label: string, value: string}} variable 
   */
  $scope.onVariableClick = function(variable) {
    if ($scope.quillRef && variable) {
      $scope.quillRef.focus();
      const cursorPosition = $scope.quillRef.getSelection()?.index || 0;
      $scope.$ctrl.message.text += variable.value;
      $scope.quillRef.setSelection(cursorPosition + variable.value.length);
    }
  }

  $scope.onRemoveAttachment = async function(index) {
    $scope.$ctrl.message.attachments.splice(index, 1);
  }

  $scope.$ctrl.$onInit = function() {
    if ($scope.$ctrl.allowAttachments === undefined) {
      $scope.$ctrl.allowAttachments = true;
    }
    if ($scope.$ctrl.attchmentMaxSize === undefined) {
      $scope.attchmentMaxSize = '10MB';
    }
  }
}