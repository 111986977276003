
CollaboratorsTaskDropdownController.$inject = ["$scope", "$element", "$attrs", "$rootScope", "$state", "Server", "PopupService", "Translate", "Util", "$window", "multiSelect"];angular.module('app')
  .component('collaboratorsDropdownTaskEdit', {
    templateUrl: '../templates/components/collaborators-dropdown-task-edit.component.html',
    controller: CollaboratorsTaskDropdownController,
    bindings: {
      options: '<',
      assignedCollaborators: '<',
      readonly: '<',
      direction: '@',
      createdBy: '<',
      onAssigned: '&',
      onRemoved: '&',
    },
  })
  .component('collaboratorsDropdownTaskList', {
    templateUrl: '../templates/components/collaborators-dropdown-task-list.component.html',
    controller: CollaboratorsTaskDropdownController,
    bindings: {
      options: '<',
      assignedCollaborators: '<',
      readonly: '<',
      direction: '@',
      createdBy: '<',
      onAssigned: '&',
      onRemoved: '&',
    },
  });

function CollaboratorsTaskDropdownController($scope, $element, $attrs, $rootScope, $state, Server, PopupService, Translate, Util, $window, multiSelect) {
  $ctrl = this;
  $scope.element = $element;
  $scope.attrs = $attrs;

  $scope.texts = $rootScope.texts;
  $scope.profilePhoto = Util.profilePhoto;

  $scope.addCollaboratorSelected = {};
  $scope.collaboratorSelected = {};

  $scope.optionsDefined = false;

  $scope.extraCollaborators = [];
  $scope.multiSelectModelCollaboratorsAssigned = [];

  $scope.filteredCollaborators = [];

  $scope.$watch(() => $rootScope.collaborators, (newValue) => {
    if (newValue) {
        $scope.loadAvailableCollaborators();
    }
  });
  
  $scope.buildCollaboratorsMenu = function () {
    if (this.internalOptions) {
      this.availableCollaborators = [{
        text: Translate.getLangString('collaborator_add_option'),
        value: 'invitecollaborator',
      }];
      var inList = [];

      if (this.$ctrl.assignedCollaborators) {
        this.assignedCollaborators = this.$ctrl.assignedCollaborators.map((obj) => {
          if (typeof obj === "string") {
            inList.push(obj);
            if ($rootScope.user._id == obj) {
              return { _id: obj, name: $rootScope.user.name, photoSrc: $rootScope.user.photoSrc }
            }
            
            const collab = this.internalOptions.find(collab => collab._id === obj);
            if (collab) {
              return collab;
            } else {
              return { _id: obj, name: 'unkown' };
            }
          } else if (obj._id) {
            inList.push(obj._id);
            return obj;
          } else {
            throw new Error('Invalid assinged collaborators for collaborators dropdown');
          }
        });
        this.assignedCollaborators.forEach(this.buildContextMenu);
      }
      
      this.internalOptions.forEach((obj) => {
        if (inList.indexOf(obj._id) == -1 && !obj.suspended) {
          this.availableCollaborators.push({
              text: $rootScope.userFullName(obj),
              value: obj._id
          });
        }
      });
    }
  }

  $scope.buildContextMenu = function(collaborator) {
    collaborator.contextMenu = [];

    let suffix = '';
    let remove = true;
    let message = true;

    if ($rootScope.campaign && $rootScope.campaign.ownerId == collaborator._id) {
      suffix = `(${Translate.getLangString('account_owner')})`;
      remove = false;
    }

    if ($rootScope.user._id == collaborator._id) {
      suffix = Translate.getLangString('collaborator_is_you');
      remove = false;
      message = false;
    }

    if (collaborator.suspended) {
      suffix += ` (${Translate.getLangString('suspended')})`;
    }

    if (!$rootScope.fns.userHasRights("hasAdminRights", true) || collaborator.userType > 0) {
      remove = false;
    }
    
    collaborator.contextMenu = [
      {
        text: $rootScope.userFullName(collaborator) + ' ' + suffix,
        divider: true
      }
    ]; 
  }

  $scope.onAddCollaboratorSelected = function(selected) {
    if (selected.value == 'invitecollaborator') {
      $state.go('collaborators');
    } else {
      if (selected.value !== this.$ctrl.createdBy) {
        this.$ctrl.onAssigned({ $collaboratorId: selected.value });
        $scope.updateFilteredCollaborators();
      }
    }
  };

  $scope.onDeleteCollaboratorSelected = function(selectedItem) {
    const collaborator = $scope.internalOptions.find(c => c._id === selectedItem.id);
    if (collaborator && collaborator._id !== this.$ctrl.createdBy) {
      this.$ctrl.onRemoved({ $collaborator: collaborator });
      // Update after removing a collaborator
      $scope.updateFilteredCollaborators();
    } else {
      console.error("No collaborator found with ID:", selectedItem.id);
    }
  };

  $ctrl.$onChanges = function(changesObj) {
    if (changesObj.options) {
      if (changesObj.options.currentValue) {
        $scope.internalOptions = changesObj.options.currentValue;
        $scope.buildCollaboratorsMenu();
      }
      else if (!$scope.internalOptions && changesObj.options.isFirstChange()) {
        if ($rootScope.collaborators) {
          $scope.internalOptions = $rootScope.collaborators;
          $scope.buildCollaboratorsMenu();
        } else {
          Server.get('collaborators').then(function(collaborators) {
            $rootScope.collaborators = collaborators;
            $scope.internalOptions = collaborators;
            $scope.buildCollaboratorsMenu();
          });
        }
      }
    }

    if (changesObj.assignedCollaborators && !changesObj.assignedCollaborators.isFirstChange() && (changesObj.assignedCollaborators.currentValue !== changesObj.assignedCollaborators.previousValue)) {
      $scope.buildCollaboratorsMenu();
      $scope.availableExtraCollaborators();
    }
}

$scope.availableExtraCollaborators = function() {
  if ($scope.assignedCollaborators && $scope.assignedCollaborators.length > 0) {
      $scope.extraCollaborators = $scope.assignedCollaborators.slice(1).map(collab => {
          $scope.buildContextMenu(collab);
          return {
              text: $rootScope.userFullName(collab),
              value: collab._id,
              contextMenu: collab.contextMenu 
          };
      });
  } else {
      $scope.extraCollaborators = [];
  }
};


$scope.loadAvailableCollaborators = function() {
  const collaborators = $rootScope.collaborators;
  // Excluding suspended collaborators.
  const filteredCollaborators = collaborators.filter(collaborator => {
    return collaborator._id && !collaborator.suspended;
  });
  // Updating the options list of available collaborators.
  $scope.collaboratorsOptions = filteredCollaborators.map(c => ({
    id: c._id,
    label: Util.userFullName(c)
  }));
  $scope.initializeSelectedCollaborators();
};


$scope.initializeSelectedCollaborators = function() {
  if (!$scope.collaboratorsOptions) {
    return;
}
  $scope.multiSelectModelCollaboratorsAssigned = $scope.assignedCollaborators.map(ac => {
      return $scope.collaboratorsOptions.find(option => option.id === ac.id);
  }).filter(ac => ac != null);
  $scope.updateFilteredCollaborators();
};

/* MULTI SELECT */
// Texts
let multiSelectCollaborators = JSON.parse(JSON.stringify(multiSelect))
$scope.multiSelectCollaboratorsTexts = multiSelectCollaborators.texts
$scope.multiSelectCollaboratorsTexts.buttonDefaultText = null;
$scope.multiSelectCollaboratorsTexts.selectionCount = null;
$scope.multiSelectCollaboratorsTexts.dynamicButtonTextSuffix = null;

// Settings
$scope.multiSelectAvailableCollaboratorsSettings = {
  showCheckAll: false,
  showUncheckAll: false,
  enableSearch: true,
  dynamicTitle: false,
}

// Events
$scope.multiSelectAvailableCollaboratorsEvents = {
  onItemSelect: function(item) {
    if (item.id !== $ctrl.createdBy) {
      $scope.onAddCollaboratorSelected({ value: item.id });
    }
  },
  onItemDeselect: function(item) {
    if (item.id !== $ctrl.createdBy) {
      $scope.onDeleteCollaboratorSelected(item);
    }
  }
};

$scope.updateFilteredCollaborators = function() {
  if ($ctrl.createdBy) { // If the task was already created
    $scope.filteredCollaborators = $scope.assignedCollaborators.filter(collab => collab._id !== $ctrl.createdBy);
  } else { // If the task is being created, the rootScope user is the creator, $ctrl.createdBy is not defined yet
    $scope.filteredCollaborators = $scope.assignedCollaborators.filter(collab => collab._id !== $rootScope.user._id);
  }
};




$scope.getTooltipContent = function() {
  let content = "<div>";
  $scope.multiSelectModelCollaboratorsAssigned.forEach(collaborator => {
    content += `<div class='extra-collaborator'>${collaborator.label}</div>`;
  });
  content += "</div>";
  return content;
};


$ctrl.$onInit = function() {
  $scope.loadAvailableCollaborators();
};

}

