angular.module('app').controller('CandidateProfileEditInfoCtrl', ["$rootScope", "$scope", "Server", "$state", "ToasterService", "PopupService", "Util", "EmailValidationService", function ($rootScope, $scope, Server, $state, ToasterService, PopupService, Util, EmailValidationService) {
  $rootScope.showBanner = true;

  $scope.resetErrors = function () {
      $scope.errorFields = {};
  }

  $scope.resetLocation = function () {
      $rootScope.candidate.address = { name: $rootScope.candidate.address.name };
  }

  $scope.validateInputs = function () {
      $scope.resetErrors();
      var emailConstraints = { from: { email: true } };
      if (!$rootScope.candidate.firstName) {
          $scope.errorFields.firstName = true;
      }
      if (!$rootScope.candidate.lastName) {
          $scope.errorFields.lastName = true;
      }
      if (!$rootScope.candidate.phoneNumber) {
          $scope.errorFields.phoneNumber = true;
      }
      if (!$rootScope.candidate.email || EmailValidationService.validate({ from: $rootScope.candidate.email }, emailConstraints)) {
          $scope.errorFields.email = true;
      }
      if (!$rootScope.candidate.address.name) {
          // if there is no country it means that no google address options has been selected
          $scope.errorFields.address = true;
      }
      return !_.isEmpty($scope.errorFields);
  }

  setDataCandidates();

  function setDataCandidates() {
      if ($rootScope.candidates.length > 0) {
        let candidateWithFirstName = _.find($rootScope.candidates, c => c.firstName);
        $rootScope.candidate = candidateWithFirstName || $rootScope.candidates[0];
    }
  }

  $scope.editInfoClicked = function () {
      if (!$scope.validateInputs()) {
          Server.put('candidates/candidate-area/me/editProfiles/', $rootScope.candidate)
              .then(function () {
                  ToasterService.success('candidate_profile_well_updated');
                  $state.go('info');
              }, function (err) {
                  ToasterService.failure(err, 'candidate_profile_not_updated');
              });
      }
  }

  $scope.canceleditInfoClicked = function () {
      if (!$scope.validateInputs())
          $state.go('info');
  }

  $scope.editProfilePhoto = function () {
      $scope.notification = '';
      const uploadUrl = 'candidates/candidate-area/me/editProfilePicture';
      const getUrl = 'candidates/candidate-area/me';
      Util.editProfilePhoto($scope, $rootScope.candidate, PopupService, uploadUrl, getUrl, $rootScope.areaCustomization);
  };
}]);
