//@ts-check
//@ts-ignore
angular.module('app').controller('CampaignThankyouCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "ToasterService", "Server", "Util", "multiSelect", "overlaySpinner", "Translate", "$timeout", "$location", function ($scope, $rootScope, $state, $stateParams, ToasterService, Server, Util, multiSelect, overlaySpinner, Translate, $timeout, $location) {
  setPageTitle();
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  // on payment completion the redirectUrl includes some stripe payment IDs to the querystring, this is used to clear that
  if (location.search) {
    if (location.search.indexOf('redirect_status=succeeded') >= 0) {
        localStorage.setItem('campaignPaymentSuccess', 'true');
    }
    location.search = location.search.replace(/&?(payment_intent|payment_intent_client_secret|redirect_status)=[_a-z0-9]+/ig, '');
  } else if (localStorage.getItem('campaignPaymentSuccess') === 'true') {
      ToasterService.success('campaign_payment_success');
      localStorage.removeItem('campaignPaymentSuccess');
  }

  $scope.backToMarketplace = function() {
    $state.go('campaign-vonq-marketplace', { campaignId: $stateParams.campaignId });
  }

  $scope.getCampaign = function() {
    overlaySpinner.show('vonq-thankyou');
    return Server.get('campaigns/' + $stateParams.campaignId)
      .then((campaign) => {
          overlaySpinner.hide('vonq-thankyou');
          $rootScope.campaign = campaign;
          setPageTitle($rootScope.campaign.title[$rootScope.campaign.language]);
          return $rootScope.campaign;
      })
      .catch((err) => {
        overlaySpinner.hide('vonq-thankyou');
          ToasterService.failure(err, 'campaign_load_error');
      });
  }
  
  function setPageTitle(campaignName) {
    const tabTitle = Translate.getLangString('job_board_marketplace')
    if (campaignName) {
        const navTitle = `${campaignName}: ${tabTitle}`;
        Util.setPageTitle(tabTitle, campaignName, navTitle);
    } else if (!$rootScope.pageTitle) {
        Util.setPageTitle(tabTitle);
    }
  }

  $scope.getCampaign();
}]);
