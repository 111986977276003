angular.module('app').controller('DashboardUserProfileScorecardsCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "PopupService", "ToasterService", "overlaySpinner", "EventTracker", "multiSelect", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, PopupService, ToasterService, overlaySpinner, EventTracker, multiSelect) {
  $scope.userScorecards = [];
  $scope.userScorecardsList = [];
  $scope.userRightName = 'campaigns.scorecards';

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights("campaigns.scorecards", "view", user)) {
      $state.go('user-profile');
    }
  });


  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.multiselectSettings = {
    ...multiSelectln.objectSettings,
    idProperty: "_id",
    dynamicTitle: true,
    smartButtonMaxItems: 5,
  };
  $scope.singleSelectSettings = {
    ...$scope.multiselectSettings,
    selectionLimit: 1,
    showCheckAll: false,
    showUncheckAll: false,
    checkBoxes: false,
  };


  // Score cards list
  $scope.scorecardsListTexts = {
    title: '_VOID',
    add_item: 'add_list',
    items: 'scorecardsList',
  }

  const scorecardsListProperties = [
    {
      type: 'text',
      location: 'header',
      text: Translate.getLangString('label'),
      key: 'label',
      noFilter: true,
      isGlobalFilter: true,
      dataCssClass: 'tag__label__col crud-list__title__col',
      filterCssClass: 'tag__label__col crud-list__title__col',
    },
  ]
  $scope.scorecardsListProperties = scorecardsListProperties;

  $scope.saveScorecardsList = function(scorecardsList) {
      const scorecardsListId = scorecardsList?._id;
      if (scorecardsListId) {
          // scorecards exists => edit it
          const updatedScorecardsListStatus = {
              label: scorecardsList.label,
              scorecardsListId: scorecardsListId,
          };
          return Server.patch('users/' + $rootScope.user.id + '/scorecards-lists/' + scorecardsListId, updatedScorecardsListStatus)
          .then(async () => {
              ToasterService.success('scorecards_updated');
              await $scope.loadScorecardsList();
          })
          .catch((err) => {
              ToasterService.failure(err, 'scorecards_not_updated');
          })
      } else {
          // scorecards doesn't exist yet => create it
          return Server.post('users/' + $rootScope.user.id + '/scorecards-lists', {label: scorecardsList.label})
          .then(async () => {
              ToasterService.success('scorecards_list_created');
              await $scope.loadScorecardsList();
              await $scope.loadScorecards();
          })
          .catch((err) => {
              ToasterService.failure(err, 'scorecards_not_created');
              return false;
          });
      }   
  }

  $scope.deleteScorecardsList = async function(item) {
    const oldUserScorecardsList = [...$scope.userScorecardsList];
    const oldUserScorecards = [...$scope.userScorecards];

    try {
        await Server.delete('users/' + $rootScope.user.id + '/scorecards-lists/' + item._id);

        await $scope.loadScorecardsList();
        await $scope.loadScorecards();

        ToasterService.success('scorecards_list_deleted');
    } catch (err) {
        $scope.userScorecardsList = oldUserScorecardsList;
        $scope.userScorecards = oldUserScorecards;
        ToasterService.failure(err, 'scorecards_not_deleted');
    }
};


  // Score cards
  $scope.scorecardsTexts = {
      title: '_VOID',
      add_item: 'add_scorecard',
      items: 'scorecards',
  };

  const scorecardsProperties = [
      {
          type: 'text',
          location: 'header',
          text: Translate.getLangString('label'),
          key: 'label',
          noFilter: true,
          isGlobalFilter: true,
          dataCssClass: 'tag__label__col crud-list__title__col',
          filterCssClass: 'tag__label__col crud-list__title__col',
      },
      {
          type: 'dropdown',
          location: 'header',
          text: Translate.getLangString('scorecards_list'),
          emptyText: Translate.getLangString('no_list'),
          key: 'scorecardsList',
          noFilter: false,
          noSort: true,
          dataCssClass: 'tag__category__col',
          filterCssClass: 'tag__category__col',
          optionsFactory: function() {
              return $scope.userScorecardsList;
          },
          extraSettings: $scope.singleSelectSettings,
      },
      {
          type: 'quill',
          location: 'body',
          key: 'body',
          text: 'Body',
          dataCssClass: 'no-grow',
          filterCssClass: '',
          noFilter: true,
          noSort: true,
      },
  ];

  $scope.scorecardsProperties = scorecardsProperties;

  $scope.saveScorecard = function(item) {
    const scorecardsListId = item.scorecardsList[0] ? item.scorecardsList[0]._id : null;

    const card = {
        _id: item._id,
        label: item.label,
        body: item.body,
        createdBy: $rootScope.user.id,
        modifiedBy: $rootScope.user.id,
        scorecardsListId: scorecardsListId || undefined,
    };

    if (card._id) {
        return Server.patch('users/' + $rootScope.user.id + '/scorecards/' + card._id, card)
        .then(async () => {
            ToasterService.success('scorecards_updated');
            await $scope.loadScorecards();
        }).catch((err) => {
            ToasterService.failure(err, 'scorecards_not_updated');
            return false;
        });
    } else {
        return Server.post('users/' + $rootScope.user.id + '/scorecards', card)
        .then(async () => {
            ToasterService.success('scorecards_created');
            await $scope.loadScorecards();
        }).catch((err) => {
            ToasterService.failure(err, 'scorecards_not_created');
            return false;
        });
    }
};

$scope.deleteScorecard = function(card) {
  const oldUserScorecards = [...$scope.userScorecards];
  
  return Server.delete(`users/${$rootScope.user.id}/scorecards/${card._id}`)
  .then(async() => {
      await $scope.loadScorecards();
      ToasterService.success('scorecards_deleted');
  }).catch((err) => {
      $scope.userScorecards = oldUserScorecards;
      ToasterService.failure(err, 'scorecards_not_deleted');
  });
};

  $scope.loadScorecardsList = function() {
    return Server.get('users/' + $rootScope.user.id + '/scorecards-lists')
    .then((scorecardsList) => {
        $scope.userScorecardsList = Array.isArray(scorecardsList) ? scorecardsList : [];
    });
};

$scope.loadScorecards = function() {
    return Server.get('users/' + $rootScope.user.id + '/scorecards')
    .then((scorecards) => {
        $scope.userScorecards = Array.isArray(scorecards) ? scorecards.map(card => ({
            label: card.label,
            body: card.body,
            scorecardsList: $scope.userScorecardsList.filter(list => list._id === card.scorecardsListId),
            _id: card._id,
            scorecardId: card._id,
        })) : [];
    });
};

  // Load scorecards
  $scope.loadScorecardsList()
  .then(()=> $scope.loadScorecards());
}])