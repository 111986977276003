//@ts-check
angular.module('app').controller('SubmissionCandidateHistoryCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "overlaySpinner", "ToasterService", "Translate", "$q", function ($scope, $rootScope, $state, $stateParams, Server, overlaySpinner, ToasterService, Translate, $q) {
  $scope.showCampaignFilter = true;
  $scope.showCandidateHistory = true;
  $scope.candidate = null;

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights('candidates.history', 'view', user)) {
      $state.go('submission', { candidateId: $stateParams.candidateId });
    }
  });

  $scope.$on('onCandidateData', function (event, candidate) {
      if (candidate) {
        $scope.candidate = candidate;
        $rootScope.$emit('candidateList.setTabAndFilters', { index: 6 });
        // $scope.candidateList.reload()
        //   .then(() => {
        //   });
      }
  });

  $scope.loadMore = function () {
    if ($scope.candidateList) {
      $scope.candidateList.loadNext();
    }
};

  $scope.fetchCandidates = function (filters, sort, offset, limit) {
    $scope.candidateLoaded = false;
    if (!$scope.candidate) {
      return Promise.resolve({});
    }
    const overlay = overlaySpinner.show('submission');
    return Server.get(`candidates/getByEmail/${$rootScope.candidate.email}?sessions=true`, { filters, sort, pagination: { offset, limit } })
      .then((res) => {
        $scope.candidateLoaded = true;
        overlay.hide();
        return res
      })
      .catch((err) => {
        overlay.hide();
        ToasterService.failure(err, 'load_candidates_error');
      });
  }
}]);
