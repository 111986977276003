angular.module('app').controller('LiveInterviewCtrl', ["$scope", "$rootScope", "Server", "$state", "PopupService", "overlaySpinner", "Util", "Translate", "TimeUtils", "Onboarding", "ToasterService", "$timeout", "$stateParams", "EventTracker", function ($scope, $rootScope, Server, $state, PopupService, overlaySpinner, Util, Translate, TimeUtils, Onboarding, ToasterService, $timeout, $stateParams, EventTracker) {
  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(['canLiveInterview'], user)) {
        $rootScope.upgradePlan('upgrd_live_interview', { backOnClose: true })
    }
    if (!$rootScope.fns.userHasRights('interviewScheduler.interviewSessions', 'view', user)) {
      $state.go('campaigns');
    }
  });

  Util.setPageTitle(Translate.getLangString('interview'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  var today = false;

  $scope.currentDate = new Date();

    //
    // URL Parameters
    //
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
  
  $scope.activeDate = false;

  $scope.todayWeekDay = -1;

  $scope.ddSessionOptions = _.filter([
      {
          text: Translate.getLangString('view'),
          value: 'view',
          requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'view'}
      },
      {
          text: Translate.getLangString('send_message'),
          value: 'sendmessage',
          requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'edit'}
      },
      {
          text: Translate.getLangString('edit'),
          value: 'edit',
          requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'edit'}
      },
      {
          text: Translate.getLangString('delete'),
          value: 'remove',
          requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'delete'}
      },
      {
        text: Translate.getLangString('invite_external_user'),
        value: 'inviteexternal',
        requiredUserRights: { name: 'interviewScheduler.interviewSessions', level: 'edit'},
        requiredPrivileges: ['useLiveInterviewV2'],
      }
  ], function(item) {
    const privilegeCheck = $rootScope.fns.hasPrivileges(item.requiredPrivileges);
    const userRightCheck = !item.requiredUserRights || $rootScope.fns.userHasRights(item.requiredUserRights.name, item.requiredUserRights.level);
    return privilegeCheck && userRightCheck;
  });

  $scope.ddSessionMenuSelected = {};


  var setupCalendar = function () {
      $scope.ddSelectMonthOptions = TimeUtils.buildMonthOptionsMenu($scope.currentDate);
      $scope.ddSelectedMonth = $scope.ddSelectMonthOptions.find(dt => dt.month === $scope.currentDate.getMonth() && dt.year === $scope.currentDate.getFullYear()) || $scope.ddSelectMonthOptions[0];
      $scope.weeks = TimeUtils.buildWeeksObject($scope.currentDate);

      today = false;
      $scope.todayWeekDay = -1;
      $scope.weeks.forEach(function (week) {
          if (today) {
              return;
          }
          today = week.find(function (day) {
              return day.today;
          });
      });
      if (today) {
          $scope.todayWeekDay = today.weekday;
      }
  };

  var reloadSessions = function () {
      $scope.sessions = [];
      Server.get('users/me/sessions', { fromTime: $scope.currentDate }).then(function (result) {
          $scope.agendaMonth = result;

          if ($scope.activeDate) {
              $scope.sessions = $scope.agendaMonth[$scope.activeDate.day];
          } else {
              Server.get('users/me/sessions').then(function (upcomingSessions) {
                  $scope.sessions = upcomingSessions;
              });
          }
      });
  };

  var addInviteCollaboratorOptions = function () {
      if (!$rootScope.collaborators || !$rootScope.fns.userHasRights('interviewScheduler.interviewSessions', 'edit'))
          return;


      var ddSessionCollaboratorOptions = $rootScope.collaborators.filter(collaborator => !collaborator.suspended).map(function (collaborator) {
          return {
              text: Translate.getLangString('add_collaborator_option_1') + $rootScope.userFullName(collaborator) + Translate.getLangString('add_collaborator_option_2'),
              value: 'addcollaborator',
              userId: collaborator.id
          }
      });

      $scope.ddSessionOptions = $scope.ddSessionOptions.concat(ddSessionCollaboratorOptions);
  };

  var loadCollaborators = function () {
      if ($rootScope.collaborators) {
          addInviteCollaboratorOptions();
          return;
      }

      Server.get('collaborators').then(function (collaborators) {
          $rootScope.collaborators = collaborators;
          addInviteCollaboratorOptions();
      });
  };

  $scope.onCalendarDropDownOpen = function () {
      $('.c-calendar__dropdown .dropdown li').eq(6).find('.dropdown-item').addClass('active');
  };

  $scope.ddSessionMenuClick = async function (selected, session) {
      switch (selected.value) {
        case 'inviteexternal':
            PopupService.openInviteExternal($scope, session);
            break;
          case 'sendmessage':
              let candidate = await Server.get(`candidates/${session.participants[0].foreignUserId}`);
              PopupService.openCandidateMessage($scope, [candidate], {});
              break;
          case 'edit':
              PopupService.openSessionEditor($scope, null, session, 'edit', $rootScope.user, $scope.campaign, $scope.candidate)
                  .then(reloadSessions);
              ;
              break;
          case 'remove':
              PopupService.openGenericPopup($scope, {
                  submit: function () {
                      $scope.modalHandle.close();
                      Server.deleteObject('users/me/session', session._id)
                          .then(function (response) {
                              ToasterService.success('remove_session_confirmation_message');
                              return reloadSessions();
                          }, function (err) {
                              ToasterService.failure(err, 'err_0_error_occurred');
                          });
                  },
                  title: Translate.getLangString('delete_confirmation_title'),
                  warningText: Translate.getLangString('remove_session_confirmation_warning'),
                  messageText: Translate.getLangString('remove_session_confirmation_message'),
                  yesText: Translate.getLangString('remove_session_confirmation_yes'),
                  noText: Translate.getLangString('cancel')
              }, 'templates/modal-confirm-warning.html', {});
              break;
          case 'addcollaborator':
              Server.post('users/me/session/' + session._id + '/collaborator/' + selected.userId)
                  .then(reloadSessions);
              break;
          case 'view':
              PopupService.viewSession($scope, null, session, 'view', $rootScope.user);
              break;
      }
  };

  $scope.newSession = function () {
      EventTracker.trackInterviewInvite();
      let date = $scope.activeDate ? $scope.activeDate.date : new Date();
      PopupService.openSessionEditor($scope, date, null, null, $rootScope.user, null, null)
      .then(reloadSessions);
  };

  $scope.gotoSession = function (session) {
    if(session.customInterviewLink) {
        window.open(session.customInterviewLink, '__blank').focus();
    } else {
        window.location.href = '/live-interview/?id=' + session._id + '&id2=' + $rootScope.user._id + '&id3=0';
    }
  };

  $scope.setActiveDate = function (d, selectToday) {
      if (d && d.empty)
          return;

      $scope.activeDate = d;

      if (!$scope.activeDate && selectToday && today) {
          $scope.activeDate = today;
      }

      reloadSessions();
  };

  $scope.ddSelectMonth = function (selected) {
      TimeUtils.addMonths($scope.currentDate, selected.offset);
      setupCalendar();
      $scope.setActiveDate(false);
  };

  $scope.prevMonth = function () {
      TimeUtils.addMonths($scope.currentDate, -1);
      setupCalendar();
      $scope.setActiveDate(false);
  };

  $scope.nextMonth = function () {
      TimeUtils.addMonths($scope.currentDate, 1);
      setupCalendar();
      $scope.setActiveDate(false);
  };

  $scope.goToday = function () {
    $scope.goToDate(new Date());
  };
  
  $scope.goToDate = function (date) {
    $scope.currentDate = date;
      setupCalendar();
      $scope.setActiveDate(false, true);
  };

  $scope.getEndTime = function(schedule, duration) {
    var startTime = new Date(schedule);
    var endTime = new Date(startTime.getTime() + duration * 60000);
    return endTime;
  };

  console.log('$stateParams', $stateParams);
  
  if($stateParams.date) {
    let date = new Date($stateParams.date);
    $scope.goToDate(date);
    let flatWeeks =  _.flatten($scope.weeks);
    let day = flatWeeks.find(d => d.day == (moment(date).date()));
    $scope.setActiveDate(day);
  } else {
  $scope.goToday();
  }
  Onboarding.initWidget('dashboard-live_interview');
  loadCollaborators();
  }]);
  