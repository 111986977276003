//@ts-check
//@ts-ignore
ListFilterCtrl.$inject = ["$scope", "$element", "$attrs", "$rootScope", "$state", "Server", "PopupService", "Translate", "Util", "$window", "multiSelect"];
angular.module('app')
  .component('listFilter', {
    templateUrl: '../templates/components/list-filter.component.html',
    controller: ListFilterCtrl,
    bindings: {
      data: '<',
      properties: '<',
      onFilter: '&',
      onSort: '&',
    },
  });
//@ts-ignore

/**
 * 
 * @param {ListFilterScope} $scope
 * @param { HTMLElement } $element
 */
function ListFilterCtrl($scope, $element, $attrs, $rootScope, $state, Server, PopupService, Translate, Util, $window, multiSelect) {
  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.multiselectSettings = {
      ...multiSelectln.objectSettings,
      idProperty: "id",
  };
  $scope.multiselectTexts = { _default: multiSelectln.texts };
  $scope.multiselectEvents = { onSelectionChanged: () => setTimeout($scope.onFilterChange, 100) };

  $scope.filter = {};
  $scope.sort = {};
  $scope.internalProperties = [];

  $scope.onFilterChange = function() {
    $scope.$ctrl.onFilter({ $filter: $scope.filter });
    $rootScope.$digest();
  };
  $scope.onTextFilterChange = _.debounce(function() {
    $scope.onFilterChange()
  }, 100, { maxWait: 100 });

  $scope.toggleSort = function(property) {
    const prevValue = $scope.sort[property.key];
    $scope.sort = {};
    $scope.sort[property.key] = prevValue === 'asc' ? 'desc' : 'asc';
    $scope.$ctrl.onSort({ $sort: $scope.sort });
  }

  $scope.initComponent = async function() {
    $scope.internalProperties = [];
    if (!$scope.$ctrl.properties) {
      return;
    }

    for (let prop of $scope.$ctrl.properties) {
      $scope.multiselectTexts[prop.key] = {
        ...$scope.multiselectTexts._default,
        buttonDefaultText: prop.text ? prop.text : $scope.multiselectTexts._default.buttonDefaultText,
      };
    }

    let isFirstLoad = _.isEmpty($scope.filter);
    $scope.internalProperties = $scope.$ctrl.properties.map(prop => {
      const internalProp = JSON.parse(JSON.stringify(prop))
      return { ...internalProp, optionsFactory: prop.optionsFactory }
    });
    for (let prop of $scope.internalProperties) {
      if (prop.type === "text") {
        if (isFirstLoad) {
          $scope.filter[prop.key] = '';
        }
      } else if (prop.type === "dropdown") {
        if (isFirstLoad) {
          $scope.filter[prop.key] = [];
        }
        // build dropdown options
        // if the options are predefined we don't need to collect from data nor execute the factory
        if (prop.options || prop.noFilter) {
          continue;
        }
  
        if (prop.optionsFactory) {
          prop.options = await prop.optionsFactory();
        }
        else if ($scope.$ctrl.data) {
          prop.options = [];
          for (let dataItem of $scope.$ctrl.data) {
            const propValue = _.get(dataItem, prop.key);
            if (!propValue) {
              continue;
            }
            if (Array.isArray(propValue)) {
              prop.options.push(...propValue.map(v => ({ id: v, label: v})));
            } else {
              prop.options.push({ id: propValue, label: propValue });
            }
          }
          prop.options.sort((a, b) => sortByProperty(a, b, 'label'));
        }
      }
    }
  }

  Object.defineProperty($scope.$ctrl, 'hasFilter', { get: () => {
    if (_.isEmpty($scope.filter)) {
      return false;
    }
    
    for (let key of Object.keys($scope.filter)) {
      if ($scope.filter[key] !== undefined) {
        if (Array.isArray($scope.filter[key]) && $scope.filter[key].length > 0) {
          return true;
        }
        else if (!Array.isArray($scope.filter[key]) && $scope.filter[key] !== undefined) {
          return true;
        }
      }
    }

    return false;
  }})

  $scope.$ctrl.applyFilter = function(data) {
    if (!$scope.$ctrl.hasFilter) {
      return $scope.$ctrl.applySort(data);
    }

    const filteredData = data.filter(item => {
      let matches = true;
      for (let prop of $scope.internalProperties) {
        const filterValue = $scope.filter[prop.key];
        if (filterValue === undefined) {
          continue;
        }

        const itemValue = _.get(item, prop.key)
        if (prop.type === 'text') {
          if (typeof filterValue !== 'string' || typeof itemValue !== 'string' || !itemValue.toLowerCase().includes(filterValue.toLowerCase())) {
            matches = false;
          }
        }
        else if (prop.type === 'dropdown') {
          if (Array.isArray(filterValue) && filterValue.length > 0) {
            if (Array.isArray(itemValue) && !filterValue.find(f => itemValue.find(item => getOptionValue(item) === getOptionValue(f)))) {
              matches = false;
            }
            else if (!Array.isArray(itemValue) && !filterValue.find(f => getOptionValue(f) === getOptionValue(itemValue))) {
              matches = false;
            }
          }
        }
        if (!matches) {
          break;
        }
      }
      return matches;
    });

    return $scope.$ctrl.applySort(filteredData);
  }

  Object.defineProperty($scope.$ctrl, 'hasSort', { get: () => {
    if (_.isEmpty($scope.sort)) {
      return false;
    }
    
    for (let key of Object.keys($scope.sort)) {
      if ($scope.sort[key]) {
        return true;
      }
    }

    return false;
  }})

  $scope.$ctrl.applySort = function(data) {
    if (!$scope.$ctrl.hasSort) {
      return [ ...data ];
    }
    
    let sorted = [ ...data ];
    for (let prop of $scope.internalProperties) {
      const sortDirection = $scope.sort[prop.key]
      if (!sortDirection) {
        continue;
      }

      sorted = sorted.sort((a, b) => {
        return sortByProperty(a, b, prop.key, sortDirection);
      });
    }

    return sorted;
  }

  $scope.$ctrl.setPropOptions = function(key, options) {
    const property = $scope.internalProperties.find(p => p.key.includes('data.' + key))
    if (property) {
      property.options = options;
    }
  }

  $scope.$ctrl.$onChanges = function(changeObj) {
    let hasChanges = false;
    if (changeObj.data && changeObj.data.currentValue !== changeObj.data.previousValue) {
      hasChanges = true;
    }
    if (changeObj.properties && changeObj.properties.currentValue !== changeObj.properties.previousValue) {
      hasChanges = true;
    }

    if (hasChanges) {
      $scope.initComponent();
    }
  }

  function getOptionValue(option) {
    if (option === undefined) {
      return option;
    }
    const value = option.id === undefined ? option : option.id === 0 ? undefined : option.id;
    if (typeof value === 'string') {
      return value.toLowerCase();
    } else {
      return value;
    }
  }

  function sortByProperty(a, b, prop, direction = "asc") {
    const itemValueA = getOptionValue(_.get(a, prop));
    const itemValueB = getOptionValue(_.get(b, prop));
    
    if (!itemValueA && !itemValueB) {
      return 0;
    }
    if (!itemValueA && itemValueB) {
      return direction === "asc" ? 1 : direction === "desc" ? -1 : 0;
    }
    if (itemValueA && !itemValueB) {
      return direction === "asc" ? -1 : direction === "desc" ? 11 : 0;
    }
    if (itemValueA > itemValueB) {
      return direction === "asc" ? 1 : direction === "desc" ? -1 : 0;
    }
    else if (itemValueA < itemValueB) {
      return direction === "asc" ? -1 : direction === "desc" ? 1 : 0;
    }
    else {
      return 0
    }
  }
}

/**
 * @typedef ListFilterController
 * @property { object[] } data
 * @property { FilterProperty[] } properties
 * @property { (changeObj: any) => void } $onChanges
 * @property { boolean } hasFilter
 * @property { boolean } hasSort
 * @property { (data: Array) => Array } applyFilter
 * @property { (data: Array) => Array } applySort
 * @property { ({ $filter: any }) => void} onFilter
 * @property { ({ $sort: any}) => void } onSort
 * @property { (key: string, options: any[]) => void} setPropOptions
 */

/**
 * @typedef ListFilterScope
 * @property { ListFilterController } $ctrl
 * @property {FilterProperty[]} internalProperties
 * @property { any } multiselectSettings
 * @property { any } multiselectTexts
 * @property { any } multiselectEvents
 * @property { {[key:string]: any } } filter
 * @property { {[key:string]: 'asc'|'desc'|undefined} } sort
 * @property { () => Promise<void> } initComponent
 * @property { () => void } onFilterChange
 * @property { () => void } onTextFilterChange
 * @property { (prop: FilterProperty) => void } toggleSort
 */

/**
 * @typedef FilterProperty
 * @property { string } key Property which the filter should be applied
 * @property { string } text Display text for this filter
 * @property { 'text' | 'dropdown'} type Type of the filter
 * @property { () => Promise<FilterOption[]> } [optionsFactory] When specified this function is used to build the filter options, only used for dropdown fitlers
 * @property { FilterOption[] } options
 * @property { boolean } noFilter Defines that the property will not be used for filtering data
 * @property { boolean } noSort Defines that the property will not be used for sorting data
 * @property { string } cssClasses Defines css classes for the property container
 */

/**
 * @typedef FilterOption
 * @property { string } id
 * @property { string } label
 */