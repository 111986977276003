//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardUserProfileCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "ToasterService", "overlaySpinner", "EventTracker", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, ToasterService, overlaySpinner, EventTracker) {
  Util.setPageTitle(Translate.getLangString('profile'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  var langsArray = Translate.getLangsArray();
  $scope.langsArray = langsArray;
  
  $scope.selectedTimezone = [{ id: $rootScope.user.timezone, name: $rootScope.user.timezone }];

  $scope.setupEditUser = function () {

    $scope.originalUser = {
        name: $rootScope.user.name,
        position: $rootScope.user.position,
        email: $rootScope.user.email,
        company: $rootScope.user.company,
        companyDescription: $rootScope.user.companyDescription,
        companyUrl: $rootScope.user.companyUrl,
        address: $rootScope.user.address || {},
        emailSignature: $rootScope.user.emailSignature || '',
        settings: $rootScope.user.settings,
        language: $rootScope.user.language,
        languageString: langsArray[$rootScope.user.language || 0],
        timezone: $rootScope.user.timezone,
        auth0: $rootScope.user.auth0,
    };
    $scope.editUser = JSON.parse(JSON.stringify($scope.originalUser));
    $scope.selectedTimezone = [{ id: $scope.editUser.timezone, name: $scope.editUser.timezone }];
  };

  $scope.saveUser = function (editUser) {
    const overlay = overlaySpinner.show('user-profile');
    var lang = $rootScope.user.language;
    return Server.patch('users/me', editUser)
      .then(function (user) {
        overlay.hide();
        $rootScope.user = user;
        $scope.setupEditUser();
        if (user.language != lang) {
            location.reload();
        }
        postMessage({ name: 'reloadOpener', user: $rootScope.user });
        ToasterService.success('user_well_updated');
      }, function (err) {
        overlay.hide();
        $scope.setupEditUser();
        ToasterService.failure(err, 'user_not_updated');
      });
  }

  if ($state.is('user-profile')) {
    console.log('redirecting')
    $state.go('user-profile.info', {}, { location: 'replace'});
  }
}])