//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardUserProfileInfoCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "PopupService", "ToasterService", "overlaySpinner", "EventTracker", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, PopupService, ToasterService, overlaySpinner, EventTracker) {
  $scope.editProfilePhoto = function() {
    $scope.notification = '';
      const uploadUrl = 'users/me/photo';
      const getUrl = 'users/me';
      Util.editProfilePhoto($scope, $rootScope.user, PopupService, uploadUrl, getUrl);
  }

  $scope.saveUserPersonalInfo = function() {
    $scope.saveUser(_.pick($scope.editUser, 'name', 'position', 'emailSignature'));
  }

  $scope.setupEditUser();
  setTimeout(function() {
    document.querySelector('#changeEmail a')?.addEventListener('click', function(event) {
      PopupService.openSupportMessage($scope, {}, Translate.getLangString("change_email_message"));
    })
  }, 500)
}])