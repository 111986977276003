//@ts-check
angular.module('app').factory('MailScheduleService', ["$q", "Translate", function($q, Translate) {
  return {
      roundToNextFiveMinutes: function(date) {
        date.setMinutes(date.getMinutes() + 5);
        let minutes = date.getMinutes();
        let remainder = minutes % 5;
        if (remainder !== 0) {
            date.setMinutes(minutes + (5 - remainder));
        }
        return date;
      },

      getScheduleOptions: function() {
          const now = new Date();
          const minScheduleDate = new Date(now.getTime() + 5 * 60 * 1000);
          const roundedTime = this.roundToNextFiveMinutes(new Date(minScheduleDate));
          const isTomorrowMonday = (now.getDay() + 1) % 7 === 1;

          let scheduleOptions = [];

          // Add tomorrow morning option only if it's not Sunday
          // Because we already have the option "Monday Morning" below
          if (!isTomorrowMonday) {
              const tomorrowDate = new Date(now);
              tomorrowDate.setDate(tomorrowDate.getDate() + 1);
              tomorrowDate.setHours(8, 0, 0, 0);
              scheduleOptions.push({
                  label: Translate.getLangString('tomorrow_morning'),
                  time: tomorrowDate.toLocaleDateString(undefined, { weekday: 'short', day: '2-digit', month: 'short' }) + ', 08:00',
                  value: 'tomorrowMorning',
                  scheduledAt: tomorrowDate.toISOString(),
              });
          }

          const currentHour = now.getHours();
          const currentMinutes = now.getMinutes();

          // Add "This Afternoon" option only if it's before 12:55
          if (currentHour < 12 || (currentHour === 12 && currentMinutes < 55)) {
              const thisAfternoon = new Date(now);
              thisAfternoon.setHours(13, 0, 0, 0);

              scheduleOptions.push({
                  label: Translate.getLangString('this_afternoon'),
                  time: thisAfternoon.toLocaleDateString(undefined, { weekday: 'short', day: '2-digit', month: 'short' }) + ', 13:00',
                  value: 'thisAfternoon',
                  scheduledAt: thisAfternoon.toISOString(),
              });
          }

          // Always add "Monday Morning"
          const daysUntilMonday = (8 - now.getDay()) % 7 || 7;
          const mondayDate = new Date(now);
          mondayDate.setDate(now.getDate() + daysUntilMonday);
          mondayDate.setHours(8, 0, 0, 0);

          scheduleOptions.push({
              label: Translate.getLangString('monday_morning'),
              time: mondayDate.toLocaleDateString(undefined, { weekday: 'short', day: '2-digit', month: 'short' }) + ', 08:00',
              value: 'mondayMorning',
                scheduledAt: mondayDate.toISOString(),
          });

          return scheduleOptions;
      },

      getDefaultScheduleDate: function() {
        const now = new Date();
        const nextHour = new Date(now.getTime() + 60 * 60 * 1000);
        return this.roundToNextFiveMinutes(nextHour);
      },

      getLastCustomSchedule: function() {
          const lastCustomSchedule = localStorage.getItem('lastCustomSchedule');
          if (lastCustomSchedule) {
              const parsedSchedule = JSON.parse(lastCustomSchedule);
              const scheduledDate = new Date(parsedSchedule.scheduledAt);
              const now = new Date();

              if (scheduledDate.getTime() > now.getTime() + 4 * 60 * 1000) {
                  return {
                      label: Translate.getLangString('last_custom_schedule'),
                      time: scheduledDate.toLocaleDateString(undefined, { weekday: 'short', day: '2-digit', month: 'short' }) + ', ' +
                            scheduledDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }),
                      value: 'lastCustom',
                      scheduledAt: scheduledDate
                  };
              }
          }
          return null;
      },

      saveLastCustomSchedule: function(scheduledAt) {
          const customSchedule = {
              scheduledAt: scheduledAt
          };
          localStorage.setItem('lastCustomSchedule', JSON.stringify(customSchedule));
      },

      validateAndFormatSchedule: function(customDate, customTime) {
        let errors = {};
    
        if (!customDate && !customTime) {
            errors.datetime = Translate.getLangString('err_160_missing_date_and_time');
            return { errors };
        }
    
        if (!customDate) {
            errors.date = Translate.getLangString('err_160_missing_date');
            return { errors };
        }
    
        if (!customTime) {
            errors.time = Translate.getLangString('err_160_missing_time');
            return { errors };
        }
    
        // Check & convert date format (dd / MM / yyyy → YYYY-MM-DD)
        const dateRegex = /^(\d{2}) \/ (\d{2}) \/ (\d{4})$/;
        const dateMatch = customDate.match(dateRegex);
    
        if (!dateMatch) {
            errors.date = Translate.getLangString('err_160_invalid_date_format');
            return { errors };
        }
    
        let [_, day, month, year] = dateMatch;
        let formattedDate = `${year}-${month}-${day}`;
        let scheduledAt = new Date(formattedDate);
    
        if (isNaN(scheduledAt.getTime())) {
            errors.date = Translate.getLangString('err_160_invalid_schedule_time');
            return { errors };
        }
    
        let hours = customTime.getHours();
        let minutes = customTime.getMinutes();
        scheduledAt.setHours(hours, minutes, 0, 0);
    
        if (scheduledAt.getTime() <= new Date().getTime() + 4 * 60 * 1000) {
            errors.datetime = Translate.getLangString('err_160_date_in_past');
            return { errors };
        }
    
        return { scheduledAt };
    },
    
  };
}]);