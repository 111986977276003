angular.module('app').controller('CareerCampaignCtrl', ["$rootScope", "$scope", "Server", "$window", "Translate", "multiSelect", "googleMaps", "$timeout", "Util", "htmlTemplates", "Cookies", function ($rootScope, $scope, Server, $window, Translate, multiSelect, googleMaps, $timeout, Util, htmlTemplates,Cookies) {
    $rootScope.secondaryNav = null;
    $rootScope.campaignLoaded = false;
    

    //
    // URL Parameters
    //
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    $scope.start = function() {
        if ($rootScope.inWelcome && $rootScope.candidateSpace) {
            window.location.replace('/interview?session=' + $rootScope.sessionId);
            return;
        }

        if ($rootScope.candidateSpace) {
            $state.go('video');
            return;
        }

        if ($rootScope.campaign.expired) {
            return;
        }

        overlaySpinner.show('welcome');
        $scope.start = function() {};
        $rootScope.nextStep().then(function() {
            overlaySpinner.hide('welcome');
        });
    };

    // fb tag test
    function setMetaTags() {
        $window.ogtitle.content = "We're hiring";
        $window.ogurl.content = "https://app.beehire.com/";
        $window.ogtype.content = "website";
        $window.ogdescription.content = "Discover our latest job opening and join the team";
        $window.ogimage.content = "/imgs/miniature_link_share_carreerpage.jpg";
    }

    $scope.shareSocialNetwork = function(network) {
        let urlToShare;
        if(location.host == 'localhost:8000') {
            urlToShare = 'https://staging-app.beehire.com/career/lafeve';
        } else {
            urlToShare = location.href;
        }

        let url;
        if(network === 'facebook') {
            url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlToShare);
        } else if (network === 'twitter') {
            url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(urlToShare);
        } else if (network === 'linkedin') {
            url = 'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(urlToShare);
        }

        
        window.open(url, 'share-dialog', 'width=626,height=436'); 
        return false;
    }

    $scope.scrollToSection = function (selector) {
        let yOffset = -80;
        let element = document.querySelector(selector);
        if (!element) {
            console.warn(`no element found to scroll with selector ${selector}`);
            return;
        }
        const elementBoundary = element.getBoundingClientRect();
        let y = elementBoundary.top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        $rootScope.toggleNavbar({ forceClose: true });
    }

    $scope.downloadLinkedDocument = function (linkedDocument) {
        var a = document.createElement('a');
        a.style.display = 'none';
        a.href = linkedDocument.downloadLink;
        a.download = linkedDocument.originalname;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 100);
    };

    $scope.loadCampaign = function() {
        const pathArray = location.pathname.split('/');
        const inviteKey = pathArray[pathArray.length - 1];
        if (!(inviteKey && pathArray[pathArray.length - 2] === "invite")) {
            console.error('Expected an inviteKey : inviteKey was not found.');
        }

        Server.get('invite/'+inviteKey+'/welcome')
            .then(res => {
                $rootScope.campaign = res.campaign;
                if (res.campaign.customization) {
                    $rootScope.employerBranding = {
                        ...$rootScope.employerBranding,
                        ...res.campaign.customization.employerBranding
                    }
                    if (res.campaign.customization.bannerImage) {
                        $rootScope.employerBranding.customization.bannerImage = res.campaign.customization.bannerImage;
                        $rootScope.employerBranding.bannerVideo = undefined;
                    }
                }

                // set custom terms for campaign
                if ($rootScope.employerBranding.legalInformation) {
                    res.campaign.customTerms = [];
                    const langPriority = Translate.getLanguagePriority(res.campaign);
                    for (let termIdx = 0; termIdx < $rootScope.employerBranding.legalInformation.customTermsAndConditions.length; termIdx++) {
                        const customTerm = $rootScope.employerBranding.legalInformation.customTermsAndConditions[termIdx];
                        for (const lang of langPriority) {
                            const title = _.get(customTerm, `title[${lang}]`)
                            const label = _.get(customTerm, `label[${lang}]`)
                            const url = _.get(customTerm, `url[${lang}]`)
                            if (title && label && url) {
                                res.campaign.customTerms.push({
                                    title, label, url
                                });
                                break; // break languages loop
                            }
                        }
                    }
                }
                
                // customization macros to make styling easier
                $rootScope.customization = {
                    brandColor: { "color": $rootScope.employerBranding.customization.mainColor || false },
                    brandBackColor: { "background-color": $rootScope.employerBranding.customization.mainColor || false },
                    navbarFontColor: { "color": $rootScope.employerBranding.customization.navbarFontColor ? $rootScope.employerBranding.customization.navbarFontColor : undefined },
                }
                if ($rootScope.campaign.location && !$rootScope.campaign.location.isWorldwide) {
                    setTimeout(() => {
                        $rootScope.goolgeStaticMap = googleMaps.embedUrl($rootScope.campaign.location);
                        document.getElementById('findus__map').src = $rootScope.goolgeStaticMap;
                        $scope.$apply();
                    }, 100);
                }

                const assessmentMatch = /\/assessments\/(\w+)\//.exec(window.location.href);
                if (assessmentMatch) {
                    $rootScope.assessmentId = assessmentMatch[1];
                } else {
                    $rootScope.assessmentId = undefined;
                }

                // Use lan
                let campaignLanguages = [];
                for(let i = 0; i < 10 ; i++) {
                    if(res.campaign.title[i]) {
                        campaignLanguages.push(i);
                    }
                }

                Translate.initVisitorLanguageFromNavigator(0);
                if(! _.includes(campaignLanguages, Translate.currentLanguage())) {
                    Translate.changeVisitorLanguage(res.campaign.language);
                }
                if(params.lang && (typeof Translate.getLangNumberFromShort(params.lang) === 'number')) {
                    Translate.changeVisitorLanguage(Translate.getLangNumberFromShort(params.lang));
                }

                // Only how languages for which the campaign has a title
                const langDropdownObject = Translate.getLangDropdownObject()
                $scope.ddLangs = _.filter(langDropdownObject, ddLang => {
                    return _.includes(campaignLanguages, ddLang.value);
                });

                document.title = `${res.campaign.title[Translate.currentLanguage()]} | ` + $rootScope.employerBranding.companyInformation.name;

                // duplicated code (ref 2402)
                if ($rootScope.employerBranding.customization.favicon || res.campaign.favicon) {
                    const favLinkTags = document.querySelectorAll('head [href*="icon"]')
                    favLinkTags.forEach(favIconTag => {
                        favIconTag.setAttribute('href', $rootScope.employerBranding.customization.favicon || res.campaign.favicon)
                    })
                }

                setCareerNavbar();
                $rootScope.setNavTitle();
                $rootScope.campaignLoaded = true;
                // Initialise cookies with gtag, if there is one
                Cookies.init($rootScope?.employerBranding?.legalInformation?.gtag, $rootScope?.employerBranding?.legalInformation?.ga);
                hideLoader();
            })
    }

    function setCareerNavbar() {
        let options = [];
        if ($rootScope.campaign && $rootScope.campaign.careerPageLink) {
            options.push({
                href: $rootScope.campaign.careerPageLink,
                text: $rootScope.texts['career_nav_home'],
                scrollWindow: true,
            });
        }
        if (!$rootScope.hideNavAbout) {
            options.push({
                elementId: 'campaign-description',
                text: $rootScope.texts['career_nav_about'],
                scrollWindow: true,
            });
        }
        $rootScope.setNavbarOptions(options);
        $rootScope.setNavbarLanguages($scope.ddLangs);
    }

    $scope.redirectToForm = function () {
        const origin = window.location.origin;
        const path = window.location.pathname;
        window.location.href = origin + path + '?form=true';
    };
    
    setMetaTags();
    $scope.loadCampaign();
    setCareerNavbar();
    setInterval(function() {
        const viewHeight = parseFloat(document.documentElement.style.getPropertyValue('--vh'))
        const bottomCta = document.querySelector('.campaign__cta-btn__bottom')
        if (window.scrollY > ((viewHeight * 100) - 80)) {
            bottomCta.classList.remove('campaign__cta-btn__bottom--hidden');
            bottomCta.classList.add('campaign__cta-btn__bottom--visible');
        } else {
            bottomCta.classList.add('campaign__cta-btn__bottom--hidden');
            bottomCta.classList.remove('campaign__cta-btn__bottom--visible');
        }
    }, 50);
}])