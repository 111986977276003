//@ts-check
// Controller for the candidate database
angular.module('app').controller('CandidatePageCtrl', ["$scope", "$rootScope", "$state", "$timeout", "Server", "overlaySpinner", "ToasterService", "Translate", "$q", "Onboarding", "PopupService", "Util", "EventTracker", function ($scope, $rootScope, $state, $timeout, Server, overlaySpinner, ToasterService, Translate, $q, Onboarding, PopupService, Util, EventTracker) {
  Util.setPageTitle(Translate.getLangString('candidate_page'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;
  $scope.canhowNoCandidatesPlaceholder = false;
  $scope.limitDisplay = $scope.displayLimitPage = 25;
  $scope.showCampaignFilter = true;
  $scope.openCsvImportPopup = function () {
      EventTracker.trackCandidateAdd();
      PopupService.openCsvImportPopup($scope, {}, $rootScope.campaign);
  };
  Onboarding.initWidget(null);

  $scope.loadMore = function () {
      if ($scope.candidateList) {
        $scope.candidateList.loadNext();
      }
  };

  $scope.fetchCandidates = function (filters, sort, offset, limit, isKanban, countsOnly, listOnly) {
    const overlay = overlaySpinner.show(countsOnly ? 'candidates-counts' : 'candidates');
    const url = isKanban ? 'candidates/kanban' : 'candidates';
    
    // Add query parameters for countsOnly or listOnly
    const queryParams = [];
    queryParams.push('fetchHistoryCount=true');
    if (countsOnly) queryParams.push('countsOnly=true');
    if (listOnly) queryParams.push('listOnly=true');
    
    const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
    
    return Server.post(url + queryString, { filters, sort, pagination: { offset, limit } }, { preserveCache: true })
      .then((res) => {
        $timeout(() => {
          overlay.hide();
        }, 1000 * (res.items?.length || 0) / 100);
        return res;
      })
      .catch((err) => {
        overlay.hide();
        ToasterService.failure(err, 'load_candidates_error');
        throw err; // Re-throw to propagate the error
      });
  }
}]);
