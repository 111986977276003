angular.module('app').controller('DashboardUserProfileCampaignStatusCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "PopupService", "ToasterService", "overlaySpinner", "EventTracker", "multiSelect", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, PopupService, ToasterService, overlaySpinner, EventTracker, multiSelect) {
  $scope.userCampaignStatus = [];

  $scope.userRightName = 'campaigns.status';

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights("campaigns.status", "view", user)) {
      $state.go('user-profile');
    }
  });


  $scope.campaignStatusTexts = {
    title: '_VOID',
    add_item: 'campaign_status_add',
    items: 'campaignStatus',
  }

  const campaignStatusProperties = [
    {
      type: 'text',
      location: 'header',
      text: Translate.getLangString('label'),
      key: 'label',
      noFilter: true,
      isGlobalFilter: true,
      dataCssClass: 'tag__label__col crud-list__title__col',
      filterCssClass: 'tag__label__col crud-list__title__col',
    },
  ]
  $scope.campaignStatusProperties = campaignStatusProperties;

  $scope.saveCampaignStatus = function(campaignStatus) {
    const campaignStatusId = campaignStatus?._id;
    if (campaignStatusId) {
      // campaign status exists => edit it
      const updatedCampaignStatus = {
        label: campaignStatus.label,
        campaignStatusId: campaignStatusId,
      };
      return Server.patch('users/' + $rootScope.user.id + '/campaign-status/' + campaignStatusId, updatedCampaignStatus)
      .then(async () => {
        ToasterService.success('campaign_status_updated');
        await $scope.loadCampaignStatus();
      })
      .catch((err) => {
        ToasterService.failure(err, 'campaign_status_not_updated');
      })
    } else {
      // campaign status doesn't exist yet => create it
      return Server.post('users/' + $rootScope.user.id + '/campaign-status', {label: campaignStatus.label,})
      .then(async () => {
        ToasterService.success('campaign_status_created');
        await $scope.loadCampaignStatus();
      })
      .catch((err) => {
        ToasterService.failure(err, 'campaign_status_not_created');
        return false;
      });
    }   
  }

  $scope.deleteCampaignStatus = function(item) {
      const oldUserCampaignStatus = [...$scope.userCampaignStatus];
      return Server.delete('users/' + $rootScope.user.id + '/campaign-status/' + item._id)
        .then(() => {
          $scope.userCampaignStatus = $scope.userCampaignStatus.filter(t => t._id !== item._id);
          ToasterService.success('campaign_status_deleted');
        }).catch((err) => {
          $scope.userCampaignStatus = oldUserCampaignStatus;
          ToasterService.failure(err, 'campaign_status_not_deleted');
        })
  }


  $scope.loadCampaignStatus = function() {
    return Server.get('users/' + $rootScope.user.id + '/campaign-status')
      .then((campaignStatus) => {
        if (campaignStatus.length) {
          $scope.userCampaignStatus = campaignStatus;
        } else {
          $scope.userCampaignStatus = [];
        }
      });
  }

  $scope.loadCampaignStatus();
}])