//@ts-check
/** {@link import('./components/crud-list.component.js) link} */

//@ts-ignore
SubmissionCandidateAssessmentCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "Server", "Translate", "ToasterService", "Util", "multiSelect", "PopupService", "drawProgressCircle"];
angular.module('app').controller('SubmissionCandidateAssessmentCtrl', SubmissionCandidateAssessmentCtrl);

function SubmissionCandidateAssessmentCtrl($scope, $rootScope, $state, $stateParams, $timeout, Server, Translate, ToasterService, Util, multiSelect, PopupService, drawProgressCircle) {
    console.log('SubmissionCandidateAssessmentCtrl');
    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user) || !$rootScope.fns.userHasRights('candidates.assessments', 'view', user)) {
          $state.go('submission', { candidateId: $stateParams.candidateId });
        }
    });

    $scope.assessmentOptions = [];
    $scope.candidate = undefined;

    $scope.openAssessmentInvite = function() {
        PopupService.openAssessmentInvite($scope, [$scope.candidate])
        .then(function() {
            return $scope.loadAssessments(true);
        });
    };

    /** @type { CrudListProperty[] } */
    const assessmentProperties = [
        {
            type: 'text',
            location: 'header',
            key: 'name',
            text: Translate.getLangString('name'),
            dataCssClass: 'crud-list__title__col',
            filterCssClass: 'crud-list__title__col',
            noSort: true,
            noFilter: true,
            ref: {
                state: 'submission-assessment-summary',
                stateParams: '{ candidateId: "' + $stateParams.candidateId + '", assessmentId: item.data._id }'
            }
        },
        {
            type: 'text',
            location: 'header',
            key: 'statusDate',
            text: Translate.getLangString('date'),
            dataCssClass: 'crud-list__datetime__col',
            filterCssClass: 'crud-list__datetime__col',
            noFilter: true,
        },
        {
            type: 'matching',
            location: 'header',
            key: 'matchingScore',
            text: Translate.getLangString('candidates_filter_by_matching'),
            noFilter: true
        },
        {
            type: 'stars',
            location: 'header',
            key: 'rating',
            text: Translate.getLangString('score'),
            noFilter: true
        }
    ]
    $scope.assessmentProperties = assessmentProperties;
    $scope.crud_translation = {
        title: 'assessments',
        items: 'assessments',
    }

    $scope.setAssessmentsOptions = (assessments) => {
        $scope.assessmentOptions = assessments
            .filter(loadedAssessment => $scope.candidate && $scope.candidate.assessments.find(a => a.assessmentId === loadedAssessment._id))
            .map(assessment => {
                const result = {
                    name: assessment.title[assessment.language],
                    id: assessment._id,
                    ...assessment
                };
                const answered = $scope.candidate.assessments.find(a => a.assessmentId === assessment._id);
                if (answered) {
                    result.matchingScore = answered.matching ? Math.floor(answered.matching.score*100) : null;
                    result.rating = answered.rating;
                    result.finishedAt = answered.finishedAt;

                    if (answered.startedAt) {
                        const date = new Date(answered.finishedAt || answered.startedAt);
                        const formattedDate = date.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
                        const formattedTime = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
                        result.statusDate = `${formattedDate} - ${formattedTime}`;
                    } else {
                        result.statusDate = Translate.getLangString("unstarted");
                    }
                }

                return result;
            });
    }

    $scope.loadAssessments = function(forceLoad = false) {
        if (forceLoad || (!$rootScope.assessments || $rootScope.assessments.length === 0)) {
            Server.get('assessments').then((assessments) => {
                $rootScope.assessments = assessments;
                $scope.setAssessmentsOptions($rootScope.assessments);
                $scope.assessmentsLoaded = true;
            }).catch((err) => {
                ToasterService.failure(err, 'load_assessments_error');
            });
        } else {
            $scope.setAssessmentsOptions($rootScope.assessments);
            $scope.assessmentsLoaded = true;
        }
    }

    $scope.$on('onCandidateData', function (event, candidate) {
        if (candidate) {
            $scope.candidate = candidate;
            $scope.loadAssessments();
        }
    });
}
