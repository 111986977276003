//@ts-check
angular.module('app').controller('SubmissionNotesCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$timeout", "$state", "PopupService", "Translate", "ToasterService", "Util", function ($scope, $rootScope, $stateParams, Server, $timeout, $state, PopupService, Translate, ToasterService, Util) {
    $scope.glue = true;
    $scope.newNoteField = '';
    $scope.editNoteField = {};
    $scope.newNoteMentions = [];
    $scope.editNoteMentions = [];

    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.userHasRights('candidates.comments', 'view', user)) {
            $state.go('submission', { candidateId: $stateParams.candidateId });
        }
    });

    $scope.addNote = function () {
        console.log('addNote' + $scope.newNoteField)
        if (!$scope.newNoteField)
            return;

        const usersForMentions = $scope.usersForMentions;

        Server.post('candidates/' + $stateParams.candidateId + '/note', {
            text: $scope.newNoteField,
            mentions: checkMentions(usersForMentions, $scope.newNoteMentions, $scope.newNoteField),
        })
            .then($scope.$parent.onCandidateData);

        $scope.newNoteMentions = [];
        $scope.newNoteField = '';
    };

    var checkMentions = function (users, mentions, textField) {
        if (mentions.length == 0)
            return [];

        var ar = [];
        users.forEach(function (collaborator) {
            if ($scope.newNoteField.indexOf('@' + collaborator.label) >= 0 && mentions.indexOf(collaborator._id) >= 0) {
                ar.push(collaborator._id);
            }
        });
        return ar;
    };

    $scope.getCollaboratorName = function (user, mentions) {
        if (mentions.indexOf(user._id) == -1)
            mentions.push(user._id);
        return ' <@' + user.label + '> ';
    };

    $scope.toggleEditNote = function (noteIndex) {
        $scope.glue = false;
        $scope.editIndex = noteIndex;
        if (_.isNil(noteIndex)) {
            return;
        }
        $scope.editNoteField[noteIndex] = $rootScope.candidate.notes[noteIndex].text;
    };

    $scope.editNote = function (noteIndex) {
        if (!$scope.editNoteField[noteIndex])
            return;

        Server.post(`candidates/${$stateParams.candidateId}/note/${noteIndex}`, {
            text: $scope.editNoteField[noteIndex],
            mentions: checkMentions($rootScope.campaign.collaborators, $scope.editNoteMentions, $scope.editNoteField[noteIndex])
        }).then($scope.$parent.onCandidateData);
        $scope.editIndex = null;
        $scope.editNoteMentions = [];
        $scope.editNoteField[noteIndex] = '';
    };

    $scope.deleteNote = function (noteIndex) {
        PopupService.openGenericPopup($scope, {
            submit: function () {
                Server.delete(`candidates/${$stateParams.candidateId}/note/${noteIndex}`)
                    .then(function (candidate) {
                        ToasterService.success('note_deleted_message');
                        $scope.$parent.onCandidateData(candidate);
                    })
                    .catch(function (err) {
                        ToasterService.failure(err, 'note_not_deleted_message');
                    });
                $scope.modalHandle.close();
            },
            title: Translate.getLangString('delete_confirmation_title'),
            warningText: Translate.getLangString('delete_confirmation_warning'),
            yesText: Translate.getLangString('delete'),
            noText: Translate.getLangString('cancel')
        }, 'templates/modal-confirm-warning.html', {});

    };

    $timeout(function () {
        if ($state.is('submission-notes')) {
            Server.get('candidates/' + $stateParams.candidateId + '/notes/seen')
                .then($scope.$parent.onCandidateData)
                .then(async function () {
                    let usersForMentions = await Server.get('campaigns/' + $rootScope.campaign._id + '/getAllUserWhoCanViewComments');
                    usersForMentions = usersForMentions.filter(user => user.suspended !== true)
                    for (let i = 0; i < usersForMentions.length; i++) {
                        usersForMentions[i].label = Util.userFullName(usersForMentions[i]);
                    }
                    $scope.usersForMentions = usersForMentions
                    $scope.$apply();
                });
        }
    }, 2000);
}]);
