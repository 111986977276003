//@ts-check
angular.module('app').controller('publicCandidateHeaderCtrl', ["$rootScope", "$scope", "Server", "Translate", "multiSelect", "googleMaps", function ($rootScope, $scope, Server, Translate, multiSelect, googleMaps) {
  // variables for language selection
  $scope.changeVisitorLanguage = Translate.changeVisitorLanguage;
  // $scope.ddLangs = Translate.getLangDropdownObject();
  // $scope.ddLangs = [{text: 'en', value: 0, full: 'eeen'}, {text: 'fr', value: 1, full: 'fffffr'}];

  $scope.scrollToSection = function (id) {
      let yOffset = -100;
      let element = document.getElementById(id);
      let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
  }
}]);
