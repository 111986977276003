//@ts-check
angular.module('app').controller('CandidateProfileEmailInputCtrl', ["$rootScope", "$scope", "Server", "$window", "Translate", "$state", "ToasterService", "Cookies", function ($rootScope, $scope, Server, $window, Translate, $state, ToasterService, Cookies) {

    Cookies.init();

    $rootScope.showBanner = true;
    
    $scope.sendEmail = function () {
      if (!$scope.email)
          return;

      Server.post('candidates/candidate-area/sendLinkToProfile', { email: $scope.email })
          .then(function (response) {
              $state.go('email-thankyou');
          })
          .catch(function (err) {
              ToasterService.failure(err, 'err_80_candidate_profile_no_candidate_with_this_email');
          });
  }
}]);
