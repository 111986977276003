angular.module('app').controller('VideoClipsCtrl', ["$scope", "$rootScope", "$timeout", "Translate", "videoStates", "VideoService", "Server", "overlaySpinner", "VideoClipUploader", function ($scope, $rootScope, $timeout, Translate, videoStates, VideoService, Server, overlaySpinner, VideoClipUploader) {
  var parentScope = $scope.$parent;
  var modal = parentScope.modal;
  var modalClose = modal.close;
  var question = modal.question;
  var videoRecordingInit = false;

  modal.close = function () {
      if (videoRecordingInit) {
          VideoService.stop();
      }
      modalClose();
  };

  var initializeVideoRecording = function (callback) {
      if (videoRecordingInit) {
          callback();
          return;
      }

      videoRecordingInit = true;

      VideoService.config({
          videoTargetSelector: '.video-modal__camera',
          constraints: DEFAULT_CONSTRAINTS,
      }).openCamera().then(VideoService.connectVideoElement).then(function () {
          callback();
      });
  };

  var durationCounter = function () {
      if (!$scope.recording) {
          return;
      }

      ++$scope.duration;
      $timeout(durationCounter, 1000);
  };

  var selectVideoClip = function (id, text) {
      overlaySpinner.hide('modal');
      question.videoClipId = id;
      if (text) {
          question.text = text;
      }
      modal.close();
  };

  var displayError = function (err) {
      overlaySpinner.hide('modal');
      // ...
      console.log(err);
  };

  var attachCategory = function () {
      if ($scope.activeCategory && $scope.activeCategory != $scope.categories[0] && $scope.activeCategory.title) {
          $scope.currentVideoClip.category = $scope.activeCategory.title;
      }
      $scope.videoClipText = question.text;
  };

  $scope.categories = [];
  $scope.activeCategory = null;
  $scope.pageTypes = {
      historic: 0,
      recording: 1,
      preview: 2
  };
  $scope.showPage = $scope.pageTypes.historic;
  $scope.recording = false;
  $scope.duration = -1;
  $scope.videoSource = null;
  $scope.videoState = videoStates.idle;
  $scope.videoStates = videoStates;
  $scope.deleteConfirm = false;
  $scope.currentVideoClip = null;
  $scope.videoClipText = '';

  $scope.recordNew = function () {
      $scope.showPage = $scope.pageTypes.recording;
      $scope.duration = 0;
      initializeVideoRecording(function () {

      });
  };

  $scope.startRecording = function () {
      $scope.recording = true;
      VideoService.startRecording();
      $scope.videoState = videoStates.recording;

      $timeout(durationCounter, 1000);
  };

  $scope.stopRecording = function () {
      $scope.recording = false;
      $scope.videoState = videoStates.uploading;
      $scope.showPage = $scope.pageTypes.preview;

      var url = VideoService.stopRecording();
      var handle = VideoService.getHandle();

      //console.log(url, handle);
      if (!handle && !url) {
          console.log('recording failed');
      } else if (handle) {
          overlaySpinner.show('modal');
          Server.post('users/me/videoClips/fromHandle', { handle: handle }).then(function (result) {
              overlaySpinner.hide('modal');
              console.log(result);

              $scope.currentVideoClip = result;
              attachCategory();
              $scope.videoState = videoStates.playback;
          });
      } else {
          $scope.videoState = videoStates.playback;
          $scope.currentVideoClip = {
              url: url,
              duration: $scope.duration
          };
          attachCategory();

          $('.video-modal__player')
              .attr('src', url)
              .on('loadedmetadata', function () {
                  //console.log($('.video-modal__player')[0].duration);
              })
              .on('playing', function () {
                  //console.log('start');
                  //console.log($('.video-modal__player')[0].duration);
              }).on('ended', function () {
              //console.log('end');
              //console.log($('.video-modal__player')[0].duration);
          });
      }
  };

  $scope.showDeleteConfirm = function (v) {
      $scope.deleteConfirm = v;
  };

  $scope.deleteVideo = function () {
      $scope.deleteConfirm = false;

      if ($scope.currentVideoClip && $scope.currentVideoClip._id) {
          Server.deleteObject('users/me/videoClips', $scope.currentVideoClip._id).then(function () {
              $scope.showHistoric();
          });
      }
  };

  $scope.saveVideo = function () {

      if ($scope.currentVideoClip) {

          var id = $scope.currentVideoClip._id;

          if (!id) {

              if ($scope.currentVideoClip.url) {
                  overlaySpinner.show('modal');
                  var metaData = {
                      text: $scope.videoClipText,
                      duration: $scope.currentVideoClip.duration,
                      category: $scope.currentVideoClip.category
                  };

                  VideoClipUploader.uploadFromVideoService(metaData).then(function (result) {
                      overlaySpinner.hide('modal');

                      if (result._id) {
                          selectVideoClip(result._id, result.text);
                      } else if (result.errorMessage) {
                          console.error(result.errorMessage);
                      }
                  });

              }

          } else {
              if ($scope.videoClipText != $scope.currentVideoClip.text) {

                  $scope.currentVideoClip.text = $scope.videoClipText;

                  Server.post('users/me/videoClips/' + id, {
                      text: $scope.videoClipText
                  }).then(function () {
                      selectVideoClip(id, $scope.videoClipText);
                  });
              } else {
                  selectVideoClip(id, $scope.currentVideoClip.text);
              }
          }
      }
  };

  $scope.editVideo = function (video) {
      //console.log(video);
      $scope.showPage = $scope.pageTypes.preview;
      $scope.currentVideoClip = video;
      $scope.duration = video.duration;
      $scope.videoClipText = video.text;
  };

  $scope.showHistoric = function (previewClipId) {
      if ($scope.recording) {
          $scope.recording = false;
          VideoService.stopRecording();
      }
      $scope.videoState = videoStates.idle;
      $scope.showPage = $scope.pageTypes.historic;
      $scope.duration = -1;
      $scope.currentVideoClip = null;
      $scope.reloadData(previewClipId);
  };

  $scope.categoryClick = function (category) {
      $scope.activeCategory = category;
  };

  $scope.reloadData = function (previewClipId) {
      if (!$rootScope.questionTemplates) {
          Server.get('users/me/templates/questions').then(function (data) {
              $rootScope.questionTemplates = data;
              console.log(data);
              $scope.reloadData(previewClipId);
          });
          return;
      }

      Server.get('users/me/videoClips').then(function (list) {
          //console.log(list, $rootScope.questionTemplates);
          var allVideos = { title: 'All', videos: [] };

          $scope.categories = [allVideos];
          $rootScope.questionTemplates.categories.forEach(function (category) {
              var title = category.title[$rootScope.questionsLanguage];
              //console.log(category.title, title);
              if (title) {
                  $scope.categories.push({ title: title, videos: [] });
              }
          });

          list.forEach(function (video) {
              if (video.category) {
                  var category = $scope.categories.find(function (item) {
                      return item.title == video.category;
                  });

                  if (!category) {
                      category = { title: video.category, videos: [] };
                      $scope.categories.push(category);
                  }

                  category.videos.push(video);
              }

              allVideos.videos.push(video);
          });

          $scope.activeCategory = $scope.categories[0];

          if (question.category) {
              var category = $scope.categories.find(function (item) {
                  return item.title == question.category;
              });

              if (!category) {
                  category = { title: question.category, videos: [] };
                  $scope.categories.push(category);
              }

              $scope.activeCategory = category;
          }

          if (!previewClipId && !$scope.categories[0].videos.length) {
              $scope.recordNew();
          } else if (previewClipId) {
              var video = $scope.categories[0].videos.find(function (item) {
                  return item._id == previewClipId;
              });

              if (video) {
                  $scope.editVideo(video);
                  return;
              }

              Server.get('users/me/videoClips/' + previewClipId).then(function (videoClip) {
                  $scope.editVideo(videoClip);

                  if (!$scope.categories[0].videos.length) {
                      $scope.categories[0].videos.push(videoClip);
                  }
              });
          }
      });
  };


  $scope.showHistoric(question.videoClipId);
}]);
