//@ts-check
//@ts-ignore
angular.module('app').controller('CampaignMarketplaceCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "ToasterService", "Server", "Util", "multiSelect", "overlaySpinner", "Translate", "$timeout", "$location", "EventTracker", function ($scope, $rootScope, $state, $stateParams, ToasterService, Server, Util, multiSelect, overlaySpinner, Translate, $timeout, $location, EventTracker) {
    /**
     * @typedef VonqProduct
     * @property { boolean } inCart Manually set property to define if the product is in the cart
     * @property { string } logoUrl Manually set property to define which logo to use on the page
     * @property { string } product_id Vonq internal product ID, also used to identify a product in the cart
     * @property { string } title Product name
     * @property { string } type Product type
     * @property { Array[Object] } vonq_price Array of objects with amount and currency that define the product price
     * @property { Object } duration Object with range and period to define the product duration
     */

    setPageTitle();
    $rootScope.secondaryNav = null;
    $rootScope.backEnabled = true;

    $scope.campaignId = $stateParams.campaignId;

    let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
    $scope.multiselectSettings = { ...multiSelectln.objectSettings, displayProp: "name" };
    $scope.multiselectTexts = multiSelectln.texts;
    $scope.multiselectEvents = {
        onSelectionChanged: () => {
            $scope.searchClick();
        }
    }
    $scope.multiselectLocationEvents = {
        ...$scope.multiselectEvents,
        onInitDone: function() {
            $('#msLocations').on('keydown', _.debounce(function(event) {
                if ($(event.target).hasClass('searchField')) {
                    $scope.loadLocations(event.target.value);
                }
            }, 100));
        }
    };
    $scope.multiselectLocTexts = {
        ...multiSelectln.texts,
        buttonDefaultText: Translate.getLangString("locations")
    };
    $scope.multiselectIndTexts = {
        ...multiSelectln.texts,
        buttonDefaultText: Translate.getLangString("industry")
    }
    $scope.multiselectJobTexts = {
        ...multiSelectln.texts,
        buttonDefaultText: Translate.getLangString("job_functions")
    }
    $scope.multiselectJobSettings = {
        ...$scope.multiselectSettings,
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        checkBoxes: false,
        displayProp: 'label',
    }
    $scope.multiselectReadyToBuild = false;
    $timeout(function () {
        $scope.multiselectReadyToBuild = true;
    }, 10);

    $scope.filter = {
        name: undefined,
        locations: [],
        industries: [],
        functions: [],
    }
    
    $scope.pagination = {
        offset: 0,
        limit: 20,
        total: 0,
    }

    $scope.locations = [];
    $scope.industries = [];
    $scope.functions = [];
    $scope.highlightProducts = [];
    $scope.products = [];
    $scope.mergedProducts = [];
    $scope.orderHistory = [];

    const checkoutStep1State = 'campaign-vonq-checkout.campaign-vonq-checkout-1';

    $scope.getCampaign = function() {
        overlaySpinner.show('marketplace');
        if ($rootScope.campaign && $rootScope.campaign._id === $stateParams.campaignId) {
            setPageTitle($rootScope.campaign.title[$rootScope.campaign.language]);
        } else {
            return Server.get('campaigns/' + $stateParams.campaignId + '?sessions=true&loadJobPostingPublicationStatus=eager')
                .then((campaign) => {
                    overlaySpinner.hide('marketplace');
                    $rootScope.campaign = campaign;
                    setPageTitle($rootScope.campaign.title[$rootScope.campaign.language]);
                    return $rootScope.campaign;
                })
                .catch((err) => {
                    overlaySpinner.hide('marketplace');
                    ToasterService.failure(err, 'campaign_load_error');
                });
        }
    }

    function loadIndustries() {
        let url = 'vonq-integration/industries';
        Server.get(url)
            .then((res) => {
                $scope.industries.push(...res);
            });
    }
    function loadFunctions() {
        let url = 'vonq-integration/job-functions';
        Server.get(url)
            .then((res) => {
                const flattenJobs = [];
                const addItemsAndChilds = function(item, prefix) {
                    item.forEach(item => {
                        flattenJobs.push({
                            id: item.id,
                            name: item.name,
                            label: (prefix ? prefix : '') + item.name,
                        });
                        addItemsAndChilds(item.children, `${item.name} - `);
                    });
                }
                if (res && res.length) {
                    addItemsAndChilds(res);
                }
                $scope.functions.push(...flattenJobs);
            });
    }
    $scope.loadLocations = function(filter) {
        if (!filter) {
            $scope.locations = [];
            return;
        }
        let url = 'vonq-integration/locations';
        if (filter) {
            url += `?text=${filter}`
        }
        Server.get(url)
            .then((res) => {
                $scope.locations = res.map(loc => {
                    loc.name = loc.fully_qualified_place_name;
                    return loc;
                });
                $scope.showLocationSuggestions = $scope.locations.length > 0;
            });
    }

    $scope.onProductData = function(product) {
        if (!product) return;
        
        if (product.logo_rectangle_url && product.logo_rectangle_url.length > 0) {
            product.logoUrl = product.logo_rectangle_url[0].url;
        } else if (product.logo_url && product.logo_url.length > 0) {
            product.logoUrl = product.logo_url[0].url;
        } else if (product.logo_square_url && product.logo_square_url.length > 0) {
            product.logoUrl = product.logo_square_url[0].url;
        }

        product.vonq_price.forEach(price => {
            price.currencySymbol = Util.getCurrencySymbol(price.currency)
        })

        if ($scope.vonqCart) {
            product.inCart = $scope.vonqCart.containsProduct(product);
        }

        // if ($scope.highlightProducts.find(p => p.product_id === product.product_id)) {
        //     product.isHighlight = true;
        // }
    }

    // $scope.getHightlightProducts = function() {
    //     const getProductsOverlay = overlaySpinner.show('marketplace');
    //     return Server.get('vonq-integration/products-highlights')
    //         .then(({ results }) => {
    //             getProductsOverlay.hide();
    //             $scope.highlightProducts = results;
    //             $scope.mergeProductLists($scope.highlightProducts, $scope.products);
    //             results.forEach((product) => {
    //                 $scope.onProductData(product);
    //                 product.isHighlight = true;
    //             });
    //         })
    //         .catch((err) => {
    //             getProductsOverlay.hide();
    //             ToasterService.failure(err, 'vonq_products_load_error');
    //         });
    // }

    $scope.searchClick = function() {
        $scope.pagination.offset = 0;
        $scope.getProducts();
    }
    $scope.getProducts = function(offset) {
        if (offset !== undefined) {
            $scope.pagination.offset = offset;
        }

        const queryString = []
        if ($scope.filter.name) {
            queryString.push(`name=${$scope.filter.name}`);
        }
        if ($scope.filter.locations && $scope.filter.locations.length > 0) {
            queryString.push(`includeLocationId=${$scope.filter.locations.map(loc => loc.id).join(',')}`);
        }
        if ($scope.filter.industries && $scope.filter.industries.length > 0) {
            queryString.push(`industryId=${$scope.filter.industries.map(ind => ind.id).join(',')}`);
        }
        if ($scope.filter.functions && $scope.filter.functions.length > 0) {
            queryString.push(`jobFunctionId=${$scope.filter.functions[0].id}`);
        }

        queryString.push(`offset=${$scope.pagination.offset}&limit=${$scope.pagination.limit}`);

        const getProductsOverlay = overlaySpinner.show('marketplace');
        return Server.get('vonq-integration/products?' + queryString.join('&'))
            .then(({ results, count }) => {
                getProductsOverlay.hide();
                $scope.products = results;
                $scope.mergeProductLists($scope.highlightProducts, $scope.products);
                $scope.pagination.total = count;
                results.forEach($scope.onProductData);
            })
            .catch((err) => {
                getProductsOverlay.hide();
                ToasterService.failure(err, 'vonq_products_load_error');
            });
    }

    $scope.mergeProductLists = function(highlights, products) {
        $scope.mergedProducts = [
            ...highlights,
            ...products.filter(product => !highlights.find(h => h.product_id === product.product_id)),
        ];
    }

    $scope.onCartLoaded = function(_) {
        $scope.mergedProducts.forEach((product) => {
            if ($scope.vonqCart) {
                product.inCart = $scope.vonqCart.containsProduct(product);
            }
        });
    }
    $scope.onItemRemoved = function(item) {
        const product = $scope.mergedProducts.find(product => product.product_id === item.product_id);
        if (product) {
            product.inCart = false;
        }
    }

    $scope.loadOrderHistory = function() {
        overlaySpinner.show('marketplace');
        Server.get('campaigns/' + $stateParams.campaignId + '/vonq-integration/orders')
            .then(({ data }) => {
                if (!data ?? data.length === 0) {
                    return [];
                }

                const fetches = []
                for (let item of data) {
                    if (item.vonqCampaignId) {
                        fetches.push(
                            Server.get('campaigns/' + $stateParams.campaignId + '/vonq-integration/campaigns/' + item.vonqCampaignId)
                        );
                    }
                }
                if (fetches.length === 0) {
                    return [];
                }
                return Promise.all(fetches);
            }).then((vonqCampaigns) => {
                const orderedProducts = vonqCampaigns.flatMap((vonqCampaign) => {
                    return vonqCampaign.orderedProductsSpecs.map((product) => {
                        const posting = vonqCampaign.postings.find(p => p.productId == product.productId);

                        return {
                            product_name: posting.name,
                            date: moment(vonqCampaign.createdOn),
                            price: { amount: 0, currency: "EUR", currencySymbol: "€" },
                            status: product.status,
                            duration: product.durationPeriod,
                            clicks: posting?.clicks,
                            views: 0,
                        }
                    });

                })

                return orderedProducts.flatMap(p => p);
            })
            .then((res) => {
                overlaySpinner.hide('marketplace');
                res.forEach((hist) => {
                    const daysSinceOrder = moment().diff(hist.date, 'days');
                    if(hist.duration.period - daysSinceOrder < 0) {
                        hist.remaining_days = 0  + ` (${hist.duration.period})`
                    } else {
                        hist.remaining_days = hist.duration.period - daysSinceOrder + ` (${hist.duration.period})`
                    }
                    hist.date = hist.date.toDate();
                });
                $scope.orderHistory = res;
            })
            .catch((err) => {
                overlaySpinner.hide('marketplace');
                ToasterService.failure(err, 'vonq_history_load_error');
            });
    }

    /**
     * Add a product to the campaign cart
     * @param {VonqProduct} product 
     */
    $scope.addProduct = function(product) {
        overlaySpinner.show('marketplace');
        $scope.vonqCart.addProduct(product)
            .then(() => {
                overlaySpinner.hide('marketplace');
            });
    }

    $scope.redirectToGoogleForm = function(product) {
        // Redirect to the Google form, depending of the user language
        let googleFormBaseUrl ;
        switch ($rootScope.user.language) {
            case 0: googleFormBaseUrl = "https://docs.google.com/forms/d/1Qu6CWlHB5iU6N5FkSNmvrNJ11kzkwHbwUwoFhxwYdoQ/viewform"; break; // 0 = English
            case 1: googleFormBaseUrl = "https://docs.google.com/forms/d/1_HtiBft4giR_21qXkpFEHWux80vw68cdBFXPuZp8Kzk/viewform"; break; // 1 = French
            case 2: googleFormBaseUrl = "https://docs.google.com/forms/d/1Qu6CWlHB5iU6N5FkSNmvrNJ11kzkwHbwUwoFhxwYdoQ/viewform"; break; // 2 = Dutch
            case 3: googleFormBaseUrl = "https://docs.google.com/forms/d/1Qu6CWlHB5iU6N5FkSNmvrNJ11kzkwHbwUwoFhxwYdoQ/viewform"; break; // 3 = German (Redirect to english form)
            case 4: googleFormBaseUrl = "https://docs.google.com/forms/d/1Qu6CWlHB5iU6N5FkSNmvrNJ11kzkwHbwUwoFhxwYdoQ/viewform"; break; // 4 = Portuguese (Redirect to english form)
            default: googleFormBaseUrl = "https://docs.google.com/forms/d/1Qu6CWlHB5iU6N5FkSNmvrNJ11kzkwHbwUwoFhxwYdoQ/viewform"; break; // Default = English
        }
    
        // Check if values are available
        const productName = encodeURIComponent(product.title || '');
        const campaignName = encodeURIComponent($rootScope.campaign?.title[$rootScope.campaign.language] || '');
        const campaignId = encodeURIComponent($stateParams.campaignId || '');
        const contactEmail = encodeURIComponent($rootScope.user?.email || '');
    
        // Build url
        const finalUrl = `${googleFormBaseUrl}?entry.1289184998=${productName}&entry.983428150=${campaignName}&entry.121103524=${campaignId}&entry.121583066=${contactEmail}`;
    
        // Redirect to the Google form
        window.open(finalUrl, '_blank');
    };
    

    /**
     * Remove a product from the campaign cart
     * @param {VonqProduct} product 
     */
    $scope.removeProduct = function(product) {
        overlaySpinner.show('marketplace');
        $scope.vonqCart.removeProduct(product)
            .then(() => {
                overlaySpinner.hide('marketplace');
            });
    }

    $scope.checkout = function() {
        if ($scope.vonqCart.isEmpty) {
            return;
        }
        EventTracker.trackVonqCheckout();
        $state.go(checkoutStep1State, { campaignId: $stateParams.campaignId });
    }

    function setPageTitle(campaignName) {
        const tabTitle = Translate.getLangString('job_board_marketplace')
        if (campaignName) {
            const navTitle = `${campaignName}: ${tabTitle}`;
            Util.setPageTitle(tabTitle, campaignName, navTitle);
        } else if (!$rootScope.pageTitle) {
            Util.setPageTitle(tabTitle);
        }
    }

    $scope.getCampaign();
    // $scope.getHightlightProducts();
    $scope.getProducts();
    $scope.loadOrderHistory();
    
    // Commenting this below since we are not using API but json
    // loadFunctions();
    // loadIndustries();
}]);
