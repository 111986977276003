//@ts-check
DashboardDepartmentController.$inject = ["$scope", "$rootScope", "$state", "Server", "Translate", "ToasterService", "overlaySpinner", "PopupService", "Util", "$q"];
angular.module('app').controller('DashboardDepartmentCtrl', DashboardDepartmentController)

function DashboardDepartmentController($scope, $rootScope, $state, Server, Translate, ToasterService, overlaySpinner, PopupService, Util, $q) {
  Util.setPageTitle(Translate.getLangString('departments'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = false;

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights('hasAdminRights', true, user) || !$rootScope.fns.hasCollaboratorsSettings(['useDepartments'], user)) {
      $state.go('campaigns');
    }
  });

  $scope.itemActions = [];

  /** @type { CrudListProperty[] } */
  const departmentsProperties = [
    {
      type: 'text',
      location: 'header',
      text: Translate.getLangString("name"),
      key: 'name',
      isGlobalFilter: true,
      dataCssClass: 'department__header__name crud-list__title__col',
      filterCssClass: 'department__header__name crud-list__title__col',
      noFilter: true,
    },
    {
        type: 'badge',
        location: 'header',
        text: Translate.getLangString("campaigns"),
        emptyText: Translate.getLangString("no_campaign"),
        key: 'campaignsNames',
        dataCssClass: 'department__header__campaigns',
        filterCssClass: 'department__header__campaigns',
        noFilter: true,
    },
    {
        type: 'badge',
        location: 'header',
        text: Translate.getLangString("collaborators"),
        emptyText: Translate.getLangString("no_collaborator"),
        key: 'collaboratorsNames',
        dataCssClass: 'department__header__campaigns',
        filterCssClass: 'department__header__campaigns',
        noFilter: true,
    },
  ]
  $scope.departmentsProperties = departmentsProperties
  $scope.crud_translation = {
    title: 'departments',
    items: 'departments',
    add_item: 'add_department',
  };

  $scope.loadDepartments = function () {
    overlaySpinner.show('departments');
    return Server.get('departments?campaigns=true&collaborators=true').then(async function (departments) {
        overlaySpinner.hide('departments');
        $rootScope.departments = departments;
        departments.filter(collab => collab.isPending).forEach(collab => {
            collab.name = Translate.getLangString("collaborator_pending_name");
            collab.position = '';
        })

        if ($rootScope.departments.length > 0) {
            $rootScope.departments.forEach(department => {
              $scope.setDepartmentCampaigns(department, department.campaigns);
              $scope.setDepartmentCollaborators(department, department.collaborators);
            });
        }

        if (!$rootScope.user) {
          const user = await $rootScope.reloadUser();
          $scope.setItemsOptions(user);
        } else {
          $scope.setItemsOptions();
        }
    });
  }

  $scope.setDepartmentCampaigns = function(department, campaigns) {
    department.campaigns == campaigns;
    department.campaignsNames = campaigns.map(campaign => Util.getDisplayedTitleAndArchiveStatus(campaign, $rootScope.user));
  }
  $scope.setDepartmentCollaborators = function(department, collaborators) {
    department.collaborators == collaborators;
    department.collaboratorsNames = collaborators.map(collaborator => Util.userFullName(collaborator));
  }

  $scope.loadCampaigns = async function() {
    if ($rootScope.campaigns && $rootScope.campaigns.length > 0) {
      const deferred = $q.defer();
      deferred.resolve($rootScope.campaigns);
      return deferred.promise;
    } else {
      try {
        return Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true');
      } catch (err) {
        ToasterService.failure(err, 'campaign_load_error');
        const deferred = $q.defer();
        deferred.resolve([]);
        return deferred.promise;
      }
    }
  };
  $scope.loadCollaborators = function () {
    return Server.get('collaborators?includesSelf=true').catch(err => {
      ToasterService.failure(err, 'collaborators_load_error');
      const deferred = $q.defer();
      deferred.resolve([]);
      return deferred.promise;
    });
  }

  $scope.setItemsOptions = function () {
    const newActions = []

    if ($rootScope.fns.userHasRights('campaigns.list', 'edit')) {
      newActions.push({
        text: Translate.getLangString('department_manage_campaigns'),
        action: async (department) => {
          const checkedCampaigns = await PopupService.openAssignableData(
            $scope,
            {
              modalTitle: Translate.getLangString('campaigns'),
              sourceItem: department,
              targets: $scope.campaigns,
              getLabel: (target) => {
                return Util.getDisplayedTitleAndArchiveStatus(target, $rootScope.user);
              },
              isAssigned: (source, target) => {
                return target.departmentIds ? target.departmentIds.some(m => m === source._id) : false;
              },
              onAssign: (id) => {
                return Server.post('campaigns/' + id + '/assign-department/' + department._id)
                  .then(res => {
                    const campaign = $scope.campaigns.find(campaign => campaign._id === id);
                    campaign.departmentIds.push(department._id);
                    return res;
                  });
              },
              onUnassign: (id) => {
                return Server.post('campaigns/' + id + '/unassign-department/' + department._id)
                  .then(res => {
                    const campaign = $scope.campaigns.find(campaign => campaign._id === id);
                    campaign.departmentIds = campaign.departmentIds.filter(depId => depId !== department._id);
                    return res;
                  });
              }
            }
          )
          $scope.setDepartmentCampaigns(department, checkedCampaigns);
          $scope.$apply();
        },
      });
    }

    if ($rootScope.fns.userHasRights('hasAdminRights', 'edit')) {
      newActions.push({
        text: Translate.getLangString('department_manage_collaborators'),
        action: async (department) => {
          const checkedCollaborators = await PopupService.openAssignableData(
            $scope,
            {
              modalTitle: Translate.getLangString('collaborators'),
              sourceItem: department,
              targets: $scope.collaborators,
              getLabel: (target) => {
                return Util.userFullName(target);
              },
              isAssigned: (source, target) => {
                return target.departmentIds ? target.departmentIds.some(m => m === source._id) : false;
              },
              onAssign: (id) => {
                return Server.post('collaborators/' + id + '/assign-department/' + department._id)
                  .then(res => {
                    const collaborator = $scope.collaborators.find(collaborator => collaborator._id === id);
                    collaborator.departmentIds.push(department._id);
                    return res;
                  });
              },
              onUnassign: (id) => {
                return Server.post('collaborators/' + id + '/unassign-department/' + department._id)
                  .then(res => {
                    const collaborator = $scope.collaborators.find(collaborator => collaborator._id === id);
                    collaborator.departmentIds = collaborator.departmentIds.filter(depId => depId !== department._id);
                    return res;
                  });
              }
            }
          )
          $scope.setDepartmentCollaborators(department, checkedCollaborators);
          $scope.$apply();
        },
      });
    }

    $scope.itemActions = newActions;
  }

  $scope.isItemEditable = function() {
    return true;
  }

  $scope.validateInputs = function(department) {
    const errorFields = {};
    if (!department.name) {
      errorFields.name = true;
    }
    return errorFields;
  }

  $scope.saveDepartment = function(department) {
    const overlay = overlaySpinner.show('departments');
    const departmentDto = JSON.parse(JSON.stringify(department));
    if (department._id) {
      return Server.put(`departments/${department._id}`, departmentDto)
        .then(() => {
          overlay.hide();
          ToasterService.success('department_updated');
          if ($scope.$parent.reloadCandidate) {
              $scope.$parent.reloadCandidate();
          }
          return department;
        })
        .catch((err) => {
            overlay.hide();
            ToasterService.failure(err, 'department_not_updated');
        });
    } else {
      return Server.post(`departments`, departmentDto)
        .then((res) => {
          overlay.hide();
          ToasterService.success('department_created');
          department._id = res._id;
          $scope.loadDepartments();
          return department;
        })
        .catch(err => {
          overlay.hide();
          ToasterService.failure(err, 'department_not_created');
        });
    }
  }

  $scope.deleteDepartment = function(department) {
    const overlay = overlaySpinner.show('departments');
    return Server.delete(`departments/${department._id}`, department)
    .then(() => {
      overlay.hide();
      ToasterService.success('department_deleted');
      if ($scope.$parent.reloadCandidate) {
          $scope.$parent.reloadCandidate();
      }
    })
    .catch((err) => {
      overlay.hide();
      ToasterService.failure(err, 'department_not_deleted');
      $scope.loadDepartments();
    });
  }

  $q(async function(resolve, reject) {
    $scope.campaigns = await $scope.loadCampaigns().catch(reject)
    $scope.collaborators = await $scope.loadCollaborators().catch(reject)
    await $scope.loadDepartments().catch(reject)
    resolve()
  });
}