//@ts-check
angular.module('app').controller('CandidateProfileCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$timeout", "$state", "PopupService", "overlaySpinner", "Translate", "ToasterService", "Util", function ($scope, $rootScope, $stateParams, Server, $timeout, $state, PopupService, overlaySpinner, Translate, ToasterService, Util) {
  $rootScope.profilePhoto = Util.profilePhoto;
  $scope.isloggedIn = false;

  // variables for language selection
  $scope.changeVisitorLanguage = Translate.changeVisitorLanguage;
  $scope.changeVisitorLanguage = function (val) {
      Translate.changeVisitorLanguage(val);
  };
  $scope.ddLangs = Translate.getLangDropdownObject();
  Translate.initVisitorLanguageFromNavigator(0);

  const queryString = Util.getQueryString(location);
  if (queryString.error) {
    setTimeout(() => ToasterService.failure({}, queryString.error), 0)
  }

    $scope.scrollToSection = function (selector) {
        let yOffset = -80;
        let element = document.querySelector(selector);
        let scrollable = document.querySelector('.career-app__container') || element.parentElement;
        if (!element) {
            console.warn(`no element found to scroll with selector ${selector}`);
            return;
        }
        const elementBoundary = element.getBoundingClientRect();
        let y = elementBoundary.top + scrollable.scrollTop + yOffset;
        scrollable.scrollTo({ top: y, behavior: 'smooth' });
    }

  $scope.Logout = function () {
      Server.get('candidates/candidate-area/me/editProfiles/logout')
          .then(function (res) {
              $state.go('insert-email');
              $scope.isloggedIn = false;
          }, function (err) {
              console.log('err', err);
          });
  };

    //Check profile candidate
    $scope.reloadCandidatesProfiles = function () {
        if (location.pathname.includes('candidate-area') && location.pathname.includes('accept')) {
            const queryString = Util.getQueryString(location)
            if (queryString.auth) {
                $state.go('login');
            } else {
                $state.go('insert-email');
            }
        } else if (location.pathname.includes('candidate-area') && location.pathname.includes('reset-password')) {
            if (queryString.auth) {
                $state.go('reset-password');
            } else {
                $state.go('insert-email');
            }
        } else {
            return Server.get('candidates/candidate-area/me/getCandidatesWithSameEmail')
                .then(function (res) {
                    if (res && res.candidates) {
                        // the candidate is logged => go to info page
                        $rootScope.candidates = res.candidates;
                        $scope.isloggedIn = true;
                        $state.go('info');
                    } else {
                        // the candidate is not logged => go to email input page
                        $state.go('insert-email');
                    }
                }, function (err) {
                    // the candidate is not logged => go to email input page
                    console.log('err', err);
                    $state.go('insert-email');
                });
        }
    };

    // collapse and expand for mobile phones
    $scope.mainNav = document.getElementById('js-menu');
    $scope.toggleNavbar = function(opts) {
        $scope.mainNav.classList.toggle('active');
        if (opts && opts.forceClose) {
            $scope.mainNav.classList.remove('active');
        }
    }

  $scope.reloadCandidatesProfiles();
}]);
