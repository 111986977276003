//@ts-check
//@ts-ignore
kanbanViewCtrl.$inject = ["$scope", "$rootScope", "$element", "$attrs", "$q"];
angular.module('app')
  .component('kanbanView', {
    templateUrl: '../templates/components/kanban-view.component.html',
    controller: kanbanViewCtrl,
    bindings: {
      items: '<',
      states: '<',
      getItemSref: '&',
      onStateChange: '&',
      onItemChecked: '&',
    }
  })

/**
 * 
 * @param {KanbanviewScope} $scope 
 * @param {any} $rootScope
 * @param { Element } $element
 * @param { any } $attrs
 */
function kanbanViewCtrl($scope, $rootScope, $element, $attrs, $q) {
  $scope.groupedItems = {};

  $scope.groupItems = function() {
    for(const state of $scope.$ctrl.states) {
      const stateItems = $scope.$ctrl.items.filter((item) => !item.stateId && !state.id || item.stateId === state.id);
      $scope.groupedItems[state.id] = stateItems;
    }
  }

  $scope.onStateChanged = function(index, item, state) {
    if (item.stateId === state.id) {
      return false;
    }
    const oldState = item.stateId
    if ($attrs.onStateChange) {
      const deferred = $q.defer();
      $scope.$ctrl.onStateChange({
        $item: item,
        $state: state,  
      }).then(() => {
        deferred.resolve();
      }).catch((err) => {
        setTimeout(() => {
          item.stateId = oldState;
          $scope.groupItems();
        }, 0)
        deferred.reject(err);
      });
    }

    if (state.total !== undefined) {
      state.total += 1;
    }

    item.stateId = state.id;
    return item;
  }

  $scope.onItemClick = function(index) {
    
  }

  $scope.onItemMoved = function(kanbanItem, state, index) {
    $scope.groupedItems[state.id].splice(index, 1);
    if (state.total !== undefined) {
      state.total -= 1;
    }
  }

  $scope.onItemChecked = function(item, forcedState) {
    item.isChecked = forcedState !== undefined ? forcedState : !item.isChecked;
    if ($attrs.onItemChecked) {
      $scope.$ctrl.onItemChecked({ $item: item })
    }
  }

  $scope.getStateItems = function(state) {
    return $scope.groupedItems[state.id]
  }
  $scope.onStateChecked = function(state) {
    switch (state.checkStatus) {
      case 'all':
        state.checkStatus = 'none';
        break;
      default:
        state.checkStatus = 'all';
    }

    for (const item of $scope.getStateItems(state)) {
      const forceCheck = state.checkStatus === 'all';
      if (item.isChecked !== forceCheck) {
        $scope.onItemChecked(item, forceCheck);
      }
    }
  }
  $scope.syncStateCheck = function() {
    for(const state of $scope.$ctrl.states) {
      const stateItems = $scope.getStateItems(state);
      if (stateItems.length > 0) {
        if (stateItems.every(item => item.isChecked)) {
          state.checkStatus = 'all';
        } else if (stateItems.some(item => item.isChecked)) {
          state.checkStatus = 'some';
        } else {
          state.checkStatus = 'none';
        }
      }
    }
  }
  $scope.countStateItems = function(state) {
    return $scope.getStateItems(state).length;
  }

  $scope.$ctrl.checkAll = function(forceCheck) {
    for (const item of $scope.$ctrl.items) {
      $scope.$ctrl.checkItem(item, forceCheck);
    }
  }
  $scope.$ctrl.checkAllState = function(state, forceCheck) {
    for (const item of $scope.getStateItems(state)) {
      $scope.$ctrl.checkItem(item, forceCheck);
    }
  }
  $scope.$ctrl.checkItem = function(item, forceCheck) {
    $scope.onItemChecked(item, forceCheck);
    $scope.syncStateCheck();
  }

  $scope.$ctrl.$onChanges = function(changeObj) {
    if (changeObj.items && changeObj.items.currentValue !== changeObj.items.previousValue) {
      $scope.groupItems();
      $scope.syncStateCheck();
    }
  }

}

/**
 * @typedef KanbanviewScope
 * @property { any } $ctrl
 * @property { () => void } $apply
 * @property { Record<string, KanbanItem[]> } groupedItems
 * @property { () => void } groupItems
 * @property {(state: KanbanState) => (item) => boolean} filterState 
 * @property {(index: number, item: KanbanItem, state: KanbanState) => void} onStateChanged 
 * @property {(index: number) => void} onItemClick
 * @property {(kanbanItem: KanbanItem, state: KanbanState, index: number) => void} onItemMoved
 * @property {(item: KanbanItem, forcedState?: boolean) => void } onItemChecked
 * @property {(item: KanbanItem) => void } getItemSref
 * @property {(state: KanbanState) => KanbanItem[]} getStateItems
 * @property {(state: KanbanState) => void} onStateChecked
 * @property {() => void} syncStateCheck
 * @property {(state: KanbanState) => number} countStateItems
 */

/**
 * @typedef KanbanItemAction
 * @property { Object } iconClass
 * @property { string } sref
 * @property { string } href
 * @property { string } label
 */

/**
 * @typedef KanbanItem
 * @property { string } title
 * @property { string } subTitle
 * @property { string } matching
 * @property { string } rating
 * @property { string } stateId
 * @property { boolean } isChecked
 * @property { KanbanItemAction[] } actions
 * @property { any } [value]
 */

/**
 * @typedef KanbanState
 * @property { string } id
 * @property { string } label
 * @property { number } [total]
 * @property { Object } style
 * @property { 'all'|'some'|'none'} checkStatus
 */