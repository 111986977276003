//@ts-check
//@ts-ignore
UserRightsManagerCtrl.$inject = ["$scope", "$rootScope", "$attrs", "Server", "ToasterService", "multiSelect", "Translate"];
angular.module('app')
  .component('userRightsManager', {
    templateUrl: '../templates/components/user-rights-manager.component.html',
    controller: UserRightsManagerCtrl,
    bindings: {
      managedRights: '<',
      contact: '<',
      onUserRightSelected: '&'
    },
  });

/**
 * 
 * @param {UserRightsManagerScope} $scope
 */
function UserRightsManagerCtrl($scope, $rootScope, $attrs, Server, ToasterService, multiSelect, Translate) {
  $scope.userRightsList = [];
  $scope.allChecked = false;
  $scope.isSSOManaged = false;
  $scope.ssoDomains = $scope.$parent?.$parent?.ssoDomains || []; // get ssoDomains from parent > parent scope

  function getDomainFromEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      const domain = email.split('@')[1];
      return domain;
    } else {
      return null;
    }
  }

  this.$onInit = function() {
    if ($scope.$ctrl.contact && getDomainFromEmail($scope.$ctrl.contact)) {
      const emailDomain = getDomainFromEmail($scope.$ctrl.contact);
      if ($scope.ssoDomains.includes(emailDomain)) {
        $scope.isSSOManaged = true;
      }
    }
  };

  $scope.$watch('$ctrl.contact', function(newVal, oldVal) {
    if (newVal !== oldVal && getDomainFromEmail(newVal)) {
      const emailDomain = getDomainFromEmail(newVal);
      if ($scope.ssoDomains.includes(emailDomain)) {
        $scope.isSSOManaged = true;
      } else {
        $scope.isSSOManaged = false;
      }
    }
  });

  $scope.specialUserRightSettings = {
    ...multiSelect.objectSettings,
    dynamicTitle: true,
    smartButtonMaxItems: 4,
    idProperty: "id",
    displayProp: 'label',
  };
  $scope.specialUserRightSettingsTexts = multiSelect.texts;
  $scope.specialUserRightSettingsEvents = {
    onSelectionChanged: function(item) {
      const candidateStagesItem = $scope.specialUserRightsList.find(userRight => userRight.key === 'candidateStages')
      if (candidateStagesItem && Array.isArray(candidateStagesItem.userRights)) {
        $scope.$ctrl.managedRights.special.candidateStages = candidateStagesItem.userRights.map(x => x.id);
      }
    },
  };

  $scope.initComponent = function() {
    $rootScope.loadUserRightsMapping()
      .then((userRights) => {
        $scope.validRights = userRights
        $scope.buildUserRightsList();
        $scope.verifyCheckboxTristate();
        setTimeout(() => { $scope.$apply() }, 0);
      });
  }

  $scope.buildUserRightsList = function() {
    $scope.userRightsList = [];
    $scope.specialUserRightsList = [];

    $scope.hasAdminRightsChecked = !!$scope.$ctrl.managedRights?.hasAdminRights;

    for (const key in $scope.validRights.basic) {
      const item = $scope.validRights.basic[key];
      if (typeof item === 'object') {
        /** @type { UserRightsListItem[] } */
        const userRights = [];
        for (const subKey in item) {
          const userRightKey = `${key}.${subKey}`;
          const selectedValue = _.get($scope.$ctrl.managedRights?.basic, userRightKey);

          /* THIS MAY BE TEMPORARY */
          // Allows only users with privilege 'canUseCampaignStatus' field to see the campaigns.status user right
          if (userRightKey === 'campaigns.status' && !$rootScope.fns.hasPrivileges(['canUseCampaignStatus'])) {
            continue;
          }

          let userRight = {
            key: userRightKey,
            userRights: selectedValue,
            deleteCheck: selectedValue === 'delete',
            editCheck: ['edit', 'delete'].includes(selectedValue),
            viewCheck: ['view', 'edit', 'delete'].includes(selectedValue),
            deleteVisible: item[subKey].includes('delete'),
            editVisible: item[subKey].includes('edit'),
            viewVisible: item[subKey].includes('view'),
          };

          userRights.push(userRight);
        }
        $scope.userRightsList.push({
          key: key,
          userRights: userRights,
          deleteCheck: userRights.every(item => item.deleteCheck),
          editCheck: userRights.every(item => item.editCheck),
          viewCheck: userRights.every(item => item.viewCheck),
          deleteVisible: userRights.some(item => item.deleteVisible),
          editVisible: userRights.some(item => item.editVisible),
          viewVisible: userRights.some(item => item.viewVisible),
        });
      }
    }

    $scope.mirroredRights = []
    const tasksRight = $scope.userRightsList.find(userRight => userRight.key === 'tasks')
    const candidatesRight = $scope.userRightsList.find(userRight => userRight.key === 'candidates')
    if (tasksRight && candidatesRight) {
      const tasksListRight = Array.isArray(tasksRight.userRights) && tasksRight.userRights.find(userRight => userRight.key === 'tasks.list')
      if (tasksListRight) {
        const mirroredRight = {
          ...tasksListRight,
          key: 'candidates.tasks',
          originalKey: 'tasks.list',
          readonly: true,
        };
        Array.isArray(candidatesRight.userRights) && candidatesRight.userRights.push(mirroredRight);
        $scope.mirroredRights.push(mirroredRight);
      }
    }

    const useDepartments = _.get($rootScope.user, 'settings.collaborators.useDepartments');
    for (const key in $scope.validRights.special) {
      const validValues = _.get($scope.validRights.special, key);
      const selectedValue = _.get($scope.$ctrl.managedRights?.special, key);
      const specialRightItem = {
        key,
        text: key === "accessAllCampaigns" && useDepartments ? "accessAllDepartments" : key,
        userRights: selectedValue !== undefined ? selectedValue : validValues.options,
      }

      if (validValues.options) {
        specialRightItem.options = validValues.options.map(opt => ({
          id: opt.id,
          label: typeof opt.label === 'string' ? opt.label : opt.label[Translate.currentLanguage()]
        }));
        specialRightItem.userRights = selectedValue && specialRightItem.options ? specialRightItem.options.filter(opt => selectedValue.some(val => val === opt.id)) : [];
      } else {
        specialRightItem.userRights = selectedValue;
      }

      $scope.specialUserRightsList.push(specialRightItem);
    }
  }

  $scope.checkHasAdminRights = function() {
    $scope.onUserRightSelected(null, 'hasAdminRights', $scope.hasAdminRightsChecked);
  }

  $scope.selectAll = function() {
    $scope.userRightsList.forEach(item => {

      return $scope.toggleCategory(item, undefined, $scope.allChecked);
    });
    $scope.specialUserRightsList.forEach(item => {
      if (item.options) {
        $scope.selectAllSpecialRight(item, !!$scope.allChecked);
      } else {
        $scope.toggleSpecialUserRight(item, !!$scope.allChecked);
      }
    });
  }

  /**
   * 
   * @param {UserRightsListItem} item 
   * @param {UserRightsLevels} [userRightsLevel] 
   * @param {boolean|null} [forcedState] 
   */
  function getCheckState(item, userRightsLevel, forcedState) {
    if (forcedState !== undefined && forcedState !== null) {
      return forcedState;
    }
    switch (userRightsLevel) {
      case "view":
        return item.viewCheck || false;
      case "edit":
        return item.editCheck || false;
      case "delete":
        return item.deleteCheck || false;
      default:
        return (item.viewCheck && item.editCheck && item.deleteCheck) || false;
    }
  }
  
  $scope.setCheckState = function(item, userRightsLevel, forcedState) {
    if (userRightsLevel) {
      switch (userRightsLevel) {
        case "view":
          if (getCheckState(item, userRightsLevel, forcedState)) {
            item.viewCheck = true;
          } else {
            item.viewCheck = false;
            item.editCheck = false;
            item.deleteCheck = false;
          }
          break;
        case "edit":
          if (getCheckState(item, userRightsLevel, forcedState)) {
            item.viewCheck = true;
            item.editCheck = true;
          } else {
            item.editCheck = false;
            item.deleteCheck = false;
          }
          break;
        case "delete":
          if (getCheckState(item, userRightsLevel, forcedState)) {
            item.viewCheck = true;
            item.editCheck = true;
            item.deleteCheck = true;
          } else {
            item.deleteCheck = false;
          }
          break;
      }
    } else {
      item.viewCheck = getCheckState(item, userRightsLevel, forcedState);
      item.editCheck = item.viewCheck;
      item.deleteCheck = item.viewCheck;
    }
  }
  $scope.toggleCategory = function(item, userRightsLevel, forcedState) {
    if (Array.isArray(item.userRights)) {
      $scope.setCheckState(item, userRightsLevel, forcedState);
      for (const subItem of item.userRights) {
        const newState = getCheckState(item, userRightsLevel, forcedState)
        if(subItem.key.indexOf('.') > -1) {
          $scope.toggleUserRight(subItem, userRightsLevel, !!newState);
        } else {
          $scope.toggleCategory(subItem, userRightsLevel, !!newState);
        }
      }
    }
  }
  $scope.toggleUserRight = function(item, userRightLevel, forcedState) {

    if (!Array.isArray(item.userRights)) {
      $scope.setCheckState(item, userRightLevel, forcedState);
      /** @type { UserRightsLevels } */
      let selectedUserRight = 'hide';
      if (item.deleteCheck) {
        selectedUserRight = 'delete'
      } else if (item.editCheck) {
        selectedUserRight = 'edit'
      } else if (item.viewCheck) {
        selectedUserRight = 'view'
      }
      $scope.onUserRightSelected('basic', item.key, selectedUserRight);
    }

    const mirroredRights = $scope.mirroredRights.filter(userRight => userRight.originalKey === item.key)
    if (mirroredRights.length) {
      mirroredRights.forEach(userRight => {
        userRight.viewCheck = item.viewCheck;
        userRight.editCheck = item.editCheck;
        userRight.deleteCheck = item.deleteCheck;
        userRight.userRights = item.userRights;
      })
    }
  }

  $scope.checkboxChanged = function(item, userRightLevel, forcedState) {
    $scope.toggleCategory(item, userRightLevel, forcedState);
    $scope.toggleUserRight(item, userRightLevel, forcedState);
    $scope.verifyCheckboxTristate();
  }

  $scope.toggleSpecialUserRight = function(item, forcedState) {
    if (forcedState !== undefined) {
      item.userRights = forcedState;
    }
    $scope.onUserRightSelected('special', item.key, item.userRights);
  }

  $scope.selectAllSpecialRight = function(item, forcedState) {
    if (forcedState !== undefined) {
      item.userRights = forcedState ? item.options||[] : [];
    }
    $scope.specialUserRightSettingsEvents.onSelectionChanged({});
    $scope.onUserRightSelected('special', item.key, item.userRights);
  }

  $scope.specialCheckboxChanged = function(item) {
    $scope.toggleSpecialUserRight(item);
    $scope.verifyCheckboxTristate();
  }

  $scope.verifyCheckboxTristate = function() {
    let allChecked = true;
    let allUnchecked = true;
    $scope.userRightsList.forEach(userRightsListItem => {
      if (Array.isArray(userRightsListItem.userRights)) {
        if (userRightsListItem.userRights.every(p => p.viewCheck))  {
          userRightsListItem.viewCheck = true;
        } else if (userRightsListItem.userRights.every(p => !p.viewCheck)) {
          userRightsListItem.viewCheck = false;
        } else {
          userRightsListItem.viewCheck = null;
        }

        if (userRightsListItem.userRights.every(p => !p.editVisible || p.editCheck))  {
          userRightsListItem.editCheck = true;
        } else if (userRightsListItem.userRights.every(p => !p.editVisible || !p.editCheck)) {
          userRightsListItem.editCheck = false;
        } else {
          userRightsListItem.editCheck = null;
        }

        if (userRightsListItem.userRights.every(p => !p.deleteVisible || p.deleteCheck))  {
          userRightsListItem.deleteCheck = true;
        } else if (userRightsListItem.userRights.every(p => !p.deleteVisible || !p.deleteCheck)) {
          userRightsListItem.deleteCheck = false;
        } else {
          userRightsListItem.deleteCheck = null;
        }
      }
      allChecked = allChecked && userRightsListItem.deleteCheck === true
      allUnchecked = allUnchecked && userRightsListItem.viewCheck === false
    })

    $scope.specialUserRightsList.forEach(userRightsListItem => {
      allChecked = allChecked && userRightsListItem.userRights === true
      allUnchecked = allUnchecked && userRightsListItem.userRights === false
    })

    $scope.allChecked = allChecked ? true : allUnchecked ? false : null;
  }

  $scope.onUserRightSelected = function(type, name, level) {
    if ($attrs['onUserRightSelected']) {
      $scope.$ctrl.onUserRightSelected({ $type: type, $name: name, $level: level });
    } else {
      _.set($scope.$ctrl.managedRights, type ? `${type}.${name}` : name, level);
    }
  }

  $scope.$ctrl.$onChanges = function(changes) {
    if (changes.managedRights && changes.managedRights.currentValue !== changes.managedRights.previousValue) {
      $scope.initComponent();
    }
  }

  $scope.initComponent();
}

/**
 * @typedef UserRightsLevels
 * @type { 'hide'|'view'|'edit'|'delete' }
 */
/**
 * @typedef UserRightsTypeEnum
 * @type { null|'basic'|'special' }
 */
/**
 * @typedef ValidUserRightsMap
 * @prop { boolean } hasAdminRights
 * @prop { Record<string, UserRightsLevels|Record<string, UserRightsLevels>> } basic
 * @prop { Record<string, boolean|string[]> } special
 */
/**
 * @typedef UserRightsItem
 * @type { Record<string, UserRightsLevels> }
 */
/**
 * @typedef UserRightParam
 * @type { Record<string, UserRightsItem>|UserRightsItem }
 */

/**
 * @typedef UserRightsManagerController
 * @property { ValidUserRightsMap } managedRights
 * @property { String } contact
 * @property { ({}: { $type: UserRightsTypeEnum, $name: string, $level: UserRightsLevels|boolean|any[] } ) => void } onUserRightSelected
 * @property { (changes: any) => void } $onChanges
 */

/**
 * @typedef UserRightsListItem
 * @property { string } key
 * @property { string } [originalKey]
 * @property { UserRightsLevels|UserRightsListItem[] } userRights
 * @property { boolean|null } [viewCheck]
 * @property { boolean|null } [editCheck]
 * @property { boolean|null } [deleteCheck]
 * @property { boolean } [viewVisible]
 * @property { boolean } [editVisible]
 * @property { boolean } [deleteVisible]
 * @property { boolean } [readonly]
 */

/**
 * @typedef SpecialUserRightsListItem
 * @property { string } key
 * @property { string } text
 * @property { boolean|any[] } userRights
 * @property { any[] } [options]
 */

/**
 * @typedef UserRightsManagerScope
 * @property { UserRightsManagerController } $ctrl
 * @property { ValidUserRightsMap } validRights
 * @property { UserRightsListItem[] } userRightsList
 * @property { UserRightsListItem[] } mirroredRights
 * @property { SpecialUserRightsListItem[] } specialUserRightsList
 * @property { boolean } hasAdminRightsChecked
 * @property { boolean|null } allChecked
 * @property { () => void } buildUserRightsList
 * @property { () => void } checkHasAdminRights
 * @property { () => void } selectAll
 * @property { (item: UserRightsListItem, level: UserRightsLevels|undefined, forceState: boolean|null) => void } setCheckState 
 * @property { (item: UserRightsListItem, level: UserRightsLevels|undefined, forceState: boolean|null) => void } toggleCategory
 * @property { (item: UserRightsListItem, level: UserRightsLevels|undefined, forceState: boolean|null) => void } toggleUserRight 
 * @property { (item: UserRightsListItem, level: UserRightsLevels|undefined, forceState: boolean|null) => void } checkboxChanged
 * @property { (item: SpecialUserRightsListItem, forceState?: boolean) => void } toggleSpecialUserRight
 * @property { (item: SpecialUserRightsListItem, forceState?: boolean) => void } selectAllSpecialRight
 * @property { (item: SpecialUserRightsListItem) => void } specialCheckboxChanged
 * @property { () => void } verifyCheckboxTristate
 * @property { (userRightsItem: UserRightParam, userRightsValue: UserRightsLevels) => void } selectLevel
 * @property { (type: UserRightsTypeEnum, name: string, userRightsLevel: UserRightsLevels|boolean|any[]) => void } onUserRightSelected
 * @property { () => void } initComponent
 * @property { any } specialUserRightSettings
 * @property { any } specialUserRightSettingsTexts
 * @property { any } specialUserRightSettingsEvents
 * @property { boolean } isSSOManaged
 * @property { any } $watch
 * @property { string[] } ssoDomains
 * @property { any } $parent
 * angular implementations
 * @property { () => void } $apply
 */