angular.module('app').controller('LoginCtrl', ["$scope", "$rootScope", "Server", "Translate", "Util", "toaster", "env", function ($scope, $rootScope, Server, Translate, Util, toaster, env) {
  var reset = function () {
      $scope.email = '';
      $scope.password = '';
      $scope.password1 = '';
      $scope.password2 = '';
      $scope.errMessage = '';
      $scope.sending = false;
      $scope.forgotPassword = 0;
  };

//   $scope.submit = function () {
//       $scope.errMessage = Translate.getLangString('login_connecting');
//       $scope.sending = true;
//       Server.post('users/login', {
//           username: $scope.email,
//           password: Hash.make($scope.password)
//       }).then(function (r) {
//           window.location.replace('/dashboard');

//       }, function (r) {
//           $scope.errMessage = '';
//           $scope.sending = false;

//           if (r && r.error) {
//               switch (r.error) {
//                   case -1: // general error
//                       $scope.errMessage = Translate.getLangString('login_unknown_error');
//                       break;
//                   case -2: // username not exist
//                       $scope.errMessage = Translate.getLangString('login_invalid_username_error');
//                       break;
//                   case -3: // invalid password
//                       $scope.errMessage = Translate.getLangString('login_invalid_password_error');
//                       break;
//               }
//               //console.log(r);
//           }
//       });
//   };

    // TODO: can we delete this since we are using auth0 universal login?
    // $scope.authenticate = function() {
    //     const webAuth = new auth0.WebAuth({
    //         domain: env.auth0Domain,
    //         clientID: env.auth0ClientID,
    //         redirectUri: env.auth0RedirectUri,
    //         responseType: 'token id_token',
    //         scope: 'openid email profile',
    //     });
      
    //     webAuth.parseHash(function(err, data) {
    //         if (err) {
    //           console.log(err)
    //         }
      
    //         if (data) {
    //           console.log('Login successful!')
    //           console.log(data)
    //         }
    //     });
      
    //     webAuth.authorize();
    // }

  reset();
  hideLoader();
}]);
