
angular.module('app.controllers.interview', ['app.services', 'ngFileUpload', 'ngImgCrop', '720kb.tooltips', 'ngDropdowns', 'jlareau.bowser', 'ngMedia'])

.controller('InterviewHeaderCtrl', ["$scope", "$rootScope", "$state", "Server", "bowser", "PopupService", "Translate", "Util", "googleMaps", "Cookies", "NotificationBannerService", function($scope, $rootScope, $state, Server, bowser, PopupService, Translate, Util, googleMaps, Cookies, NotificationBannerService) {

    $scope.badBrowser = false;

    //
    // URL Parameters
    //
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    $rootScope.$state = $state;

    $rootScope.secondaryNav = null;
    $rootScope.hideCopyright = true;
    $rootScope.hasProcessNav = false;
    
    // todo: remove duplicate fn
    $rootScope.profilePhoto = Util.profilePhoto;

    // variables for language selection
    $scope.changeVisitorLanguage = Translate.changeVisitorLanguage;
    // $scope.ddLangs = Translate.getLangDropdownObject();
    // $scope.ddLangs = [{text: 'en', value: 0, full: 'English'}, {text: 'fr', value: 1, full: 'French'}];
    $scope.showInterviewStep = true;
    // collapse and expand for mobile phones
    let mainNav = document.getElementById('js-menu');
    $scope.toggleNavbar = function (opts) {
        mainNav.classList.toggle('active');
        if (opts && opts.forceClose) {
            mainNav.classList.remove('active');
        }
    }

    $rootScope.setNavTitle = function() {
        if ($rootScope.campaign) {
            $rootScope.navTitle = $rootScope.campaign.title[$rootScope.campaign.language];
        }
        else if ($rootScope.assessment) { 
            $rootScope.navTitle = $rootScope.assessment.title[$rootScope.assessment.language];
        }
        else {
            $rootScope.navTitle = '';
        }
    }

    $rootScope.backToCampaignPage = function() {
        if ($rootScope.campaign) {
            window.location.href = window.location.href.replace('form=true', 'form=false');
        }
    }

    $scope.scrollToSection = function (selector) {
        let yOffset = -80;
        let element = document.querySelector(selector);
        if (!element) {
            console.warn(`no element found to scroll with selector ${selector}`);
            return;
        }
        const elementBoundary = element.getBoundingClientRect();
        let y = elementBoundary.top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    $rootScope.processNext = function() {
        console.log('next', $state.current);
    };

    $rootScope.onDataLoad = function(me) {
        $rootScope.me = me;
        $rootScope.campaign = me.campaign;
        $rootScope.branding = {
            customization: {},
            companyInformation: {},
            legalInformation: {
                customTermsAndConditions: [],
            },
        }
        if (me.campaign.customization) {
            $rootScope.branding = {
                ...$rootScope.branding,
                ...me.campaign.customization.employerBranding
            }
            if (me.campaign.customization.bannerImage) {
                $rootScope.branding.customization.bannerImage = me.campaign.customization.bannerImage;
                $rootScope.branding.bannerVideo = undefined;
            }
        }
        if ($rootScope.assessment && $rootScope.assessment.customization && $rootScope.assessment.customization.employerBranding) {
            $rootScope.branding = $rootScope.assessment.customization.employerBranding;
        }

        // set custom terms for campaign
        if ($rootScope.branding.legalInformation) {
            me.campaign.customTerms = [];
            const langPriority = Translate.getLanguagePriority(me.campaign);
            for (let termIdx = 0; termIdx < $rootScope.branding.legalInformation.customTermsAndConditions.length; termIdx++) {
                const customTerm = $rootScope.branding.legalInformation.customTermsAndConditions[termIdx];
                for (const lang of langPriority) {
                    const title = _.get(customTerm, `title[${lang}]`)
                    const label = _.get(customTerm, `label[${lang}]`)
                    const url = _.get(customTerm, `url[${lang}]`)
                    if (title && label && url) {
                        me.campaign.customTerms.push({
                            title, label, url
                        });
                        break; // break languages loop
                    }
                }
            }
        }

        // customization macros to make styling easier
        $rootScope.customization = {
            brandColor: { "color": $rootScope.branding.customization.mainColor || false },
            brandBackColor: { "background-color": $rootScope.branding.customization.mainColor || false },
            navbarFontColor: { "color": $rootScope.branding.customization.navbarFontColor ? $rootScope.branding.customization.navbarFontColor : undefined },
        }
        if ($rootScope.campaign.location) {
            setTimeout(() => {
                $rootScope.goolgeStaticMap = googleMaps.embedUrl($rootScope.campaign.location);
                if(document.getElementById('findus__map')) {
                    document.getElementById('findus__map').src = $rootScope.goolgeStaticMap;
                }
                $scope.$apply();
            }, 100);
        }

        const assessmentMatch = /\/assessments\/(\w+)\//.exec(window.location.href);
        if (assessmentMatch) {
            $rootScope.assessmentId = assessmentMatch[1];
        } else {
            $rootScope.assessmentId = undefined;
        }

        // Use lan
        let campaignLanguages = [];
        for(let i = 0; i < 10 ; i++) {
            if(me.campaign.title[i]) {
                campaignLanguages.push(i);
            }
        }

        $rootScope.me.customInformations = _.map($rootScope.campaign.customInformationFields, customInformationField => {
            return {
                title: customInformationField.title,
                optional: customInformationField.optional,
                answer: '',
            };
        });

        Translate.initVisitorLanguageFromNavigator(0);
        if(! _.includes(campaignLanguages, Translate.currentLanguage())) {
            Translate.changeVisitorLanguage(me.campaign.language);
        }
        if(params.lang && (typeof Translate.getLangNumberFromShort(params.lang) === 'number')) {
            Translate.changeVisitorLanguage(Translate.getLangNumberFromShort(params.lang));
        }

        // Only how languages for which the campaign has a title
        const langDropdownObject = Translate.getLangDropdownObject()
        $scope.ddLangs = _.filter(langDropdownObject, ddLang => {
            return _.includes(campaignLanguages, ddLang.value);
        });

        document.title = `${me.campaign.title[Translate.currentLanguage()]} | ` + $rootScope.branding.companyInformation.name;

        if ($rootScope.inWelcome && (me.step > 0 || !me.campaign || !me.campaign.isActive) && !$rootScope.assessmentId) {
            window.location.replace('/interview');
            return;
        }

        // duplicated code (ref 2402)
        if ($rootScope.branding.customization.favicon || me.campaign.favicon) {
            const favLinkTags = document.querySelectorAll('head [href*="icon"]')
            favLinkTags.forEach(favIconTag => {
                favIconTag.setAttribute('href', $rootScope.branding.customization.favicon || me.campaign.favicon)
            })
        }

        $rootScope.setNavTitle();
        hideLoader();

        if (!$rootScope.assessmentId) {
            if ($rootScope.inWelcome) {
                $state.go('welcome');
                return;
            }

            if (!me.campaign || !me.campaign.isActive) {
                if (me.step != 5) {
                    $state.go('expired');
                    return;
                }
            }

            switch (me.step) {
                case 0:
                    $state.go('welcome');
                    break;
                case 1:
                    if(me.campaign?.owner?.settings?.ui?.userSpecificFields.includes('richemont') && me.campaign?.introVideoClipId?.length) {
                        $state.go('interview-intro-video');
                    } else {
                        $state.go('information');
                    }
                    break;
                case 2:
                    if (me.campaign.questions.length > 0)
                        $state.go('questions');
                    else
                        Server.get('candidates/me/finishinterview')
                        .then(function() {
                        $state.go('end');
                        });
                    break;
                case 4:
                case 5:
                    $state.go('end');
                    break;
            }
        } else {
            $state.go('questions');
        }
       // Initialise cookies with gtag, if there is one
       Cookies.init($rootScope?.employerBranding?.legalInformation?.gtag, $rootScope?.employerBranding?.legalInformation?.ga);
    };

    $rootScope.reloadData = function() {
        return Server.get('candidates/me').then($rootScope.onDataLoad);
        };

    $rootScope.nextStep = function() {
        return Server.get('candidates/me/next')
            .then($rootScope.onDataLoad);
    };

    $rootScope.goToVideoQuestions = function() {
        $state.go('video');
    };

    $rootScope.openSkipVideoQuestionsPopup = function() {
        PopupService.openGenericPopup($scope, {
            onYes: function() {
                $rootScope.goToVideoQuestions();
                $scope.modalHandle.close();
            },
            onNo: function() {
                Server.get('candidates/me/finishinterview?skipRemaining=true')
                .then(function() {
                    $state.go('end');
                });
                $scope.modalHandle.close();
            },
            title: Translate.getLangString('pre_video_modal_title'),
            subTitle: Translate.getLangString('pre_video_modal_subtitle'),
            messageText: Translate.getLangString('pre_video_modal_text'),
            yesText: Translate.getLangString('pre_video_modal_yes'),
            noText: Translate.getLangString('pre_video_modal_no'),
        }, 'templates/modal-interview-confirm.html', {});
    };

    var loadCandidateSpaceData = function() {
        $rootScope.me = {
            step: 2
        };
        $rootScope.campaign = {
            language: Translate.languageFromUrl(),
            company: Translate.getLangString('beehire_name'),
            title: Translate.getLangString('candidate_space_campaign_title'),
        };

        $rootScope.nextStep = function() {

        };

        if ($rootScope.inWelcome) {
            $state.go('welcome');
        } else {
            if ($rootScope.sessionId == 0) {
                $state.go('end');
            } else {
                $state.go('welcome');
            }
        }

        hideLoader();
    };

    if ($rootScope.candidateSpace) {
        loadCandidateSpaceData();
    } else {
        $rootScope.reloadData();
    }
    $rootScope.setNavTitle();

     // Load notification banner
     NotificationBannerService.loadNotificationBanner($scope,'candidate');
}])

.controller('IIntroVideoCtrl', ["$scope", "$rootScope", "$state", function($scope, $rootScope, $state) {

    $rootScope.secondaryNav = 'editcampaign';

    $rootScope.processNext = function() {
        $state.go('information');
    };
}])

.controller('IInformationCtrl', ["$scope", "$rootScope", "Server", "Upload", "PopupService", "overlaySpinner", "Translate", "InterviewDocuments", "Util", "$timeout", "$interval", "overlaySpinner", "ToasterService", "Cookies", function($scope, $rootScope, Server, Upload, PopupService, overlaySpinner, Translate, InterviewDocuments, Util, $timeout, $interval, overlaySpinner, ToasterService, Cookies) {

    overlaySpinner.show('apply-form-section-with-places');

    const MAPBOX_ACCESS_TOKEN = window.__env.mapbox_access_token;

    $scope.addressResults = [];
    $scope.loadingAddress = false;
    $scope.inputDebounceTimeout = null;
    $scope.isAldelia = $rootScope.me.campaign?.owner?.settings?.ui?.userSpecificFields.includes('aldelia');
    
    function getMapboxLanguageParam() {
        const availableLangs = ['fr', 'en', 'nl', 'de', 'pt'];
        const currentLang = Translate.getShortFromLangNumber(Translate.currentLanguage());
        const otherLangs = availableLangs.filter(lang => lang !== currentLang);
        return [currentLang, ...otherLangs].join(',');
    }

    

    $scope.initAddressAutocomplete = function() {
    
        $scope.addressResults = [];
        $scope.loadingAddress = false;

        // Function to handle Enter key press to initiate the Google Places API call.
        $scope.keyPressEnter = function(event, address) {
            if (event.keyCode === 13) {
                event.preventDefault();
                if (address.length <= 2) {    
                    $scope.updateAddressDebounced(address, true);
                }
            }
        }
        // Debounced function to update the address.
        $scope.updateAddressDebounced = function(address, triggeredByEnter = false) {
            if ($scope.inputDebounceTimeout) {
                $timeout.cancel($scope.inputDebounceTimeout);
            }
        
            // Api call will be triggered if the address length is greater than 3 or if the address length is less than 3 and the call is triggered by the Enter key.
            $scope.inputDebounceTimeout = $timeout(function() {
                let mapboxLangParam = getMapboxLanguageParam();
                if (address.length > 2 || (address.length <= 2 && triggeredByEnter)) {
                    $scope.loadingAddress = true;
        
                    fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${encodeURIComponent(address)}&access_token=${MAPBOX_ACCESS_TOKEN}&limit=5&types=address,place,street,neighborhood,country,region,district,postcode,locality&language=${mapboxLangParam}&proximity=ip`)
                        .then(response => response.json())
                        .then(data => {
                            $scope.$apply(function() {
                                $scope.addressResults = data.features || [];
                                $scope.loadingAddress = false;
                            });
                        })
                        .catch(error => {
                            console.error('error when fetching address:', error);
                            $scope.$apply(function() {
                                $scope.addressResults = [];
                                $scope.loadingAddress = false;
                            });
                        });
                } else {
                    $scope.$apply(function() {
                        $scope.addressResults = []; 
                        $scope.loadingAddress = false;
                    });
                }
            }, 500);
        };
        
        // Function to select an address from the predictions dropdown.
        $scope.selectAddress = function(prediction) {
        
            if (!$scope.$$phase) {
                $scope.$apply(function() {
                    setAddressDetails(prediction);
                });
            } else {
                setAddressDetails(prediction);
            }
        };
        
        function setAddressDetails(prediction) {
            if (!prediction) return;

            const props = prediction.properties || {};
            const geometry = prediction.geometry || {};
            const coords = Array.isArray(geometry.coordinates) ? geometry.coordinates : [];
            const context = props.context || {};

            const name = props.full_address || prediction.place_name;
            const country = context.country?.name;

            if (!name || !country) return;

            // Required
            $scope.me.address.name = name;
            $scope.me.address.country = country;

            // Optional
            $scope.me.address.latitude = typeof coords[1] === 'number' ? coords[1] : null;
            $scope.me.address.longitude = typeof coords[0] === 'number' ? coords[0] : null;
            $scope.me.address.city = context.place?.name || '';
            $scope.me.address.state = context.region?.name || '';
            $scope.me.address.zip = context.postcode?.name || '';

            // Hiding the address predictions dropdown after selection.
            $scope.addressResults = [];
        }
        overlaySpinner.hide('apply-form-section-with-places');
    };
    
    $timeout($scope.initAddressAutocomplete, 0);
    
    // Calling the function to initialize the Google Places API.
    $scope.initAddressAutocomplete();

    $rootScope.secondaryNav = 'editcampaign';
    $scope.resetErrors = function() {
        $scope.errors = {};
    }
    $scope.resetErrors();
    $scope.notification = '';
    $scope.uploadDoc = InterviewDocuments.upload;
    $scope.isCV = InterviewDocuments.isCV;
    $scope.getButtonTitle = InterviewDocuments.getButtonTitle;
    $scope.isDevelopment = window.__env.isDevelopment;
    $scope.isGooglePlaces = window?.google?.maps?.places;
    
    Util.loadReferralSources()
    .then(function(refSources){
        $scope.refSources = refSources;
    });

    var processNext = function() {

        const isLiveInterview = $rootScope.liveInterview && $rootScope.liveInterview[0];
        const isQuickApply = $rootScope.me.campaign.candidateInfoForm && $rootScope.me.campaign.candidateInfoForm.quickApply;
        
        $scope.errors = checkMandatoryFields(isLiveInterview, isQuickApply);

        // check errors on input fields
        if (!_.isEmpty($scope.errors)) {
            ToasterService.failure(null, 'err_1_form');
            return;
        }

        $rootScope.processNext = false;
        overlaySpinner.show('information');
        Server.post('candidates/me', $rootScope.me)
        .then($rootScope.nextStep)
        .then(function() {
            overlaySpinner.hide('information');
        },function(err) {
            overlaySpinner.hide('information');
            $rootScope.processNext = processNext;
            if (err && err.error == -10) {
                PopupService.openGenericPopup($scope, {
                    title: Translate.getLangString('duplicate_candidate_confirmation_title'),
                    messageText: Translate.getLangString('duplicate_candidate_confirmation_message', null, [err.link, err.link]),
                    isHtmlBody: true,
                    cancelText: Translate.getLangString('cancel'),
                }, 'templates/modal-alert.html', {});

                $scope.errors.email = true;
            } else {
                $scope.notification = ''; // too small, can't be seen
                ToasterService.failure(err, 'err_1_form');
            }
        });
    };

    $scope.editProfilePhoto = function() {
        $scope.notification = '';
        const uploadUrl = 'candidates/me/photo';
        const getUrl = 'candidates/me';
        Util.editProfilePhoto($scope, $rootScope.me, PopupService, uploadUrl, getUrl);
    };

    
    $scope.autoFillFieldsRandomInformationsInACampaignInvitation = function(devName) {
        const randomFirstNames = ['Lucas','Emma','Mathis','Lina','Noah','Léa','Adam','Sofia','Liam','Nora','Louis','Sarah','Arthur','Amira','Gabriel','Fatima','Ethan','Aya','Victor','Yara','Mila','Ali','Anna','Omar','Yasmine','Jules','Amina','Finn','Zara','Elena'];
        const randomLastNames = ['Dupont','Martin','Dubois','Moreau','Lefevre','Leroy','Rousseau','Mercier','Fournier','Girard','Caron','Petit','Nguyen','Diop','El Hadi','Fernandez','Gomez','Abdullah','Traoré','Bouchard','Bakayoko','Ndiaye','Lam','Chung','Diallo','Ouedraogo','Alami','Kone','Moussaoui','Kabila'];
        const phoneNumber = '+1234567890';
        const referralSource = 'emailInvitation';
        const customTermAccepted = true;
        const randomIndexFirstNames = Math.floor(Math.random() * randomFirstNames.length);
        const randomIndexLastNames = Math.floor(Math.random() * randomLastNames.length);
        $scope.me.firstName = randomFirstNames[randomIndexFirstNames];
        $scope.me.lastName = randomLastNames[randomIndexLastNames];
        $scope.me.phoneNumber = phoneNumber;
        $scope.me.referralSource = referralSource
        $scope.me.termsAccepted = customTermAccepted;
        /*
        $scope.me.address.country = "Belgique";
        $scope.me.address.latitude = 50.8503;
        $scope.me.address.longitude = 4.3517;
        $scope.me.address.city = "Bruxelles";
        $scope.me.address.state = ""; 
        
        $scope.me.address.zip = "1000"; 
        $scope.me.address.street = ""; 
        $scope.me.address.street_number = "";
        $scope.me.address.name = "Bruxelles";
        */
        function generateRandomString(length) {
            let result           = '';
            let characters       = '0123456789azertyuiopmlkjhgfdsqwxcvbnAZERTYUIOPMLKJHGFDSQWXCVBN';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
       let randomString = generateRandomString(12);
       switch(devName){
        case 'Simon' : 
            $scope.me.email = "simon+"+ randomString + "@beehire.com";
            break;
        case 'Gregory' : 
            $scope.me.email = "gregory+" + randomString + "@beehire.com";
            break;
        case 'Guenter' : 
            $scope.me.email = "guenter.bloemer+"+ randomString + "@toptal.com";
            break;
        default : $scope.me.email="email@email.com" 
       }
       
    }

    function checkMandatoryFields(isLiveInterview = false, isQuickApply = false) {
        var errors = {};

        // 
        // Normal text fields
        // 

        function checkStr (val) {
            return val && val.trim().length;
        }
        function checkCustomInformationFields (vals) {
            let missingNonOptionalAnswer = false;
            _.forEach(vals, val => {
                if (!val.optional && !checkStr(val.answer)) {
                    missingNonOptionalAnswer = true;
                }
            });
            return !missingNonOptionalAnswer;
        }
        function checkEmail (val) {
            return val && _.includes(val, '@') && _.includes(val, '.');
        }
        function checkBoolean (val) {
            return val;
        }
        function checkCustomTerms() {
            if (!$scope.me.campaign.customTerms || $scope.me.campaign.customTerms.length === 0) {
                return;
            }
            for (let termIdx = 0; termIdx < $scope.me.campaign.customTerms.length; termIdx++) {
                const customTerm = $scope.me.campaign.customTerms[termIdx];
                if (!customTerm.accepted) {
                    errors["customTerms"+termIdx] = true;
                }
            }
        }

        let mandatoryFields = [
            {key: 'firstName', checkFunction: checkStr},
            {key: 'lastName', checkFunction: checkStr},
            {key: 'address.name', checkFunction: checkStr},
            {key: 'address.country', checkFunction: checkStr}, // if there is no country it means that no google address options has been selected 
            {key: 'email', checkFunction: checkEmail, inQuickApply: true},
            {key: 'phoneNumber', checkFunction: checkStr, inQuickApply: true},
            {key: 'customInformations', checkFunction: checkCustomInformationFields, inQuickApply: true},
            {key: 'termsAccepted', checkFunction: checkBoolean, inQuickApply: true},
        ];

        /* Don't check/validate those fields, they wont appear in the screen (only if campaign settings is candidateInfoForm.quickApply === true) */
        if(isQuickApply) {
            mandatoryFields = _.filter(mandatoryFields, field => field.inQuickApply);
        }

        if($rootScope.me.campaign.askReferralSource && !isLiveInterview) {
            mandatoryFields.push({key: 'referralSource', checkFunction: checkStr});
        }
        if($rootScope.me.campaign.askPasswordForCandidateArea && !isLiveInterview) {
            const passwordCheck = Util.checkPassword($rootScope.me.password, $rootScope.me.confirmPassword)
            if (passwordCheck) {
                errors.password = passwordCheck;
            }
        }

        _.forEach(mandatoryFields, function(field) {
            let val = _.get($rootScope.me, field.key);
            if (!field.checkFunction(val)) {
                _.set(errors, field.key, true);
            }
        });

        checkCustomTerms();

        // 
        // CV upload
        //  check missing CV (if not optional)
        // 

        if (!InterviewDocuments.checkAllUploaded($rootScope.me.documents)) {
            errors.documents = true;
        }

        return errors;
    }

    $rootScope.processNext = processNext;
}])

.controller('IVideoCtrl', ["$scope", "$rootScope", "$state", "VideoService", "$timeout", "Server", "Translate", "videoStates", "eqTestData", function($scope, $rootScope, $state, VideoService, $timeout, Server, Translate, videoStates, eqTestData) {

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    var meters = $('.interview-video__mic-left-filled, .interview-video__mic-right-filled');
    var meterHeight =  $('.interview-video__mic-left').height();
    var multiplier = meterHeight / 128;


    $rootScope.secondaryNav = 'editcampaign';
    $rootScope.processNext = false;
    $scope.canConfirm = false;
    $scope.isReady = false;
    $scope.hideMeters = false;
    $scope.errorMessage = false;
    $scope.videoState = videoStates.idle;
    $scope.videoStates = videoStates;


    var audioMeterUpdate = function(peak) {
        meters.height(peak * multiplier);
    };

    var recordTestVideo = function() {
        if (!VideoService.isAudioMeterWorking()) {
            $scope.hideMeters = true;
        }

        $timeout(function() {
            VideoService.startRecording();
            $scope.videoState = videoStates.recording;
        }, 1000);

        $timeout(function() {
            var url = VideoService.stopRecording();
            var handle = VideoService.getHandle();

            //console.log(url, handle);
            if (!handle && !url) {
                $scope.errorMessage = Translate.getLangString('interview_test_error_1');
            } else if (handle) {
                $scope.videoState = videoStates.uploading;
                Server.post('candidates/addvideo', {handle: handle}).then(function(result) {
                    console.log(Server.makeResourceUrl(result.filename));
                    $rootScope.recorderVideoUrls.push(Server.makeResourceUrl(result.filename));
                    playbackTestVideo($rootScope.recorderVideoUrls[0]);
                });
            } else {
                $rootScope.recorderVideoUrls.push(url);
                playbackTestVideo($rootScope.recorderVideoUrls[0]);
            }
        }, 3500);
    };

    var playbackTestVideo = function(url) {
        $('.interview-video__camera').replaceWith('<video class="interview-video__camera" autoplay></video>');
        if (isSafari) {
            url = url.replace('.webm', '.mp4');

        }
        console.log(url);
        //$scope.canConfirm = true;
        VideoService.stop();
        $scope.videoState = videoStates.playback;
        var started = false, done = false;
        //$('.interview-video__camera')[0].muted = false;
        $('.interview-video__camera')
            .attr('src', url)
            .on('playing', function() {
                started = true;
                console.log('start');
            }).on('ended', function() {
                console.log('end');
                if (started) {
                    done = true;
                    $scope.canConfirm = true;
                    $scope.videoState = videoStates.done;
                    $scope.$apply();
                }
            });
        $timeout(function() {
            if (done)
                return;
            $scope.errorMessage = Translate.getLangString('interview_test_error_1');
        }, 10000);
    };

    $scope.confirm = function() {
        $state.go('video_q');
        eqTestData.save();
    };

    $scope.reload = function() {
        window.location.reload();
    };

    $scope.readyForTest = function() {
        $scope.isReady = true;
        initController();
    };

    function initController() {
        $rootScope.recorderVideoUrls = [];

        VideoService.config({
            videoTargetSelector: '.interview-video__camera',
            audioMeterCallback: audioMeterUpdate,
            constraints: DEFAULT_CONSTRAINTS,
        }).openCamera().then(VideoService.connectVideoElement).then(VideoService.connectAudioMeter).then(recordTestVideo);

    };

}])

.controller('IVideoQuestionsCtrl', ["$scope", "$rootScope", "$state", "VideoService", "Server", "Util", "$timeout", "$interval", "ToasterService", "overlaySpinner", "Translate", "$sce", "eqTestData", "PopupService", "NG_QUILL_CONFIG_ASSESSMENTS_CANDIDATES", "$filter", "SidebarDocumentsService", "$window", function($scope, $rootScope, $state, VideoService, Server, Util, $timeout, $interval, ToasterService, overlaySpinner, Translate, $sce, eqTestData, PopupService, NG_QUILL_CONFIG_ASSESSMENTS_CANDIDATES, $filter, SidebarDocumentsService, $window) {

    var prepTimerContainer, answerTimerContainer;
    var shouldAutoStart;
    var videoConnected;
    var lastVideoUrl;
    var interval;
    var demoQuestion = 1;
    $scope.imageModalOpen = false;
    $scope.errors = {};

    var reset = function() {
        console.log('reset');
        $scope.questionText = '';
        $scope.prepActive = false;
        $scope.prepTimeLeft = 0;
        $scope.answerActive = false;
        $scope.answerTimeLeft = 0;
        $scope.ctrlBtnClick = function(){};
        $scope.ctrlBtnDisabled = true;
        $scope.ctrlBtnCntdDown = false;
        $scope.ctrlBtnNext = false;
        $scope.ctrlBtnText = '';
        $scope.isRecording = false;
        $scope.notReady = false;
        $scope.timersHidden = false;
        $scope.retryBtnVisible = false;
        $scope.showPlayback = false;
        $scope.playQuestionVideoClip = false;
    };

    var countWordsCheck = function(question) {
        question.wordsLeft = question.wordLimit;

        if (question.answer && question.mode == 0 && question.wordLimit > 0)  {
            var c = Util.countWords(question.answer);
            question.wordsLeft = question.wordLimit - c;
            if (question.wordsLeft < 0) {
                question.wordsLeft = 0;
                return true;
            }
        }

        return false;
    };

    $scope.liveWordCount = function(question) {
        if (question && question.mode === 0 && question.wordLimit > 0) {
            if (question.answer) {
                question.currentWordCount = Util.countWords(question.answer);
                question.wordsLeft = question.wordLimit - question.currentWordCount;
                if (question.wordsLeft < 0) {
                    question.wordsLeft = 0;
                }
            } else {
                question.currentWordCount = 0;
                question.wordsLeft = question.wordLimit;
            }
        }
    };
    

    var setupTimer = function(container) {
        var width = container.width();
        var customFill = $rootScope.customization && $rootScope.customization.brandColor;
        container.circleProgress({
            size: width,
            startAngle: -Math.PI / 4 * 2,
            value: 0,
            fill: customFill || {
                gradient: ['#FFB700', '#FFB700']
            },
            emptyFill: '#e7e7e7',
            thickness: 10,
            reverse: true,
            animation: false
        });
    };
        
    var updateTimer = function(container, v, max) {
        var nv = 0;
        if (max > 0) {
            max *= 60;
            nv = v / max;
        }
        //console.log(nv);
        container.circleProgress({value: nv});
    };

    var setupTimers = function() {
        prepTimerContainer = $('.timer-circular--prep');
        answerTimerContainer = $('.timer-circular--answer');

        setupTimer(prepTimerContainer);
        setupTimer(answerTimerContainer);
    };

    var clearTimer = function(container) {
        container.circleProgress({value: 0});
    };

    var startRecording = function() {

        var minRecTime = 3;

        if ($scope.isRecording) {
            return;
        }
        VideoService.startRecording();
        console.log('recording started');
        $scope.isRecording = true;
        $scope.ctrlBtnText = Translate.getLangString('interview_stop_recording_btn');
        $scope.ctrlBtnClick = stopRecording;
        setTimeout(function () {
            $scope.ctrlBtnDisabled = false;
        }, minRecTime * 1000);
        $scope.ctrlBtnCntdDown = false;
        $scope.prepActive = false;
        $scope.answerActive = true;
        $scope.answerTimeLeft = Math.min($scope.currentQuestion.answerTime * 60, $scope.answerTimeLeft);
        $scope.prepTimeLeft = 0;
        updateTimer(prepTimerContainer, $scope.prepTimeLeft, $scope.currentQuestion.prepTime);
    };

    var startCountdown = function() {

        var timeoutBeforeStart = 3;

        $scope.ctrlBtnCntdDown = true;
        $scope.ctrlBtnDisabled = true;
        $scope.prepActive = false;

        var counter = timeoutBeforeStart;
        $scope.ctrlBtnText = counter;
        function myLoop() {
            setTimeout(function () {
                counter--;
                $scope.ctrlBtnText = counter;
                if (counter >= 0) {
                    myLoop();
                } else {
                    startRecording();
                }
            }, 1000)
        }
        
        myLoop();
    };

    var stopRecording = function() {

        if (!$scope.isRecording) {
            return;
        }
        console.log('stop recording');
        if (interval) {
            $interval.cancel(interval);
            interval = false;
        }
        $scope.ctrlBtnDisabled = true;
        $scope.isRecording = false;
        $scope.prepActive = $scope.answerActive = false;
        $scope.prepTimeLeft = $scope.answerTimeLeft = 0;
        updateTimer(prepTimerContainer, 0, $scope.currentQuestion.prepTime);
        updateTimer(answerTimerContainer, 0, $scope.currentQuestion.answerTime);
        overlaySpinner.show('videoquestions');
        lastVideoUrl = VideoService.stopRecording();
        var handle = VideoService.getHandle();

        if ($rootScope.candidateSpace) {
            if (!handle) {
                $rootScope.recorderVideoUrls.push($sce.trustAsResourceUrl(lastVideoUrl));
                allowNextQuestion();
            } else {
                Server.post('candidates/addvideo', {handle: handle}).then(function(result) {
                    console.log(Server.makeResourceUrl(result.filename));
                    $rootScope.recorderVideoUrls.push(Server.makeResourceUrl(result.filename));
                    allowNextQuestion();
                });
            }
        } else {
            if ($rootScope.me.videoTrialsLeft > 0) {
                $scope.timersHidden = true;
                $scope.ctrlBtnDisabled = false;
                $scope.ctrlBtnText = Translate.getLangString('interview_send_btn');
                $scope.ctrlBtnClick = function() {
                    uploadVideo(handle);
                };
                $scope.retryBtnVisible = true;
                previewVideo(lastVideoUrl, handle);
            } else {
                uploadVideo(handle);
                //previewVideo(lastVideoUrl, handle);
            }
        }
    };

    var uploadVideo = function(handle) {
        console.log('upload video');
        stopVideoPreview();
        overlaySpinner.show('videoquestions');
        $scope.ctrlBtnText = Translate.getLangString('interview_uploading_video_wait');
        $scope.videoUploaded = true;
        $rootScope.currentQuestion = undefined;

        if (!handle) {
            var req = VideoService.uploadVideoEx(Server.makeUrl('candidates/me/videoFast'));
            //req.addEventListener("progress", updateProgress);
            req.addEventListener("load", () => getNextQuestion());
            //req.addEventListener("error", transferFailed);
            //req.addEventListener("abort", transferCanceled);
        } else {
            //console.log(1);
            Server.postWithTimeout('candidates/me/addvideo', {handle: handle}, 1000 * 60 * 10).then(() => getNextQuestion());
        }
    };

    var allowNextQuestion = function() {
        stopVideoPreview();

        $timeout(function() {
            console.log('allow next question');
            overlaySpinner.hide('videoquestions');
            $scope.ctrlBtnDisabled = false;
            $scope.ctrlBtnText = Translate.getLangString('interview_next_question_btn');
            $scope.ctrlBtnNext = true;
            $scope.ctrlBtnClick = getNextQuestion;

            if ($rootScope.questionNumber == $rootScope.questionsTotal) {
                console.log('no more questions');
                endInterview();
            }
        }, 0);
    };

    var previewVideo = function(url, handle) {
        console.log('previewVideo', url, handle);

        if (handle) {
            overlaySpinner.show('videoquestions');
            $scope.ctrlBtnText = Translate.getLangString('interview_uploading_video_wait');
            Server.postWithTimeout('candidates/me/previewvideo', {handle: handle}, 1000 * 60 * 10).then(function(result) {
                url = result.filename;
                previewVideo(url);
            });
        } else if (url) {
            $scope.showPlayback = url;
            $scope.ctrlBtnText = Translate.getLangString('interview_send_btn');
            overlaySpinner.hide('videoquestions');
        }
    };

    var stopVideoPreview = function() {
        $scope.showPlayback = false;
        $scope.questionText = '';
    };

    var openCamera = function() {
        VideoService.stop();

        VideoService.config({
            videoTargetSelector: '.interview-video__camera',
            constraints: DEFAULT_CONSTRAINTS,
        }).openCamera().then(VideoService.connectVideoElement).then(function() {
            // safari patch
            var navBars = ($rootScope.candidateSpace ? 0 : 160);
            $('object.interview-video__camera--full-screen').height($(window).height() - navBars);

            videoConnected = true;
        });
    };

    var closeCamera = function() {
        VideoService.stop();
    };

    var endAssessment = function() {
        overlaySpinner.hide('videoquestions');
        $scope.stopTimer();
        Util.stopTrackPageFocus();
        $state.go('endAssessment');
    };

    var endInterview = function() {
        if (interval) {
            $interval.cancel(interval);
            interval = false;
        }
        closeCamera();
        Server.get('candidates/me/finishinterview')
            .then(function() {
                overlaySpinner.hide('videoquestions');
                $state.go('end');
            });
    };

    var setupInterval = function() {
        console.log('setupInterval');
        interval = $interval(function () {
            //console.log($scope.prepTimeLeft, $scope.answerTimeLeft);
            if ($scope.prepActive) {
                if ($scope.prepTimeLeft > 0) {
                    --$scope.prepTimeLeft;
                    --$scope.answerTimeLeft;
                    updateTimer(prepTimerContainer, $scope.prepTimeLeft, $scope.currentQuestion.prepTime);
                } else {
                    $scope.prepActive = false;
                    $scope.answerActive = true;
                    clearTimer(prepTimerContainer);
                    startRecording();
                    console.log('clear');
                }
            }
            if ($scope.answerActive) {
                //console.log(1);
                if ($scope.answerTimeLeft > 0) {
                    --$scope.answerTimeLeft;
                    //console.log(2);
                    updateTimer(answerTimerContainer, $scope.answerTimeLeft, $scope.currentQuestion.answerTime);
                    if (videoConnected && shouldAutoStart) {
                        shouldAutoStart = false;
                        startRecording();
                    }
                } else {
                    $scope.answerActive = false;
                    clearTimer(answerTimerContainer);
                    console.log('- next question!');
                    stopRecording();
                }
            }
        }, 1000);
    };

    var onQuestionData = function(data){
        reset();
        if (!data) {
            return;
        }

        console.log('onQuestionData: ', data.current);

        if (!data.current) {
            endInterview();
            return;
        }

        $rootScope.questionNumber = data.num;
        $rootScope.questionsTotal = data.total;
        $scope.questionText = data.current.text;
        $scope.currentQuestion = data.current;
        $scope.questions = [$scope.currentQuestion];

        if (!data.current.isWritten && data.current.mode !== 20) {
            if ($state.current.name !== 'video_q') {
                // duplicate: question to video transaction
                if(Util.isMobileDevice()) {
                    return $state.go('pre-app-mobile');
                }
                if (!$rootScope.prevideoVisited || !$rootScope.questionVideoWatched && $scope.videoClipId) {
                    return $state.go('pre-video-desktop');
                } else {
                    if (eqTestData.check()) {
                        return $state.go('video_q');
                    } else {
                        return $state.go('video');
                    }
                }
            }
            openCamera();

            if (data.current.prepTimeLeft > 0) {
                $scope.prepActive = true;
                $scope.prepTimeLeft = data.current.prepTimeLeft;
                $scope.answerTimeLeft = data.current.answerTimeLeft;
                $scope.ctrlBtnDisabled = false;
                $scope.ctrlBtnText = Translate.getLangString('interview_ready_to_answer_btn');
                $scope.ctrlBtnClick = startCountdown;
                setupInterval();
            } else if (data.current.answerTimeLeft > 0) {
                $scope.answerActive = true;
                $scope.answerTimeLeft = data.current.answerTimeLeft;
                $scope.ctrlBtnDisabled = false;
                $scope.ctrlBtnText = Translate.getLangString('interview_stop_recording_btn');
                $scope.ctrlBtnClick = stopRecording;
                shouldAutoStart = true;
                setupInterval();
            } else {
                // goto next question
                console.log('next question!');
            }
        } else {
            closeCamera();
            if ($state.current.name !== 'questions') {
                $state.go('questions');
            }

            $scope.ctrlBtnDisabled = false;
            $scope.ctrlBtnClick = submitWrittenQuestion;
            $scope.ctrlBtnText = Translate.getLangString('interview_next_question_btn');
        }
    };

    var onQuestionDataError = function(data) {
        if (data && data.error == -6) {
            console.log('no video questions in this campaign!');
            $rootScope.nextStep();
        }
    };

    var handleGetQuestion = function(data) {
        if (!data.current) {
            endInterview();
            return;
        }

        if ($scope.currentQuestion && data.current) {
            if ($scope.currentQuestion._id !== data.current._id) {
                $rootScope.questionVideoWatched = false;
            }
        }
        $scope.previousQuestion = $scope.currentQuestion;
        $rootScope.questionNumber = data.num;
        $rootScope.questionsTotal = data.total;
        $rootScope.me.videoTrialsLeft = data.videoTrialsLeft;
        $rootScope.currentQuestion = $scope.currentQuestion = data.current;
        const hasVideoClip = !!data.current.videoClipId;

        overlaySpinner.hide('videoquestions');
        if (data.current.isWritten || data.current.mode === 20 || ((data.current.prepTime > 0 && data.current.prepTimeLeft < data.current.prepTime * 60) || (data.current.answerTime > 0 && data.current.answerTimeLeft < data.current.answerTime * 60))) {
            Server.get('candidates/me/getquestion')
            .then(onQuestionData, onQuestionDataError);
        } else {
            if (!$scope.videoUploaded) {
                if ((!hasVideoClip || hasVideoClip && $rootScope.questionVideoWatched) && $state.current.name === 'video_q' && eqTestData.check()) {
                    onQuestionData(data)
                    $scope.notReady = false;
                } else {
                    // duplicate: question to video transaction
                    if(Util.isMobileDevice()) {
                        return $state.go('pre-app-mobile');
                    }
                    if (!$rootScope.prevideoVisited || !$rootScope.questionVideoWatched && $scope.videoClipId) {
                        return $state.go('pre-video-desktop');
                    } else {
                        if (eqTestData.check()) {
                            return $state.go('video_q');
                        } else {
                            return $state.go('video');
                        }
                    }
                }
            } else {
                $scope.notReady = true;
                if ($scope.previousQuestion && $scope.currentQuestion && $scope.previousQuestion._id !== $scope.currentQuestion._id) {
                    $scope.videoUploaded = false;
                    getNextQuestion(false);
                }
            }
        }
        console.log($scope.currentQuestion);
    };

    var assessmentSetCurrent = function(questionId) {
        if ($rootScope.assessment.finishedAt) {
            return;
        }

        let questionIdx = -1
        if ($rootScope.allQuestions) {
            if (questionId) {
                const idx = $rootScope.allQuestions.findIndex(a => a._id === questionId)
                if (idx >= 0) {
                    questionIdx = idx;
                } else {
                    questionIdx = 0;
                }
            } else {
                const idx = $rootScope.allQuestions.findIndex(a => (a.answer === '' || a.answer === undefined) && !a.skipped)
                if (idx >= 0) {
                    questionIdx = idx;
                } else {
                    questionIdx = 0;
                }
            }
        }
        if (questionIdx < 0) {
            return null;
        }
        $scope.currentQuestion = $rootScope.allQuestions[questionIdx];
        $scope.questions = [$scope.currentQuestion];
        $rootScope.questionNumber = questionIdx + 1;
        $rootScope.answeredQuestions = $rootScope.allQuestions.filter(x => x.answer !== '' && x.answer !== undefined || x.skipped).length;
        $rootScope.isLastAnswer = $rootScope.answeredQuestions >= $rootScope.allQuestions.length - 1 && $scope.currentQuestion;

        if ($rootScope.questionNumber === $rootScope.allQuestions.length && $rootScope.isLastAnswer) {
            $scope.ctrlBtnClick = $scope.finishAssessment;
            $scope.ctrlBtnText = Translate.getLangString('finish');
        } else {
            $scope.ctrlBtnClick = $scope.nextAssessmentQuestion;
            $scope.ctrlBtnText = Translate.getLangString('interview_next_question_btn');
        }

        if ($scope.currentQuestion) {
            $scope.runTimer();
        }

        return $scope.currentQuestion;
    }

    var handleAssessmentGet = function(data) {
        $rootScope.assessment = data.assessment;
        $rootScope.questionsTotal = data.assessment.questions.length;
        $rootScope.allQuestions = data.assessment.questions;
        $rootScope.branding = _.get(data.assessment, 'customization.employerBranding') || _.get($rootScope.me, 'campaign.customization.employerBranding') || { customization: {} };
        // customization macros to make styling easier
        $rootScope.customization = {
            brandColor: { "color": $rootScope.branding.customization.mainColor || false },
            brandBackColor: { "background-color": $rootScope.branding.customization.mainColor || false },
            navbarFontColor: { "color": $rootScope.branding.customization.navbarFontColor ? $rootScope.branding.customization.navbarFontColor : undefined },
        }

        // set custom terms for campaign
        if ($rootScope.branding.legalInformation) {
            $rootScope.assessment.customTerms = [];
            const langPriority = Translate.getLanguagePriority($rootScope.me.campaign);
            for (let termIdx = 0; termIdx < $rootScope.branding.legalInformation.customAssessmentTerms.length; termIdx++) {
                const customTerm = $rootScope.branding.legalInformation.customAssessmentTerms[termIdx];
                for (const lang of langPriority) {
                    const title = _.get(customTerm, `title[${lang}]`)
                    const label = _.get(customTerm, `label[${lang}]`)
                    const url = _.get(customTerm, `url[${lang}]`)
                    if (title && label && url) {
                        $rootScope.assessment.customTerms.push({
                            title, label, url
                        });
                        break; // break languages loop
                    }
                }
            }
        }

        if (!assessmentSetCurrent()) {
            endAssessment();
            return;
        }

        if ($rootScope.assessment.startedAt) {
            $scope.startedTime = new Date($rootScope.assessment.startedAt)
        }

        $scope.trackAssessmentPageFocus();

        $scope.ctrlBtnDisabled = false;
        $scope.ctrlBtnText = Translate.getLangString('interview_next_question_btn');
    }

    $scope.stopTimer = function() {
        if ($scope.timerInterval !== undefined) {
            clearTimeout($scope.timerInterval);
            $scope.timerInterval = undefined;
        }
    }
    $scope.runTimer = function(forceTimer) {
        if (!forceTimer && !$rootScope.assessment.useTimer) {
            return;
        }

        if ($rootScope.assessment && $scope.startedTime) {
            if ($rootScope.assessment.startedAt && !$rootScope.assessment.finishedAt) {
                const limit = $rootScope.assessment.timerMinutes || 60;
                $scope.timerCountdown = Util.getRemainingTime($scope.startedTime, limit);
            } else {
                $scope.timerCountdown = undefined;
            }
            if ($scope.timerCountdown === undefined || $scope.timerCountdown === '00:00:00') {
                $scope.answersTimeout();
                ToasterService.failure({}, 'assessment_timeout');
                return;
            }
        }
        
        if (!$scope.timerInterval || forceTimer) {
            $scope.timerInterval = setTimeout(() => {
                $scope.runTimer(true);
                $scope.$apply();
            }, 1000);
        }
    }

    var getNextQuestion = function(peek) {
        //console.log(2);
        reset();

        if ($rootScope.assessmentId) {
            Server.get('assessments/' + $rootScope.assessmentId + '/getquestions')
                .then(handleAssessmentGet, onQuestionDataError)
        } else {
            if ($rootScope.candidateSpace) {

                onQuestionData({
                    current: {
                        answerTime: 2,
                        prepTime: 2,
                        prepTimeLeft: 120,
                        answerTimeLeft: 240,
                        start: (new Date()).toISOString(),
                        text: Translate.getLangString('candidate_space_question_' + demoQuestion),
                        isWritten: false
                    },
                    num: demoQuestion,
                    total: 3
                });

                ++demoQuestion;

                return;
            }

            if (peek) {
                Server.get('candidates/me/peekquestion')
                .then(handleGetQuestion, onQuestionDataError);
            } else {
                Server.get('candidates/me/getquestion')
                .then(handleGetQuestion, onQuestionDataError);
            }
        }
    };

    $scope.startAssessment = function() {
        $scope.errors = {};
        if ($rootScope.assessment.customTerms?.length) {
            for (let termIdx = 0; termIdx < $rootScope.assessment.customTerms.length; termIdx++) {
                const customTerm = $rootScope.assessment.customTerms?.[termIdx];
                if (!customTerm.accepted) {
                    $scope.errors["customTerms"+termIdx] = true;
                }
            }
        }
        if (!_.isEmpty($scope.errors)) {
            return;
        }

        Server.post("candidates/me/assessments/"+$rootScope.assessmentId+"/start")
            .then(res => {
                $scope.startedTime = new Date();
                handleAssessmentGet(res)
            }).catch(err => {
                ToasterService.failure(err, 'assessment_start_error')
            })
    }

    $scope.trackAssessmentPageFocus = function() {
        if ($rootScope.assessmentId && $rootScope.assessment.startedAt && !$rootScope.assessment.finishedAt) {
            const onPageFocusIn = () => {
                $scope.runTimer();
                Server.post("logs/assessment/" + $rootScope.assessmentId, {
                    messageCode: "log_0_page_focus",
                    message: Translate.getLangString("log_0_page_focus"),
                }).catch(err => {
                    console.error(err);
                })
            }
            const onPageFocusOut = () => {
                $scope.runTimer();
                Server.post("logs/assessment/" + $rootScope.assessmentId, {
                    messageCode: "log_1_page_blur",
                    message: Translate.getLangString("log_1_page_blur"),
                }).catch(err => {
                    console.error(err);
                })
            }
            const onBeforeUnload = function(e) {
                // e.returnValue = "Are you sure you want to close the assessment?";
                // return e.returnValue;
            }
        
            Util.trackPageFocus(onPageFocusIn, onPageFocusOut, onBeforeUnload);
        }
    }

    $scope.previousQuestionClick = function() {
        const currentIdx = $rootScope.questionNumber-1;
        let previousQuestion = $rootScope.allQuestions[currentIdx];
        if (currentIdx > 0) {
            previousQuestion = $rootScope.allQuestions[currentIdx-1];
        }
        submitWrittenQuestion(previousQuestion._id);
    }

    $scope.nextAssessmentQuestion = function () {
        const currentIdx = $rootScope.questionNumber-1;
        const isLastQuestion = currentIdx === $rootScope.allQuestions.length - 1;

        if (isLastQuestion && !$scope.areAllQuestionsAnswered()) {
            ToasterService.failure(null, 'interview_error_unanswered_questions');
            return;
        }
        
        let nextQuestion = $rootScope.allQuestions[currentIdx];
        if (currentIdx < $rootScope.allQuestions.length - 1) {
            nextQuestion = $rootScope.allQuestions[currentIdx+1];
        }
        submitWrittenQuestion(nextQuestion._id);
    }

    $scope.areAllQuestionsAnswered = function() {
        return $rootScope.allQuestions.every(function(question) {
            return question.answer && question.answer.trim().length > 0;
        });
    };

    $scope.finishAssessment = function() {
        PopupService.openGenericPopup($scope, {
            submit: function () {
                $scope.modalHandle.close();
                submitWrittenQuestion(null, true);
            },
            title: Translate.getLangString('confirm_finish_assessment_title'),
            messageText: Translate.getLangString('confirm_finish_assessment_body'),
            yesText: Translate.getLangString('confirm'),
            noText: Translate.getLangString('cancel'),
            friendlyAlert: true,
        }, 'templates/modal-confirm-warning.html', {});
    }

    $scope.lastQuestionClick = function() {
        const lastQuestion = $rootScope.allQuestions[$rootScope.allQuestions.length-1];
        submitWrittenQuestion(lastQuestion._id);
    }

    var submitWrittenQuestion = function(nextQuestionId, finish) {
        let hasErrors = false;
        for (const questionItem of $scope.questions) {
            delete questionItem.error;
            if (questionItem.mode === 20) {
                continue;
            }
            questionItem.answer = questionItem.answer ? questionItem.answer.toString() : '';
            if (!questionItem.answer || !questionItem.answer.trim().length) {
                if(questionItem.mode === 0) {
                    questionItem.error = Translate.getLangString('interview_error_no_text_answer');
                } else if (questionItem.mode === 1) {
                    questionItem.error = Translate.getLangString('interview_error_no_selected_answer_single_choice');
                } else if (questionItem.mode === 2) {
                    questionItem.error = Translate.getLangString('interview_error_no_selected_answer_multiple_choice');
                } else if (questionItem.mode === 3) {
                    questionItem.error = Translate.getLangString('interview_error_no_selected_answer_linear_scale');
                }
            }
            if (countWordsCheck(questionItem)) {
                questionItem.error = Translate.getLangString('interview_error_max_words') + questionItem.wordLimit;
            }
            if (questionItem.error) {
                hasErrors = true;
            }
        }
        $scope.questionsHasErrors = hasErrors
        overlaySpinner.show('writtenquestions');

        if ($rootScope.assessmentId) {
            if ($scope.questionsHasErrors && !nextQuestionId) {
                return;
            }
            if (!$scope.questionsHasErrors) {
                $scope.stopTimer();
                if ($scope.currentQuestion && $scope.currentQuestion.mode === 20) {
                    $scope.currentQuestion.skipped = true;
                }
                const answerAssessmentUrl = finish ? 'candidates/me/assessments/'+$rootScope.assessmentId+'/finish' : 'candidates/me/answer-assessment/'+$rootScope.assessmentId;
                Server.post(answerAssessmentUrl, { questions: $rootScope.allQuestions })
                    .then(function (res) {
                        overlaySpinner.hide('writtenquestions');
                        $rootScope.assessment.finishedAt = res.finishedAt;
                        if (!assessmentSetCurrent(nextQuestionId)) {
                            endAssessment();
                        }
                    })
                    .catch(err => {
                        $scope.runTimer();
                        ToasterService.failure(err, 'interview_error_answer')
                    });
            } else {
                assessmentSetCurrent(nextQuestionId);
            }
        } else {
            if ($scope.questionsHasErrors) {
                return;
            }
            // information question
            if ($scope.currentQuestion.mode === 20) {
                $scope.skipQuestion();
            } else {
                Server.post('candidates/me/answer', { question: $scope.currentQuestion })
                .then(function() {
                    overlaySpinner.hide('writtenquestions');
        
                    if ($rootScope.questionNumber == $rootScope.questionsTotal) {
                        console.log('no more questions');
                        $scope.showInterviewStep = false;
                        endInterview();
                    } else {
                        getNextQuestion(true);
                    }
                })
                .catch(err => {
                    ToasterService.failure(err, 'interview_error_answer')
                });
            }
        }
    };

    $scope.answersTimeout = function() {
        $scope.stopTimer();
        if ($rootScope.assessmentId) {
            overlaySpinner.show('writtenquestions');
            Server.post('candidates/me/assessment-timeout/'+$rootScope.assessmentId)
                .then(function () {
                    overlaySpinner.hide('writtenquestions');
                    endAssessment();
                })
                .catch(err => {
                    ToasterService.failure(err, 'interview_error_answer')
                });
        }
    }

    $scope.setAnswer = function(index, question) {
        var answers = [];
        for (var i = 0; i < question.predefinedAnswers.length; ++i) {
            if (question['answersCb' + i])
                answers.push(i);
        }
        question.answer = answers.join(',');
        //console.log('setanswer', index, question.answer);
    };

    $scope.goToVideoAnswer = function () {
        if ($scope.currentQuestion.mode === 20) {
            $scope.skipQuestion()
                .then(onQuestionData, onQuestionDataError);
        } else {
            Server.get('candidates/me/getquestion')
                .then(onQuestionData, onQuestionDataError);
        }
    };

    $scope.skipQuestion = function() {
        return Server.get('candidates/me/skipquestion').then(function() {
            getNextQuestion();
        }, onQuestionDataError);
    };

    $scope.retryBtnClick = function() {
        stopVideoPreview();
        reset();
        $scope.notReady = false;

        Server.get('candidates/me/retryquestion').then(function(data) {
            if (!data.current) {
                endInterview();
                return;
            }

            $rootScope.questionNumber = data.num;
            $rootScope.questionsTotal = data.total;
            $rootScope.me.videoTrialsLeft = data.videoTrialsLeft;
            $scope.currentQuestion = data.current;
            $scope.notReady = false;
            Server.get('candidates/me/getquestion').then(onQuestionData, onQuestionDataError);
        }, onQuestionDataError);
    };

    $scope.trustAsHtml = function(html) {
        var processedHtml = $filter('replaceFirstParagraphBySpan')(html);
        return $sce.trustAsHtml(processedHtml);
    };
    
    
    $scope.editorModules = NG_QUILL_CONFIG_ASSESSMENTS_CANDIDATES.modules;

    // <PDF SIDEBAR>
    $scope.sidebarService = SidebarDocumentsService;
    // Expand sidebar by default
    $scope.sidebarService.isSidebarExpanded = $window.innerWidth > 1250;

    (function initController() {

        $rootScope.secondaryNav = 'editcampaign';
        $rootScope.processNext = false;
        $rootScope.recorderVideoUrls = [];

        setTimeout(setupTimers, 0);

        getNextQuestion($state.current.name !== 'video_q');
    })();

}])

.controller('IPreVideoDesktopCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "VideoService", "$timeout", "Server", "Translate", "videoStates", "eqTestData", "PopupService", function($scope, $rootScope, $state, $stateParams, VideoService, $timeout, Server, Translate, videoStates, eqTestData, PopupService) {
    $scope.preparationStep = 1;
    $scope.videoClipId = $rootScope.currentQuestion ? $rootScope.currentQuestion.videoClipId : undefined;
    
    $scope.preparationsReady = function() {
        $scope.preparationStep = 3;
        $rootScope.prevideoVisited = true;
        if (!$scope.videoClipId) {
            $scope.videoQuestionWatchedClicked();
        }
    }

    $scope.videoQuestionWatchedClicked = function() {
        $rootScope.questionVideoWatched = true;
        if (eqTestData.check()) {
            $state.go('video_q');
        } else {
            $state.go('video');
        }
    }
}])

.controller('IPreAppMobileCtrl', ["$scope", "$rootScope", "$state", "VideoService", "$timeout", "Server", "Translate", "videoStates", "eqTestData", "PopupService", function($scope, $rootScope, $state, VideoService, $timeout, Server, Translate, videoStates, eqTestData, PopupService) {

    $scope.openMobileApp = function() {
        const privateLinkForMobileApp = `beehire://interview/${$rootScope.campaign.inviteKey}/${$rootScope.me.id}/${$rootScope.me.authKey}`;
        window.location = privateLinkForMobileApp;
    };
}])

.controller('IVideoEndCtrl', ["$scope", "$rootScope", "Translate", "Server", function($scope, $rootScope, Translate, Server) {

    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    $rootScope.secondaryNav = 'editcampaign';
    $rootScope.questionNumber = $rootScope.questionsTotal = 0;

    $scope.currentQuestion = 1;

    $scope.questions = [
        {text: Translate.getLangString('candidate_space_question_1')},
        {text: Translate.getLangString('candidate_space_question_2')},
        {text: Translate.getLangString('candidate_space_question_3')}
    ];

    if (isSafari) {
        $('video').attr('type', 'video/mp4');

        for (var i = 0; i < 3; ++i) {
            var url = $rootScope.recorderVideoUrls[i];
            if (url && url.length) {
                $rootScope.recorderVideoUrls[i] = url.replace('.webm', '.mp4');
            }
        }
    }

    $scope.play = function(index) {
        $scope.currentQuestion = Number(index);
        $('video').attr('autoplay', 'autoplay');
    };



    if ($rootScope.candidateSpace) {
        $rootScope.processNext = false;

        if ($rootScope.sessionId && $rootScope.sessionId != 0 && window.localStorage) {
            if (window.localStorage.demoSessions) {
                window.localStorage.demoSessions += ' ' + $rootScope.sessionId;
            } else {
                window.localStorage.demoSessions = $rootScope.sessionId;
            }
        }
    } else {
        $rootScope.processNext = function() {
            $rootScope.processNext = false;
            $rootScope.nextStep();
        };

        $rootScope.nextStep()
        .then(Server.get('candidates/logout'));
    }
}])

.controller('IProceedCtrl', ["$scope", "$state", "$rootScope", "Translate", function($scope, $state, $rootScope, Translate) {

    $rootScope.processNext = function() {
          $rootScope.nextStep();
    };

    $rootScope.secondaryNav = 'editcampaign';
    if ($state.is('documents_proceed')) {
        $rootScope.title = Translate.getLangString('documents');
        $rootScope.line1 = Translate.getLangString('proceed_page_documents_msg');
    }
    $rootScope.line2 = Translate.getLangString('proceed_page_desc');
}])

.controller('IEndCtrl', ["$scope", "$rootScope", "Server", "Translate", function($scope, $rootScope, Server, Translate) {
    //
    // URL Parameters
    //
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    Server.get('candidates/logout');
    $rootScope.hasProcessNav = false;
    $rootScope.hideNavAbout = true;
    $rootScope.secondaryNav = null;
    
    $scope.loadCampaign = function() {
        const pathArray = location.pathname.split('/');
        const inviteKey = pathArray[pathArray.length - 1];
        if (!(inviteKey && pathArray[pathArray.length - 2] === "invite")) {
            console.error('Expected an inviteKey : inviteKey was not found.');
        }

        Server.get('invite/'+inviteKey+'/welcome')
            .then(res => {
                $rootScope.campaign = res.campaign;
                // customization macros to make styling easier
                $rootScope.customization = {
                    brandColor: { "color": $rootScope.employerBranding.customization.mainColor || false },
                    brandBackColor: { "background-color": $rootScope.employerBranding.customization.mainColor || false },
                    navbarFontColor: { "color": $rootScope.employerBranding.customization.navbarFontColor ? $rootScope.employerBranding.customization.navbarFontColor : undefined },
                }

                // Use lan
                let campaignLanguages = [];
                for(let i = 0; i < 10 ; i++) {
                    if(res.campaign.title[i]) {
                        campaignLanguages.push(i);
                    }
                }

                Translate.initVisitorLanguageFromNavigator(0);
                if(! _.includes(campaignLanguages, Translate.currentLanguage())) {
                    Translate.changeVisitorLanguage(res.campaign.language);
                }
                if(params.lang && (typeof Translate.getLangNumberFromShort(params.lang) === 'number')) {
                    Translate.changeVisitorLanguage(Translate.getLangNumberFromShort(params.lang));
                }

                // Only how languages for which the campaign has a title
                const langDropdownObject = Translate.getLangDropdownObject()
                $scope.ddLangs = _.filter(langDropdownObject, ddLang => {
                    return _.includes(campaignLanguages, ddLang.value);
                });

                $rootScope.setNavTitle();
                $rootScope.campaignLoaded = true;
                hideLoader();
            })
    }

    $scope.loadCampaign();
}])

.controller('LiveInterviewHeaderCtrl', ["$scope", "$rootScope", "$state", "Server", "bowser", "PopupService", "Translate", "Util", "ToasterService", "NG_QUILL_CONFIG_DESCRIPTIONS", "SocketService", async function($scope, $rootScope, $state, Server, bowser, PopupService, Translate, Util, ToasterService, NG_QUILL_CONFIG_DESCRIPTIONS, SocketService) {

    $rootScope.backEnabled = true;
    $rootScope.showSidebar = false; // set to false to hide for candidates
    Util.setPageTitle(Translate.getLangString('live_interview'));
    $rootScope.back = function() {
        window.location.href = window.location.origin + '/dashboard#!/live-interview';
    }
    $scope.editorModules = NG_QUILL_CONFIG_DESCRIPTIONS.modules;

    var loadCollaborators = function() {
        Server.get('collaborators').then(function(collaborators) {
            if (!collaborators)
                return;
            $rootScope.collaborators = collaborators
            
            $scope.ddSessionOptions = collaborators.map(function (collaborator) {
                return {
                    text: Translate.getLangString('add_collaborator_option_1') + Util.userFullName(collaborator) + Translate.getLangString('add_collaborator_option_2'),
                    value: 'addcollaborator',
                    userId: collaborator.id
                }
            });

            $scope.ddSessionOptions.unshift({
                text: Translate.getLangString('invite_external_user'),
                value: 'addexternal',
            });
        });
    };

    $scope.ddSessionMenuSelected = {};
    $scope.ddSessionOptions = [];

    $scope.terminateSession = function() {
        $rootScope.$broadcast('terminateSession');
    };
    
    $scope.openInviteExternalUserPopup = function() {
        PopupService.openInviteExternal($scope, $rootScope.session);
    };
    

    $scope.ddSessionMenuClick = async function (selected, session) {
        switch (selected.value) {
            case 'addcollaborator':
                let collaborator = $rootScope.collaborators.find(collaborator => collaborator._id === selected.userId)
                Server.post('users/me/session/' + session._id +'/collaborator/' + selected.userId);
                ToasterService.success('add_collaborator_success', [Util.userFullName(collaborator)]);
                break;
            case 'addexternal':
                PopupService.openInviteExternal($scope, $rootScope.session);
                break;
        }
    };

    // $scope.badBrowser = !((bowser.chrome && bowser.version > 43) || (bowser.firefox && bowser.version > 39) || (bowser.opera && bowser.version > 30) ||
    //     (bowser.msedge && bowser.version >= 15) || (bowser.msie && bowser.version >= 11) ||
    //     (bowser.safari && bowser.version >= 8)
    // );
    // if ($rootScope.inWelcome && $scope.badBrowser) {
    //     window.location.replace('/interview');
    //     return;
    // }
    // DON'T FAIL ON BAD BROWSER. It's better to break then to redirect to nothing
    $scope.badBrowser = false;

    $rootScope.$state = $state;

    $rootScope.nextStep = function() {
        return Server.get('candidates/me/next')
            .then(function() {
                $state.go('session');
            });
    };

    $rootScope.profilePhoto = Util.profilePhoto;

    // default = candidate
    var userRoute = 'candidates/me';
    $rootScope.isRecruiter = false;
    $rootScope.isCandidate = true;
    $rootScope.isCollaborator = false;
    
    // teacher (=recruiter)
    if ($rootScope.liveInterview[2] == 0) {
        userRoute = 'users/me';
        $rootScope.isRecruiter = true;
        $rootScope.isCandidate = false;
        $rootScope.showSidebar = true;
    }
    
    // external
    if ($rootScope.liveInterview[1].startsWith('external')) {
        userRoute = 'external/me';
        $rootScope.isRecruiter = false;
        $rootScope.isCandidate = false;
        $rootScope.isExternal = true;
    }

    if($rootScope.isExternal) {
        $rootScope.me = await Server.get(userRoute + '?sessionId=' + $rootScope.liveInterview[0]);
    } else {
        $rootScope.me = await Server.get(userRoute);
    }
    if (!$rootScope.me) {
        return;
    }

    const session = await Server.get(userRoute + '/session/' + $rootScope.liveInterview[0]);
    $rootScope.session = session;

    if (!session) {
        return;
    }

    // 
    // Sidebar participants list (user-list)
    // 

    $rootScope.sidebarParticipants = makeSidebarParticipants($rootScope.session, $rootScope.me);

    /**
     * Builds the sidebar participants list from the session (from squareboard)
     * This list can be edited later on when external users join in
     */
    function makeSidebarParticipants(session, me) {
        let candidate = session.participants[0];
        let collaboratorParticipants = session.participants.slice(1); 
        let teacher = session.teacher;
        let participants = [
            {
                name: teacher.name,
                isMe: me.id === teacher._id,
                photoSrc: teacher.photoSrc ? `${window.location.origin}${teacher.photoSrc}` : null,
            },
            {
                name: candidate.name,
                waitingRoomStatus: candidate.waitingRoomStatus, // pending, accepted, rejected
                isMe: me.id === candidate.foreignUserId,
                isTheCandidate: true,
                photoSrc: candidate.photoSrc ? `${window.location.origin}${candidate.photoSrc}` : null,
                socketId: candidate.socketId,
            },
        ];
        if(collaboratorParticipants?.length) {
            participants.push(...collaboratorParticipants.map(cParticipant => {
                return {
                    name: cParticipant.name,
                    isMe: me.id ? (me.id === cParticipant.foreignUserId) : (me.name === cParticipant.name), // for recruiters and candidate, base this test on the ID, but for external users we can only rely on the name
                    waitingRoomStatus: cParticipant.waitingRoomStatus,
                    photoSrc: cParticipant.photoSrc ? `${window.location.origin}${cParticipant.photoSrc}` : null,
                    socketId: cParticipant.socketId,
                }
            }));
        }
        return participants;
    }

    $rootScope.acceptWaitingParticipant = async function(participant) {
        // await Server.post(`liveinterview/waitingStatus`, {status: 'accepted', sessionId: $rootScope.session._id});
        SocketService.emit('let-in', { meetingId: $rootScope.session._id, targetSocketId:  participant.socketId});
        ToasterService.success('interview_accept_participant', [participant.name]);
    }
    $rootScope.rejectWaitingParticipant = function(participant) {
        // Server.post(`liveinterview/waitingStatus`, {status: 'rejected', sessionId: $rootScope.session._id});
        SocketService.emit('reject', { meetingId: $rootScope.session._id, targetSocketId:  participant.socketId});
        ToasterService.success('interview_reject_participant', [participant.name]);
    }
    $rootScope.ddSidebarActions = [{
        text: Translate.getLangString('interview_waiting_room_reject'),
        value: 'rejectWaitPartic'
    }];
    $rootScope.ddSidebarActionSelect = function (selected, participant) {
        switch (selected.value) {
            case 'rejectWaitPartic':
                $rootScope.rejectWaitingParticipant(participant);
                break;
        }
    }

    // 
    // Websocket
    // 

    $scope.participantsWithPeopleWaiting = {};

    /**
     * Socket triggered on each change in the participants (ex: entering the waiting room)
     */
    SocketService.on('user-list', ({ meetingId, peopleWaiting }) => {
        console.log('user-list socket meetingId, peopleWaiting', meetingId, peopleWaiting);

        /* $rootScope.session.participants :
            - contains the candidate in position 0
            - contains the invited recruiters in position 1, 2, 3, ...
            - DOESN'T contain the external users
        */

        /**
         * peopleWaiting :
         *  - contains the waiting participants (candidates or external users)
         *  - it's an object with each key is the session ID of each participant
         * ex : {
         *  uujH4aZTcbdmBLkAAAV: {
         *      candidateId: "667e9c73b7f2a0abe8f7e94d"
         *      externalName: "",
         *      isRejected: false
         *  }}
         */

        const peopleWaitingEntries = Object.entries(peopleWaiting);
        const waitingCandidate = peopleWaitingEntries.find(p => p[1].candidateId === $rootScope.session.participants[0].foreignUserId);
        const candidateNames = {
            [$rootScope.session.participants[0].foreignUserId]: $rootScope.session.participants[0].name
        }

        /**
         * updating the candidate information on $rootScope.session.participants
         */
        if(waitingCandidate) {
            $rootScope.session.participants[0].socketId = waitingCandidate[0];
            if(waitingCandidate[1].isRejected) {
                $rootScope.session.participants[0].waitingRoomStatus = 'rejected';
            } else if (waitingCandidate[1].isAccepted) {
                $rootScope.session.participants[0].waitingRoomStatus = 'accepted';
            } else {
                $rootScope.session.participants[0].waitingRoomStatus = 'pending';
            }
        }
        
        /**
         * updating list of participants in the sidebar 
         */
        const sessionDeepCopy = JSON.parse(JSON.stringify($rootScope.session));
        const peopleWaitingWithoutCandidate = peopleWaitingEntries.filter(pw => pw[1].candidateId !== $rootScope.session.participants[0].foreignUserId);
        const allParticipants = [...$rootScope.session.participants, ...peopleWaitingWithoutCandidate.map(function (pw){
            return {
                name: pw[1].candidateId && candidateNames[pw[1].candidateId] ? candidateNames[pw[1].candidateId] : pw[1].externalName,
                waitingRoomStatus: pw[1].isRejected ? 'rejected' : (pw[1].isAccepted ? 'accepted' : 'pending'),
                socketId: pw[0],
                candidateId: pw[1].candidateId,
                externalName: pw[1].externalName,
            }
        })];
        sessionDeepCopy.participants = allParticipants;
        const newSidebarParticipants = makeSidebarParticipants(sessionDeepCopy, $rootScope.me);
        if(hasPendingStatusChangedOrAdded($rootScope.sidebarParticipants, newSidebarParticipants) && $rootScope.isRecruiter) {
            ToasterService.info('interview_waiting_pending');
        }
        $rootScope.sidebarParticipants = newSidebarParticipants;
    });

    function hasPendingStatusChangedOrAdded(oldArray, newArray) {
        // Convertir l'ancien tableau en un objet pour une recherche rapide par 'name'
        const oldStatusMap = {};
        oldArray.forEach(item => {
          oldStatusMap[item.name] = item.waitingRoomStatus;
        });
      
        // Parcourir le nouveau tableau pour détecter les changements ou les ajouts
        for (let newItem of newArray) {
          const oldStatus = oldStatusMap[newItem.name];
      
          // Si l'élément n'existait pas dans l'ancien tableau ou a changé pour 'pending'
          if ((oldStatus === undefined && newItem.waitingRoomStatus === 'pending') ||
              (oldStatus !== 'pending' && newItem.waitingRoomStatus === 'pending')) {
            return true;
          }
        }
    }

    /**
     * Socket triggered for a candidate/external when a recruiter lets in
     */
    SocketService.on('let-me-in', ({ meetingId }) => {
        $state.go('session');
        ToasterService.success('interview_accepted');
    });

    /**
     * Socket triggered for a candidate/external when a recruiter rejects
     */
    SocketService.on('reject-me', ({ meetingId }) => {
        $state.go('rejected');
        ToasterService.failure({}, 'interview_rejected');
    });

    if ($rootScope.isRecruiter) {
        SocketService.emit('re-emit-user-list', $rootScope.session._id);
        console.log('called re-emit-user-list', $rootScope.session._id);
    }

    // 
    // User type (teacher, candidate, external)
    // 

    for (var i = 1; i < $rootScope.session.participants.length; ++i) {
        if ($rootScope.session.participants[i].foreignUserId == $rootScope.me?._id) {
            $rootScope.isCollaborator = true;
        }
    }

    hideLoader();

    if ($rootScope.isRecruiter) {
        $state.go('session');
    } else if ($rootScope.isExternal) {
        $state.go('waiting-room');
    } else if ($rootScope.isCandidate) {

        $rootScope.me.studentUserId = session.participants[0].userId;

        if ($rootScope.me.step === 0) {
            $state.go('information');
        } else {

            // let waitingStatus = await Server.get(`liveinterview/waitingStatusCandidate?sessionId=${$rootScope.session._id}&studentUserId=${$rootScope.me.studentUserId}`)
            // if(waitingStatus?.status === 'accepted') {
            //     $state.go('session');
            // } else if(waitingStatus?.status === 'rejected') {
            //     $state.go('rejected');
            // } else {
                // $state.go('waiting-room');
                // }
            $state.go('waiting-room');
        }
    }

    if ($rootScope.isRecruiter) {
        loadCollaborators();
    }
}])

.controller('IWaitingRoomCtrl', ["$scope", "$rootScope", "$interval", "$sce", "Server", "$window", "$state", "Translate", "Util", "SocketService", function($scope, $rootScope, $interval, $sce, Server, $window, $state, Translate, Util, SocketService) {

    $scope.isReady = false;

    $scope.ready = async function() {

        if($rootScope.isExternal && !$rootScope.me.name) {
            // input field was not filled in
            $rootScope.errorOnExternalName = true;
            return;
        }

        $rootScope.errorOnExternalName = false;
        $scope.isReady = true;
        
        if($rootScope.isCandidate) {
            // let message = await Server.post(`liveinterview/waitingStatusCandidate`, {
            //     sessionId: $rootScope.session._id,
            //     status: 'pending'
            // });
            SocketService.emit('waiting', {meetingId: $rootScope.session._id, candidateId: $rootScope.me.id});
            console.log('My socket ID is:', SocketService.id());
        } else if($rootScope.isExternal) {
            // to be implemented for security
            // let message = await Server.post(`liveinterview/waitingStatusExternal`, {
            //     sessionId: $rootScope.session._id,
            //     status: 'pending'
            // });
            SocketService.emit('waiting', {meetingId: $rootScope.session._id, externalName: $rootScope.me.name || 'External'}); // TODO: only call after putting name?
            console.log('My socket ID is:', SocketService.id());
        }
    }

    if($rootScope.isCandidate) {
        $scope.ready();
    }

}])

.controller('IRejectedCtrl', ["$scope", "$rootScope", "$interval", "$sce", "Server", "$window", "$state", "Translate", "Util", function($scope, $rootScope, $interval, $sce, Server, $window, $state, Translate, Util) {
    console.log('in IRejectedCtrl');

    $scope.join = async function() {
        $state.go('waiting-room');
    }
}])

.controller('ISessionCtrl', ["$scope", "$rootScope", "$interval", "$sce", "Server", "$window", "$state", "Translate", "Util", "ToasterService", function($scope, $rootScope, $interval, $sce, Server, $window, $state, Translate, Util, ToasterService) {

    Util.setPageTitle(Translate.getLangString('live_interview'));
    $scope.notesText = '';

    let queryParms = {
        langShortCode: Translate.getShortFromLangNumber(Translate.currentLanguage()),
        wait: $rootScope.isRecruiter ? 'false' : 'true',
        prefilledName: ''
    };

    // 
    // Comments, notes
    // 

    /**
     * Saves the notes text in a new candidate's interviewNotes 
     * If clicked multiple times, only one same note will be saved (its edited each time)
     */
    $scope.addComment = async function() {
        var text = $scope.notesText;
        if (!text)
            return;
        var candidateId = $rootScope.session.participants[0].foreignUserId;
        if($scope.addedCommentId) {
            Server.put(`candidates/${candidateId}/interviewNotes/${$scope.addedCommentId}`, {
                title: Translate.getLangString('interview_live'),
                body: text,
                date: new Date(),
            });
        } else {
            let addedComment = await Server.post(`candidates/${candidateId}/interviewNotes`, {
                title: Translate.getLangString('interview_live'),
                body: text,
                date: new Date(),
            });
            $scope.addedCommentId = addedComment._id;
        }
        ToasterService.success('session_notes_save_success');
    };

    $window.onbeforeunload = $scope.addComment;

    //
    // Other
    //

    $scope.$on('terminateSession', async function () {
        if ($rootScope.isRecruiter) {
            $scope.addComment().then(() => {
                Server.get('session/' + $rootScope.liveInterview[0] + '/end');
            }) 
        } else if ($rootScope.isCandidate) {
            $scope.iframeUrl = $sce.trustAsResourceUrl('about:blank');
            $rootScope.session = null;
            $state.go('end');
        }
    });

    // 
    // Setting the iframe
    // 

    if ($rootScope.isRecruiter) {
        if (!$rootScope.isCollaborator) {
            $scope.iframeUrl = $rootScope.session.serviceUrl + 'sessions/' + $rootScope.session._id + '/accept/' + $rootScope.me._id + '/' + $rootScope.me.authKey;
        } else {
            for (var i = 1; i < $rootScope.session.participants.length; ++i) {
                if ($rootScope.session.participants[i].foreignUserId == $rootScope.me._id) break;
            }
            $scope.iframeUrl = $rootScope.session.serviceUrl + 'sessions/' + $rootScope.session._id + '/accept/' + $rootScope.session.participants[i].id + '/' + $rootScope.session.participants[i].authKey;
        }
    } else if ($rootScope.isCandidate) {
        $scope.iframeUrl = $rootScope.session.serviceUrl + 'sessions/' + $rootScope.session._id + '/accept/' + $rootScope.session.participants[0].id + '/' + $rootScope.session.participants[0].authKey;
    }

    // live V2 overrides all (for BOSA and maybe other clients)
    let user = $rootScope.me?.campaign?.owner || $rootScope.me;
    let pingLiveServerInterval;
    if($rootScope.isRecruiter) {
        queryParms.prefilledName = $rootScope.me?.name || '';
    } else if ($rootScope.isCandidate) {
        queryParms.prefilledName = $rootScope.me?.firstName || '';
    }
    // Applies only for recruiters/candidates using V2, and external users (who only exist in V2)
    if (user?.privileges?.useLiveInterviewV2 || $rootScope.isExternal) {
        $scope.iframeUrl = `https://live-v2-scheduler.beehire.com/liveinterview?iframe=true`;
        if (!$rootScope.isExternal && !$rootScope.isCandidate) {
            Server.get('liveinterview/server-ping');
            Server.post(`liveinterview/server-status`, {
                action: "start"
            });
        }
        let checkForLiveV2ReadyBusy = false;
        let checkForLiveV2Ready = $interval(() => {
            // don't make another call if the last one isn't done
            if (checkForLiveV2ReadyBusy === false) {
                checkForLiveV2ReadyBusy = true;
                Server.get(`liveinterview/server-status`).then(function(status) {
                    checkForLiveV2ReadyBusy = false;
                    if (status.statusTargetsReady) {
                        $scope.iframeUrl = $sce.trustAsResourceUrl(`https://live-v2.beehire.com/liveinterview/${$rootScope.session._id}?iframe=true&name=${queryParms.prefilledName}&wait=${queryParms.wait}&lang=${queryParms.langShortCode}`);
                        $interval.cancel(checkForLiveV2Ready);
                        checkForLiveV2Ready = null;
                        if (!$rootScope.isExternal && !$rootScope.isCandidate) {
                            pingLiveServerInterval = $interval(() => {
                                Server.get('liveinterview/server-ping');
                            }, 30000);
                        }
                    }
                }).catch(function(err) {
                    checkForLiveV2ReadyBusy = false;
                    console.log('Err:', err);
                });
            }
        }, 5000);
    }

    // Needed to not get error "Blocked loading resource from url not allowed by $sceDelegate policy"
    $scope.iframeUrl = $sce.trustAsResourceUrl($scope.iframeUrl);

    // Listen for the $destroy event to clean up the interval when the scope is destroyed
    $scope.$on('$destroy', function() {
        if (pingLiveServerInterval) {
            $interval.cancel(pingLiveServerInterval);
            pingLiveServerInterval = null;
        }
    });
}])

;
