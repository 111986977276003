//@ts-check
angular.module('app').controller('CandidateProfileLoginCtrl', ["$rootScope", "$scope", "Server", "$window", "Translate", "$state", "overlaySpinner", "ToasterService", "Util", function ($rootScope, $scope, Server, $window, Translate, $state, overlaySpinner, ToasterService, Util) {
    $rootScope.showBanner = false;

    $scope.email = '';
    $scope.password = '';
    $scope.confirmPassword = '';
    $scope.auth = null;
    $scope.authData = null;
    $scope.errors = {};

    $scope.login = function() {
        const overlay = overlaySpinner.show('candidate-login');
        Server.post(
            'candidates/candidate-area/login?auth=' + $scope.auth,
            { password: $scope.password }
        ).then(res => {
            overlay.hide();
            ToasterService.success('login_connecting');
            location.href = '/candidate-area';
        }).catch(err => {
            overlay.hide();
            if (err.data.redirect) {
                location.href = err.data.redirect + "?error=" + (err.data.error.code || 'login_unknown_error');
            } else {
                ToasterService.failure(err, 'login_unknown_error');
            }
        });
    }

    $scope.requestReset = function() {
        const overlay = overlaySpinner.show('candidate-login');
        Server.post(
            'candidates/candidate-area/forgot-password?auth=' + $scope.auth,
            { email: $scope.email }
        ).then(res => {
            overlay.hide();
            ToasterService.success('profile_password_change_request');
            $scope.passwordRequestSent = true;
        }).catch(err => {
            overlay.hide();
            if (err.data.redirect) {
                location.href = err.data.redirect + "?error=" + (err.data.error.code || 'login_unknown_error');
            } else {
                ToasterService.failure(err, 'login_unknown_error');
            }
        });
    }

    $scope.resetPasssword = function() {
        const passwordCheck = Util.checkPassword($scope.password, $scope.confirmPassword)
        $scope.errors = {}
        if (passwordCheck) {
            $scope.errors.password = passwordCheck
            return;
        }

        const overlay = overlaySpinner.show('candidate-login');
        Server.post(
            'candidates/candidate-area/reset-password?auth=' + $scope.auth,
            { password: $scope.password, confirmPassword: $scope.confirmPassword }
        ).then(res => {
            overlay.hide();
            $scope.passwordChanged = true;
        }).catch(err => {
            overlay.hide();
            if (err.data.redirect) {
                location.href = err.data.redirect + "?error=" + (err.data.error.code || 'login_unknown_error');
            } else {
                ToasterService.failure(err, 'login_unknown_error');
            }
        });
    }

    if ($state.is('reset-password') || $state.is('login')) {
        const queryString = Util.getQueryString(location);
        $scope.auth = queryString.auth;
        $scope.authData = Util.parseJwt($scope.auth);
        if (Util.jwtExpired($scope.authData)) {
            ToasterService.failure({}, 'candidate_profile_invalid_link');
            if ($state.is('reset-password')) {
                $state.go('forgot-password');
            } else {
                $state.go('insert-email');
            }
        }
    }
}]);
