
PugTooltipController.$inject = ["$scope", "$element", "$attrs"];angular.module('app')
  .component('pugTooltip', {
    templateUrl: '../templates/components/pug-tooltip.component.html',
    controller: PugTooltipController,
    bindings: {
      title: '@',
      width: '@',
      parentselector: '<',
    },
    transclude: true,
  });

function PugTooltipController($scope, $element, $attrs) {
  var $ctrl = this;
  $ctrl.id = $ctrl.id ? $ctrl.id : $ctrl.parentselector + '__tooltip';
  $ctrl.visible = false;

  /**
   * set top and left styles of the element
   * @param {Element} el 
   * @param {number} top 
   * @param {number} left 
   */
  function setPosition(el, top, left) {
    el.style.top = top + 'px';
    el.style.left = left + 'px';
  }

  $scope.smartPositioning = function() {
    const containerElement = $element[0].querySelector('.tooltip__container');
    let top = Math.round(event.target.clientHeight / 2);
    let left = Math.round(event.target.clientWidth / 2);
    setPosition(containerElement, top, left);

    const boundRect = containerElement.getBoundingClientRect();
    if (boundRect.bottom > (window.innerHeight - 20)) {
      top -= boundRect.bottom - window.innerHeight + 20;
    }
    if (boundRect.right > (window.innerWidth - 20)) {
      left -= boundRect.right - window.innerWidth + 20;
    }
    setPosition(containerElement, top, left);
  }

  $ctrl.mouseEnterHandler = function(event) {
    $ctrl.visible = true;
    $scope.$digest();

    $scope.smartPositioning();
  }
  $ctrl.mouseLeaveHandler = function() {
    $ctrl.visible = false;
    $scope.$digest();
  }

  $ctrl.$onInit = function() {
    setTimeout(function() {
      const parent = document.querySelector($ctrl.parentselector);
      if (parent) {
        parent.addEventListener('mouseenter', $ctrl.mouseEnterHandler);
        parent.addEventListener('mouseleave', $ctrl.mouseLeaveHandler);
      }
    }, 100);
  }
}