angular.module('app').factory('EmailValidationService', function() {
  function isValidEmail(email) {
    // This is the HTML5 email validation regex standard for input elements
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  }

  return {
    validate: function(attributes, constraints) {
      if (constraints.from && constraints.from.email) {
        if (!isValidEmail(attributes.from)) {
          return {
            from: ['is not a valid email']
          };
        }
      }
      return undefined;
    }
  };
});