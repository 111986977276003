
StripeCheckoutController.$inject = ["$scope", "$rootScope", "Translate", "ToasterService", "overlaySpinner", "Server", "EventTracker"];angular.module('app')
    .component('stripeCheckout', {
        templateUrl: '../templates/components/stripe-checkout.component.html',
        controller: StripeCheckoutController,
        bindings: {
            clientSecret: '=',
            returnUrl: '=',
            price: '=',
            campaignId: '=',
            orderId: '=',
            showSubmit: '<',
            onCancel: "&",
            onSuccess: "&",
        },
    });

function StripeCheckoutController($scope, $rootScope, Translate, ToasterService, overlaySpinner, Server, EventTracker) {
    var $ctrl = this;
    $ctrl.loading = true;

    // Make translations accessible to the component's scope
    $scope.texts = $rootScope.texts;
    $scope.coupon = {
        code: "",
        isValid: true,
        discountPrice: null,
        isFree: false,
        hasDiscount: false,
        previousCode: "",
        show: false,
    };

    $scope.termsText = Translate.getLangString("terms_sale_check_with_link")
    $scope.termsAccepted = false;
    $scope.errors = {};

    $scope.validateCoupon = _.debounce(function() {
        if (!$scope.$ctrl.campaignId || !$scope.$ctrl.orderId) {
            return;
        }
        if ($scope.coupon.code === $scope.coupon.previousCode) {
            return;
        }
        $scope.coupon.previousCode = $scope.coupon.code;

        const overlay = overlaySpinner.show('payment');
        Server.post('campaigns/' + $scope.$ctrl.campaignId + '/vonq-integration/orders/' + $scope.$ctrl.orderId + '/apply-coupon?couponCode=' + $scope.coupon.code)
            .then((res) => {
                overlay.hide();
                $scope.coupon.isValid = res.isValid;
                if (res.isValid) {
                    $scope.coupon.discountPrice = res.vonqOrder.amountInclVat;
                    ToasterService.success("coupon_accepted");
                } else {
                    ToasterService.failure({}, "invalid_coupon");
                }
                $scope.coupon.isFree = res.vonqOrder.amountInclVat === 0;
                $scope.coupon.hasDiscount = $scope.coupon.code && res.isValid && Math.round($scope.coupon.discountPrice*100) !== Math.round($ctrl.price*100);
            }).catch(err => {
                overlay.hide();
                ToasterService.failure({}, "invalid_coupon");
                $scope.coupon.isValid = false;
                $scope.coupon.isFree = false;
                $scope.coupon.hasDiscount = false;
            })
    }, 500);

    $ctrl.cancel = function () {
        if ($ctrl.onCancel) {
            $ctrl.onCancel();
        }
    }

    $ctrl.submit = async function () {
        if (!$scope.termsAccepted) {
            $scope.errors.termsAccepted = true;
            return;
        }
        if ($scope.coupon.code && !$scope.coupon.isValid) {
            ToasterService.failure({}, "invalid_coupon");
            return;
        }

        if ($scope.coupon.isFree) {
            Server.post('campaigns/' + $scope.$ctrl.campaignId + '/vonq-integration/orders/' + $scope.$ctrl.orderId + '/pay')
            .then((res) => {
                if (res.success) {
                    ToasterService.success("campaign_payment_success");
                    if ($ctrl.onSuccess) {
                        $ctrl.onSuccess()
                    }
                    window.location.href = $ctrl.returnUrl;
                } else {
                    ToasterService.failure({}, "failed_to_execute_payment");
                }
            }).catch(err => {
                ToasterService.failure(err, "failed_to_execute_payment");
            })
        }
        else {
            overlaySpinner.show('payment');
            const {error} = await $scope.stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: $ctrl.returnUrl,
                },
            });
            overlaySpinner.hide('payment');
            if (error) {
                if (error.message) {
                    ToasterService.failureMessage(error.message);
                } else {
                    ToasterService.failure(error, 'failed_to_execute_payment');
                }
            } else {
                EventTracker.trackVonqPaySuccess();
            }
        }
    };

    $ctrl.$onInit = async function () {
        if (!$ctrl.clientSecret || !$ctrl.returnUrl) {
            return;
        }
        overlaySpinner.show('payment');
        $ctrl.loading = true;

        // get and cache the publishable key
        if (!$scope.publishableKey) {
            const {publishableKey} = await Server.get('vonq-integration/stripe/publishableKey');
            if (!publishableKey) {
                throw new Error('Failed to load stripe publishable key');
            }
            $scope.publishableKey = publishableKey;
        }

        $scope.stripe = Stripe($scope.publishableKey);
        const appearance = {
            theme: 'stripe',
        };
        elements = $scope.stripe.elements({
            appearance,
            clientSecret: $ctrl.clientSecret,
        });

        const paymentElement = elements.create("payment");
        paymentElement.on('ready', function () {
            $ctrl.loading = false;
            overlaySpinner.hide('payment');
            $scope.$digest();
        });
        paymentElement.on('change', function ({error}) {
            $ctrl.loading = false;
            if (error) {
                ToasterService.failure(error, error.message);
            }
            $scope.$digest();
        });
        paymentElement.mount("#payment-element");
    }
}
