//@ts-check
angular.module('app').controller('DashboardCareerCtrl', ["$scope", "$rootScope", "Server", "Onboarding", "clipboard", "toaster", "Translate", "$timeout", "Upload", "Util", "ToasterService", "overlaySpinner", "VideoClipUploader", "PopupService", "NG_QUILL_CONFIG_DESCRIPTIONS", function ($scope, $rootScope, Server, Onboarding, clipboard, toaster, Translate, $timeout, Upload, Util, ToasterService, overlaySpinner, VideoClipUploader, PopupService, NG_QUILL_CONFIG_DESCRIPTIONS) {
  Util.setPageTitle(Translate.getLangString('career_page'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  $scope.editorModules = NG_QUILL_CONFIG_DESCRIPTIONS.modules;
  $scope.ddLangs = Translate.getLangDropdownObject();
  $scope.careerPageVideos = [];
  $scope.careerPageHeaderVideo;
  $scope.locationOptions = [
    { value: 'beforeJobs', label: 'Before section "Jobs"' },
    { value: 'beforeInfo', label: 'Before section "About us"' },
    { value: 'beforeFindUs', label: 'Before section "Where to find us"' },
    { value: 'beforeFooter', label: 'Before footer' }
  ];
  $scope.arrangementOptions = [
    { value: 'textThenMedia', label: 'Text then media' },
    { value: 'mediaThenText', label: 'Media then text' },
    { value: 'onlyText', label: 'Only text' },
    { value: 'onlyMedia', label: 'Only media' },
    { value: 'imageBanner', label: 'Image banner' },
    { value: 'videoBanner', label: 'Video banner' },
  ];
  $scope.uploadVideoClip = function (file, data) {
    if (!file) {
        return;
    }
    var metaData = {
        text: (file && file.name ? file.name : ''),
        category: data.category
    };
    overlaySpinner.show('dashboard-career');
    VideoClipUploader.uploadFromFile(file, metaData)
        .then(function (result) {
            overlaySpinner.hide('dashboard-career');
            if (result.errorMessage) {
                alert(result.errorMessage);
            } else if (result._id) {
                data.videoClipId = result._id;
            }
        });
  };
  $scope.openVideoClips = function (data) {
      PopupService.openVideoClips($scope, data);
  };
  $scope.removeVideo = function (data) {
      data.videoClipId = null;
  };

  // 
  // Section videos
  // 

  var updateVideoIds = function () {
      $scope.careerPageVideos = _.map($rootScope.user.careerPage.sections, section => ({
          videoClipId: section?.video?.[0]?.videoClipId,
          text: '',
          category: 'Career Page Video'
      }));
  };

  function setVideoClips(careerPageVideos) {
      _.forEach(careerPageVideos, (careerPageVideo, i) => {
          if ($rootScope?.user?.careerPage?.sections?.[i]) {
              $rootScope.user.careerPage.sections[i].video = {
                  0: {
                      videoClipId: careerPageVideo.videoClipId
                  }
              };
          }
      });
  }

  $scope.$watch('careerPageVideos', function (newValues, oldValue) {
      setVideoClips(newValues);
  }, true);

  // 
  // Banner video
  // 

  var updateHeaderVideoIds = function () {
      $scope.careerPageHeaderVideo = {
              videoClipId: $rootScope?.user?.careerPage?.headerVideoClipId,
              text: '',
              category: 'Career Page Header Video'
      };
  };
  function setHeaderVideoClips(careerPageHeaderVideo) {
      $rootScope.user.careerPage.headerVideoClipId = careerPageHeaderVideo.videoClipId;
  }
  $scope.$watch('careerPageHeaderVideo', function (newValues, oldValue) {
      setHeaderVideoClips(newValues);
  }, true);

  // 
  // 
  // 
  
  $scope.dragSection = function (index) {
      $rootScope.user.careerPage.sections.splice(index, 1);
      updateVideoIds();
  };

  $timeout(function() {
      if (!$rootScope.fns.hasPrivileges(['hasCareerPage'])) {
          $rootScope.upgradePlan('upgrd_career_page', { backOnClose: true })
      }
  }, 1000);

  $scope.$watch('$root.user', function () {
      if ($rootScope.user) {
          updateVideoIds();
          updateHeaderVideoIds();
          Server.get('users/' + $rootScope.user._id + '/career/link')
              .then(function (result) {
                  if (result) {
                      $scope.careerLink = result.link
                  }
              });
      }
  });

  $scope.copyLink = function () {
      clipboard.copyText($scope.careerLink);
      ToasterService.success('link_copied', {
          timeout: 2000
      });
  }

  $scope.save = function () {
      setVideoClips($scope.careerPageVideos);
      Server.post('users/me/careerPage', $rootScope.user.careerPage)
      .then(function (user) {
          ToasterService.success('user_well_updated');
      }, function (err) {
          ToasterService.failure(err, 'user_not_updated');
      });
  }

  $scope.addSection = function () {
      $rootScope.user.careerPage.sections.push({
          title: {},
          location: 'beforeJobs',
          arrangement: 'onlyText',
          text: {},
          images: [],
          video: {}
      });
      $scope.careerPageVideos.push({
          text: '',
          category: 'Career Page Video'
      });
  }

  $scope.removeSection = function (index) {
      $rootScope.user.careerPage.sections.splice(index, 1);
      $scope.careerPageVideos.splice(index, 1);
  }

  $scope.uploadImage = function (sectionIndex, file) {
      if (!file) {
          return console.log('error no file!', file);
      }
      let url = `users/me/careerPageImage/${sectionIndex}`;
      overlaySpinner.show('dashboard-career');
      Upload.upload({
          url: Server.makeUrl(url),
          data: {
              file: file
          }
      }).then(function (resp) {
          $rootScope.user = resp.data;
          overlaySpinner.hide('dashboard-career');
      }, function (resp) {
          overlaySpinner.hide('dashboard-career');
          console.log('Error' + resp);
      });
  }

  $scope.updateSocialMedia = function () {
     const lang = $rootScope.user.language;
      Server.post('users/me', $scope.user)
          .then(function (user) {
              overlaySpinner.hide('dashboard-career');
              $rootScope.user = user;
              if (user.language !== lang) {
                  location.reload();
              }
              ToasterService.success('user_well_updated');
          }, function (err) {
              overlaySpinner.hide('dashboard-career');
              ToasterService.failure(err, 'user_not_updated');
          });
  }

  Onboarding.initWidget(null);
}]);
