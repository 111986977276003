//@ts-check
//@ts-ignore
SubmissionCandidateAssessmentSummaryCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Translate", "ToasterService", "Util"];
angular.module('app').controller('SubmissionCandidateAssessmentSummaryCtrl', SubmissionCandidateAssessmentSummaryCtrl);

function SubmissionCandidateAssessmentSummaryCtrl($scope, $rootScope, $state, $stateParams, Server, Translate, ToasterService, Util) {
    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user)) {
          $state.go('submission', { candidateId: $stateParams.candidateId });
        }
    });

    $scope.selectedAssessment = undefined;
    $scope.assessmentInfo = undefined;
    $scope.candidate = undefined;
    $scope.assessmentLogs = []
    $scope.answersRef = { candidateId: $stateParams.candidateId, assessmentId: $stateParams.assessmentId }
    $scope.isFocused = true;
    $scope.startedTime = undefined;
    $scope.timerCountdown = undefined;

    $scope.setAssessment = function() {
        if ($scope.candidate && $scope.candidate.assessments) {
            $scope.selectedAssessment = $scope.candidate.assessments.find(a => a.assessmentId === $stateParams.assessmentId);
            if ($scope.selectedAssessment.startedAt) {
                $scope.startedTime = new Date($scope.selectedAssessment.startedAt);
            }
        }
    }

    $scope.$on('onCandidateData', function (event, candidate) {
        if (candidate) {
            $scope.candidate = candidate;
            $scope.setAssessment();
        }
    });

    $scope.loadAssessmentInfo = function() {
        Server.get('assessments/' + $stateParams.assessmentId)
            .then(assessment => {
                $scope.assessmentInfo = assessment;
                if ($scope.assessmentInfo.useTimer) {
                    const timerInterval = setInterval(() => {
                        if (!$scope.assessmentInfo || !$scope.selectedAssessment || !$scope.startedTime) {
                            return;
                        }
                        if ($scope.selectedAssessment.status === 'in_progress') {
                            const limit = $scope.assessmentInfo.timerMinutes || 60;
                            $scope.timerCountdown = Util.getRemainingTime($scope.startedTime, limit);
                        } else {
                            $scope.timerCountdown = undefined
                        }
                        if ($scope.timerCountdown === undefined || $scope.timerCountdown === '00:00:00') {
                            clearInterval(timerInterval);
                        }
                        $scope.$apply();
                    }, 1000);
                }
            }).catch((err) => {
                ToasterService.failure(err, 'load_assessments_error');
            })
    }

    $scope.loadAssessmentLogs = function() {
        Server.get('candidates/'+$stateParams.candidateId+'/assessments/'+$stateParams.assessmentId+'/logs')
            .then((logs) => {
                $scope.assessmentLogs = logs.map($scope.formatLogEntry)
                if (logs.length > 0 && logs[logs.length-1].messageCode === 'log_1_page_blur') {
                    $scope.isFocused = false;
                }
            }).catch(err => {
                ToasterService.failure(err, 'load_assessments_error');
            })
    }

    $scope.formatLogEntry = function(appLog) {
        return {
            id: appLog._id,
            date: Util.getRelativeDateTime(appLog.date),
            messageCode: appLog.messageCode,
            message: appLog.messageCode ? Translate.getLangString(appLog.messageCode) : appLog.message,
        }
    }
    
    try {
        $scope.socket = io("/candidates/" + $stateParams.candidateId + "/assessments/" + $stateParams.assessmentId, {transports: ["websocket"]});
        $scope.socket.on("connect", () => {
            // console.log('connected');
        });
        const onMessage = (data) => {
            $scope.assessmentLogs.push($scope.formatLogEntry(data));
            $scope.$apply();
        };
        $scope.socket.on("started", (data) => {
            $scope.isFocused = true;
            $scope.selectedAssessment.status = "in_progress";
            $scope.startedTime = new Date();
            onMessage(data);
        });
        $scope.socket.on("page_focus", (data) => {
            $scope.isFocused = true;
            onMessage(data);
        });
        $scope.socket.on("page_blur", (data) => {
            $scope.isFocused = false;
            onMessage(data);
        });
        $scope.socket.on("done", (data) => {
            $scope.isFocused = true;
            $scope.selectedAssessment.status = "done";
            onMessage(data);
        });
    } catch(err) {
        console.error("Failed to connect socket", err)
    }

    $scope.setAssessment();
    $scope.loadAssessmentInfo();
    $scope.loadAssessmentLogs();
}
