//@ts-check
angular.module('app').controller('SubmissionDocumentsCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Upload", "overlaySpinner", "Util", "ToasterService", "PopupService", "Translate", function ($scope, $rootScope, $state, $stateParams, Server, Upload, overlaySpinner, Util, ToasterService, PopupService, Translate) {
    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.submissionKey && !$rootScope.fns.userHasRights('candidates.documents', 'view', user)) {
            $state.go('submission', { candidateId: $stateParams.candidateId })
        }
    });

    $scope.onCandidateData = function(candidate) {
        $scope.candidateDocuments = candidate.documents.filter($scope.docFilterFn);
        if ($scope.candidateDocuments) {
            for (const document of $scope.candidateDocuments) {
                document.url = document.s3Url;
                document.viewUrl = document.viewUrl;
                if (document.url && $rootScope.submissionKey) {
                    const paramPrefix = document.url.includes('?') ? '&' : '?';
                    document.url += `${paramPrefix}submissionKey=${$rootScope.submissionKey}`;
                    document.viewUrl += `${paramPrefix}submissionKey=${$rootScope.submissionKey}`;
                }
            }
        }
    }

    $scope.printAllDocuments = function () {
        PopupService.openCandidateDocumentPrinter($scope, [$rootScope.candidate]);
    }

    $scope.download = function (doc) {
        console.log(doc);
        var a = document.createElement('a');
        a.style.display = 'none';
        if (doc.url) {
            a.href = doc.url;
        } else {
            a.href = Server.makeResourceUrl(doc.filename);
        }
        a.download = Util.userFullName($rootScope.candidate) + ' - ' + doc.downloadFilename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
        }, 100);
    };

    $scope.delete = function (doc) {
        PopupService.openGenericPopup($scope, {
            submit: function () {
                $scope.modalHandle.close();
                Server.post(`users/${$rootScope.user._id}/deleteSubmissionDocument/${$rootScope.candidate._id}`, doc)
                .then(resp => {
                    $rootScope.candidate.documents = resp.documents;
                    $scope.onCandidateData($rootScope.candidate);
                })
                .catch(err => {
                    ToasterService.failure(err);
                });
            },
            title: Translate.getLangString('delete_confirmation_title'),
            warningText: Translate.getLangString('delete_confirmation_warning'),
            yesText: Translate.getLangString('delete'),
            noText: Translate.getLangString('cancel')
        }, 'templates/modal-confirm-warning.html', {});
    };

    $scope.docFilterFn = function (doc) {
        if ($rootScope.fns.hasUiSettings(['showDuplicatePdf'])) {
            return doc.downloadFilename && !doc.isDeleted;
        }
        return doc.downloadFilename && !doc.isPdfConversion && !doc.isDeleted;
    };

    // duplicate code (reference 1345)
    $scope.uploadDoc = function (files) {
        if (!files || files.length === 0) {
            return;
        }
        overlaySpinner.showWithoutTimeout('submission');
        Upload
            .upload({
                url: Server.makeUrl('users/' + $rootScope.user._id + '/addDocumentToSubmission/' + $rootScope.candidate._id),
                data: { files: files },
                arrayKey: '[]',
            })
            .then(function (resp) {
                overlaySpinner.hide('submission');
                $rootScope.candidate.documents = resp.data.documents;
                $scope.onCandidateData($rootScope.candidate, true);
            }, function (err) {
                overlaySpinner.hide('submission');
                ToasterService.failure(err)
            });
    };

    $scope.$on('onCandidateData', function(event, candidate) {
        $scope.onCandidateData(candidate)
    });
    if ($rootScope.candidate) {
        $scope.onCandidateData($rootScope.candidate);
    }
}]);
