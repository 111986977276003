//@ts-check
// Controller for the candidate database
angular.module('app').controller('CandidatePageCtrl', ["$scope", "$rootScope", "$state", "$timeout", "Server", "overlaySpinner", "ToasterService", "Translate", "$q", "Onboarding", "PopupService", "Util", "EventTracker", function ($scope, $rootScope, $state, $timeout, Server, overlaySpinner, ToasterService, Translate, $q, Onboarding, PopupService, Util, EventTracker) {
  Util.setPageTitle(Translate.getLangString('candidate_page'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;
  $scope.canhowNoCandidatesPlaceholder = false;
  $scope.limitDisplay = $scope.displayLimitPage = 25;
  $scope.showCampaignFilter = true;
  $scope.openCsvImportPopup = function () {
      EventTracker.trackCandidateAdd();
      PopupService.openCsvImportPopup($scope, {}, $rootScope.campaign);
  };
  Onboarding.initWidget(null);

  $scope.loadMore = function () {
      if ($scope.candidateList) {
        $scope.candidateList.loadNext();
      }
  };

  $scope.fetchCandidates = function (filters, sort, offset, limit, isKanban) {
    const overlay = overlaySpinner.show('candidates');
    const url = isKanban ? 'candidates/kanban' : 'candidates';
    return Server.post(url + '?fetchHistoryCount=true', { filters, sort, pagination: { offset, limit } }, { preserveCache: true })
      .then((res) => {
        $timeout(() => {
          overlay.hide();
        }, 1000 * res.items.length / 100); // add one second delay for every 100 items to account for dom lockup period
        return res
      })
      .catch((err) => {
        overlay.hide();
        ToasterService.failure(err, 'load_candidates_error');
      });
  }
}]);
