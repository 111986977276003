//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardUserProfileSecurityCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "ToasterService", "PopupService", "overlaySpinner", "EventTracker", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, ToasterService, PopupService, overlaySpinner, EventTracker) {
  $scope.mfaEnabled = window.__env.auth0MfaEnabled;
  $scope.checkMfaPermission = function() {
    $scope.disableRemoveMfa = $rootScope.user?.auth0?.mfaActivated && $rootScope.user?.auth0?.isMfaForcedForAccount;
  }

  $scope.resetPassword = function() {
    $scope.showResetPassword = true;
    $scope.message = $scope.password1 = $scope.password2 = '';
  }

  $scope.cancelResetPassword = function() {
    $scope.showResetPassword = false;
  }

  $scope.sendPasswordResetEmail = function() {
    const overlay = overlaySpinner.show('user-profile');
    Server.post('users/me/resetpwd')
      .then((res) => {
        $scope.cancelResetPassword();
        ToasterService.success('send_reset_pass_email_success');
        overlay.hide();
      }).catch(err => {
        ToasterService.failure(err, 'err_0_error_occurred');
        overlay.hide();
      });
  }

  $scope.checkMfa = function() {
    const overlay = overlaySpinner.show('user-profile');
    Server.post('users/me/check-mfa')
        .then((res) => {
            overlay.hide();
            const wasActivated = _.get($rootScope.user, 'auth0.mfaActivated');
            if (!!wasActivated !== !!res.mfaActivated) {
              ToasterService.success('mfa_synchronized');
              _.set($rootScope.user, 'auth0.mfaActivated', res.mfaActivated);
            }
            $scope.checkMfaPermission();
        }).catch(err => {
            ToasterService.failure(err, 'err_0_error_occurred');
            overlay.hide();
        });
  }

  $scope.setupMfa = function() {
    const overlay = overlaySpinner.show('user-profile');
    Server.post('users/me/setup-mfa').then((res) => {
      overlay.hide();
      const mfaWindow = window.open(res.ticketUrl, '_blank');
      if (mfaWindow) {
        const interval = setInterval(() => {
          if (mfaWindow && mfaWindow.closed) {
            ToasterService.success('mfa_configured');
            clearInterval(interval);
            $scope.checkMfa();
          }
        }, 200);
      }
    }).catch(err => {
        ToasterService.failure(err, 'err_0_error_occurred');
        overlay.hide();
    });
  }

  $scope.removeMfa = function() {
    if ($scope.disableRemoveMfa) {
      return;
    }

    PopupService.openGenericPopup($scope, {
      submit: function () {
        const overlay = overlaySpinner.show('user-profile');
        Server.delete('users/me/remove-mfa').then((res) => {
            overlay.hide();
            ToasterService.success('mfa_removed');
            $rootScope.user.auth0.mfaActivated = false;
            $scope.modalHandle.close();
        }).catch(err => {
            ToasterService.failure(err, 'err_0_error_occurred');
            overlay.hide();
        });
      },
      title: Translate.getLangString('confirmation'),
      warningText: Translate.getLangString('remove_mfa_confirmation'),
      yesText: Translate.getLangString('remove_mfa'),
      noText: Translate.getLangString('cancel')
    }, 'templates/modal-confirm-warning.html', {});
  }

  $scope.saveUserSecurity = function() {
    if ($rootScope.user.userType > 0) {
      console.log($scope.editUser);
      $scope.saveUser(_.pick($scope.editUser, 'auth0'))
        .then($scope.checkMfaPermission);
    } else {
      ToasterService.warning('no_changes_to_save');  
    }
  }

  $scope.setupEditUser();
  $scope.checkMfaPermission();
  if ($scope.mfaEnabled && (!$rootScope.user.auth0 || !$rootScope.user.auth0.mfaActivated)) {
    $scope.checkMfa();
  }
}])