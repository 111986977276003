//@ts-check
//@ts-ignore
MediaUploadCtrl.$inject = ["$scope", "$rootScope", "$element", "$attrs", "PopupService", "Util", "ToasterService"];
angular.module('app')
  .component('mediaUpload', {
    templateUrl: '../templates/components/media-upload.component.html',
    controller: MediaUploadCtrl,
    bindings: {
      type: '@',
      mediaId: '<',
      permission: '<',
      onUpload: '&',
      onDelete: '&',
      onSelected: '&',
    },
  });

/**
 * 
 * @param { MediaUploadScope } $scope 
 * @param { HTMLElement } $element
 */
function MediaUploadCtrl($scope, $rootScope, $element, $attrs, PopupService, Util, ToasterService) {
  $scope.texts = $rootScope.texts;
  $scope.sizeLimit = '1mb';
  $scope.fileTypes = '.jpg, .png, .gif';

  $scope.videoUpload = { }
  $scope.$watch('videoUpload.videoClipId', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      $scope.$ctrl.onSelected({ $videoClip: $scope.videoUpload });
    }
  });

  $scope.$ctrl.$onInit = function() {
    $scope.internalMediaId = $scope.$ctrl.mediaId;
    $scope.initComponent();
  }

  $scope.uploadMedia = async function($file) {
    if ($scope.$ctrl.permission && !$rootScope.fns.hasPrivileges([$scope.$ctrl.permission.key])) {
      ToasterService.failure(null, $scope.$ctrl.permission.text);
      return;
    }
    if (!$file) {
      ToasterService.failure(null, 'file_not_selected_or_invalid');
      return;
    }
    if ($file.size > Util.parseBytes($scope.sizeLimit)) {
      ToasterService.failure(null, 'upload_size_limit_error');
      return;
    }
    return $scope.$ctrl.onUpload({ $file })
  }
  $scope.deleteMedia = async function() {
    if ($attrs['onDelete']) {
      return $scope.$ctrl.onDelete();
    } else {
      return $scope.$ctrl.onUpload({ $file: null })
    }
  }

  $scope.$ctrl.$onChanges = function(changeObj) {
    let hasChanges = false;
    if (changeObj.mediaId && changeObj.mediaId.currentValue !== changeObj.mediaId.previousValue) {
      $scope.internalMediaId = $scope.$ctrl.mediaId;
    }
    if (changeObj.type && changeObj.type.currentValue !== changeObj.type.previousValue) {
      hasChanges = true;
    }
    if (hasChanges) {
      $scope.initComponent()
    }
  }

  $scope.initComponent = function() {
    if ($scope.$ctrl.type !== "image-small"
        && $scope.$ctrl.type !== "image-large"
        && $scope.$ctrl.type !== "video") {
      $scope.$ctrl.type = "image-large"
    }
    switch ($scope.$ctrl.type) {
      case "image-small":
        $scope.pattern = "image/gif,image/jpeg,image/png";
        $scope.sizeLimit = '200kb';
        $scope.fileTypes = '.jpg, .png, .gif'
        break;
      case "image-large":
        $scope.pattern = "image/gif,image/jpeg,image/png";
        $scope.sizeLimit = '1mb';
        $scope.fileTypes = '.jpg, .png, .gif'
        break;
      case "video":
        $scope.pattern = "video/webm,video/mp4,.mp4,.webm,.mov,.avi";
        $scope.sizeLimit = '100mb';
        $scope.fileTypes = '.webm, .mp4, .mov, .avi'
        break;
    }
  }

  $scope.openVideoClips = function () {
    PopupService.openVideoClips($scope, $scope.videoUpload);
  };
}

/**
 * @typedef MediaUploadController
 * @property {'image-small'|'image-large'|'video'} type
 * @property { string } [mediaId]
 * @property { PermissionConfig } permission
 * @property { ({ $file: any }) => Promise<void> } onUpload
 * @property { () => Promise<void> } onDelete
 * @property { ({ $videoClip: VideoClip }) => Promise<void> } onSelected
 * @property { (changeObj: any) => void } $onChanges
 * @property { () => void } $onInit
*/

/**
 * @typedef MediaUploadScope
 * @property { MediaUploadController } $ctrl
 * @property { any } texts
 * @property { (prop: string, cb: (val: any) => void) => void } $watch
 * @property { boolean } libraryOpen
 * @property { string } [internalMediaId]
 * @property { string } pattern
 * @property { string } sizeLimit
 * @property { string } fileTypes
 * @property { VideoClip } videoUpload
 * @property { ($file: any) => Promise<void> } uploadMedia
 * @property { () => Promise<void> } deleteMedia
 * @property { () => void } openVideoClips
 * @property { () => void } initComponent
*/

/**
 * @typedef PermissionConfig
 * @property { string } key
 * @property { string } upgradePlan
 * @property { string } text
 */

/**
 * @typedef VideoClip
 * @property { string } [videoClipId]
*/