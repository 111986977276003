//@ts-check
angular.module('app').controller('SubmissionCandidateAssessfirstCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "Server", "Translate", "$q", "ToasterService", "PopupService", "drawProgressCircle", function ($scope, $rootScope, $state, $stateParams, $timeout, Server, Translate, $q, ToasterService, PopupService, drawProgressCircle) {
  
  $rootScope.validateUserAccess((user) => {
    const hasAssessfirstPermission = $rootScope.fns.userHasRights('candidates.assessFirst', 'view', user) && $rootScope.fns.hasPrivileges(["canAssessfirst", "individual_canAssessfirst"]);
    const hasDocimoPermission = $rootScope.fns.hasPrivileges(["useDocimo"]);
    if (!(hasAssessfirstPermission || hasDocimoPermission)) {
        $state.go('submission', { candidateId: $stateParams.candidateId });
    }
  });


  let candidateId = $stateParams.candidateId;

  $timeout(function () {
      if ($rootScope.fns.hasPrivileges(['useDocimo'])) {
          return;
      }
      if (!$rootScope.fns.hasPrivileges(['canAssessfirst'])) {
          $rootScope.upgradePlan('upgrd_assessfirst', { backOnClose: true });
      } else if ($rootScope.fns.hasPrivileges(['canAssessfirst']) && !$rootScope.fns.hasPrivileges(['individual_canAssessfirst'])) {
          $rootScope.upgradePlan('upgrd_assessfirst_individual', { backOnClose: true });
      }
  }, 1000);

  $scope.refreshDocimo = function () {
      Server.get(`docimo-integration/${candidateId}/getUserData`)
          .then(function (docimoUser) {
              $rootScope.candidate.docimoUser = docimoUser;
          }, function (err) {
          });
  };

  $scope.inviteDocimo = async function () {
      PopupService.opentInviteToAssessFirst($scope, [$rootScope.candidate], 'docimo', function (invitedCandidates) {
          if (invitedCandidates && invitedCandidates.length) {
              $scope.$parent.onCandidateData(invitedCandidates[0]);
          }
      });
  };

  $scope.reinviteDocimo = async function () {
      PopupService.opentInviteToAssessFirst($scope, [$rootScope.candidate], 'docimo', function (invitedCandidates) {
          if (invitedCandidates && invitedCandidates.length) {
              $scope.$parent.onCandidateData(invitedCandidates[0]);
          }
      });
  };

  $scope.inviteAssessFirst = function () {
      PopupService.opentInviteToAssessFirst($scope, [$rootScope.candidate], 'assessfirst', function (invitedCandidates) {
          if (invitedCandidates && invitedCandidates.length) {
              $scope.$parent.onCandidateData(invitedCandidates[0]);
          }
      });
  };

  $scope.reinviteAssessfirst = function () {
      PopupService.opentInviteToAssessFirst($scope, [$rootScope.candidate], 'assessfirst', function (reinvitedCandidates) {
          if (reinvitedCandidates && reinvitedCandidates.length) {
              $scope.$parent.onCandidateData(reinvitedCandidates[0]);
          }
      });
  };

  $scope.refreshAssessFirst = function () {
      Server.get(`assessfirst-integration/${candidateId}/getUserData`)
          .then(function (assessfirstUser) {
              $rootScope.candidate.assessfirstUser = assessfirstUser;
          }, function (err) {
          });
  };

  $scope.makeDownloadReportLink = function (reportId, isDisplayed, adequacyUuid) {
      let url = window.location.origin + `/assessfirst-integration/${candidateId}/downloadReport/${reportId}`;
      if (adequacyUuid) {
          url += `/${adequacyUuid}`;
      }
      if (isDisplayed) {
          url += '?isDisplayed=true';
      }
      return url;
  }
  // $scope.makeDownloadAdequacyReportLink = function(adequacyUuid, isDisplayed) {
  //     let url = window.location.origin + `/assessfirst-integration/${candidateId}/downloadAdequacyReport/${adequacyUuid}`;
  //     if (isDisplayed) {
  //         url += '?isDisplayed=true';
  //     }
  //     return url;
  // }

  $scope.reportsFilter = function (report) {
      // First we'v been said that the report with id=2 (Personality - Summary of findings) is not interresting for the recruiter, only for the candidate
      // Then apparently it is interresting (source: AF), so there you go
      // return report.is_available && (report.id != 2);
      return report.is_available;
  }

  $timeout(function () {
      $scope.refreshAssessFirst();
      $scope.refreshDocimo();
  }, 1000);
}]);
