
VonqCartController.$inject = ["$scope", "$rootScope", "Translate", "ToasterService", "overlaySpinner", "Server", "Util", "EventTracker"];angular.module('app')
  .component('vonqCart', {
    templateUrl: '../templates/components/vonq-cart.component.html',
    controller: VonqCartController,
    bindings: {
      campaignId: '<',
      hideVat: '<',
      currency: '@',
      onCartLoaded: '&',
      onItemRemoved: '&',
    },
    transclude: true,
  });

function VonqCartController($scope, $rootScope, Translate, ToasterService, overlaySpinner, Server, Util, EventTracker) {
  var $ctrl = this;
  $scope.texts = $rootScope.texts;
  $scope.cartProducts = [];

  Object.defineProperty($ctrl, 'getCartTotal', { get: function() {
    return $scope.cartProducts.reduce((prev, curr) => prev + $ctrl.getItemPrice(curr), 0);
  }})
  Object.defineProperty($ctrl, 'getCartVat', { get: function() {
    return ($ctrl.getCartTotal * 0.21).toFixed(2);
  }})
  Object.defineProperty($ctrl, 'getCartTotalWithVat', { get: function() {
    return ($ctrl.getCartTotal * 1.21).toFixed(2);
  }})
  Object.defineProperty($ctrl, 'isEmpty', { get: function() {
    return !$scope.cartProducts || $scope.cartProducts.length === 0;
  }})

  function getCart() {
    overlaySpinner.show('vonq-cart');
    return Server.get('campaigns/' + $ctrl.campaignId + '/vonq-integration/cart/products')
      .then((cartProducts) => {
          overlaySpinner.hide('vonq-cart');
          cartProducts.forEach(cartProduct => {
            cartProduct.vonq_price.forEach(price => price.currencySymbol = Util.getCurrencySymbol(price.currency));
          })
          $scope.cartProducts = cartProducts;
          if ($ctrl.onCartLoaded) {
            $ctrl.onCartLoaded({ $cartProducts: $scope.cartProducts });
          }
      })
      .catch((err) => {
          overlaySpinner.hide('vonq-cart');
          ToasterService.failure(err, 'vonq_cart_load_error');
      });
  }

  $ctrl.refresh = function() {
    getCart();
  }

  $ctrl.containsProduct = function({ product_id }) {
    return !!$scope.cartProducts.find(item => item.product_id === product_id);
  }

  $ctrl.getItemPrice = function(item) {
    let priceOnCurrency = item.vonq_price.find(p => p.currency === $ctrl.currency);
    if (!priceOnCurrency) {
      priceOnCurrency = item.vonq_price[0];
      $ctrl.currency = priceOnCurrency.currency
    }
    return priceOnCurrency.amount
  }

  $ctrl.addProduct = function(product) {
    overlaySpinner.show('vonq-cart');
    return Server.post(`campaigns/${$ctrl.campaignId}/vonq-integration/cart/products/`, { productId: product.product_id })
      .then((res) => {
        overlaySpinner.hide('vonq-cart');
        EventTracker.trackVonqAddCart();
        // Add the product on the cart if it was added, or should be already there
        if (res.isSucessful || (res.productAlreadyAdded && !$ctrl.containsProduct(res))) {
            product.inCart = true;
            $ctrl.refresh();
        }
        else {
            ToasterService.failure(res, 'vonq_cart_add_error');
        }
      })
      .catch((err) => {
        overlaySpinner.hide('vonq-cart');
        ToasterService.failure(err, 'vonq_cart_add_error');
      });
  }

  $ctrl.removeProduct = function(item) {
    overlaySpinner.show('vonq-cart');
    return Server.delete(`campaigns/${$ctrl.campaignId}/vonq-integration/cart/products/${item.product_id}`)
      .then(() => {
        overlaySpinner.hide('vonq-cart');
        $scope.cartProducts = $scope.cartProducts.filter(prod => prod.product_id !== item.product_id);
        if ($ctrl.onItemRemoved) {
          $ctrl.onItemRemoved({ $item: item });
        }
      })
      .catch((err) => {
        overlaySpinner.hide('vonq-cart');
        ToasterService.failure(err, 'vonq_cart_remove_error');
      })
  }

  $ctrl.$onInit = function() {
    if (!$ctrl.currency) {
      $ctrl.currency = 'EUR';
    }
    $scope.currencySymbol = Util.getCurrencySymbol($ctrl.currency);
    getCart();
  }
}