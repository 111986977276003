//@ts-check
angular.module('app').controller('CandidateProfileInfoCtrl', ["$rootScope", "$scope", "Server", "$window", "Upload", "Translate", "multiSelect", "googleMaps", "$timeout", "$state", "overlaySpinner", "ToasterService", "Cookies", function ($rootScope, $scope, Server, $window, Upload, Translate, multiSelect, googleMaps, $timeout, $state, overlaySpinner, ToasterService, Cookies) {
    
    Cookies.init();
    
    $rootScope.showBanner = true;
    $rootScope.areaCustomization = {};

    $scope.$parent.reloadCandidatesProfiles()
      .then(function () {
          setDataCandidates();
      });

  function setDataCandidates() {
        if ($rootScope.candidates.length > 0) {
            $rootScope.candidate = $rootScope.candidates[$rootScope.candidates.length - 1];
            for (const candidate of $rootScope.candidates) {
                candidate.customization = _.get(candidate, "campaign.customization.employerBranding.customization") || {};
            }
        }
        if ($rootScope.candidates.length === 1) {
            $rootScope.areaCustomization = $rootScope.candidate.customization;
        } else if ($rootScope.candidates.length > 1) {
            $rootScope.areaCustomization.mainColor = $rootScope.candidates.every(c => c.customization.mainColor === $rootScope.candidate.customization.mainColor) ? $rootScope.candidate.customization.mainColor : undefined;
            $rootScope.areaCustomization.fontColor = $rootScope.candidates.every(c => c.customization.fontColor === $rootScope.candidate.customization.fontColor) ? $rootScope.candidate.customization.fontColor : undefined;
            $rootScope.areaCustomization.logo = $rootScope.candidates.every(c => c.customization.logo === $rootScope.candidate.customization.logo) ? $rootScope.candidate.customization.logo : undefined;
            $rootScope.areaCustomization.bannerImage = $rootScope.candidates.every(c => c.customization.bannerImage === $rootScope.candidate.customization.bannerImage) ? $rootScope.candidate.customization.bannerImage : undefined;
            $rootScope.areaCustomization.bannerOverlayOpacity = $rootScope.candidates.every(c => c.customization.bannerOverlayOpacity === $rootScope.candidate.customization.bannerOverlayOpacity) ? $rootScope.candidate.customization.bannerOverlayOpacity : undefined;
            $rootScope.areaCustomization.navbarOpacity = $rootScope.candidates.every(c => c.customization.navbarOpacity === $rootScope.candidate.customization.navbarOpacity) ? $rootScope.candidate.customization.navbarOpacity : undefined;
            $rootScope.areaCustomization.navbarFontColor = $rootScope.candidates.every(c => c.customization.navbarFontColor === $rootScope.candidate.customization.navbarFontColor) ? $rootScope.candidate.customization.navbarFontColor : undefined;
        }
        
        // customization macros to make styling easier
        $rootScope.customization = {
            brandColor: { "color": $rootScope.areaCustomization.mainColor || false },
            brandBackColor: { "background-color": $rootScope.areaCustomization.mainColor || false },
            navbarFontColor: { "color": $rootScope.areaCustomization.navbarFontColor ? $rootScope.areaCustomization.navbarFontColor : undefined },
        }
  }

  $scope.editInfoClick = function () {
      $state.go('edit-info');
  }

  $scope.uploadDocument = function (file, documentIndex, candidateId) {
      if (!file) {
          return;
      }
      overlaySpinner.show('info');
      Upload
          .upload({
              url: Server.makeUrl(`candidates/candidate-area/me/uploadDocument/${documentIndex}`),
              data: { documentIndex: 0, candidateId: candidateId, originalFilename: file.name, file: file }
          })
          .then(function (resp) {
              ToasterService.success('user_well_updated');
              overlaySpinner.hide('info');
              setTimeout(() => {
                  window.location.reload();
              }, 2000)
              /**
               * The code below should make an update of the view's data. But it's not working for some reason.
               */
              // let cId = _.findIndex($rootScope.candidates, c => c.id === "5eecdf5c76f1b4c621d1db4d")
              // $rootScope.candidates[cId].documents = resp.data.documents;
          }, function (err) {
              overlaySpinner.hide('info');
              ToasterService.failure(err, 'err_0_error_occurred');
          });
  };

  $scope.stepTextTranslationKeys = {
      0: 'candidate_profile_status_not_started',
      1: 'candidate_profile_status_not_started',
      2: 'candidate_profile_status_questions',
      4: 'candidate_profile_status_documents',
      5: 'candidate_profile_status_completed',
  };
}]);
