//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardUserProfileSettingsCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "ToasterService", "overlaySpinner", "EventTracker", "multiSelect", "$q", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, ToasterService, overlaySpinner, EventTracker, multiSelect, $q) {
  let multiSelectTimezone = JSON.parse(JSON.stringify(multiSelect))
  $scope.multiSelectTimezoneSettings = {
    ...multiSelectTimezone.objectSettings,
    idProperty: "id",
    displayProp: "name",
    dynamicTitle: true,
    smartButtonMaxItems: 1,
    selectionLimit: 1,
    showCheckAll: false,
    showUncheckAll: false,
    checkBoxes: false,
  };
  $scope.multiSelectTimezoneTexts = multiSelectTimezone.texts;
  
  Server.get('users/calendar-sync/getAuthLink')
  .then(function (data) {
      $scope.calendarAuthLink = data.link;
  });
  
  $scope.removeCalendarAuth = function (profile_id) {
    const overlay = overlaySpinner.show('user-profile');
    Server.delete(`users/calendar-sync/authentication/${profile_id}`)
        .then(function (user) {
            overlay.hide();
            $rootScope.user = user;
            $scope.setupEditUser();
            ToasterService.success('user_well_updated');
        }, function (err) {
            overlay.hide();
            ToasterService.failure(err, 'user_not_updated');
        });
  }

  $scope.saveUserSettings = function() {
    $scope.editUser.language = $scope.langsArray.indexOf($scope.editUser.languageString);
    if ($scope.editUser.language < 0) {
      $scope.editUser.language = 0;
    }

    if ($scope.selectedTimezone[0]) {
      $scope.editUser.timezone = $scope.selectedTimezone[0].id;
    }

    $scope.saveUser(_.pick($scope.editUser, 'language', 'timezone', 'settings'));
  }

  $scope.setupEditUser();
  if (!$rootScope.timezones) {
    Util.listTimezones().then(timezones => {
      $rootScope.timezones = timezones;
    }).then(() => {
      $scope.$digest();
    });
  }
}])