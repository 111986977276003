//@ts-check
//@ts-ignore
QuestionsAnswersCtrl.$inject = ["$scope", "$rootScope", "$attrs", "$state", "Server", "Translate", "ToasterService", "Util", "multiSelect", "matchingSliderOpts", "$sce"];
angular.module('app')
  .component('questionsAnswers', {
    templateUrl: '../templates/components/questions-answers.component.html',
    controller: QuestionsAnswersCtrl,
    bindings: {
      questions: '<',
      featureType: '@',
      onRate: '&'
    },
  });

/**
 *
 * @param { QuestionsAnswersScope } $scope
 */
function QuestionsAnswersCtrl($scope, $rootScope, $attrs, $state, Server, Translate, ToasterService, Util, multiSelect, matchingSliderOpts, $sce) {
  $scope.matchingFcts = Util.matchingFcts;
  $scope.questions = [];

  $scope.selectQuestion = function(question) {
    $scope.currentQuestion = question;
  }

  $scope.isAnsweredQuestion = function (index) {
    var question = $scope.currentQuestion;
    if (question.mode == 1 && String(question.answer) == String(index)) {
        return true;
    }
    if (question.mode == 2) {
        var ar = question.answer.split(',');
        if (ar.indexOf(String(index)) >= 0) {
            return true;
        }
    }
    return false;
  };

  $scope.rate = function(question, rate) {
    if ($attrs.onRate) {
      $scope.$ctrl.onRate({ $question: question, $rate: rate });
    } else {
      question.rating = rate;
    }
  }

  $scope.trustAsHtml = function(html) {
    return $sce.trustAsHtml(html);
  }

  $scope.$ctrl.$onChanges = function(changeObj) {
    if (changeObj.questions && changeObj.questions.currentValue !== changeObj.questions.previousValue) {
      $scope.questions = changeObj.questions.currentValue || [];
      $scope.currentQuestion = $scope.questions[0];
      $scope.isAnswered = $scope.questions.filter(q => q.answer).length === $scope.questions.length;
    }
  }

  $scope.matchingSliders = _.cloneDeep(matchingSliderOpts);
	$scope.matchingSliders.question.options.disabled = true;
	$scope.matchingSliders.answer.options.disabled = true;
}

/** 
 * @typedef QuestionsAnswersController
 * @property { Question[] } questions
 * @property { ({ $question, $rate }: {$question: Question, $rate: number}) => void } onRate
 * @property { (changeObj: any) => void } $onChanges
 */

/**
 * @typedef QuestionsAnswersScope
 * @property { QuestionsAnswersController } $ctrl
 * @property { Question[] } questions
 * @property { Question } currentQuestion
 * @property { boolean } isAnswered
 * @property { (question: Question) => void } selectQuestion
 * @property { (index: number) => boolean } isAnsweredQuestion
 * @property { (question: Question, rate: number) => void } rate
 * @property { any } matchingFcts
 * @property { any } matchingSliders
 */

/**
 * @typedef Question
 * @property { string } text
 * @property { string } answer
 * @property { 1|2|3|4|5 } mode
 * @property { number } rating
 */