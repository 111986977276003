//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardBrandingCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "ToasterService", "overlaySpinner", "PopupService", "EventTracker", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, ToasterService, overlaySpinner, PopupService, EventTracker) {
  Util.setPageTitle(Translate.getLangString('employer_branding'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  $scope.brandings = []
  $scope.brandingsLoaded = false;
  $scope.brandingId = $stateParams.brandingId;
  $scope.brandingLimitWarning = '';
  $scope.limitExceeded = false;

  $scope.checkBrandLimit = function() {
    if ($rootScope.user && $scope.brandingsLoaded) {
      $scope.limitExceeded = $rootScope.user.privileges.maxEmployerBrandingNumber <= $scope.brandings.length;
    }
  }

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights('employerBranding.list', 'view', user)) {
      $state.go('campaigns');
    }

    $scope.brandingLimitWarning = Translate.getLangString('branding_limit_reached', null, [
      user.privileges.maxEmployerBrandingNumber,
    ]);
    $scope.checkBrandLimit();
  });

  $scope.trackAddBranding = function() {
    EventTracker.trackEmployerBrandingAdd();
  }

  $scope.getBrandings = function(redirect = true) {
    const overlay = overlaySpinner.show('dashboard-branding');
    return Server.get('employer-brandings')
      .then(res => {
        overlay.hide();
        $scope.brandings = res;
        $scope.brandingsLoaded = true;
        $scope.checkBrandLimit();
        if ($scope.brandings.length === 0 && redirect) {
          $state.go('dashboard-branding.new');
        }

        $scope.brandings.forEach(brand => {
          if (!brand.customization) {
            brand.customization = {}
          }
          if (!brand.companyInformation) {
            brand.companyInformation = {}
          }
          if (!brand.careerPage) {
            brand.careerPage = {}
          }
          if (!brand.careerPage.filters) {
            brand.careerPage.filters = {}
          }
        });
        
        if (redirect) {
          const mainBranding = $scope.brandings.find(branding => branding.isMainEB)
          if (mainBranding && $state.current.name === 'dashboard-branding') {
            $state.go('dashboard-branding.info', { brandingId: mainBranding.id }, { location: 'replace' })
          } else {
            $scope.$broadcast('onBrandingsData', $scope.brandings);
          }
        }
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'load_brandings_error');
      })
  }

  $scope.getBrandings();
}]);
