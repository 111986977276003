
PaginatorController.$inject = ["$scope", "$element", "$attrs", "$rootScope"];angular.module('app')
  .component('paginator', {
    templateUrl: '../templates/components/paginator.component.html',
    controller: PaginatorController,
    bindings: {
      limit: '<',
      offset: '<',
      total: '<',
      onPageChanged: '&',
    },
  });

function PaginatorController($scope, $element, $attrs, $rootScope) {
  $ctrl = this;
  $scope.element = $element;
  $scope.attrs = $attrs;

  $scope.texts = $rootScope.texts;

  $scope.prevDisabled = true;
  $scope.nextDisabled = true;
  $scope.currentPage = 0;
  $scope.totalPages = 0;
  $scope.pagesArray = [];

  $scope.setPagesArray = function() {
    if ($scope.totalPages === 0) {
      return [0];
    }
    const pagesArray = [];
    let page = 1;
    const prevFill = Math.max($scope.currentPage - ($scope.totalPages - 4), 0);
    const nextFill = Math.max((1 + 4) - $scope.currentPage, 0);
    while (page <= $scope.totalPages) {
      if (page === 1 || page === $scope.totalPages ||
        page >= $scope.currentPage - (2 + prevFill) && page <= $scope.currentPage + 2 + nextFill
      ) {
        pagesArray.push(page);
        page++;
      }
      else if (page < $scope.currentPage) {
        pagesArray.push('.. .');
        page = $scope.currentPage - (2 + prevFill);
      }
      else if (page > $scope.currentPage) {
        pagesArray.push('. ..');
        page = $scope.totalPages;
      }
      else {
        page++;
      }
    }

    $scope.pagesArray = pagesArray;
  }

  $scope.onArgsChanged = function(offset, limit, total) {
    $scope.prevDisabled = offset <= 0;
    $scope.nextDisabled = offset >= total - limit;
    $scope.currentPage = !total ? 0 : 1 + offset / limit;
    if (!total) {
      $scope.totalPages = 0;
    } else {
      $scope.totalPages = Math.trunc(total / limit);
      if (total % limit > 0) {
        $scope.totalPages += 1;
      }
    }
    $scope.setPagesArray();
  }

  $scope.onPageChanged = function(newOffset) {
    if ($scope.attrs.onPageChanged) {
      this.$ctrl.onPageChanged({ $offset: newOffset });
    } else {
      this.$ctrl.offset = newOffset;
      $scope.onArgsChanged(newOffset, this.$ctrl.limit, this.$ctrl.total);
    }
  }

  $ctrl.setPage = function(page) {
    const newOffset = (page - 1) * this.limit;
    $scope.onPageChanged(newOffset);
  }

  $ctrl.previousClicked = function() {
    const newOffset = this.offset < this.limit ? 0 : this.offset - this.limit;
    $scope.onPageChanged(newOffset);
  };

  $ctrl.nextClicked = function() {
    const newOffset = this.offset + this.limit;
    $scope.onPageChanged(newOffset);
  }

  $ctrl.$onChanges = function(changes) {
    if (changes.offset && changes.offset.currentValue !== changes.offset.previousValue ||
      changes.limit && changes.limit.currentValue !== changes.limit.previousValue ||
      changes.total && changes.total.currentValue !== changes.total.previousValue
    ) {
      const offset = !changes.offset || changes.offset.currentValue === undefined ? this.offset : changes.offset.currentValue;
      const limit = !changes.limit || changes.limit.currentValue === undefined ? this.limit : changes.limit.currentValue;
      const total = !changes.total || changes.total.currentValue === undefined ? this.total : changes.total.currentValue;
      $scope.onArgsChanged(offset, limit, total);
    }
  }
}