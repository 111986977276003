//@ts-check
//@ts-ignore
DashboardAssessmentEditCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "Server", "Translate", "Util", "ToasterService", "overlaySpinner", "PopupService", "multiSelect", "Upload"];
angular.module('app').controller('DashboardAssessmentEditCtrl', DashboardAssessmentEditCtrl);

/**
 * @param {DashboardAssessmentEditScope} $scope 
 */
function DashboardAssessmentEditCtrl($scope, $rootScope, $state, $stateParams, $timeout, Server, Translate, Util, ToasterService, overlaySpinner, PopupService, multiSelect, Upload) {
  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user)) {
      $state.go('campaigns');
    }
    if (!$rootScope.fns.userHasRights('assessments.list', 'view', user)) {
      $state.go('campaigns');
    }
  });

  $rootScope.secondaryNav = 'editassessment';
  $rootScope.backEnabled = true;

  $scope.brandings = [];
  $scope.brandingsLoaded = false;

  $scope.ddLangs = Translate.getLangDropdownObject();
  $scope.langsArray = Translate.getLangsArray();
  let languageString;

  $scope.collaboratorsOptions = [];

  $scope.assessmentTitle = ''
  $scope.assessmentDescription = ''

  $scope.currentStep = $stateParams.currentStep;
  $rootScope.processNextText = $stateParams.currentStep < 2 ? Translate.getLangString('next') : Translate.getLangString('finish');

  $scope.changeLanguage = function (languageString) {
    let langIdx = $scope.langsArray.indexOf(languageString);
    if (langIdx < 0) {
      langIdx = 0;
    }
    
    $rootScope.assessment.languageString = $scope.langsArray[langIdx];
    $rootScope.assessment.language = langIdx;
    $scope.editLang = langIdx;
  };
  
  var isValidQuestion = function (el) {
    return !(!el.text || !el.text.trim().length);
  };

  $scope.clearEmptyQuestions = function () {
    $rootScope.assessment.questions = $rootScope.assessment.questions || [];
    $rootScope.assessment.questions = $rootScope.assessment.questions.filter(isValidQuestion);
    $rootScope.assessment.questions.forEach(function (question) {
        if (question.isWritten && (question.mode == 1 || question.mode == 2)) {
            if (!question.predefinedAnswers || question.predefinedAnswers.length == 0) {
                question.mode = 0;
            } else {
                question.predefinedAnswers = question.predefinedAnswers.filter(function (answer) {
                    return (answer && answer.text && answer.text.length > 0 && answer.text.trim().length > 0);
                });
                if (question.predefinedAnswers.length === 0) {
                    question.mode = 0;
                }
            }
        }
    });
  };

  $scope.setPageTitle = function() {
    const pageName = `${Translate.getLangString('assessment')}`
    Util.setPageTitle(pageName, $scope.assessmentTitle);
  }

  $scope.loadAssessment = function() {
    if (!$stateParams.assessmentId || $stateParams.assessmentId === "new") {
      $rootScope.assessment = {
        _id: "new",
        title: {},
        description: {},
        language: $rootScope.user.language,
        timerMinutes: 60,
        customization: {}
      };
      $scope.assessmentDescription = Translate.getLangString('assessment_start_paragraph1') + '<br>' + 
        Translate.getLangString('assessment_start_paragraph2') + '<br>' + 
        Translate.getLangString('assessment_start_paragraph4');

      languageString = $scope.langsArray[$rootScope.assessment.language];
      $scope.changeLanguage(languageString);
      $scope.ensureDefaultBranding();
      $scope.ensureCampaignBrandingAccessible();
      return Promise.resolve($rootScope.assessment);
    } else {
      const overlay = overlaySpinner.show('assessment');
      return Server.get('assessments/' + $stateParams.assessmentId)
        .then(response => {
          overlay.hide();
          $rootScope.assessment = response;
          if ($scope.collaboratorsOptions) {
            $rootScope.assessment.assignedCollaborators = $scope.collaboratorsOptions ? $scope.collaboratorsOptions.filter(x => x.allowedAssessmentIds?.includes($stateParams.assessmentId)) : [];
          } else {
            $rootScope.assessment.assignedCollaborators = [];
          }
          languageString = $scope.langsArray[$rootScope.assessment.language];
          $scope.assessmentTitle = $rootScope.assessment.title[$rootScope.assessment.language || 0];
          $scope.assessmentDescription = $rootScope.assessment.description[$rootScope.assessment.language || 0];          
          $scope.changeLanguage(languageString);
          $scope.setPageTitle();
          $scope.ensureDefaultBranding();
          $scope.ensureCampaignBrandingAccessible();
        })
        .catch(err => {
          overlay.hide();
          ToasterService.failure(err, 'load_assessments_error');
        });
    }
  }

  $scope.ensureCampaignBrandingAccessible = function() {
    if ($rootScope.assessment && $scope.brandingsLoaded && $rootScope.assessment.customization && $rootScope.assessment.customization.employerBrandingId) {
      if (!$scope.brandings.find(eb => eb.value === $rootScope.assessment.customization.employerBrandingId)) {
        $scope.brandings.push({
          value: $rootScope.assessment.customization.employerBrandingId,
          text: `[ ${Translate.getLangString("restricted_branding")} ]`
        });
      }
    }
  }

  $scope.ensureDefaultBranding = function() {
    if ($scope.brandings.length > 0 && $rootScope.assessment) {
      if (!$rootScope.assessment.customization) {
          $rootScope.assessment.customization = {}
      }
      if (!$rootScope.assessment.customization.employerBrandingId) {
          const mainBranding = $scope.brandings.find(b => b.isMainEB)
          $rootScope.assessment.customization.employerBrandingId = (mainBranding ?? $scope.brandings[0]).value;
      }
    }
  }

  $scope.loadEmployerBrandings = async function() {
    return Server.get('employer-brandings')
      .then(res => {
          $scope.brandings.push(...res.map(branding => ({ value: branding.id, text: branding.name, isMainEB: branding.isMainEB })));
          $scope.brandingsLoaded = true;
          $scope.ensureDefaultBranding();
          $scope.ensureCampaignBrandingAccessible();
      })
      .catch(err => {
          ToasterService.failure(err, 'load_brandings_error');
      })
  }

  $scope.loadCollaborators = function() {
    const overlay = overlaySpinner.show('assessment-candidates');
    return Server.get(`collaborators`)
      .then(res => {
        overlay.hide();
        $scope.collaboratorsOptions = res;
        if ($rootScope.assessment && $scope.collaboratorsOptions) {
          $rootScope.assessment.assignedCollaborators = $scope.collaboratorsOptions.filter(x => x.allowedAssessmentIds?.includes($stateParams.assessmentId));
        }
      })
      .catch(err => {
        overlay.hide();
      })
  }
  
  $scope.assignCollaborator = (collaboratorId) => {
    const collabTranslation = Translate.getLangString("collaborator");
    if ($rootScope.assessment?._id === "new") {
      $rootScope.assessment.assignedCollaborators = [
        ...$rootScope.assessment.assignedCollaborators,
        $scope.collaboratorsOptions.find(x => x._id === collaboratorId),
      ].filter(x => x);
      return Promise.resolve();
    }
    else {
      return Server.post('collaborators/' + collaboratorId + '/assign-assessment/' + $stateParams.assessmentId)
        .then((res) => {
          if ($rootScope.assessment && $scope.collaboratorsOptions) {
            $rootScope.assessment.assignedCollaborators = [
              ...$rootScope.assessment.assignedCollaborators,
              $scope.collaboratorsOptions.find(x => x._id === collaboratorId),
            ].filter(x => x);
          }
          ToasterService.success('assign_items_success', [collabTranslation]);
        })
        .catch((err) => {
          ToasterService.failure(err, 'assign_items_fail', [collabTranslation]);
        });
    }
  },
  $scope.unassignCollaborator = (collaborator) => {
    const collabTranslation = Translate.getLangString("collaborator");
    if ($rootScope.assessment?._id === "new") {
      $rootScope.assessment.assignedCollaborators = $rootScope.assessment.assignedCollaborators.filter(x => x._id !== collaborator._id);
      return Promise.resolve();
    }
    else {
      return Server.post('collaborators/' + collaborator._id + '/unassign-assessment/' + $stateParams.assessmentId)
        .then((res) => {
          if ($rootScope.assessment && $scope.collaboratorsOptions) {
            $rootScope.assessment.assignedCollaborators = $rootScope.assessment.assignedCollaborators.filter(x => x._id !== collaborator._id);
            ToasterService.success('unassign_items_success', [collabTranslation]);
          }
        })
        .catch((err) => {
          ToasterService.failure(err, 'unassign_items_fail', [collabTranslation]);
        });
    }
  }
  
  $scope.onChangeAssessmentQuestions = function(newQuestions) {
    $rootScope.assessment.questions = newQuestions;
  }

  $rootScope.saveAssessment = async function(advanceStep) {
    const overlay = overlaySpinner.show('assessment');
    const assessmentSaved = function(assessment, redirect, updated) {
      overlay.hide();
      if (updated) {
        ToasterService.success('assessment_saved');
        $rootScope.assessment = assessment;
        languageString = $scope.langsArray[$rootScope.assessment.language];
        $scope.changeLanguage(languageString);
      }

      if (advanceStep) {
        switch ($scope.currentStep) {
          case 1:
            $state.go('assessment-edit-questions', { assessmentId: $rootScope.assessment?._id });
            break;
          case 2:
            $state.go('assessment-candidates', {assessmentId: $rootScope.assessment?._id});
            break;
        }
      } else if (redirect) {
        $state.go('assessment-edit', { assessmentId: $rootScope.assessment?._id });
      }
    }

    // if the user has permission to edit
    if ($rootScope.fns.userHasRights('assessments.list', 'edit')) {
      // Assessment's title can't be empty
      if (!$scope.assessmentTitle || $scope.assessmentTitle.trim() === '') {
        overlay.hide();
        ToasterService.failure(null,'assessment_title_empty');
        return;
      }
      $rootScope.assessment.title[$rootScope.assessment.language] = $scope.assessmentTitle;
      $rootScope.assessment.description[$rootScope.assessment.language] = $scope.assessmentDescription;

      if ($rootScope.assessment?._id === "new") {
        Server.post('assessments', $rootScope.assessment)
          .then((assessment) => assessmentSaved(assessment, true, true))
          .catch((err) => {
            overlay.hide();
            ToasterService.failure(err, 'assessment_not_saved');
          });
      } else {
        Server.put('assessments/' + $rootScope.assessment?._id, $rootScope.assessment)
          .then((assessment) => assessmentSaved(assessment, false, true))
          .catch((err) => {
            overlay.hide();
            ToasterService.failure(err, 'assessment_not_saved');
          });
      }
    } else {
      assessmentSaved($rootScope.assessment, false, false);
    }
  }

  $scope.uploadAssessmentPdfDocument = function (file) {
    let url = 'assessments/' + $rootScope.assessment._id + '/uploadPdf';
    if (!file) {
      console.log('error no file!', file);
      return;
    }

    // Check if file is a .pdf
    const allowedExtensions = /(\.pdf)$/i;
    if (!allowedExtensions.exec(file.name)) {
      ToasterService.failure(null, 'only_pdf_files_allowed');
      return;
    }

    // Check if file is more than 100MB
    const maxSizeInBytes = 104857600;
    if (file.size > maxSizeInBytes) {
        ToasterService.failure(null, 'file_size_must_not_exceed', ['100']);
        return;
    }

    overlaySpinner.show('assessment');

    Upload.upload({
        url: Server.makeUrl(url),
        data: {
            file: file,
        }
    }).then(function (resp) {
        overlaySpinner.hide('assessment');
        console.log('Success ' + resp.config.data.file.name + ' uploaded. Response: ' + resp.data);
        $rootScope.assessment.linkedAssessmentPdf.push(resp.data.document);
    }, function (resp) {
        overlaySpinner.hide('assessment');
        console.log('Error status: ' + resp.status);
        ToasterService.failure(resp, 'err_113_save_assessment_before_uploading_pdf');
    }, function (evt) {
        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
        console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
    });
};

  $scope.downloadAssessmentPdfDocument = function (pdf) {
    let url = window.location.origin + '/s3/beehire-development-assessment-pdf?filePath=' + pdf.path;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = pdf.originalFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  $scope.deleteAssessmentPdfDocument = function (pdfId) {
    let url = 'assessments/' + $rootScope.assessment._id + '/removePdf';
    overlaySpinner.show('assessment');
    Server.post(url, { documentId: pdfId })
        .then(function (resp) {
            overlaySpinner.hide('assessment');
            $rootScope.assessment.linkedAssessmentPdf = $rootScope.assessment.linkedAssessmentPdf.filter(function (item) {
                return item._id !== pdfId;
            });
        }, function (resp) {
            overlaySpinner.hide('assessment');
            ToasterService.failure(resp, 'err_0_error_occurred');
        });
};

  $scope.loadAssessment();
  $scope.loadEmployerBrandings();
  $scope.loadCollaborators();
  $scope.setPageTitle();
};

/**
 * @typedef DashboardAssessmentEditScope
 * @property { Assessment } [assessment]
 * @property { any[] } brandings
 * @property { boolean } brandingsLoaded
 * @property { () => Promise<Assessment|undefined> } loadAssessment
 * @property { () => Promise<void> } loadEmployerBrandings
 * @property { () => void } ensureDefaultBranding
 * @property { () => void } ensureCampaignBrandingAccessible
 * @property { number } currentStep
 * @property { string } processNextText
 * @property { string } assessmentTitle
 * @property { string } assessmentDescription
 * @property { (advanceStep: boolean) => Promise<void> } saveAssessment
 * @property { any } changeLanguage
 * @property { () => void } setPageTitle
 * @property { any } ddLangs
 * @property { any } editLang
 * @property { any } langsArray
 * @property { () => Promise<void> } loadCollaborators
 * @property { any[] } collaboratorsOptions
 * @property { (collaboratorId: string ) => Promise<void> } assignCollaborator
 * @property { (collaborator: any ) => Promise<void> } unassignCollaborator
 * @property { any } clearEmptyQuestions
 * @property { any } onChangeAssessmentQuestions
 * @property { () => Promise<void> } $apply
 */
