angular.module('app').controller('SubmissionInterviewCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "matchingSliderOpts", "Util", "$location", "$timeout", function ($scope, $rootScope, $stateParams, Server, matchingSliderOpts, Util, $location, $timeout) {
	$scope.activeQuestion = false;
	$scope.videoSource = null;
	$scope.showVideo = false;
	$scope.matchingFcts = Util.matchingFcts;

	var refreshRatings = function (candidate) {
			$rootScope.candidate.rating = candidate.rating;
			$rootScope.candidate.reviews = candidate.reviews;
	};

	$scope.rate = function (question, nstars) {
			if (!$rootScope.fns.userHasRights('candidates.questions', 'edit')) {
				return;
			}
			var reviews;
			if ($rootScope.candidate.reviews && $rootScope.candidate.reviews[$rootScope.user._id]) {
					reviews = $rootScope.candidate.reviews && $rootScope.candidate.reviews[$rootScope.user._id];
			} else {
					reviews = {};
			}

			reviews[question.id] = nstars;

			question.rating = nstars;

			Server.post('candidates/' + $stateParams.candidateId, {
					reviews: reviews
			}).then(refreshRatings);
	};

	$scope.open = function (question, dontPlay) {
			//console.log('open', question.id);
			$scope.activeQuestion = question.id;
			$scope.currentQuestion = question;

			if (!question.isWritten && question.filename) {
					// there is a video
					$scope.videoSource = question.filename;
					$scope.showVideo = true;

					if (dontPlay) {
							$('.submission-video__element video').removeAttr('autoplay');
					} else {
							$('.submission-video__element video').attr('autoplay', 'autoplay');

							setTimeout(function () {
									$('.submission-video__element video')[0].play();
							}, 1000);
					}
			} else {
					$scope.videoSource = null;
					$scope.showVideo = false;
			}
	};

	$scope.isAnsweredQuestion = function (index) {
			var question = $scope.currentQuestion;
			if (question.mode == 1 && String(question.answer) == String(index)) {
					return true;
			}
			if (question.mode == 2) {
					var ar = question.answer.split(',');
					if (ar.indexOf(String(index)) >= 0) {
							return true;
					}
			}
			return false;
	};

    $scope.scrollToQuestion = function (questionId) {
        var element = document.getElementById('question-' + questionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

	$scope.$on('onCandidateData', function (event, candidate) {

        if (candidate && candidate.questions && candidate.questions.length) {
            if (!$location.search().firstVideoQuestion) {
				// General case, open first written question
				$scope.open(candidate.questions[0], true);
            } else {
				// Open first video question when query param is there
				for (let i = 0; i < $scope.candidate.questions.length; i++) {
					if (!$scope.candidate.questions[i].isWritten && $scope.candidate.questions[i].filename) {
						$scope.open($scope.candidate.questions[i], true);
						$scope.scrollToQuestion($scope.candidate.questions[i].id);
						break;
					}
				}
            }
        }

        matchingSliderOpts.setAllSlidersPosition($scope);
    });

	$scope.matchingSliders = _.cloneDeep(matchingSliderOpts);
	$scope.matchingSliders.question.options.disabled = true;
	$scope.matchingSliders.answer.options.disabled = true;
}]);
