angular.module('app')
    .component('questionTemplates', {
        templateUrl: '../templates/components/question-templates.component.html',
        bindings: {
            userRightName: '@',
        },
        controller: ["$scope", "$rootScope", "Server", "overlaySpinner", "Translate", function QuestionTemplatesCtrl ($scope, $rootScope, Server, overlaySpinner, Translate) {
            let $ctrl = this;

            var saveMyQuestions = function () {
                Server.post('users/me/templates/questions', $scope.my);
            };
        
            var addToMyQuestions = function (text) {
                if (!text || text.trim().length == 0) {
                    return;
                }
        
                if (!$scope.my[$rootScope.questionsLanguage]) {
                    $scope.my[$rootScope.questionsLanguage] = [];
                }
                $scope.my[$rootScope.questionsLanguage].unshift({ text: text });
        
                saveMyQuestions();
            };
        
            $scope.ddTemplateOptions = [
                {
                    text: Translate.getLangString('duplicate_template_question_option'),
                    value: 'duplicate'
                }
            ];
        
            $scope.ddTemplateSelected = {};
        
            $scope.ddMyTemplateOptions = [
                {
                    text: Translate.getLangString('edit'),
                    value: 'edit'
                },
                {
                    text: Translate.getLangString('delete'),
                    value: 'delete'
                }
            ];
        
            $scope.ddMyTemplateSelected = {};
        
            $scope.activeTemplateQuestion = false;
        
            $scope.addQuestionOpen = false;
            $scope.addQuestionText = '';
        
        
            $scope.ddTemplateMenuClick = function (selected, index, text) {
                switch (selected.value) {
                    case 'duplicate':
                        addToMyQuestions(text);
                        break;
                }
            };
        
            $scope.ddMyTemplateMenuClick = function (selected, index, text) {
                switch (selected.value) {
                    case 'edit':
                        $scope.addQuestionOpen = false;
                        $scope.activeTemplateQuestion = $scope.my[$rootScope.questionsLanguage][index];
                        break;
                    case 'delete':
                        $scope.delete(index);
                        break;
                }
            };
        
            $scope.done = function (index, item) {
        
                if (!item.text || item.text.trim().length == 0) {
                    $scope.delete(index);
                    return;
                }
        
                saveMyQuestions();
                $scope.activeTemplateQuestion = false;
            };
        
            $scope.delete = function (index) {
                $scope.my[$rootScope.questionsLanguage].splice(index, 1);
                $scope.activeTemplateQuestion = false;
                saveMyQuestions();
            };
        
            $scope.addQuestion = function () {
                if ($scope.addQuestionOpen) {
                    return;
                }
                $scope.activeTemplateQuestion = false;
                $scope.addQuestionText = '';
                $scope.addQuestionOpen = true;
            };
        
            $scope.addQuestionDone = function () {
                if ($scope.addQuestionText && $scope.addQuestionText.trim().length > 0) {
                    addToMyQuestions($scope.addQuestionText);
                }
                $scope.addQuestionText = '';
                $scope.addQuestionOpen = false;
            };
        
            $scope.addQuestionDelete = function () {
                $scope.addQuestionText = '';
                $scope.addQuestionOpen = false;
            };
        
            $scope.categoryClick = function (category) {
                $scope.activeCategory = category;
            };
        
            $scope.questionClick = function (text, item) {
                // if user has no permission
                if ($ctrl.userRightName && !$rootScope.fns.userHasRights($ctrl.userRightName, 'edit')) {
                    return;
                }
                if (item == $scope.activeTemplateQuestion) {
                    return;
                }
        
                $rootScope.$broadcast('questiontext', text);
            };
        
            $scope.changeQuestionsLanguage = function () {
        
                $rootScope.questionsLanguage = langsArray.indexOf($scope.questionsLanguageSelect);
        
                if ($rootScope.questionsLanguage < 0) {
                    $rootScope.questionsLanguage = 0;
                }
            };
        
        
            var langsArray = Translate.getLangsArray();
        
            $scope.langsArray = langsArray;
        
            if (!$rootScope.questionsLanguage || $rootScope.questionsLanguage < 0) {
                // if ($rootScope.campaign && $rootScope.campaign.language) {
                //     $rootScope.questionsLanguage = $rootScope.campaign.language;
                // } else {
                // }
                $rootScope.questionsLanguage = 0;
            }
        
            $scope.questionsLanguageSelect = langsArray[$rootScope.questionsLanguage] || 'en';
        
            if (!$rootScope.questionTemplates) {
                overlaySpinner.show('questiontemplates');
                Server.get('users/me/templates/questions')
                .then(function (data) {
                    $rootScope.questionTemplates = data;
                    $scope.categories = data.categories;
                    $scope.my = data.my;
        
                    $scope.categoryClick($scope.categories[0]);
        
                    overlaySpinner.hide('questiontemplates');
                });
            } else {
                $scope.categories = $rootScope.questionTemplates.categories;
                $scope.my = $rootScope.questionTemplates.my;
        
                $scope.categoryClick($scope.categories[0]);
            }
        
            overlaySpinner.hide('questiontemplates');
        }]
    });
