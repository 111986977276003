//@ts-check
angular.module('app').controller('CampaignCheckoutStepCtrl', ["$state", "$scope", "$rootScope", "$location", "$stateParams", "ToasterService", "Server", "Util", "Translate", "PopupService", "overlaySpinner", "multiSelect", "$timeout", "$window", function ($state, $scope, $rootScope, $location, $stateParams, ToasterService, Server, Util, Translate, PopupService, overlaySpinner, multiSelect, $timeout, $window) {
  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.singleSelectSettings = {
      ...multiSelectln.objectSettings,
      idProperty:"id",
      dynamicTitle: true,
      displayProp: "name",
      smartButtonMaxItems: 5,
      selectionLimit: 1,
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: false,
  };
  $scope.multiselectTexts = multiSelectln.texts;
  $scope.multiselectEventsEducationLevel = {
      onItemDeselect: (item) => $scope.validateField('educationLevel'),
      onItemSelect: (item) => $scope.validateField('educationLevel'),
  };
  $scope.multiselectEventsSeniority = {
      onItemDeselect: (item) => $scope.validateField('seniority'),
      onItemSelect: (item) => $scope.validateField('seniority'),
  };
  $scope.multiselectEventsIndustry = {
      onItemDeselect: (item) => $scope.validateField('industry'),
      onItemSelect: (item) => $scope.validateField('industry'),
  };
  $scope.multiselectEventsJobCategory = {
      onItemDeselect: (item) => $scope.validateField('jobCategory'),
      onItemSelect: (item) => $scope.validateField('jobCategory'),
  };
  $scope.singleSelectJobSettings = {
    ...$scope.singleSelectSettings,
    displayProp: 'label',
  }

  $scope.campaign = $stateParams.campaign ?? {};
  $scope.vonqFields = $scope.$parent.vonqFields;
  $scope.errorFields = {};

  $scope.editAccountUrl = 'dashboard#!/account';
  $scope.getEditEmployerBrandingCompanyUrl = (mainEBId) => `dashboard#!/dashboard-branding/${mainEBId}/information`;
  $scope.getEditEmployerBrandingUrl = (mainEBId) => `dashboard#!/dashboard-branding/${mainEBId}/customization`;
  $scope.editCampaignUrl = 'dashboard#!/editcampaign/' + $stateParams.campaignId;
  const checkoutStep1State = 'campaign-vonq-checkout-1';

  $scope.validateField = function(fieldName) {
      const errors = this.$parent.checkVonqFields($scope.vonqFields);
      if (errors[fieldName]) {
          $scope.$parent.errorFields[fieldName] = errors[fieldName];
      } else {
          $scope.$parent.errorFields[fieldName] = false;
      }
  }
  
  $scope.openEditAccount = function() {
      const childWindow = window.open($scope.editAccountUrl, '__blank');
      if (childWindow) {
          childWindow.onmessage = function(msg) {
              if (!msg.data) return;
              if (msg.data.name === 'reloadOpener') {
                  if (!msg.data.user) {
                      location.reload();
                  } else {
                      $rootScope.user = msg.data.user;
                      $scope.$parent.errorFields = $scope.$parent.checkCampaignFields();
                      $scope.$digest();
                  }
              }
          };
      }
  }

  $scope.openEditMainBrandingCompanyInformation = function() {
      const childWindow = window.open($scope.getEditEmployerBrandingCompanyUrl($scope.employerBranding._id), '__blank');
      if (childWindow) {
          childWindow.onmessage = function(msg) {
              if (!msg.data) return;
              if (msg.data.name === 'reloadCompanyInformation') {
                  if (!msg.data.mainEmployerBranding) {
                      location.reload();
                  } else {
                    $scope.updateEmployerBrandingBasedFields(msg.data.mainEmployerBranding);
                    $scope.$parent.errorFields = $scope.$parent.checkCampaignFields();
                    $scope.$digest();
                  }
              }
          };
      }
  }

  $scope.openEditMainBrandingCustomization = function() {
      const childWindow = window.open($scope.getEditEmployerBrandingUrl($scope.employerBranding._id), '__blank');
      if (childWindow) {
          childWindow.onmessage = function(msg) {
              if (!msg.data) return;
              if (msg.data.name === 'reloadCustomization') {
                  if (!msg.data.mainEmployerBranding) {
                      location.reload();
                  } else {
                    $scope.updateEmployerBrandingBasedFields(msg.data.mainEmployerBranding);
                    $scope.$parent.errorFields = $scope.$parent.checkCampaignFields();
                    $scope.$digest();
                  }
              }
          };
      }
  }

  $scope.openEditCampaign = function() {
      const childWindow = window.open($scope.editCampaignUrl, '__blank');
      if (childWindow) {
          childWindow.onmessage = function(msg) {
              if (!msg.data) return;
              if (msg.data.name === 'reloadCampaign') {
                  $scope.$parent.getCampaign(true)
                      .then(campaign => {
                          $scope.campaign = campaign;
                          $scope.$parent.errorFields = $scope.$parent.checkCampaignFields();
                      });
              }
          };
      }
  }

  function getNameOnCampaignLanguage(option) {
      const lang = Translate.currentLanguage();
      const langShort = Translate.getLangString('_SHORT', [lang]);
      if (option.name.find) {
        let localizedName = option.name.find(opt => opt.languageCode.startsWith(langShort));
        // if the language is not found use english
        if (!localizedName) {
            localizedName = option.name.find(opt => opt.languageCode.startsWith('en'));
        }
        if (localizedName) {
            return localizedName.value;
        }
      }

      // if the language is not found use the first language available
      return option.name[0].value;
  }

  $scope.educationLevels = [];
  $scope.seniorities = [];
  $scope.industries = [];
  $scope.jobCategories = [];
  $scope.multiselectReadyToBuild = false;
  $timeout(function () {
      $scope.multiselectReadyToBuild = true;
  }, 10);
  const loadEducationLevel = function() {
      let url = 'vonq-integration/education-levels';
      Server.get(url)
          .then((res) => {
              res.forEach(item => item.name = getNameOnCampaignLanguage(item));
              $scope.educationLevels.push(...res);
          });
  }
  const loadSeniority = function() {
      let url = 'vonq-integration/seniority';
      Server.get(url)
          .then((res) => {
              res.forEach(item => item.name = getNameOnCampaignLanguage(item));
              $scope.seniorities.push(...res);
          });
  }
  const loadIndustries = function() {
      let url = 'vonq-integration/industries';
      Server.get(url)
          .then((res) => {
              $scope.industries.push(...res);
          });
  }
  const loadJobCategory = function() {
      let url = 'vonq-integration/job-functions';
      Server.get(url)
          .then((res) => {
              const flattenJobs = [];
              const addItemsAndChilds = function(item, prefix) {
                  item.forEach(item => {
                      flattenJobs.push({
                          id: item.id,
                          name: item.name,
                          label: (prefix ? prefix : '') + item.name,
                      });
                      addItemsAndChilds(item.children, `${item.name} - `);
                  });
              }
              if (res && res.length) {
                  addItemsAndChilds(res);
              }
              $scope.jobCategories.push(...flattenJobs);
          });
  }

  $scope.nextStep = function(campaign) {
      $scope.$parent.nextStep(campaign);
  }
  $scope.previousStep = function() {
      $scope.$parent.previousStep();
  }

  $scope.checkoutStepInit = function() {
      if ($stateParams.step === 1) {
          loadEducationLevel();
          loadSeniority();
          loadIndustries();
          loadJobCategory();
      } else if ($stateParams.step > 1) {
          $scope.errorFields = $scope.$parent.checkVonqFields($scope.$parent.vonqFields);
          if (!_.isEmpty($scope.errorFields)) {
              $state.go('^.' + checkoutStep1State);
          }
      }

      $scope.updateEmployerBrandingBasedFields();
  }

  $scope.updateEmployerBrandingBasedFields = function(newEmployerBranding) {
    let campaignBranding = _.get($scope.campaign, 'customization.employerBranding');
    if (newEmployerBranding) {
        if (campaignBranding._id === newEmployerBranding._id) {
            $scope.campaign.customization.employerBranding = newEmployerBranding;
            campaignBranding = newEmployerBranding;
        }
        if ($rootScope.user.mainEmployerBranding._id === newEmployerBranding._id) {
            $rootScope.user.mainEmployerBranding = newEmployerBranding;
        }
    }

    $scope.employerBranding = campaignBranding || $rootScope.user.mainEmployerBranding;
    $scope.$parent.ebCompanyName = _.get(campaignBranding, 'companyInformation.name') || $rootScope.user.mainEmployerBranding.companyInformation.name;
    $scope.$parent.ebCompanyLogo = _.get(campaignBranding, 'customization.logo') || $rootScope.user.mainEmployerBranding.customization.logo;
  }

  if (!$stateParams.campaign && !$scope.$parent.campaign) {
      $scope.$parent.getCampaign()
          .then(function(campaign) {
              $scope.campaign = campaign
              $scope.checkoutStepInit();
          });
  } else {
      $scope.campaign = $stateParams.campaign || $scope.$parent.campaign;
      $scope.checkoutStepInit();
  }
  $timeout(function() {
      $window.document.querySelector('.scrollable').scrollTop = 0;
  }, 10);
}]);
