angular.module('app')
    .component('dashboardQuestion', {
        templateUrl: '../templates/components/dashboard-question.component.html',
        bindings: {
            questionType: '@',
            featureType: '@',
            userRightName: '@',
            questions: '<',
            onChangeQuestions: '&',
        },
        controller: ["$scope", "$rootScope", "$timeout", "Translate", "ToasterService", "PopupService", "VideoClipUploader", "overlaySpinner", "matchingSliderOpts", "Server", "Upload", "NG_QUILL_CONFIG_ASSESSMENTS", function DashboardQuestionController($scope, $rootScope, $timeout, Translate, ToasterService, PopupService, VideoClipUploader, overlaySpinner, matchingSliderOpts, Server, Upload, NG_QUILL_CONFIG_ASSESSMENTS) {

            let $ctrl = this;

            // Make translations accessible to the component's scope
            $scope.texts = $rootScope.texts; 

            $scope.editorModules = NG_QUILL_CONFIG_ASSESSMENTS.modules;

            $scope.updateQuestionsCount = function () {
                if (!$scope.componentQuestions) {
                    $scope.questionsCount = 0;
                    return;
                }
                
                let questionCount = 1;
                
                $scope.questionsCount = $scope.componentQuestions.map(function (question) {
                    // Counting ONLY questions (no information section)
                    if (question.mode < 20) {
                        return questionCount++;
                    }
                    return null;
                });
            };
            
            // 
            // Watch questions
            // 
            
            $scope.$ctrl.$onChanges = updateComponentQuestions;
            $scope.$watch('$ctrl.questions', updateComponentQuestions, true);
            $scope.$watch('componentQuestions', updateRootScopeCampaignQuestions, true);
            $scope.$watch('componentQuestions', $scope.updateQuestionsCount, true);

            // 
            // Matching Sliders options
            // 

            $scope.matchingSliders = matchingSliderOpts;
            $scope.matchingSliders.answer.options.onChange = onMatchingEliminatoryCheckboxChecked;

            // Other

            $scope.errorMessage = '';
            $scope.scrolledToBottom = false;

            function updateRootScopeCampaignQuestions() {
                if (!$scope.componentQuestions || !$scope.$ctrl.questions)
                    return;

                $scope.$ctrl.questions = $scope.writtenQuestions.concat($scope.videoQuestions);
                $scope.$ctrl.onChangeQuestions({
                    $questions: $scope.$ctrl.questions
                });
            }

            function updateComponentQuestions() {
                if (!$scope.$ctrl.questions)
                    return;
                $scope.writtenQuestions = _.filter($scope.$ctrl.questions, function (question) {
                    return question.isWritten;
                });
                $scope.videoQuestions = _.filter($scope.$ctrl.questions, function (question) {
                    return !question.isWritten;
                });
                $scope.componentQuestions = $ctrl.questionType == 'written' ? $scope.writtenQuestions : $scope.videoQuestions;
            }


            function onMatchingEliminatoryCheckboxChecked(){
                $scope.$parent.activeQuestion.predefinedAnswers.forEach(function(a) {
                    if (a.matching.weight != 0) {
                        a.matching.isEliminatory = false;
                    }
                });
            }

            $scope.addQuestion = function () {

                // if ($scope.$parent.activeQuestion && !validateFields($scope.$parent.activeQuestion)) {
                //     return;
                // }
                        
                $scope.$parent.clearEmptyQuestions();

                var q = {
                    text: '',
                    prepTime: 3,
                    answerTime: 3,
                    wordLimit: 50,
                    mode: 0,
                    modeOption: $scope.ddQuestionOptions[1],
                    isWritten: true,
                    matching: {
                        coef: 0
                    },
                    predefinedAnswers: []
                };

                if ($ctrl.questionType != 'written') {
                    q.isWritten = false;
                    q.modeOption = $scope.ddQuestionOptions[0];
                }

                $scope.componentQuestions.push(q);
                updateRootScopeCampaignQuestions();
                $scope.$parent.activeQuestion = q;

                scrollToBottom();
            };

            $scope.deleteQuestion = function (index) {
                $scope.componentQuestions.splice(index, 1);
                updateRootScopeCampaignQuestions();
                $scope.editQuestion(-1); // to unselect activeQuestion
            };

            $scope.clickMatchingEliminatoryCheckbox = function (answer) {
                // warning, this function is called before the change in the model (ng-change)
                if (!answer.matching.isEliminatory) {
                    answer.matching.weight = 0;
                }
            };

            $scope.ddQuestionOptionClick = function (selected, question) {
                switch (selected.value) {
                    case 'video':
                        question.isWritten = false;
                        question.mode = selected.mode;
                        break;
                    case 'written':
                        question.isWritten = true;
                        question.mode = selected.mode;
                        break;
                    case 'single':
                        question.isWritten = true;
                        question.mode = selected.mode;
                        break;
                    case 'multiple':
                        question.isWritten = true;
                        question.mode = selected.mode;
                        break;
                    case 'linear_scale':
                        question.isWritten = true;
                        question.mode = selected.mode;
                        break;
                    case 'text':
                        question.isWritten = selected.isWritten;
                        question.mode = selected.mode;
                        break;
                }
                // Multiple choice questions
                if (question.mode == 1 || question.mode == 2) {
                    if (!question.predefinedAnswers || question.predefinedAnswers.length == 0) {
                        question.predefinedAnswers = [{
                            text: '',
                            matching: {
                                isEliminatory: false,
                                weight: 0
                            }
                        }];
                    }
                } else if (question.mode == 3) {
                    question.linearScale = {
                        min: 1,
                        max: 10,
                    };
                } else {
                    question.predefinedAnswers = [];
                }
            };

            function scrollToBottom() {
                $scope.scrolledToBottom = true;
                $timeout(function () {
                    $scope.scrolledToBottom = false;
                }, 300);
            };

            $rootScope.$on('cc-questions.onboarding.init', function() {
                console.log('in cc-questions.onboarding.init');

                $scope.$apply(function() {
                    $scope.$parent.clearEmptyQuestions();
                    document.getElementById('my-questions').scrollTop = 0;
                });
            });

            $scope.ddQuestionOptions = [{
                    text: Translate.getLangString('video_question_option'),
                    value: 'video',
                    isWritten: false,
                    mode: 0,
                },
                {
                    text: Translate.getLangString('written_question_option'),
                    value: 'written',
                    isWritten: true,
                    mode: 0,
                },
                {
                    text: Translate.getLangString('written_question_single_choice_option'),
                    value: 'single',
                    isWritten: true,
                    mode: 1,
                },
                {
                    text: Translate.getLangString('written_question_multiple_choice_option'),
                    value: 'multiple',
                    isWritten: true,
                    mode: 2,
                },
                {
                    text: Translate.getLangString('written_question_linear_scale'),
                    value: 'linear_scale',
                    isWritten: true,
                    mode: 3,
                },
                {
                    text: Translate.getLangString('question_option_text'),
                    value: 'text',
                    isWritten: true,
                    mode: 20,
                },
                {
                    text: Translate.getLangString('question_option_text'),
                    value: 'text',
                    isWritten: false,
                    mode: 20,
                },
            ];

            $scope.ddWrittenQuestionOptions = $scope.ddQuestionOptions.filter(mode => mode.isWritten);

            $scope.confirmQuestion = function (index, question) {

                // if (!validateFields(question)) {
                //     return;
                // }

                $timeout(function () {
                    $scope.editQuestion(-1);
                });

            };

            function validateFields(question) {
                $scope.errorMessage = getFieldErrorMsg(question);
                if ($scope.errorMessage) {
                    return false;
                } else {
                    return true;
                }
            }

            /**
             * Returns a String if the question's fields are not valid
             */
            function getFieldErrorMsg(question) {

                if (!question.text || question.text.trim().length == 0) {
                    if (question.mode == 0) {
                        return Translate.getLangString('warning_no_text');
                    } else if (!question.predefinedAnswers || question.predefinedAnswers.length == 0) {
                        return Translate.getLangString('warning_no_answer');
                    }
                }

                if (question.mode == 1 || question.mode == 2) {
                    if (!question.predefinedAnswers || question.predefinedAnswers.length == 0) {
                        return Translate.getLangString('warning_no_answer');
                    }

                    let activeAnswers = question.predefinedAnswers.slice(0, -1);
                    let allWeightsEqual = activeAnswers.every(function (val, i, arr) {
                        if (val && val.matching && typeof val.matching.weight == 'number') {
                            return val.matching.weight === arr[0].matching.weight;
                        }
                    });
                    if (question.matching && question.matching.coef && allWeightsEqual) {
                        return Translate.getLangString('warning_matching_equal');
                    }
                }

                return '';
            }

            /** Called from the controller, or from the view when a question is clicked - selected */
            $scope.editQuestion = function (index) {

                var thisQuestion = $scope.componentQuestions[index];

                if($scope.$parent.activeQuestion != thisQuestion) {
                    $scope.$parent.activeQuestion = thisQuestion;
                    $scope.$parent.clearEmptyQuestions();
                }

                matchingSliderOpts.setAllSlidersPosition($scope);

                // if ($scope.$parent.activeQuestion && !validateFields($scope.$parent.activeQuestion)) {
                //     return;
                // }

                if ($scope.$parent.activeQuestion) {
                    if (!$scope.$parent.activeQuestion.mode) {
                        $scope.$parent.activeQuestion.mode = 0;
                    }
                    if ($scope.$parent.activeQuestion.isWritten) {
                        $scope.$parent.activeQuestion.modeOption = $scope.ddQuestionOptions.find(x => x.isWritten === true && x.mode === $scope.$parent.activeQuestion.mode);
                    } else {
                        $scope.$parent.activeQuestion.modeOption = $scope.ddQuestionOptions.find(x => x.isWritten === false && x.mode === $scope.$parent.activeQuestion.mode);
                    }
                    if ($scope.$parent.activeQuestion.mode == 1 || $scope.$parent.activeQuestion.mode == 2) {
                        $scope.autoAddAnswer($scope.$parent.activeQuestion);
                    }
                }
            };

            $scope.autoAddAnswer = function (question) {
                var hasEmpty = question.predefinedAnswers.find(function (answer) {
                    return (answer.text.length == 0);
                });
                if (!hasEmpty) {
                    question.predefinedAnswers.push({
                        text: '',
                        matching: {
                            weight: 0,
                            isEliminatory: false
                        },
                    });
                }
            };

            $scope.openVideoClips = function (question) {
                PopupService.openVideoClips($scope, question);
            };

            $scope.removeVideo = function (question) {
                question.videoClipId = null;
            };

            $scope.uploadVideoClip = function (file, question) {
                //console.log('UPLOAD', file);
                if (!file) {
                    //console.log('no file', file);
                    return;
                }

                var metaData = {
                    text: question.text || (file && file.name ? file.name : ''),
                    category: ''
                };

                overlaySpinner.show('editcampaign');
                VideoClipUploader.uploadFromFile(file, metaData).then(function (result) {
                    overlaySpinner.hide('editcampaign');
                    if (result.errorMessage) {
                        alert(result.errorMessage);
                    } else if (result._id) {
                        question.videoClipId = result._id;
                    }
                });
            };

            $scope.uploadImage = function (question, file) {
                overlaySpinner.show('editcampaign');
                Upload.upload({
                    url: Server.makeUrl('users/me/questionImage'),
                    data: {
                        file: file || {}
                    }
                }).then(function (resp) {
                    overlaySpinner.hide('editcampaign');
                    if (resp.status === 200 && resp.data) {
                        if (resp.data.id) {
                            question.imageId = resp.data.id;
                            ToasterService.success('upload_done');
                        } else {
                            question.imageId = null;
                            ToasterService.success('question_image_delete_success');
                        }
                    } else {
                        ToasterService.failure(err, 'err_0_error_occurred');
                    }
                }).catch(err => {
                    overlaySpinner.hide('editcampaign');
                    ToasterService.failure(err, 'err_0_error_occurred');
                })
            }

            $scope.$on('questiontext', function (event, text) {
                if ($scope.$parent.activeQuestion && text) {
                    $scope.$parent.activeQuestion.text = text;
                }
            });

            $scope.contentNgQuillChanged = function(editor) {
            
                let text = editor.root.innerHTML;
                let modified = false;
                let totalSizeInMB = 0;
            
                // Replace images and update total size accordingly
                text = text.replace(/<img\s+[^>]*?src="data:image\/(png|jpg|jpeg);base64,([^"]*?)"[^>]*?>/gi, (match, format, base64) => {
                    const sizeInBytes = (base64.length * 3/4) - (base64.match(/=+$/g) || []).length;
                    const sizeInMB = sizeInBytes / (1024 * 1024);
            
                    if (totalSizeInMB + sizeInMB > 1) {
                        ToasterService.failure(null, 'adding_this_image_would_exceed_the_maximum_allowed_content_size_limit');
                        modified = true;
                        return '';
                    } else {
                        totalSizeInMB += sizeInMB;
                        return match;
                    }
                });
            
                if (modified) {
                    editor.root.innerHTML = text;
                }
            };  

            /* truncate HTML to a specified length of plain text while ensuring that the truncation does not break the HTML structure */
            $scope.truncateHtml = function (text, length) {
                if (!text) return '';
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = text;
                // ensures that when the text content is extracted, there is a space between paragraphs instead of them running together
                tempDiv.querySelectorAll('p').forEach(p => {
                    p.insertAdjacentText('beforeend', ' ');
                });
                const plainText = tempDiv.textContent || tempDiv.innerText || '';
                return plainText.length > length ? plainText.substring(0, length) + '...' : plainText;
            };

            function adjustNgQuillEditorHeight(editorElement) {
                const editor = editorElement.querySelector('.ql-editor');
                if (editor) {
                    editor.style.height = `${editorElement.clientHeight}px`;
                }
            }

            $timeout(function() {
                const resizableEditors = document.querySelectorAll('.quill-editor-resize--container');
                resizableEditors.forEach(editorElement => {
                    editorElement.addEventListener('resize', function() {
                        adjustNgQuillEditorHeight(editorElement);
                    });
                });
            }, 0);
        }]

    })        
    // Truncate the text in the information section if it is too long
    .filter('truncate', function () {
        return function (input, maxLength) {
            if (!input || typeof input !== 'string') return input;
            maxLength = maxLength || 100;
            if (input.length <= maxLength) return input;
            return input.substring(0, maxLength) + '...';
        };
    });
