//@ts-check
angular.module('app').controller('SubmissionPrintoutCtrl', ["$scope", "$rootScope", "$sce", "$filter", "Translate", function ($scope, $rootScope, $sce, $filter, Translate) {
console.log($scope.candidate);
  $scope.calcScore = function (question, candidate) {
      if (!candidate || !candidate.reviews || !$rootScope.campaign || !$rootScope.campaign.collaborators) {
          return '...';
      }

      var i, id, sum = 0, count = 0;

      for (id in candidate.reviews) {
          var found = false;
          if (id == $rootScope.user._id) {
              found = true;
          } else {
              for (i in $rootScope.campaign.collaborators) {
                  if ($rootScope.campaign.collaborators[i]._id == id) {
                      found = true;
                      break;
                  }
              }
          }
          if (found) {
              for (i in candidate.reviews[id]) {
                  if (question.id == i) {
                      sum += candidate.reviews[id][i];
                      ++count;
                  }
              }
          }
      }

      if (sum == 0 || count == 0) {
          return '...';
      }

      var rating = Math.round(sum / count);

      return rating + '/5';
  };

  $scope.trustAsHtml = function(html) {
    return $sce.trustAsHtml(html);
  };

  $scope.formatDate = function(date) {
    return date ? $filter('completeDate')(date) : Translate.getLangString('not_finished');
  };

  // This function is used to format the answers in the printout (single/multiple choice questions)
  $scope.formatAnswers = function(question) {
    if (!question || !question.predefinedAnswers || !question.answer) {
        return '';
    }
    // First we split the answer string into an array of integers
    var answersArray = question.answer.split(',').map(Number);
    var answersSet = new Set(answersArray);

    // Then we map the array to a new array of strings, where each string is the formatted answer
    var formattedAnswers = question.predefinedAnswers.map(function(answer, index) {
    var isCandidateAnswer = answersSet.has(index);
    var answerText =  index + ': ' + answer.text;
    if (isCandidateAnswer) {
        return '<span style="color: orange; font-weight:bold;"> -> ' + answerText + '</span>';
    } else {
        return '<span style="margin-left: 22px;">' + answerText + '</span>';
    }
});

    return '<br>' + formattedAnswers.join('<br>');
};

}]);
