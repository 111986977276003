angular.module('app').controller('AnalyticsCtrl', ["$scope", "$rootScope", "$state", "$state", "Server", "ToasterService", "overlaySpinner", "Onboarding", "Translate", "$timeout", "multiSelect", "dateRangePicker", "Util", "$q", "$filter", "$http", function ($scope, $rootScope, $state, $state, Server, ToasterService, overlaySpinner, Onboarding, Translate, $timeout, multiSelect, dateRangePicker, Util, $q, $filter, $http) {
  let multiSelectCampaign = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectBranding = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectDepartment = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectStatus = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectSource = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectLn = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectTags = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectStages = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectCreators = JSON.parse(JSON.stringify(multiSelect))
  let multiSelectHasVideoAnswer = JSON.parse(JSON.stringify(multiSelect))
  $rootScope.validateUserAccess((user, isAsync) => {
    if (!$rootScope.fns.hasPrivileges(['hasAnalytics'], user)) {
        $rootScope.upgradePlan('upgrd_analytics', { backOnClose: true })
    }
    if (!$rootScope.fns.userHasRights('statistics.list', 'view', user)) {
      $state.go('campaigns');
    }
    $scope.multiselectReadyToBuild = true;
    setMultiselectTexts($scope);
  });

  Util.setPageTitle(Translate.getLangString('analytics'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  $scope.filters = {
      startDate: undefined,
      endDate: undefined,
      brandings: [],
      departments: [],
      status: [],
      campaigns: [],
      referralSources: [],
      language: [],
      tags: [],
      creators: [],
      stages: [],
      hasVideoAnswer: [],
  };
  $scope.initialAnalyticsLoad = true;
  $scope.analyticsBusyLoading = false;
  $scope.allMyBrandings = [];
  $scope.allMyCampaigns = [];
  $scope.filteredCampaigns = [];
  $scope.allMyReferralSources = [];
  $scope.allLanguages = [];
  $scope.tagOptions = [];
  $scope.creatorsOptions = [];
  $scope.stagesOptions = [];
  $scope.hasVideoAnswerOptions = [
    { id: true, label: Translate.getLangString("yes") },
    { id: false, label: Translate.getLangString("no") },
];
  $scope.multiSelectBrandingSettings = multiSelectBranding.objectSettings;
  $scope.multiSelectBrandingEvents = { onSelectionChanged: filterCampaigns };
  $scope.multiSelectDepartmentSettings = { ...multiSelectDepartment.objectSettings, displayProp: "name"  };
  $scope.multiSelectDepartmentEvents = { onSelectionChanged: filterCampaigns };
  $scope.multiSelectStatusSettings = {
    ...multiSelectStatus.objectSettings,
    showCheckAll: false,
    showUncheckAll: false,
  };
  $scope.multiSelectStagesSettings = multiSelectStages.objectSettings;
  $scope.multiSelectHasVideoAnswerSettings = multiSelectHasVideoAnswer.objectSettings;
  $scope.multiSelectStagesEvents = { onSelectionChanged: reloadCandidates };
  $scope.multiSelectStatusEvents = { onSelectionChanged: filterCampaigns };
  $scope.multiSelectCampaignSettings = multiSelectCampaign.objectSettings;
  $scope.multiSelectCampaignEvents = { onSelectionChanged: reloadCandidates };
  $scope.multiSelectRefSourceSettings = multiSelectSource.objectSettings;
  $scope.multiSelectRefSourceEvents = { onSelectionChanged: reloadCandidates };
  $scope.multiselectSettingsln = multiSelectSource.objectSettings;
  $scope.multiSelectLnEvents = { onSelectionChanged: reloadCandidates };
  $scope.multiSelectTagsSettings = multiSelectTags.objectSettings;
  $scope.multiSelectTagsSettings.template = multiSelect.optionsTemplates.tagsWithCategories;
  $scope.multiSelectTagsSettings.selectedToTop = true;
  $scope.multiSelectTagsSettings.searchField = 'text';
  $scope.multiSelectCreatorsSettings = multiSelectCreators.objectSettings;
  $scope.multiSelectCreatorsEvents = { onSelectionChanged: reloadCandidates };
  $scope.multiSelectHasVideoAnswerEvents = { onSelectionChanged: reloadCandidates };
    
  $scope.multiSelectTagsEvents = { onSelectionChanged: () => {
    reloadCandidates();
  }};
  $scope.candidates = [];
  $scope.filteredCandidates = [];
  $scope.allMyCampaignschart = [];
  $scope.statusOptions = [
    { id: true, label: Translate.getLangString("campaign_filter_active") },
    { id: false, label: Translate.getLangString("campaign_filter_not_active") }
  ];
  $scope.filters.status = [ ...$scope.statusOptions ]

  function setMultiselectTexts(scope) {
    $scope.multiSelectBrandingTexts = multiSelectBranding.texts;
    $scope.multiSelectDepartmentTexts = multiSelectDepartment.texts;
    $scope.multiSelectStatusTexts = multiSelectStatus.texts;
    $scope.multiSelectCampaignTexts = multiSelectCampaign.texts;
    $scope.multiSelectRefSourceTexts = multiSelectSource.texts;
    $scope.multiselectTextsln = multiSelectLn.texts;
    $scope.multiSelectTagsTexts = multiSelectTags.texts;
    $scope.multiSelectCreatorsTexts = multiSelectCreators.texts;
    $scope.multiSelectStagesTexts = multiSelectStages.texts;
    $scope.multiSelectHasVideoAnswerTexts = multiSelectHasVideoAnswer.texts;
    scope.multiSelectBrandingTexts.buttonDefaultText = Translate.getLangString('brand');
    scope.multiSelectDepartmentTexts.buttonDefaultText = Translate.getLangString('department');
    scope.multiSelectStatusTexts.buttonDefaultText = Translate.getLangString('status');
    scope.multiSelectCampaignTexts.buttonDefaultText = Translate.getLangString('candidates_select_campaign');
    scope.multiSelectRefSourceTexts.buttonDefaultText = Translate.getLangString('referral_source');
    scope.multiselectTextsln.buttonDefaultText = Translate.getLangString('language');
    scope.multiSelectTagsTexts.buttonDefaultText = Translate.getLangString('tags');
    scope.multiSelectCreatorsTexts.buttonDefaultText = Translate.getLangString('creator');
    scope.multiSelectStagesTexts.buttonDefaultText = Translate.getLangString('user_rights_candidateStages');
    scope.multiSelectHasVideoAnswerTexts.buttonDefaultText = Translate.getLangString('video_answer');
  }

  function loadBrandings() {
    return Server.get('employer-brandings')
      .then(brandings => {
        $scope.allMyBrandings = brandings.map(brand => ({
          id: brand._id,
          label: brand.name,
        }));
        return filterCampaigns();
      })
      .catch(err => {
          ToasterService.failure(err, 'load_brandings_error');
      })
  }

  function loadStages() {
    return Server.get('stages')
      .then(stages => {
        $scope.stagesOptions = stages.map(stage => ({
          id: stage._id,
          key: stage.key,
          label: stage.label[Translate.currentLanguage()],
      }));
    })
      .catch(err => {
          ToasterService.failure(err, 'load_stages_error');
      })
  };


  function tagsLoaded(tags) {
    if (tags.length) {
        // duplicate candidate-list.component#tags
        const withoutCategory = _.sortBy(tags.filter(t => !t.tagCategory).map((tag) => {
            return { ...tag, id: tag._id, text: tag.label.replaceAll('-', ' ')+'|'+tag.label }
        }), 'label');
        const withCategory = _.sortBy(tags.filter(t => t.tagCategory).map((tag) => {
            return {
                ...tag,
                id: tag._id,
                text: tag.tagCategory.label.replaceAll('-', ' ') + ' : ' + tag.label.replaceAll('-', ' ')
                    + '|' + tag.tagCategory.label+ ' : ' +tag.label
            }
        }), 'tagCategory.label', 'label');

        $scope.tagOptions = [ ...withoutCategory, ...withCategory ];
    } else {
        $scope.tagOptions = [];
    }
  }

  function downloadCsv(data, name) {
    // Create a Blob with the CSV data and type
    const blob = new Blob([data], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor tag for downloading
    const a = document.createElement('a');

    // Set the URL and download attribute of the anchor tag
    a.href = url;
    a.download = name || `analytics_export_${new Date().toISOString()}.csv`;

    // Trigger the download by clicking the anchor tag
    a.click();
  }

  function extractCountAndPercent(input) {
    if (typeof input !== 'string') {
      return null;
    }
    const regex = /^(\d+)\s\((\d+(\.\d+)?)%\)$/;
    const match = input.match(regex);
    if (match) {
      const count = match[1];
      const percent = match[2] + "%";
      return { count, percent };
    } else {
      return null;
    }
  }

  function convertJsonToCsv(jsonData) {
    const title = Translate.getLangString('analytics_page_header');
    let csv = title + '\n\n';

    jsonData.forEach(section => {
      // Add the section header
      csv += section.section + '\n';

      // add the column names for the "Candidate Information" section
      if (section.section === "\n" && section.data.length > 0) {
        const rowValues = Object.keys(section.data[0]).map(value => `"${value}"`).join(',');
        csv += rowValues + '\n';
      }

      // Add the data rows
      section.data.forEach(row => {
        if (typeof row === 'object' && !Array.isArray(row) && !row.label || !row.value) {
          const rowValues = Object.values(row).map(value => `"${value}"`).join(',');
          csv += rowValues + '\n';
        } else {
          const countPercent = extractCountAndPercent(row.value);
          csv += countPercent ? `"${row.label}",${countPercent.count},${countPercent.percent}\n` : `"${row.label}","${row.value}"\n`;
        }
      });

      // Add an empty line between sections
      csv += '\n';
    });

    return csv;
  }

  $scope.exportData = async function () {
    const candiReferralPieSources = $scope.candiReferralPie.data.rows.map(s => ({
      label: s.c[0].v.split(' : ')[0],
      value: s.c[0].v.split(' : ')[1]
    }));
    const distributionStages = $filter('filter')($scope.stagesWithCandidateCount, $scope.stageIsAccessible).map(s => ({
      label: s.label[Translate.currentLanguage()],
      value: `${s.candidatesCount} (${s.percentage}%)`
    }));
    const tagsValueById = $scope.tagOptions.reduce((p,c) => ({
      ...p,
      [c.id]: c.label
    }), {});
    const stageValueByKey = $scope.stagesOptions.reduce((p,c) => ({
      ...p,
      [c.key]: c.label
    }), {});
    const referralSourceOptions = $scope.allMyReferralSources.reduce((p,c) => ({
      ...p,
      [c.id]: c.label
    }), {});
    const addedByOptions = (await Server.get('collaborators?includesSelf=true')).reduce((p,c) => ({
      ...p,
      [c.id]: c.email
    }), {});

    const candidatesData = $scope.candidatesForCsvExport.map(c => ({
      "First Name": c.firstName || '',
      "Last Name": c.lastName || '',
      "Step Start Time": c.stepStartTime || '',
      "Referral Source": referralSourceOptions[c.referralSource] || '',
      Tags: c.tags?.map(t => tagsValueById[t]).join(', ') || '',
      Stage: stageValueByKey[c.stageCurrent?.key || ""] || stageValueByKey[""],
      "Added by": addedByOptions[c.addedByUserId] || '',
    }));

    const csvData = [
      {
          "section": Translate.getLangString('analytics_campaigns_header'),
          "data": [
            {
              "label": Translate.getLangString('analytics_campaigns_active'),
              "value": $scope.numberOfActiveCampaigns,
            },
            {
              "label": Translate.getLangString('analytics_campaigns_archived'),
              "value": $scope.numberOfArchivedCampaigns,
      
            },
            {
              "label": Translate.getLangString('analytics_campaigns_mean'),
              "value": $scope.meanCandidatePerCampaign,
            },
            {
              "label": Translate.getLangString('analytics_interviewed_mean'),
              "value": $scope.meanInterviewedPerCampaign,
            }
          ]
      },
      {
        "section": "Filters",
        "data": [
          {
            label: $scope.multiSelectCreatorsTexts.buttonDefaultText,
            value: $scope.filters.creators.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectBrandingTexts.buttonDefaultText,
            value: $scope.filters.brandings.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectStatusTexts.buttonDefaultText,
            value: $scope.filters.status.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectCampaignTexts.buttonDefaultText,
            value: $scope.filters.campaigns.map(v => v.label).join(', ')
          }, {
            label: Translate.getLangString('analytics_date_filter'),
            value: !isNaN(Date.parse($scope.filters.startDate)) 
              && !isNaN(Date.parse($scope.filters.endDate)) ? `${
                new Date($scope.filters.startDate).toLocaleDateString()
              } - ${
                new Date($scope.filters.endDate).toLocaleDateString()
              }` : ''
          }, {
            label: $scope.multiSelectRefSourceTexts.buttonDefaultText,
            value: $scope.filters.referralSources.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectTagsTexts.buttonDefaultText,
            value: $scope.filters.tags.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectStagesTexts.buttonDefaultText,
            value: $scope.filters.stages.map(v => v.label).join(', ')
          }, {
            label: $scope.multiSelectHasVideoAnswerTexts.buttonDefaultText,
            value: $scope.filters.hasVideoAnswer.map(v => v.label).join(', ')
          }
        ]
      },
      {
          "section": `${Translate.getLangString('analytics_candidates_header')},${Translate.getLangString('count')},${Translate.getLangString('percent')}`,
          "data": [
            {
              "label": Translate.getLangString('analytics_total_candidates'),
              "value": $scope.totalCandidates,
            },
            ...$scope.qualifiedWithCandidateCount.accessible ? [{
              "label": Translate.getLangString('candidate_stage_qualified'),
              "value": `${$scope.qualifiedWithCandidateCount.candidatesCount} (${$scope.qualifiedWithCandidateCount.percentage}%)`,
            }] : [],
            ...$scope.qualifiedWithCandidateCount.accessible ? [{
              "label": Translate.getLangString('candidate_stage_hired'),
              "value": `${$scope.hiredWithCandidateCount.candidatesCount} (${$scope.hiredWithCandidateCount.percentage}%)`,
            }] : [],
            {
              "label": Translate.getLangString('analytics_recruitment_time'),
              "value": `${$scope.meanHiringTime} ${Translate.getLangString('days')}`,
            }
          ]
      },
      {
          "section": `${Translate.getLangString('referral_source')},${Translate.getLangString('count')},${Translate.getLangString('percent')}`,
          "data": candiReferralPieSources
      },
      {
          "section": `${Translate.getLangString('analytics_stage_distribution')},${Translate.getLangString('count')},${Translate.getLangString('percent')}`,
          "data": distributionStages
      },
      {
          "section": "\n", // no title just an extra empty row for spacing
          "data": candidatesData
      }
    ];

    const outputCsv = convertJsonToCsv(csvData);
    downloadCsv(outputCsv);
  }

  $scope.loadDepartments = async function() {
    if (!$rootScope.fns.hasCollaboratorsSettings(["useDepartments"])) {
        return;
    }
    if ($rootScope.departments && $rootScope.departments.length > 0) {
        const deferred = $q.defer();
        deferred.resolve($rootScope.departments);
        return deferred.promise;
    } else {
        try {
            return Server.get('departments').then(departments => {
                $rootScope.departments = departments;
                return departments;
            });
        } catch (err) {
            ToasterService.failure(err, 'departments_load_error');
            const deferred = $q.defer();
            deferred.resolve([]);
            return deferred.promise;
        }
    }
};

  function loadCampaigns() {

        let url = 'campaigns?skipCollaboratorLoad=true&fetchCandidates=true&fetchCreators=true';

        if(!$rootScope.user || $rootScope.fns.hasCampaignsSettings(['excludeHiddenCampaignsFromAnalytics'])) {
            url = url + '&type=normalCampaign';
        }

        return Server.get(url)
            .then(function (allCampaigns) {
                let sortedCampaigns = Util.sortByDisplayedTitleAndArchiveStatus(allCampaigns, $rootScope.user);
                let campaigns = _.map(sortedCampaigns, function (campaign) {
                    return {
                        id: campaign._id,
                        language: campaign.language,
                        label: Util.getDisplayedTitleAndArchiveStatus(campaign, $rootScope.user),
                        customization: campaign.customization,
                        departmentIds: campaign.departmentIds,
                        isActive: campaign.isActive,
                    };
                });
                $scope.allMyCampaigns = campaigns;
                let allCandidates = _.flatten(_.map(allCampaigns, 'candidates'));
                let allRefSources = _.compact(_.uniq(_.map(allCandidates, 'referralSource')));
                $scope.allMyReferralSources = _.orderBy(_.map(allRefSources, referralKey => {
                    return {
                        id: referralKey,
                        label: Translate.getLangString('jb_' + referralKey),
                    }
                }), refSource => refSource.label.toLowerCase());
                $scope.allLanguages = _.uniq(_.map(campaigns, c => {
                    return {
                        id: c.language,
                        label: Translate.getLangString('lang_option_' + c.language),
                    }
                }));

                $scope.allCreators = _.uniqBy(allCampaigns.map(c => {
                    return {
                      id: c.createdBy?._id,
                      name: c.createdBy?.name,
                      email: c.createdBy?.email,
                    };
                  }), 'id');
                
                  
                  $scope.creatorsOptions = $scope.allCreators.map(creator => {
                    return {
                      id: creator.id, 
                      label: Util.userFullName(creator),
                    };
                  });

                let tagIds = [];
                /** Listing all the tags related to the selected campaigns */
                for (let i = 0; i < allCampaigns?.length; i++) {
                    const campaign = allCampaigns[i];
                    for (let j = 0; j < campaign?.candidates?.length; j++) {
                        const candidate = campaign.candidates[j];
                        for (let k = 0; k < candidate?.tags.length; k++) {
                            const tag = candidate.tags[k];
                            if(tagIds.indexOf(tag) < 0) {
                                tagIds.push(tag);
                            }
                        }
                    }
                }

                Server.post(`tags`, {tagIds: tagIds.join(',')})
                    .then(tags => {
                        tagsLoaded(tags)
                    })
                    .catch(err => {
                        ToasterService.failure(err, 'load_tags_error');
                    })

              $scope.numberOfArchivedCampaigns = _.filter(allCampaigns, 'isArchived').length;
              $scope.numberOfActiveCampaigns = _.filter(allCampaigns, campaign => {
                  return !campaign.isArchived && (campaign.type === 'normalCampaign');
              }).length;
              if (allCampaigns.length === 0) { // Avoid "NaN"
                $scope.meanCandidatePerCampaign = 0;
                $scope.meanInterviewedPerCampaign = 0;
            } else {
                // Calculate meanCandidatePerCampaign
                $scope.meanCandidatePerCampaign = _.meanBy(allCampaigns, c => c.candidates.filter(Util.realCandidatesFilter).length).toFixed(1);
                if ($scope.meanCandidatePerCampaign >= 10) {
                    $scope.meanCandidatePerCampaign = Number($scope.meanCandidatePerCampaign).toFixed(0);
                }
                // Calculate meanInterviewedPerCampaign
                $scope.meanInterviewedPerCampaign = _.meanBy(allCampaigns, campaign => {
                    let interviewCandidates = campaign.candidates.filter(candidate => {
                        let stageKeys = _.map(candidate.stages, 'stage.key');
                        let hasHadStageInterviewed = _.includes(stageKeys, 'interviewed');
                        return hasHadStageInterviewed;
                    });
                    return interviewCandidates.length;
                })
                   .toFixed(2);
                if ($scope.meanInterviewedPerCampaign >= 1) {
                    $scope.meanInterviewedPerCampaign = Number($scope.meanInterviewedPerCampaign).toFixed(1);
                }
            }
            }).catch(err => {
                ToasterService.failure(err, "load_campaign_error");
            });
  }

  function filterCampaignsSetScope() {
    $scope.filteredCampaigns = $scope.allMyCampaigns.filter(camp => {
      const brandingCheck = $scope.filters.brandings.length === 0 || $scope.filters.brandings.some(brand => {
        return camp.customization && camp.customization.employerBrandingId && camp.customization.employerBrandingId._id === brand.id;
      })
      const statusCheck = $scope.filters.status.length === 0 || $scope.filters.status.some(stat => stat.id === camp.isActive);
      const departmentCheck = $scope.filters.departments.length === 0 || $scope.filters.departments.some(filter => camp.departmentIds && camp.departmentIds.includes(filter._id));
      return brandingCheck && statusCheck && departmentCheck;
    });
    $scope.filters.campaigns = $scope.filters.campaigns.filter(c => $scope.filteredCampaigns.includes(c));
  }

  function filterCampaigns() {
    filterCampaignsSetScope();
    return reloadCandidates();
  }

  let currentRequest = null;
  function reloadCandidates() {
    if (currentRequest && currentRequest.resolve) {
      currentRequest.resolve();
    }
    currentRequest = $q.defer();
    $scope.analyticsBusyLoading = true;
    function processAnalyticsData({ stagesWithCandidateCount, candidates, meanHiringTime }) {
      $scope.lang = Translate.currentLanguage();
      $scope.meanHiringTime = (meanHiringTime && meanHiringTime.toFixed(0)) || '...';

      // Stages
      $scope.stagesWithCandidateCount = stagesWithCandidateCount;
      $scope.totalCandidates = candidates.length;
      $scope.stagesWithCandidateCount.forEach((stage) => {
        stage.percentage = $scope.totalCandidates
          ? ((stage.candidatesCount * 100) / $scope.totalCandidates).toFixed(1)
          : 0;
      });

      const maxCandidateCount = _.maxBy($scope.stagesWithCandidateCount, 'candidatesCount');
      const maxPercentStage = (maxCandidateCount?.percentage / 100) || 100;

      $scope.stagesWithCandidateCount.forEach((stage) => {
        stage.customization = {
          ...stage.customization,
          width: `${(Math.sqrt(stage.percentage / maxPercentStage) / Math.sqrt(100)) * 100}%`,
        };
      });

      $scope.qualifiedWithCandidateCount = _.find($scope.stagesWithCandidateCount, stage => stage.key === 'qualified');
      $scope.hiredWithCandidateCount = _.find($scope.stagesWithCandidateCount, stage => stage.key === 'hired');

      $scope.stageIsAccessible = (stage) => stage.accessible;

      // Charts
      const candidatesForCharts = candidates.map((candidate) => ({
        id: candidate._id,
        email: candidate.email,
        stepStartTime: candidate.stepStartTime,
        referralSource: candidate.referralSource,
      }));

      $scope.candidatesForCsvExport = candidates.map((candidate) => ({
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        stepStartTime: candidate.stepStartTime,
        referralSource: candidate.referralSource,
        tags: candidate.tags,
        stageCurrent: candidate.stageCurrent,
        addedByUserId: candidate.addedByUserId,
      }));

      filterCampaignsSetScope();
      initCandidatesTimeLineChart(candidatesForCharts);
      initCandidatesReferralPie(candidatesForCharts);
    }

    if ($scope.filteredCampaigns.length === 0 && !$scope.initialAnalyticsLoad) {
      processAnalyticsData({ stagesWithCandidateCount: [], candidates: [] });
      return Promise.resolve();
    } else {
      const filterBody = buildRequestFilter();
      if ($scope.filters) {
        $scope.initialAnalyticsLoad = false;
        const overlay2 = overlaySpinner.show("cards-overlay-2");
        // use $http to bypass error handling and prevent redirect on the previous request if still running
        return $http({
          method: 'POST',
          url: '/analytics/allAnalytics',
          data: filterBody,
          timeout: currentRequest.promise,
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json, text/plain, */*',
          },
        })
        .then((res) => {
          processAnalyticsData(res.data);
          overlay2.hide();
          $scope.analyticsBusyLoading = false;
        })
        .catch((error) => {
          if (error.status === -1 || error.xhrStatus === 'timeout') {
            console.log('Request was canceled or timed out');
          } else {
            console.error('Error in request:', error);
          }
          overlay2.hide();
          $scope.analyticsBusyLoading = false;
        });
      }
    }
  }

  function buildRequestFilter() {
      const filterBody = {};
      if ($scope.filters.startDate?.isValid() && $scope.filters.startDate?.isValid()) {
        filterBody.startDate = $scope.filters.startDate.toISOString();
        filterBody.endDate = $scope.filters.endDate.toISOString();
      }
      if($scope.filters.campaigns && $scope.filters.campaigns.length) {
        filterBody.campaigns = _.map($scope.filters.campaigns, 'id');
      } else if ($scope.filteredCampaigns.length !== $scope.allMyCampaigns.length) {
        filterBody.campaigns = _.map($scope.filteredCampaigns, 'id');
      }
      if($scope.filters.referralSources && $scope.filters.referralSources.length) {
        filterBody.referralSources = _.map($scope.filters.referralSources, 'id');
      }
      if($scope.filters.tags && $scope.filters.tags.length) {
        filterBody.tags = _.map($scope.filters.tags, 'id');
      }
      if($scope.filters.creators && $scope.filters.creators.length) {
        filterBody.creators = _.map($scope.filters.creators, 'id');
      }
      if($scope.filters.stages && $scope.filters.stages.length) {
        filterBody.stages = _.map($scope.filters.stages, 'id');
      }
      if ($scope.filters.hasVideoAnswer && $scope.filters.hasVideoAnswer.length) {
        filterBody.hasVideoAnswer = _.map($scope.filters.hasVideoAnswer, 'id');
      }
      return filterBody;
  }

  function monthsDiff(d1, d2) {
      // get list of months between 2 dates start date end date
      const startDate = moment(d1, 'YYYY-MM-DDThh:mm:ss');
      const endDate = moment(d2, 'YYYY-MM-DDThh:mm:ss');
      let result = [];
      if (endDate.isBefore(startDate)) {
          throw "End date must be greated than start date."
      }
      while (startDate.isBefore(endDate)) {
          result.push(
              {
                  month: startDate.format("MM"),
                  year: startDate.format("YYYY")
              });
          startDate.add(1, 'month');
      }
      return result;
  }

  function sortListBydate(list) {
      list.sort(function (a, b) {
          const dateA = moment(a.stepStartTime).month(), dateB = moment(b.stepStartTime).month();
          return dateA - dateB;
      });
  }

  /**
    * Instanciate Google Chart for the timeline of candidates
    * uses library http://angular-google-chart.github.io/angular-google-chart/docs/0.1.0-beta.1/examples/hide-series/
    * find options at https://developers-dot-devsite-v2-prod.appspot.com/chart/interactive/docs/gallery/piechart.html#configuration-options
    */
  function initCandidatesTimeLineChart(candidateList) {
      sortListBydate(candidateList);
      $scope.candiTimeLineChart = {};
      $scope.candiTimeLineChart.type = "LineChart";
      $scope.candiTimeLineChart.displayed = false;
      let rowData = [];
      let listeOfMonths;
      if ($scope.filters.startDate?.isValid() && $scope.filters.endDate?.isValid()) {
          listeOfMonths = monthsDiff($scope.filters.startDate, $scope.filters.endDate);
      } else {
          listeOfMonths = monthsDiff(moment().startOf('year'), moment());
      }

      listeOfMonths.forEach(item => {
          let candidates = candidateList
              .filter(c => (moment(c.stepStartTime, 'YYYY-MM-DDThh:mm:ss').month() + 1) === parseInt(item.month)
                  && (moment(c.stepStartTime, 'YYYY-MM-DDThh:mm:ss').year()) === parseInt(item.year));
          rowData.push({
              c: [{ v: moment().month(item.month - 1).format('MMM') + " " + moment().year(item.year).format('YY') }, { v: candidates != null ? candidates.length : 0 }]
          });
      });

      $scope.candiTimeLineChart.data = {
          "cols": [{
              id: "month-id",
              label: 'month',
              type: "string"
          }, {
              id: "total-id",
              label: Translate.getLangString('analytics_new_candidates'),
              type: "number"
          }],
          "rows": rowData
      };
      $scope.candiTimeLineChart.options = {
          "colors": ['#28bd83', 'lightskyblue'],
          "defaultColors": ['#28bd83', 'lightskyblue'],
          "isStacked": "true",
          "fill": 20,
          "displayExactValues": true,
          legend: { position: 'none' },
          vAxis: {
              // viewWindowMode: 'explicit',
              // viewWindow: {
              //    min: 0
              //}
          },
          hAxis: {
              //title: 'Month',
              textStyle: {
                  //fontSize:5,
                  bold: false
              }
          },
      };
      $scope.candiTimeLineChart.view = {
          columns: [0, 1]
      };
  }

  /**
    * Instanciate Google Chart for the timeline of candidates
    * uses library http://angular-google-chart.github.io/angular-google-chart/docs/0.1.0-beta.1/examples/hide-series/
    * find options at https://developers-dot-devsite-v2-prod.appspot.com/chart/interactive/docs/gallery/piechart.html#configuration-options
    */
  function initCandidatesReferralPie(candidateList) {
      $scope.candiReferralPie = {};
      $scope.candiReferralPie.type = "PieChart";
      let rowData = [];

      const countByReferral = _.chain(candidateList)
          .countBy('referralSource')
          .omit('undefined')
          .value(); // result is like {actiris: 4, LinkedIn: 12, ...}
      const total = Object.values(countByReferral).reduce((a, b) => a + b, 0);
      for (referralKey in countByReferral) {
          let count = countByReferral[referralKey];
          let percentage = (count * 100 / total).toFixed(1);
          let referralLabel;
          if (Translate.exists('jb_' + referralKey)) {
              referralLabel = Translate.getLangString('jb_' + referralKey);
          } else {
              referralLabel = referralKey;
          }
          rowData.push({
              c: [
                  { v: `${referralLabel} : ${count} (${percentage}%)` },
                  { v: count },
              ]
          });
      }
      rowData = _.sortBy(rowData, row => row.c[1].v * (-1));

      $scope.candiReferralPie.data = {
          cols: [
              { id: 't', label: 'Referral Source', type: 'string' },
              { id: 's', label: 'Candidates', type: 'number' }
          ],
          rows: rowData
      };
      $scope.candiReferralPie.options = {
          pieHole: 0.2,
          fontName: 'Gotham Book',
          fontSize: 14,
          height: 220,
          // colors taken from https://venngage.com/blog/pastel-color-palettes/
          colors: ['#ffc2d1', '#9cadce', '#d4afb9', '#d1cfe2', '#9cadce', '#7ec4cf', '#d3ab9e', '#a7bed3', '#c6e2e9', '#f1ffc4', '#ffcaaf', '#dab894', '#7ec4cf', '#d3ab9e', '#ffee93', '#fcf5c7', '#b5d6d6', '#efcfe3', '#b3dee2', '#ffdab9', '#bde0fe', '#eac9c1', '#d1d1d1', '#abc4ff', '#809bce', '#95b8d1', '#b8e0d2', '#d6eadf', '#eac4d5', '#fff1e6', '#a2d2ff', '#fcf5c7', '#e8dff5'],
          chartArea: {right: 80, width: '190%', height:'85%'}
      };
  }

  
  const deferLoadCampaign = $q.defer();
  const overlay1 = overlaySpinner.show("cards-overlay-1");
  const overlay2 = overlaySpinner.show("cards-overlay-2");
  $q.all([
    loadCampaigns(),
    loadBrandings(),
    loadStages(),
    $scope.loadDepartments()
  ])
  .then(async ([campaignsResult, brandingsResult, departmentsResult]) => {
    overlay1.hide();
    dateRangePicker.initDatePicker($scope.filters, { useLongRange: false }, reloadCandidates);
    overlay2.hide();
    deferLoadCampaign.resolve();
  })
  .catch(err => {
    overlay.hide();
    deferLoadCampaign.reject(err);
  });
  
  Onboarding.initWidget(null);
  
  return deferLoadCampaign.promise;
}]);
