var DEFAULT_CONSTRAINTS = {
    video: {
        width: { min: 320, ideal: 640, max: 1920 },
        height: { min: 240, ideal: 480, max: 1080 }
    },
    audio: true
};

var __DEFAULT_CONSTRAINTS = {
    video: true,
    audio: true
};

var hideLoader = function() {
    $('#loader-wrapper').addClass('loaded').empty();
    setTimeout(function() {
        $('#loader-wrapper').remove();
    }, 300);
};

function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

var app = angular.module('app', ['ui.router', 'analytics.mixpanel', 'app.controllers', 'app.controllers.interview', 'angular-cache']);
app.config(['$compileProvider', '$sceDelegateProvider', function( $compileProvider, $sceDelegateProvider ) {   
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|beehire|data):/);
    $sceDelegateProvider.resourceUrlWhitelist([
        // Allow loading resources from the same origin
        'self',
        // Allow loading resources from a specific domain
        'https://www.google.com/**',
        'https://live-v2.beehire.com/**'
    ]);
}]);

function dashboardApp() {
    app.run(["$rootScope", "$templateCache", "privilegeFactory", "Util", "$http", "CacheFactory", function ($rootScope, $templateCache, privilegeFactory, Util, $http, CacheFactory) {
        $http.defaults.cache = CacheFactory('$http', {
            maxAge: 60 * 1000, // Items added to this cache expire after 15 minutes
            deleteOnExpire: 'aggressive' // Items will be deleted from this cache when they expire
        });

        if (!$rootScope.componentCache) {
            $rootScope.componentCache = {};
        }
        // setTimeout(function () {
        //     $rootScope.$broadcast('test', 'test data');
        // }, 5000);

        // connectToSocket();

        // function connectToSocket() {
        //     const hostname = window.location.hostname === 'localhost' ? window.location.hostname + ':8000' : window.location.hostname;
        //     const host = window.location.protocol + '//' + hostname;
        //     let socket = io.connect(host, { secure: true, reconnect: true, rejectUnauthorized : false, transports: ["websocket"] });
        //     socket.on($rootScope.user._id, (msg) => {
        //         console.log('socket msg = ', msg);
        //         $rootScope.$broadcast(msg.message);
        //     });
        // }

        // for the library ngDropdowns : use custom template
        $templateCache.put('ngDropdowns/templates/dropdownMenuItem.html', [
            '<li ng-class="{divider: dropdownMenuItem.divider, \'divider-label\': dropdownMenuItem.divider && dropdownMenuItem[dropdownItemLabel]}">',
                '<a href="" class="dropdown-item {{dropdownMenuItem.class}}" ng-style="dropdownMenuItem.style || dropdownMenuItem.customization" ng-if="!dropdownMenuItem.divider" ng-href="{{dropdownMenuItem.href}}" ng-click="selectItem()">',
                    '<span> {{dropdownMenuItem[dropdownItemLabel]}} </span>',
                '</a>',
                '<span ng-if="dropdownMenuItem.divider"> {{dropdownMenuItem[dropdownItemLabel]}} </span>',
            '</li>'
          ].join(''));

        // Keep history of state changes
        let prevs = $rootScope.previousStates = [];
        $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from, fromParams) {
            $rootScope.previousStates.push({
                name: from.name,
                params: fromParams
            });
            if(prevs.length > 20) {
                // prevent memory leak
                prevs.shift();
            }
        });

        $rootScope.fns = {};

        // 
        // Make services available from the root scope
        // 

        $rootScope.fns.hasPrivileges = function(privileges, user) {
            return privilegeFactory.hasPrivileges(privileges, user ? user : $rootScope.user)
        }
        $rootScope.fns.hasUiSettings = function(uiSettings, user) {
            return privilegeFactory.hasUiSettings(uiSettings, user ? user : $rootScope.user)
        },
        $rootScope.fns.hasCampaignsSettings = function(settings, user, defaultValue) {
            return privilegeFactory.hasCampaignsSettings(settings, user ? user : $rootScope.user, defaultValue)
        },
        $rootScope.fns.hasCollaboratorsSettings = function(settings, user) {
            return privilegeFactory.hasCollaboratorsSettings(settings, user ? user : $rootScope.user)
        },
        $rootScope.fns.hasCandidatesSettings = function(settings, user) {
            return privilegeFactory.hasCandidatesSettings(settings, user ? user : $rootScope.user)
        }
        $rootScope.fns.hasPayPlan = function(requiredPayPlan, user) {
            return privilegeFactory.hasPayPlan(requiredPayPlan, user ? user : $rootScope.user)
        }
        $rootScope.fns.userHasRights = function(userRightName, requiredLevel, user) {
            return privilegeFactory.userHasRights(userRightName, requiredLevel, user ? user : $rootScope.user)
        }
        $rootScope.fns.getDisplayedTitle = Util.getDisplayedTitle;
        $rootScope.fns.getDisplayedTitleAndEmployerBranding = Util.getDisplayedTitleAndEmployerBranding;
        $rootScope.fns.getDisplayedTitleAndArchiveStatus = Util.getDisplayedTitleAndArchiveStatus;
        $rootScope.fns.realCandidatesFilter = Util.realCandidatesFilter;
        $rootScope.fns.getDisplayedUserWithSuspendedLabel = Util.getDisplayedUserWithSuspendedLabel;

        /**
         * @param  { [String] } states - names of states
         * Return: name of the last visited state included in the given state list
         */
        $rootScope.fns.getLastStateIn = function(states) {
            let lastKnownState = _.findLast($rootScope.previousStates, function(state) {
                return states.includes(state.name);
            });
            return lastKnownState;
        };

        // duplicate code ref validateUserAccess
        $rootScope.validateUserAccess = function (callback) {
            if (!$rootScope.user) {
                $rootScope.$on('onCurrentUser', (_, user) => {
                    callback(user, true);
                })
            } else {
                callback($rootScope.user, false);
            }
        }

    }]).config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('campaigns', {
                url: '/',
                templateUrl: '/templates/dashboard-campaigns.html',
                controller: 'CampaignsCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('assessments', {
                url: '/assessments',
                templateUrl: '/templates/dashboard-assessments.html',
                controller: 'DashboardAssessmentsCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('assessment-edit', {
                url: '/editassessments/:assessmentId',
                templateUrl: '/templates/dashboard-assessment-edit.html',
                controller: 'DashboardAssessmentEditCtrl',
                params: {
                    currentStep: 1
                },
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('assessment-edit-questions', {
                url: '/editassessments/:assessmentId/questions',
                templateUrl: '/templates/dashboard-assessment-edit-questions.html',
                controller: 'DashboardAssessmentEditCtrl',
                params: {
                    currentStep: 2
                },
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('assessment-candidates', {
                url: '/assessments/:assessmentId',
                templateUrl: '/templates/dashboard-assessment-candidates.html',
                controller: 'DashboardAssessmentCandidatesCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('live-interview', {
                url: '/live-interview',
                templateUrl: '/templates/dashboard-live-interview.html',
                controller: 'LiveInterviewCtrl',
                params: { 
                    date: {
                      value: null,
                    },
                },
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('collaborators', {
                url: '/collaborators?includesSelf=true',
                templateUrl: '/templates/dashboard-collaborators.html',
                controller: 'CollaboratorsCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('dashboard-departments', {
                url: '/departments',
                templateUrl: '/templates/dashboard-departments.html',
                controller: 'DashboardDepartmentCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('dashboard-career', {
                url: '/dashboard-career',
                templateUrl: '/templates/dashboard-career.html',
                controller: 'DashboardCareerCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('dashboard-branding', {
                url: '/dashboard-branding',
                templateUrl: '/templates/dashboard-branding.html',
                controller: 'DashboardBrandingCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.permissionCheck({
                            "employerBranding.list": "view"
                        });
                    }],
                }
            })
            .state('dashboard-branding.new', {
                url: '/new',
                templateUrl: '/templates/dashboard-branding-edit.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('dashboard-branding.edit', {
                url: '/:brandingId/edit',
                templateUrl: '/templates/dashboard-branding-edit.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('dashboard-branding.info', {
                url: '/:brandingId/information',
                templateUrl: '/templates/dashboard-branding-information.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('dashboard-branding.customization', {
                url: '/:brandingId/customization',
                templateUrl: '/templates/dashboard-branding-customization.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('dashboard-branding.legal', {
                url: '/:brandingId/legal',
                templateUrl: '/templates/dashboard-branding-legal.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('dashboard-branding.career', {
                url: '/:brandingId/career',
                templateUrl: '/templates/dashboard-branding-career.html',
                controller: 'DashboardBrandingEditCtrl'
            })
            .state('analytics', {
                url: '/analytics',
                templateUrl: '/templates/analytics.html',
                controller: 'AnalyticsCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('tasks', {
                url: '/tasks',
                templateUrl: '/templates/dashboard-submission-tasks.html',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('account', {
                url: '/account',
                templateUrl: '/templates/dashboard-account.html',
                controller: 'AccountCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('account-select', {
                url: '/account-select',
                templateUrl: '/templates/dashboard-account-select.html',
                controller: 'AccountSelectCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('user-profile', {
                url: '/user-profile',
                templateUrl: '/templates/dashboard-user-profile.html',
                controller: 'DashboardUserProfileCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('user-profile.info', {
                url: '/personal-information',
                templateUrl: '/templates/dashboard-user-profile-info.html',
                controller: 'DashboardUserProfileInfoCtrl',
            })
            .state('user-profile.settings', {
                url: '/settings',
                templateUrl: '/templates/dashboard-user-profile-settings.html',
                controller: 'DashboardUserProfileSettingsCtrl',
            })
            .state('user-profile.security', {
                url: '/security',
                templateUrl: '/templates/dashboard-user-profile-security.html',
                controller: 'DashboardUserProfileSecurityCtrl',
            })
            .state('user-profile.tags', {
                url: '/tags',
                templateUrl: '/templates/dashboard-user-profile-tags.html',
                controller: 'DashboardUserProfileTagsCtrl',
            })
            .state('user-profile.campaignStatus', {
                url: '/campaign-status',
                templateUrl: '/templates/dashboard-user-profile-campaign-status.html',
                controller: 'DashboardUserProfileCampaignStatusCtrl',
            })
            .state('user-profile.scorecards', {
                url: '/scorecards',
                templateUrl: '/templates/dashboard-user-profile-scorecards.html',
                controller: 'DashboardUserProfileScorecardsCtrl',
            })
            .state('cc-settings', {
                url: '/editcampaign/:campaignId',
                templateUrl: '/templates/dashboard-cc-settings.html',
                controller: 'CampaignEditorCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('cc-documents', {
                url: '/editcampaign/:campaignId/documents',
                templateUrl: '/templates/dashboard-cc-documents.html',
                controller: 'CampaignEditorCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('cc-questions', {
                url: '/editcampaign/:campaignId/questions',
                templateUrl: '/templates/dashboard-cc-questions.html',
                controller: 'CampaignEditorCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('cc-automation', {
                url: '/editcampaign/:campaignId/automation',
                templateUrl: '/templates/dashboard-cc-automation.html',
                controller: 'CampaignEditorCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('cc-tests', {
                url: '/editcampaign/:campaignId/tests',
                templateUrl: '/templates/dashboard-cc-tests.html',
                controller: 'CampaignEditorCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('candidates', {
                url: '/campaign/:campaignId',
                templateUrl: '/templates/dashboard-candidates.html',
                controller: 'CandidatesCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('campaign-vonq-marketplace', {
                url: '/campaign/:campaignId/vonq-marketplace',
                templateUrl: '/templates/campaign-vonq-marketplace.html',
                controller: 'CampaignMarketplaceCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('campaign-vonq-checkout', {
                url: '/campaign/:campaignId/vonq-checkout',
                templateUrl: '/templates/campaign-vonq-checkout.html',
                controller: 'CampaignCheckoutCtrl',
                abstract: true,
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('campaign-vonq-checkout.campaign-vonq-checkout-1', {
                url: '-1',
                templateUrl: '/templates/campaign-vonq-checkout-1.html',
                controller: 'CampaignCheckoutStepCtrl',
                params: {
                    step: 1
                }
            })
            .state('campaign-vonq-checkout.campaign-vonq-checkout-2', {
                url: '-2',
                templateUrl: '/templates/campaign-vonq-checkout-2.html',
                controller: 'CampaignCheckoutStepCtrl',
                params: {
                    step: 2
                }
            })
            .state('campaign-vonq-thankyou', {
                url: '/campaign/:campaignId/vonq-thankyou',
                templateUrl: '/templates/campaign-vonq-thankyou.html',
                controller: 'CampaignThankyouCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('candidate-page', {
                url: '/candidate-page',
                templateUrl: '/templates/dashboard-candidate-page.html',
                controller: 'CandidatePageCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission', {
                url: '/candidates/:candidateId?firstVideoQuestion',
                templateUrl: '/templates/dashboard-submission.html',
                controller: 'SubmissionCtrl',
                params: {
                    markCandidateAsViewed: true,
                },
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-tests', {
                url: '/candidates/:candidateId/tests',
                templateUrl: '/templates/dashboard-submission-tests.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-documents', {
                url: '/candidates/:candidateId/documents',
                templateUrl: '/templates/dashboard-submission-documents.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-conversations', {
                url: '/candidates/:candidateId/conversations',
                templateUrl: '/templates/dashboard-submission-documents.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-notes', {
                url: '/candidates/:candidateId/notes',
                templateUrl: '/templates/dashboard-submission-notes.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-messages', {
                url: '/candidates/:candidateId/messages',
                templateUrl: '/templates/dashboard-submission-messages.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-candidatehistory', {
                url: '/candidates/:candidateId/candidatehistory',
                templateUrl: '/templates/dashboard-submission-candidatehistory.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-assessments', {
                url: '/candidates/:candidateId/assessments',
                templateUrl: '/templates/dashboard-submission-assessment.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-assessment-summary', {
                url: '/candidates/:candidateId/assessments/:assessmentId',
                templateUrl: '/templates/dashboard-submission-assessment-summary.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-assessment-result', {
                url: '/candidates/:candidateId/assessments/:assessmentId/answers',
                templateUrl: '/templates/dashboard-submission-assessment-result.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-assessfirst', {
                url: '/candidates/:candidateId/assessfirst',
                templateUrl: '/templates/dashboard-submission-assessfirst.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-tags', {
                url: '/candidates/:candidateId/tags',
                templateUrl: '/templates/dashboard-submission-tags.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-interview-notes', {
                url: '/candidates/:candidateId/interviews',
                templateUrl: '/templates/dashboard-interview-notes.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-rating-notes', {
                url: '/candidates/:candidateId/rating-notes',
                templateUrl: '/templates/dashboard-rating-notes.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            })
            .state('submission-tasks', {
                url: '/candidates/:candidateId/tasks',
                templateUrl: '/templates/dashboard-submission-tasks.html',
                controller: 'SubmissionCtrl',
                resolve: {
                    permission: ["authorizationService", function(authorizationService) {
                        return authorizationService.isAuthenticated();
                    }],
                }
            });


        $urlRouterProvider.otherwise('/');
    }]);
}

function loginApp() {

    app.run(["$rootScope", function($rootScope) {
        var id = getURLParameter('id');
        if (id) {
            var key = getURLParameter('auth');
            if (key) {
                $rootScope.userId = id;
                $rootScope.authKey = key;
            }
        }

        if (getURLParameter('suspended')) {
            $rootScope.accountSuspended = true;
        }

        if (getURLParameter('unauthorized')) {
            $rootScope.accountUnauthorized = true;
        }
        const adminEmail = getURLParameter('admin')
        if (adminEmail) {
            $rootScope.adminUserEmail = adminEmail;
        }
    }]);
}

function signupApp() {
    app.run(function() {
        hideLoader();
    });
}

function interviewApp(options) {

    app.run(["$rootScope", "$state", function($rootScope, $state) {

        $rootScope.sessionId = 0;
        $rootScope.candidateSpace = (options && options.candidateSpace);

        $rootScope.inWelcome = (options && options.inWelcome ? options.inWelcome : false);

        if ($rootScope.candidateSpace) {
            $rootScope.sessionId = getURLParameter('session');

            if (window.localStorage && window.localStorage.demoSessions && window.localStorage.demoSessions.indexOf($rootScope.sessionId) >= 0) {
                if ($rootScope.inWelcome) {
                    window.location.replace('/interview?session=' + $rootScope.sessionId);
                }
                $rootScope.sessionId = 0;
            }
        }

    }]).config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('interview-intro-video', {
                //url: '/interview',
                templateUrl: '/templates/interview-intro-video.html',
                controller: 'IIntroVideoCtrl'
            })

            .state('information', {
                //url: '/interview',
                templateUrl: '/templates/interview-information.html',
                controller: 'IInformationCtrl'
            })

            .state('pre-video-desktop', {
                templateUrl: '/templates/interview-pre-video.html',
                controller: 'IPreVideoDesktopCtrl'
            })

            .state('pre-app-mobile', {
                templateUrl: '/templates/interview-pre-app-mobile.html',
                controller: 'IPreAppMobileCtrl'
            })

            .state('video', {
                //url: '/interview',
                templateUrl: '/templates/interview-video.html',
                controller: 'IVideoCtrl'
            })

            .state('questions', {
                //url: '/interview',
                templateUrl: '/templates/interview-questions.html',
                controller: 'IVideoQuestionsCtrl'
            })

            .state('video_q', {
                //url: '/interview',
                templateUrl: '/templates/interview-video-questions.html',
                controller: 'IVideoQuestionsCtrl'
            })

            .state('video_e', {
                //url: '/interview',
                templateUrl: '/templates/interview-video-end.html',
                controller: 'IVideoEndCtrl'
            })

            .state('end', {
                //url: '/interview',
                templateUrl: '/templates/interview-end.html',
                controller: 'IEndCtrl'
            })

            .state('endAssessment', {
                //url: '/interview',
                templateUrl: '/templates/assessment-end.html',
            })

            .state('expired', {
                //url: '/interview',
                templateUrl: '/templates/interview-expired.html',
                controller: 'IEndCtrl'
            });
    }]);
}

function submissionApp() {
    app.run(["$rootScope", "Util", "privilegeFactory", function ($rootScope, Util, privilegeFactory) {

        $rootScope.userFullName = Util.userFullName;
        $rootScope.profilePhoto = Util.profilePhoto;

        $rootScope.submissionKey = getURLParameter('key');

        $rootScope.fns = {
            hasPrivileges: function(privileges, user) {
                return privilegeFactory.hasPrivileges(privileges, user ? user : $rootScope.user)
            },
            hasUiSettings: function(privileges, user) {
                return privilegeFactory.hasUiSettings(privileges, user ? user : $rootScope.user)
            },
            hasCampaignsSettings: function(settings, user, defaultValue) {
                return privilegeFactory.hasCampaignsSettings(settings, user ? user : $rootScope.user, defaultValue)
            },
            hasCollaboratorsSettings: function(settings, user) {
                return privilegeFactory.hasCollaboratorsSettings(settings, user ? user : $rootScope.user)
            },
            hasCandidatesSettings: function(settings, user) {
                return privilegeFactory.hasCandidatesSettings(settings, user ? user : $rootScope.user)
            },
            userHasRights: function(userRightName, requiredLevel, user) {
                return privilegeFactory.userHasRights(userRightName, requiredLevel, user ? user : $rootScope.user)
            }
        };

        // duplicate code ref validateUserAccess
        $rootScope.validateUserAccess = function (callback) {
            if (!$rootScope.user) {
                $rootScope.$on('onCurrentUser', (_, user) => {
                    callback(user, true);
                })
            } else {
                callback($rootScope.user, false);
            }
        }

    }]).config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('submission', {
                url: '/',
                templateUrl: '/templates/dashboard-submission.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-tests', {
                url: '/tests',
                templateUrl: '/templates/dashboard-submission-tests.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-documents', {
                url: '/documents',
                templateUrl: '/templates/dashboard-submission-documents.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-notes', {
                url: '/notes',
                templateUrl: '/templates/dashboard-submission-notes.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-tags', {
                url: '/tags',
                templateUrl: '/templates/dashboard-submission-tags.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-assessment', {
                url: '/assessment',
                templateUrl: '/templates/dashboard-submission-assessment.html',
                controller: 'SubmissionCtrl'
            })
            .state('submission-candidatehistory', {
                url: '/candidatehistory',
                templateUrl: '/templates/dashboard-submission-candidatehistory.html',
                controller: 'SubmissionCtrl'
            });


        $urlRouterProvider.otherwise('/');
    }]);
}

function liveInterviewApp() {
    app.run(["$rootScope", function ($rootScope) {
        $rootScope.liveInterview = [getURLParameter('id'), getURLParameter('id2'), getURLParameter('id3')];
    }]).config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('information', {
                //url: '/interview',
                templateUrl: '/templates/interview-information.html',
                controller: 'IInformationCtrl'
            })
            .state('waiting-room', {
                templateUrl: '/templates/interview-live-waiting-room.html',
                controller: 'IWaitingRoomCtrl'
            })
            .state('session', {
                templateUrl: '/templates/interview-live-session.html',
                controller: 'ISessionCtrl'
            })
            .state('rejected', {
                templateUrl: '/templates/interview-live-rejected.html',
                controller: 'IRejectedCtrl'
            })
            .state('end', {
                templateUrl: '/templates/interview-end.html',
                controller: 'IEndCtrl'
            });

        $urlRouterProvider.otherwise('/');
    }]);
}

function candidateProfileApp() {
    app
    .run(["$rootScope", "$state", "Util", function ($rootScope, $state, Util) {
        $rootScope.fns = {};
        $rootScope.fns.realCandidatesFilter = Util.realCandidatesFilter;
        hideLoader();
    }])
    .config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
        .state('insert-email', {
            templateUrl: '/templates/candidate-profile-email-input.html',
            controller: 'CandidateProfileEmailInputCtrl'
        })
        .state('login', {
            templateUrl: '/templates/candidate-profile-login.html',
            controller: 'CandidateProfileLoginCtrl'
        })
        .state('forgot-password', {
            templateUrl: '/templates/candidate-profile-forgot-password.html',
            controller: 'CandidateProfileLoginCtrl'
        })
        .state('reset-password', {
            templateUrl: '/templates/candidate-profile-reset-password.html',
            controller: 'CandidateProfileLoginCtrl'
        })
        .state('email-thankyou', {
            templateUrl: '/templates/candidate-profile-email-thank-you.html',
            controller: 'CandidateProfileEmailThankYouCtrl'
        })
        .state('info', {
            templateUrl: '/templates/candidate-profile-info.html',
            controller: 'CandidateProfileInfoCtrl'
        })
        .state('edit-info', {
            templateUrl: '/templates/candidate-profile-edit-info.html',
            controller: 'CandidateProfileEditInfoCtrl'
        });
    }]);
}