//@ts-check
angular.module('app').controller('CampaignCheckoutCtrl', ["$state", "$scope", "$rootScope", "$stateParams", "ToasterService", "Server", "Util", "PopupService", "Translate", "overlaySpinner", function ($state, $scope, $rootScope, $stateParams, ToasterService, Server, Util, PopupService, Translate, overlaySpinner) {
  setPageTitle();
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  $scope.campaignId = $stateParams.campaignId;
  $scope.vonqFields = {
      yearsOfExperience: undefined,
      weeklyWorkingHours: {
          from: undefined,
          to: undefined,
      },
      salaryIndication: {
          range: {
              from: undefined,
              to: undefined,
          },
      },
      remoteWork: false,
      educationLevel: [],
      seniority: [],
      industry: [],
      jobCategory: [],
      contactPhoneNumber: undefined,
  };
  $scope.errorFields = {};

  const marketplaceState = 'campaign-vonq-marketplace';
  const checkoutStep1State = 'campaign-vonq-checkout-1';
  const checkoutStep2State = 'campaign-vonq-checkout-2';

  $scope.checkEmpty = function() {
      if (!$scope.vonqCart || $scope.vonqCart.isEmpty) {
          $state.go(marketplaceState, { campaignId: $stateParams.campaignId });
      }
  }

  $scope.checkVonqFields = function(vonqFields) {
      const errorFields = {};
      if (!(vonqFields.yearsOfExperience >= 0)) {
          errorFields['yearsOfExperience'] = true;
      }
      if (!(vonqFields.weeklyWorkingHours.to > 0)) {
          errorFields['weeklyWorkingHoursTo'] = true;
      } else if (vonqFields.weeklyWorkingHours.from !== undefined && vonqFields.weeklyWorkingHours.from !== null) {
          if (!(vonqFields.weeklyWorkingHours.from >= 0)) {
              errorFields['weeklyWorkingHoursFrom'] = true;
          } else if (vonqFields.weeklyWorkingHours.from > vonqFields.weeklyWorkingHours.to) {
              errorFields['weeklyWorkingHoursTo'] = Translate.getLangString('invalid_range_limits');
          }
      }
      if (!(vonqFields.salaryIndication.range.to >= 0)) {
          errorFields['salaryIndicationTo'] = true;
      } else if (vonqFields.salaryIndication.range.from !== undefined && vonqFields.salaryIndication.range.from !== null) {
          if (!(vonqFields.salaryIndication.range.from >= 0)) {
              errorFields['salaryIndicationFrom'] = true;
          }
          else if (vonqFields.salaryIndication.range.from > vonqFields.salaryIndication.range.to) {
              errorFields['salaryIndicationTo'] = Translate.getLangString('invalid_range_limits');
          }
      }
      if (!vonqFields.educationLevel || vonqFields.educationLevel.length <= 0) {
          errorFields['educationLevel'] = true;
      }
      if (!vonqFields.seniority || vonqFields.seniority.length <= 0) {
          errorFields['seniority'] = true;
      }
      if (!vonqFields.industry || vonqFields.industry.length <= 0) {
          errorFields['industry'] = true;
      }
      if (!vonqFields.jobCategory || vonqFields.jobCategory.length <= 0) {
          errorFields['jobCategory'] = true;
      }
      
      return errorFields;
  }

  $scope.checkCampaignFields = function() {
      const errorFields = {};
      if (!$scope.user.name) {
          errorFields['recruiter'] = true;
          errorFields['userName'] = true;
      }
      if (!$scope.user.email) {
          errorFields['recruiter'] = true;
          errorFields['email'] = true;
      }
      if (!$scope.ebCompanyName) {
          errorFields['company'] = true;
          errorFields['companyName'] = Translate.getLangString('company_name_required');
      }
      if (!$scope.ebCompanyLogo) {
          errorFields['branding'] = true;
          errorFields['brandingLogo'] = Translate.getLangString('company_logo_required');
      }
      if (!$scope.campaign || !$scope.campaign.fullDescription[$scope.campaign.language] || Util.countWords($scope.campaign.fullDescription[$scope.campaign.language]) < 50) {
          errorFields['campaign'] = true;
          errorFields['campaignDescription'] = Translate.getLangString('job_description_limit');
      }
      if (!$scope.campaign || !$scope.campaign.details || !$scope.campaign.details.contract || !$scope.campaign.details.contract.type) {
          errorFields['campaign'] = true;
          errorFields['contractType'] = Translate.getLangString('no_campaign_contract_type');
      }
      if (!$scope.campaign.location || !$scope.campaign.location.name) {
          errorFields['campaign'] = true;
          errorFields['locationName'] = Translate.getLangString('campaign_location_name_required');
      }
      if (!$scope.campaign.location || !$scope.campaign.location.city) {
          errorFields['campaign'] = true;
          errorFields['locationCity'] = Translate.getLangString('campaign_location_city_required');
      }
      if (!$scope.campaign.location || !$scope.campaign.location.country) {
          errorFields['campaign'] = true;
          errorFields['locationCountry'] = Translate.getLangString('campaign_location_country_required');
      }
      if (!$scope.campaign.location || !$scope.campaign.location.zip) {
          errorFields['campaign'] = true;
          errorFields['locationZip'] = Translate.getLangString('campaign_location_zip_required');
      }

      return errorFields;
  }

  $scope.nextStep = function(campaign) {
      overlaySpinner.show('checkout-step');
      if (campaign) {
          $scope.campaign = campaign;
      }
      switch ($state.current.params.step) {
          case 1:
              $scope.continueFromStep1();
              break;
          case 2:
              $scope.continueFromStep2();
              break;
      }
  }
  $scope.previousStep = function(campaign) {
      overlaySpinner.show('checkout-step');
      if (campaign) {
          $scope.campaign = campaign;
      }
      switch ($state.current.params.step) {
          case 1:
              $state.go(marketplaceState, { campaignId: $stateParams.campaignId });
              break;
          case 2:
              $state.go('^.' + checkoutStep1State, { campaign: $scope.campaign});
              break;
      }
      overlaySpinner.hide('checkout-step');
  }

  function mapVonqItem(item) {
      return {
          vonqId: item.id.toString(),
          description: item.name,
      };
  }

  $scope.continueFromStep1 = function() {
      $scope.errorFields = $scope.checkVonqFields($scope.vonqFields);

      if (!_.isEmpty($scope.errorFields)) {
          ToasterService.validationFailure($scope.errorFields)
          overlaySpinner.hide('checkout-step');
          return;
      }

      overlaySpinner.hide('checkout-step');

      $scope.vonqFields.salaryIndication.period = 'monthly';
      $scope.vonqFields.salaryIndication.range.currency = 'EUR';
      
      $state.go('^.' + checkoutStep2State, { campaign: $scope.campaign });
  }

  $scope.continueFromStep2 = function() {
      $scope.errorFields = $scope.checkCampaignFields();
      overlaySpinner.hide('checkout-step');
      if (!_.isEmpty($scope.errorFields)) {
          ToasterService.validationFailure($scope.errorFields);
          return;
      }

      const confirmPost = () => {
        const overlay = overlaySpinner.show('checkout-step');
        Server.post('campaigns/' + $stateParams.campaignId + '/vonq-integration/campaigns', {
            currency: $scope.vonqCart.currency,
            postingDetails: {
                workingLocation: {
                    allowsRemoteWork: $scope.vonqFields.remoteWork ? 1 : 0,
                },
                contactInfo: {
                    phoneNumber: $scope.vonqFields.contactPhoneNumber,
                },
                yearsOfExperience: $scope.vonqFields.yearsOfExperience,
                weeklyWorkingHours: $scope.vonqFields.weeklyWorkingHours,
                salaryIndication: {
                    ...$scope.vonqFields.salaryIndication,
                },
            },
            targetGroup: {
                industry: $scope.vonqFields.industry.map(mapVonqItem),
                educationLevel: $scope.vonqFields.educationLevel.map(mapVonqItem),
                seniority: $scope.vonqFields.seniority.map(mapVonqItem),
                jobCategory: $scope.vonqFields.jobCategory.map(mapVonqItem),
            }
        }).then((res) => {
            overlay.hide();

            PopupService.openStripeCheckoutPopup($scope, {
                clientSecret: res.clientSecret,
                returnUrl: window.location.origin + '/dashboard#!/campaign/' + $stateParams.campaignId + '/vonq-thankyou',
                price: { amount: $scope.vonqCart.getCartTotalWithVat, currency: $scope.vonqCart.currency, currencySymbol: Util.getCurrencySymbol($scope.vonqCart.currency) },
                campaignId: $stateParams.campaignId,
                orderId: res.vonqOrder._id,
            });
        })
        .catch((err) => {
            overlay.hide();
            let count = 0;
            if (err.data && err.data.errors && Object.keys(err.data.errors).length > 0) {
                for (let errKey of Object.keys(err.data.errors)) {
                    ToasterService.failureMessage(err.data.errors[errKey]);
                    if (++count > 3) {
                        break;
                    }
                }
            } else {
                ToasterService.failure(err, 'failed_to_save_vonq_campaign');
            }
        });
    }

    PopupService.openGenericPopup($scope, {
        submit: function () {
            $scope.modalHandle.close();
            confirmPost();
        },
        title: Translate.getLangString('confirm_campaign_post_title'),
        messageText: Translate.getLangString('confirm_campaign_post_text', null, [$scope.campaign.title[$scope.campaign.language]]),
        yesText: Translate.getLangString('confirm_campaign_post_yes'),
        noText: Translate.getLangString('confirm_campaign_post_no'),
        friendlyAlert: true,
    }, 'templates/modal-confirm-warning.html', {});
  }

  $scope.getCampaign = function(force) {
      const mapCampaignFields = (campaign) => {
          if (campaign.details && campaign.details.contract && campaign.details.contract.type)
          campaign.contractTypeName = Translate.getLangString(campaign.details.contract.type);
      };
      if (!force && $rootScope.campaign && $rootScope.campaign._id === $stateParams.campaignId) {
          $scope.campaign = $rootScope.campaign;
          setPageTitle($rootScope.campaign.title[$rootScope.campaign.language]);
          mapCampaignFields($rootScope.campaign);
          return $rootScope.campaign;
      } else {
          overlaySpinner.show('checkout-step');
          return Server.get('campaigns/' + $stateParams.campaignId + '?sessions=true&loadJobPostingPublicationStatus=eager')
              .then((campaign) => {
                  overlaySpinner.hide('checkout-step');
                  $rootScope.campaign = $scope.campaign = campaign;
                  setPageTitle($rootScope.campaign.title[$rootScope.campaign.language]);
                  mapCampaignFields($rootScope.campaign);
                  return $scope.campaign;
              })
              .catch((err) => {
                  overlaySpinner.hide('checkout-step');
                  ToasterService.failure(err, 'campaign_load_error');
              });
      }
  }

  function setPageTitle(campaignName) {
      const tabTitle = Translate.getLangString('job_board_marketplace')
    if (campaignName) {
        const navTitle = `${campaignName}: ${tabTitle}`;
        Util.setPageTitle(tabTitle, campaignName, navTitle);
    } else if (!$rootScope.pageTitle) {
        Util.setPageTitle(tabTitle);
    }
  }
}])
